export const basePageInfo = {
  tableSingleName: "Lead",
  tablePluralName: "Leads",
  tablePerm: "Web",
  tableSysName: "LeadContacts",
  reducerDataName: "leads",
  tableInclude: [],
  getDataFrom: []
};

const dataTitle = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

const dataVisualNecesaryView = [
  {
    dataName: "nombre",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "email",
    dataLabel: "Email",
    dataType: "string"
  },
  {
    dataName: "phone",
    dataLabel: "Telefono",
    dataType: "string"
  },
  {
    dataName: "subject",
    dataLabel: "Asunto",
    dataType: "string"
  },
  {
    dataName: "mensaje",
    dataLabel: "Mensaje",
    dataType: "string"
  },
  {
    dataName: "datetime",
    dataLabel: "Registro",
    dataType: "date"
  }
];

const dataVisualNecesary = [...dataVisualNecesaryView];

export const tableCreateItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesary]
};

export const tableEditItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesary]
};

export const tableViewItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesaryView]
};

export const tableData = [...dataVisualNecesaryView];

export const filterTable = [
  {
    dataName: "nombre",
    dataLabel: "Nombre",
    dataType: "string",
    dataFilterType: "like"
  },
  {
    dataName: "email",
    dataLabel: "Email",
    dataType: "string",
    dataFilterType: "like"
  },
  {
    dataName: "subject",
    dataLabel: "Asunto",
    dataType: "string",
    dataFilterType: "like"
  },
  {
    dataName: "datetime",
    dataLabel: "Registro",
    dataType: "date",
    dataFilterType: "like"
  }
];
