import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Dropdown, Grid, Icon } from "semantic-ui-react";
import { dataCreateData } from "../../TablesData/sectionDataTable";
import ModularText from "../../components/ModularText";
import { openFrontLoading } from "../../redux/actions";
import {
  renderTopName,
  renderLabel,
  renderModifyData,
  ImagesRes
} from "../../scripts/System";
import {
  adminSeparation,
  colors,
  styleCenteredObject,
  textSizeHeaderSubTitle,
  textSizeSubHeaderTitle
} from "../../style";
import {
  systemLog,
  setURLType,
  setBaseFileNameDataCheck
} from "../../scripts/General";
import { isFirstDayOfMonth } from "date-fns/esm";
import { BasedataImageNameSet } from "../../MainInfoData";

const saveContent = "SE ESTA CREANDO EL ELEMENTO";
const saveContentC = "¡SE CREO EL ELEMENTO";
const saveContentB = "HUBO UN ERROR AL CREAR EL NUEVO ELEMENTO";

const privateDataPrivateName = {
  dataLabel: "Nombre",
  dataType: "string",
  dataName: "name",
  dataInsideSize: "100%"
};
const privateDataPublicName = {
  dataLabel: "Nombre Publico",
  dataType: "string",
  dataName: "publicName",
  dataInsideSize: "100%"
};
const privateDataPublicNumber = {
  dataLabel: "Orden",
  dataType: "number",
  dataName: "orden",
  dataInsideSize: "100%"
};

const dataPrivateName = {
  dataLabel: "Nombre",
  dataType: "string",
  dataName: "name",
  dataInsideSize: "100%"
};
const dataPublicName = {
  dataLabel: "Nombre Publico",
  dataType: "string",
  dataName: "publicName",
  dataInsideSize: "100%"
};

const dataBase = {
  dataLabel: "Es Base",
  dataType: "bool",
  dataName: "isBase",
  dataInsideSize: "100%"
};

const dataValue = {
  dataLabel: "Orden",
  dataType: "string",
  dataName: "value",
  dataInsideSize: "100%"
};

const dataText = {
  dataLabel: "Contenido",
  dataType: "string",
  dataName: "content",
  mainDataName: "content"
};

const dataLink = {
  dataLabel: "Link",
  dataType: "string",
  dataName: "link",
  mainDataName: "link"
};

const dataImage = {
  dataLabel: "Imagen",
  dataType: "image",
  dataName: "b64",
  mainDataName: "b64"
};

const dataTint = {
  dataLabel: "Tinta",
  dataType: "color",
  dataName: "tintColor",
  mainDataName: "tintColor"
};

const dataRichText = {
  dataLabel: "Texto Rico",
  dataType: "richText",
  dataName: "content",
  mainDataName: "content"
};

const dataFile = {
  dataLabel: "Archivo",
  dataType: "file",
  dataName: "file",
  mainDataName: "file"
};

const separationTables = "0.5rem";
const columnSize = "3rem";
const columnStyle = {
  ...styleCenteredObject,
  width: "100%",
  margin: 0,
  padding: 0,
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: columnSize,
  marginLeft: "0.5rem"
};

const rowStyle = {
  ...styleCenteredObject,
  width: "100%",
  margin: 0,
  padding: 0,
  marginTop: "1rem",
  marginBottom: "0.6rem",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "flex-start",
  flexDirection: "row",
  minHeight: "0.1rem",
  marginLeft: "0.5rem"
};

class EditContent extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    let tempLanguage = "";
    let tempLanguageId = "";
    if (this.props.languages.length > 0) {
      tempLanguageId = this.props.languages[0].value;
      tempLanguage = this.props.languages[0].data;
    }
    this.state = {
      currentLanguage: tempLanguage,
      currentLanguageId: tempLanguageId
    };
    this.currentTableToChange = JSON.parse(JSON.stringify(this.props.table));
    this.renderTopExtraInfo = this.renderTopExtraInfo.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.renderValueColumn = this.renderValueColumn.bind(this);
    this.renderSectionMainInfo = this.renderSectionMainInfo.bind(this);
    this.renderCreateData = this.renderCreateData.bind(this);
    this.renderEditPrivateData = this.renderEditPrivateData.bind(this);
    this.renderInsideData = this.renderInsideData.bind(this);
    this.renderSingleCreateData = this.renderSingleCreateData.bind(this);
    this.renderCreateElements = this.renderCreateElements.bind(this);

    this.uploadDataContentText = this.uploadDataContentText.bind(this);
    this.uploadDataContentLink = this.uploadDataContentLink.bind(this);
    this.uploadDataContentRichText = this.uploadDataContentRichText.bind(this);
    this.uploadDataContentImages = this.uploadDataContentImages.bind(this);
    this.uploadDataContentFiles = this.uploadDataContentFiles.bind(this);
    this.checkDidUploadAll = this.checkDidUploadAll.bind(this);

    this.currentDataContentText = [];
    this.currentDataContentImage = [];
    this.currentDataContentLink = [];
    this.currentDataContentRichText = [];
    this.currentDataContentFile = [];
    this.totalDataContent = [];
    this.deleteContent = [];

    this.createDataContentText = [];
    this.createDataContentImage = [];
    this.createDataContentLink = [];
    this.createDataContentRichText = [];
    this.createDataContentFile = [];

    this.dirToModify = this.props.tableSysName;
    systemLog(this, this.currentTableToChange);
  }

  componentWillMount() {
    this.props.API.GetContentSection(this.currentTableToChange.id).then(
      response => {
        this.totalDataContent = [];
        this.currentDataContentText = response.dataContentTexts;
        this.currentDataContentImage = response.dataContentImages;
        this.currentDataContentLink = response.dataContentLinks;
        this.currentDataContentRichText = response.dataContentRichTexts;
        this.currentDataContentFile = response.dataContentFiles;

        for (let i = 0; i < this.currentDataContentText.length; i++) {
          this.currentDataContentText[i].typeContent = "text";
          this.totalDataContent.push(this.currentDataContentText[i]);
        }
        for (let i = 0; i < this.currentDataContentImage.length; i++) {
          this.currentDataContentImage[i].typeContent = "image";
          for (let l = 0; l < this.props.languages.length; l++) {
            const currentLanguage = this.props.languages[l];
            this.currentDataContentImage[i][
              "originalB64" + currentLanguage.data
            ] = this.currentDataContentImage[i]["b64" + currentLanguage.data];
          }
          this.totalDataContent.push(this.currentDataContentImage[i]);
        }
        for (let i = 0; i < this.currentDataContentLink.length; i++) {
          this.currentDataContentLink[i].typeContent = "link";
          this.totalDataContent.push(this.currentDataContentLink[i]);
        }
        for (let i = 0; i < this.currentDataContentRichText.length; i++) {
          this.currentDataContentRichText[i].typeContent = "richtext";
          this.totalDataContent.push(this.currentDataContentRichText[i]);
        }
        for (let i = 0; i < this.currentDataContentFile.length; i++) {
          this.currentDataContentFile[i].typeContent = "file";
          for (let l = 0; l < this.props.languages.length; l++) {
            const currentLanguage = this.props.languages[l];
            this.currentDataContentFile[i][
              "originalFile" + currentLanguage.data
            ] = this.currentDataContentFile[i]["file" + currentLanguage.data];
          }
          this.totalDataContent.push(this.currentDataContentFile[i]);
        }

        this.totalDataContent.sort((a, b) => {
          return a.orden - b.orden;
        });
        this.forceUpdate();
      }
    );
  }

  componentDidMount() {
    if (this.props.languages.length > 0) {
      let tempLanguage = "";
      let tempLanguageId = "";
      tempLanguageId = this.props.languages[0].value;
      tempLanguage = this.props.languages[0].data;
      this.setState({
        currentLanguage: tempLanguage,
        currentLanguageId: tempLanguageId
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (nextProps.languages.length > 0) {
        let tempLanguage = "";
        let tempLanguageId = "";
        tempLanguageId = nextProps.languages[0].value;
        tempLanguage = nextProps.languages[0].data;
        this.setState({
          currentLanguage: tempLanguage,
          currentLanguageId: tempLanguageId
        });
      }
    }
  }

  saveItem() {
    const currentDataContentText = [];
    const currentDataContentImage = [];
    const currentDataContentLink = [];
    const currentDataContentRichText = [];
    const currentDataContentFile = [];

    for (let i = 0; i < this.currentDataContentText.length; i++) {
      const dataCheck = this.currentDataContentText[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["content" + currentLanguage.data] =
          dataCheck["content" + currentLanguage.data];

        if (newData["content" + currentLanguage.data] === undefined) {
          newData["content" + currentLanguage.data] = "";
        }
      }
      currentDataContentText.push(newData);
    }

    for (let i = 0; i < this.currentDataContentImage.length; i++) {
      const dataCheck = this.currentDataContentImage[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["b64" + currentLanguage.data] =
          dataCheck["b64" + currentLanguage.data];
        newData["originalB64" + currentLanguage.data] =
          dataCheck["originalB64" + currentLanguage.data];
        newData["tintColor" + currentLanguage.data] =
          dataCheck["tintColor" + currentLanguage.data];

        if (newData["tintColor" + currentLanguage.data] === undefined) {
          newData["tintColor" + currentLanguage.data] =
            "rgba(255, 255, 255, 1)";
        }
        if (newData["b64" + currentLanguage.data] === undefined) {
          newData["b64" + currentLanguage.data] = {};
        }
      }
      currentDataContentImage.push(newData);
    }

    for (let i = 0; i < this.currentDataContentLink.length; i++) {
      const dataCheck = this.currentDataContentLink[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["link" + currentLanguage.data] =
          dataCheck["link" + currentLanguage.data];

        if (newData["link" + currentLanguage.data] === undefined) {
          newData["link" + currentLanguage.data] = "";
        }
      }
      currentDataContentLink.push(newData);
    }

    for (let i = 0; i < this.currentDataContentRichText.length; i++) {
      const dataCheck = this.currentDataContentRichText[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["content" + currentLanguage.data] =
          dataCheck["content" + currentLanguage.data];

        if (newData["content" + currentLanguage.data] === undefined) {
          newData["content" + currentLanguage.data] = "";
        }
      }
      currentDataContentRichText.push(newData);
    }

    for (let i = 0; i < this.currentDataContentFile.length; i++) {
      const dataCheck = this.currentDataContentFile[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["file" + currentLanguage.data] =
          dataCheck["file" + currentLanguage.data];
        newData["originalFile" + currentLanguage.data] =
          dataCheck["originalFile" + currentLanguage.data];

        if (newData["file" + currentLanguage.data] === undefined) {
          newData["file" + currentLanguage.data] = {};
        }
      }
      currentDataContentFile.push(newData);
    }
    let savedContent = saveContentC + "!";
    const toastSave = toast.info(saveContent, {
      autoClose: false
    });

    let finalObjectSave = { ...this.currentTableToChange };
    if (
      this.props.tableParentIdConnect !== undefined &&
      this.props.tableParentId
    ) {
      finalObjectSave[
        this.props.tableParentIdConnect
      ] = this.props.tableParentId;
    }

    if (
      finalObjectSave.baseName !== undefined &&
      finalObjectSave.value !== undefined
    ) {
      finalObjectSave.name = finalObjectSave.baseName + finalObjectSave.value;
    }

    systemLog(this, finalObjectSave);
    systemLog(this, currentDataContentImage);
    systemLog(this, this.createDataContentImage);
    this.props.API.PatchTo(
      this.dirToModify,
      finalObjectSave.id,
      finalObjectSave
    )
      .then(response => {
        systemLog(this, response);
        let thereIsError = false;
        if (response.error !== undefined) {
          savedContent = saveContentB;
          thereIsError = true;
        }
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: thereIsError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
          });
        } else {
          if (thereIsError) {
            toast.error(savedContent);
          } else {
            toast.success(savedContent);
          }
        }
        if (thereIsError === false) {
          for (let i = 0; i < this.deleteContent.length; i++) {
            const dataCheck = this.deleteContent[i];
            systemLog("DeleteFile", dataCheck);
            let urlDelete = "";
            if (dataCheck.typeContent === "text") {
              urlDelete = "DataContentTexts";
            } else if (dataCheck.typeContent === "image") {
              urlDelete = "DataContentImages";
              if (dataCheck.b64 !== undefined) {
                if (dataCheck.b64.fileName !== undefined) {
                  this.props.API.DeleteFile(dataCheck.b64.fileName);
                }
              }
            } else if (dataCheck.typeContent === "link") {
              urlDelete = "DataContentLinks";
            } else if (dataCheck.typeContent === "richtext") {
              urlDelete = "DataContentRichTexts";
            } else if (dataCheck.typeContent === "file") {
              urlDelete = "DataContentFiles";
              if (dataCheck.b64 !== undefined) {
                if (dataCheck.b64.fileName !== undefined) {
                  this.props.API.DeleteFile(dataCheck.b64.fileName);
                }
              }
            }
            if (urlDelete !== "") {
              this.props.API.DeleteThisFrom(urlDelete, dataCheck.id).then(
                deleteRes => {
                  systemLog("DeleteDataContent", deleteRes);
                }
              );
            }
          }
          for (let i = 0; i < this.createDataContentText.length; i++) {
            const dataCheck = this.createDataContentText[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentTexts", dataCheck).then(
              createRes => {
                systemLog(this, createRes);
              }
            );
          }
          for (let i = 0; i < this.createDataContentImage.length; i++) {
            const dataCheck = this.createDataContentImage[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentImages", dataCheck).then(
              createRes => {
                systemLog(this, createRes);
              }
            );
          }
          for (let i = 0; i < this.createDataContentLink.length; i++) {
            const dataCheck = this.createDataContentLink[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentLinks", dataCheck).then(
              createRes => {
                systemLog(this, createRes);
              }
            );
          }
          for (let i = 0; i < this.createDataContentRichText.length; i++) {
            const dataCheck = this.createDataContentRichText[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentRichTexts", dataCheck).then(
              createRes => {
                systemLog(this, createRes);
              }
            );
          }

          for (let i = 0; i < this.createDataContentFile.length; i++) {
            const dataCheck = this.createDataContentFile[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentFiles", dataCheck).then(
              createRes => {
                systemLog(this, createRes);
              }
            );
          }

          this.props.openFrontLoading(true);
          this.uploadDataContentText(
            response,
            currentDataContentText,
            currentDataContentLink,
            currentDataContentRichText,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      })
      .catch(error => {
        systemLog(this, error);
        savedContent = saveContentB;
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: toast.TYPE.ERROR
          });
        } else {
          toast.error(savedContent);
        }
      });
  }

  uploadDataContentText(
    contentSectionRes,
    currentDataContentText,
    currentDataContentLink,
    currentDataContentRichText,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentText");
    if (currentDataContentText.length === 0) {
      this.uploadDataContentLink(
        contentSectionRes,
        currentDataContentLink,
        currentDataContentRichText,
        currentDataContentImage,
        currentDataContentFile
      );
    }

    let countUpload = 0;
    for (let i = 0; i < currentDataContentText.length; i++) {
      const dataCheck = currentDataContentText[i];

      dataCheck["contentSectionId"] = contentSectionRes.id;
      if (dataCheck.id !== undefined) {
        this.props.API.PatchTo(
          "DataContentTexts",
          dataCheck.id,
          dataCheck
        ).then(response => {
          countUpload = countUpload + 1;
          if (countUpload >= currentDataContentText.length) {
            this.uploadDataContentLink(
              contentSectionRes,
              currentDataContentLink,
              currentDataContentRichText,
              currentDataContentImage,
              currentDataContentFile
            );
          }
        });
      } else {
        countUpload = countUpload + 1;
        if (countUpload >= currentDataContentText.length) {
          this.uploadDataContentLink(
            contentSectionRes,
            currentDataContentLink,
            currentDataContentRichText,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      }
    }
  }

  uploadDataContentLink(
    contentSectionRes,
    currentDataContentLink,
    currentDataContentRichText,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentLink");
    if (currentDataContentLink.length === 0) {
      this.uploadDataContentRichText(
        contentSectionRes,
        currentDataContentRichText,
        currentDataContentImage,
        currentDataContentFile
      );
    }

    let countUpload = 0;
    for (let i = 0; i < currentDataContentLink.length; i++) {
      const dataCheck = currentDataContentLink[i];

      dataCheck["contentSectionId"] = contentSectionRes.id;
      if (dataCheck.id !== undefined) {
        this.props.API.PatchTo(
          "DataContentLinks",
          dataCheck.id,
          dataCheck
        ).then(response => {
          countUpload = countUpload + 1;
          if (countUpload >= currentDataContentLink.length) {
            this.uploadDataContentRichText(
              contentSectionRes,
              currentDataContentRichText,
              currentDataContentImage,
              currentDataContentFile
            );
          }
        });
      } else {
        countUpload = countUpload + 1;
        if (countUpload >= currentDataContentLink.length) {
          this.uploadDataContentRichText(
            contentSectionRes,
            currentDataContentRichText,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      }
    }
  }

  uploadDataContentRichText(
    contentSectionRes,
    currentDataContentRichText,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentRichText");
    if (currentDataContentRichText.length === 0) {
      this.uploadDataContentImages(
        contentSectionRes,
        currentDataContentImage,
        currentDataContentFile
      );
    }

    let countUpload = 0;
    for (let i = 0; i < currentDataContentRichText.length; i++) {
      const dataCheck = currentDataContentRichText[i];

      dataCheck["contentSectionId"] = contentSectionRes.id;
      systemLog("CreateBaseContent", dataCheck);
      if (dataCheck.id !== undefined) {
        this.props.API.PatchTo(
          "DataContentRichTexts",
          dataCheck.id,
          dataCheck
        ).then(response => {
          countUpload = countUpload + 1;
          if (countUpload >= currentDataContentRichText.length) {
            this.uploadDataContentImages(
              contentSectionRes,
              currentDataContentImage,
              currentDataContentFile
            );
          }
        });
      } else {
        countUpload = countUpload + 1;
        if (countUpload >= currentDataContentRichText.length) {
          this.uploadDataContentImages(
            contentSectionRes,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      }
    }
  }

  uploadDataContentImages(
    contentSectionRes,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentImages");
    systemLog("UPLOAD_CHECK", currentDataContentImage);
    if (currentDataContentImage.length === 0) {
      this.uploadDataContentFiles(contentSectionRes, currentDataContentFile);
    } else {
      this.doUploadImages(
        contentSectionRes,
        currentDataContentImage,
        "DataContentImages",
        "b64",
        0,
        0,
        0,
        "originalB64",
        () => {
          this.uploadDataContentFiles(
            contentSectionRes,
            currentDataContentFile
          );
        }
      );
    }
  }

  uploadDataContentFiles(contentSectionRes, currentDataContentFile) {
    systemLog("UploadTest", "uploadDataContentFiles");
    if (currentDataContentFile.length === 0) {
      this.checkDidUploadAll();
    } else {
      this.doUploadFiles(
        contentSectionRes,
        currentDataContentFile,
        "DataContentFiles",
        "file",
        0,
        0,
        "originalFile",
        () => {
          this.checkDidUploadAll();
        }
      );
    }
  }

  doUploadImages(
    contentSectionRes,
    currentDataUpload,
    uploadTo,
    dataName,
    curretnIndex,
    currentIndexLanguage,
    currentIndexImages,
    originalCheck,
    callBack
  ) {
    this.checkUploadingImages(
      currentDataUpload,
      curretnIndex,
      currentIndexLanguage,
      currentIndexImages,
      dataName,
      originalCheck,
      (dataCheck, returnIndex) => {
        dataCheck["contentSectionId"] = contentSectionRes.id;
        if (dataCheck.id !== undefined) {
          const nextIndexImages = currentIndexImages + 1;
          if (nextIndexImages >= ImagesRes.length) {
            const nextLanguageIndex = currentIndexLanguage + 1;
            if (nextLanguageIndex >= this.props.languages.length) {
              systemLog("UploadTestImages", dataCheck);
              this.props.API.PatchTo(uploadTo, dataCheck.id, dataCheck).then(
                response => {
                  const nextIndex = curretnIndex + 1;
                  if (nextIndex >= currentDataUpload.length) {
                    callBack();
                  } else {
                    this.doUploadImages(
                      contentSectionRes,
                      currentDataUpload,
                      uploadTo,
                      dataName,
                      nextIndex,
                      0,
                      0,
                      originalCheck,
                      () => {
                        callBack();
                      }
                    );
                  }
                }
              );
            } else {
              this.doUploadImages(
                contentSectionRes,
                currentDataUpload,
                uploadTo,
                dataName,
                curretnIndex,
                nextLanguageIndex,
                0,
                originalCheck,
                () => {
                  callBack();
                }
              );
            }
          } else {
            this.doUploadImages(
              contentSectionRes,
              currentDataUpload,
              uploadTo,
              dataName,
              curretnIndex,
              currentIndexLanguage,
              nextIndexImages,
              originalCheck,
              () => {
                callBack();
              }
            );
          }
        } else {
          callBack();
        }
      }
    );
  }

  checkUploadingImages(
    array,
    currentIndex,
    currentIndexLanguage,
    currentIndexImages,
    dataName,
    originalCheck,
    callback
  ) {
    if (currentIndex < array.length) {
      const dataCheck = array[currentIndex];
      let addDataName = "";
      if (this.props.languages.length > currentIndexLanguage) {
        addDataName = this.props.languages[currentIndexLanguage].data;
      }
      systemLog("UploadTestImages", currentIndexImages);
      systemLog(
        "UploadTestImages",
        dataCheck[dataName + addDataName][
          ImagesRes[currentIndexImages].dataName
        ]
      );
      if (
        dataCheck[dataName + addDataName][
          ImagesRes[currentIndexImages].dataName
        ] !== undefined
      ) {
        if (
          dataCheck[dataName + addDataName][
            ImagesRes[currentIndexImages].dataName
          ].name !== undefined &&
          dataCheck[dataName + addDataName][
            ImagesRes[currentIndexImages].dataName
          ].isNewFile === true
        ) {
          if (currentIndexImages >= ImagesRes.length - 1) {
            delete dataCheck[originalCheck + addDataName];
          }
          dataCheck[dataName + addDataName][
            ImagesRes[currentIndexImages].dataName
          ].name = setBaseFileNameDataCheck(
            BasedataImageNameSet,
            dataCheck[dataName + addDataName][
              ImagesRes[currentIndexImages].dataName
            ].name
          );
          this.props.API.CheckFileExist(
            dataCheck[dataName + addDataName][
              ImagesRes[currentIndexImages].dataName
            ].name
          ).then(exist => {
            let canChangeName = true;
            systemLog(
              "UploadTestImages",
              dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ]
            );
            if (dataCheck[dataName + addDataName].fileName !== undefined) {
              canChangeName =
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName ===
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].name
                  ? false
                  : true;
            }
            if (exist === true && canChangeName === true) {
              const splitData = dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].name.split(".");
              const extension = splitData[splitData.length - 1];
              const name =
                setURLType(splitData[0]) +
                "_" +
                moment()
                  .unix()
                  .toString() +
                "." +
                extension;
              dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].name = name;
            }
            if (
              dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].fileName !== undefined
            ) {
              this.props.API.UploadFile(
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].result,
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName,
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].name
              )
                .then(response => {
                  systemLog("UploadTestImages", response);
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].isNewFile;
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].b64;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].fileName = response.name;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].preview = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].result = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].resultWEBP = response.pathWEBP;
                  setTimeout(() => {
                    callback(dataCheck, currentIndex);
                  }, 620);
                })
                .catch(error => {
                  systemLog("UploadTestImages", error);
                  callback({}, currentIndex);
                });
            } else {
              this.props.API.DoUploadFile(
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].result,
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].name
              )
                .then(response => {
                  systemLog("UploadTestImages", response);
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].isNewFile;
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].b64;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].fileName = response.name;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].preview = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].result = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].resultWEBP = response.pathWEBP;
                  setTimeout(() => {
                    callback(dataCheck, currentIndex);
                  }, 620);
                })
                .catch(error => {
                  systemLog("UploadTestImages", error);
                  callback(dataCheck, currentIndex);
                });
            }
          });
        } else {
          if (
            dataCheck[originalCheck + addDataName] !== undefined &&
            dataCheck[originalCheck + addDataName] !== null
          ) {
            if (
              (dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].fileName === undefined ||
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName === null ||
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName === "") &&
              dataCheck[originalCheck + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].fileName !== undefined &&
              dataCheck[originalCheck + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].fileName !== null &&
              dataCheck[originalCheck + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].fileName !== ""
            ) {
              systemLog(
                "UploadDeleteImages",
                dataCheck[originalCheck + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ]
              );
              systemLog(
                "UploadDeleteImages",
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ]
              );
              this.props.API.DeleteFile(
                dataCheck[originalCheck + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName
              ).then(deleteRes => {
                systemLog("UploadTestImages", deleteRes);
                if (currentIndexImages >= ImagesRes.length - 1) {
                  delete dataCheck[originalCheck + addDataName];
                }
                setTimeout(() => {
                  callback(dataCheck, currentIndex);
                }, 620);
              });
            } else {
              if (currentIndexImages >= ImagesRes.length - 1) {
                delete dataCheck[originalCheck + addDataName];
              }
              callback(dataCheck, currentIndex);
            }
          } else {
            if (currentIndexImages >= ImagesRes.length - 1) {
              delete dataCheck[originalCheck + addDataName];
            }
            callback(dataCheck, currentIndex);
          }
        }
      } else {
        if (currentIndexImages >= ImagesRes.length - 1) {
          delete dataCheck[originalCheck + addDataName];
        }
        callback(dataCheck, currentIndex);
      }
    } else {
      callback({}, currentIndex);
    }
  }

  doUploadFiles(
    contentSectionRes,
    currentDataUpload,
    uploadTo,
    dataName,
    curretnIndex,
    currentIndexLanguage,
    originalCheck,
    callBack
  ) {
    systemLog("UploadTest", contentSectionRes);
    systemLog("UploadTest", currentDataUpload);
    systemLog("UploadTest", uploadTo);
    systemLog("UploadTest", curretnIndex);
    systemLog("UploadTest", callBack);
    this.checkUploadingOfFile(
      currentDataUpload,
      curretnIndex,
      currentIndexLanguage,
      dataName,
      originalCheck,
      (dataCheck, returnIndex) => {
        dataCheck["contentSectionId"] = contentSectionRes.id;
        if (dataCheck.id !== undefined) {
          const nextLanguageIndex = currentIndexLanguage + 1;
          if (nextLanguageIndex >= this.props.languages.length) {
            this.props.API.PatchTo(uploadTo, dataCheck.id, dataCheck).then(
              response => {
                const nextIndex = curretnIndex + 1;
                if (nextIndex >= currentDataUpload.length) {
                  callBack();
                } else {
                  this.doUploadFiles(
                    contentSectionRes,
                    currentDataUpload,
                    uploadTo,
                    dataName,
                    nextIndex,
                    0,
                    originalCheck,
                    () => {
                      callBack();
                    }
                  );
                }
              }
            );
          } else {
            this.doUploadFiles(
              contentSectionRes,
              currentDataUpload,
              uploadTo,
              dataName,
              curretnIndex,
              nextLanguageIndex,
              originalCheck,
              () => {
                callBack();
              }
            );
          }
        } else {
          callBack();
        }
      }
    );
  }

  checkUploadingOfFile(
    array,
    currentIndex,
    currentIndexLanguage,
    dataName,
    originalCheck,
    callback
  ) {
    if (currentIndex < array.length) {
      const dataCheck = array[currentIndex];
      let addDataName = "";
      if (this.props.languages.length > currentIndexLanguage) {
        addDataName = this.props.languages[currentIndexLanguage].data;
        systemLog("FILE_UPLOAD_CHECK", addDataName);
      }
      systemLog("FILE_UPLOAD_CHECK", dataCheck[dataName + addDataName]);
      if (
        dataCheck[dataName + addDataName].name !== undefined &&
        dataCheck[dataName + addDataName].isNewFile === true
      ) {
        delete dataCheck[originalCheck + addDataName];
        dataCheck[dataName + addDataName].name = setBaseFileNameDataCheck(
          BasedataImageNameSet,
          dataCheck[dataName + addDataName].name
        );
        this.props.API.CheckFileExist(
          dataCheck[dataName + addDataName].name
        ).then(exist => {
          let canChangeName = true;
          systemLog("ImageLanding", dataCheck[dataName + addDataName]);
          if (dataCheck[dataName + addDataName].fileName !== undefined) {
            canChangeName =
              dataCheck[dataName + addDataName].fileName ===
              dataCheck[dataName + addDataName].name
                ? false
                : true;
          }
          if (exist === true && canChangeName === true) {
            const splitData = dataCheck[dataName + addDataName].name.split(".");
            const extension = splitData[splitData.length - 1];
            const name =
              setURLType(splitData[0]) +
              "_" +
              moment()
                .unix()
                .toString() +
              "." +
              extension;
            dataCheck[dataName + addDataName].name = name;
          }
          if (dataCheck[dataName + addDataName].fileName !== undefined) {
            this.props.API.UploadFile(
              dataCheck[dataName + addDataName].result,
              dataCheck[dataName + addDataName].fileName,
              dataCheck[dataName + addDataName].name
            )
              .then(response => {
                systemLog("FILE_UPLOAD_CHECK", response);
                delete dataCheck[dataName + addDataName].isNewFile;
                delete dataCheck[dataName + addDataName].b64;
                dataCheck[dataName + addDataName].fileName = response.name;
                dataCheck[dataName + addDataName].preview = response.path;
                dataCheck[dataName + addDataName].result = response.path;
                dataCheck[dataName + addDataName].resultWEBP =
                  response.pathWEBP;
                setTimeout(() => {
                  callback(dataCheck, currentIndex);
                }, 620);
              })
              .catch(error => {
                systemLog("FILE_UPLOAD_CHECK", error);
                callback({}, currentIndex);
              });
          } else {
            this.props.API.DoUploadFile(
              dataCheck[dataName + addDataName].result,
              dataCheck[dataName + addDataName].name
            )
              .then(response => {
                systemLog("FILE_UPLOAD_CHECK", response);
                delete dataCheck[dataName + addDataName].isNewFile;
                delete dataCheck[dataName + addDataName].b64;
                dataCheck[dataName + addDataName].fileName = response.name;
                dataCheck[dataName + addDataName].preview = response.path;
                dataCheck[dataName + addDataName].result = response.path;
                dataCheck[dataName + addDataName].resultWEBP =
                  response.pathWEBP;
                setTimeout(() => {
                  callback(dataCheck, currentIndex);
                }, 620);
              })
              .catch(error => {
                systemLog("FILE_UPLOAD_CHECK", error);
                callback(dataCheck, currentIndex);
              });
          }
        });
      } else {
        if (
          dataCheck[originalCheck + addDataName] !== undefined &&
          dataCheck[originalCheck + addDataName] !== null
        ) {
          if (
            (dataCheck[dataName + addDataName].fileName === undefined ||
              dataCheck[dataName + addDataName].fileName === null ||
              dataCheck[dataName + addDataName].fileName === "") &&
            dataCheck[originalCheck + addDataName].fileName !== undefined &&
            dataCheck[originalCheck + addDataName].fileName !== null &&
            dataCheck[originalCheck + addDataName].fileName !== ""
          ) {
            this.props.API.DeleteFile(
              dataCheck[originalCheck + addDataName].fileName
            ).then(deleteRes => {
              systemLog("FILE_UPLOAD_DELETE", deleteRes);
              delete dataCheck[originalCheck + addDataName];
              setTimeout(() => {
                callback(dataCheck, currentIndex);
              }, 620);
            });
          } else {
            delete dataCheck[originalCheck + addDataName];
            callback(dataCheck, currentIndex);
          }
        } else {
          delete dataCheck[originalCheck + addDataName];
          callback(dataCheck, currentIndex);
        }
      }
    } else {
      callback({}, currentIndex);
    }
  }

  checkDidUploadAll() {
    systemLog("UploadTest", "checkDidUploadAll");
    this.props.openFrontLoading(false);
    if (this.props.clickOnSave !== undefined) {
      this.props.clickOnSave();
      toast.success(saveContentC);
    }
  }

  renderUploadImage() {
    return (
      <div
        style={{
          ...styleCenteredObject,
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: colors.adminBackgroundColor
        }}
      >
        <Icon
          name={"upload"}
          style={{ color: colors.disableColor, fontSize: "3rem" }}
        />
        <ModularText style={{ fontSize: textSizeSubHeaderTitle }}>
          {"Selecciona o Arrastrar una Imagen"}
        </ModularText>
      </div>
    );
  }

  renderTopExtraInfo() {
    return (
      <div
        style={{
          ...styleCenteredObject,
          justifyContent: "flex-end",
          marginRight: adminSeparation,
          padding: "0.7rem",
          height: "100%",
          width: "10rem",
          border: "1px solid " + colors.borderColor
        }}
      >
        <Dropdown
          fluid
          search
          onChange={(event, changedData) => {
            const checkIdiom = changedData.value;
            const languageCheck = this.props.languages.find(language => {
              return language.value === checkIdiom;
            });
            this.setState({
              currentLanguageId: checkIdiom,
              currentLanguage: languageCheck.data
            });
            this.forceUpdate();
          }}
          value={this.state.currentLanguageId}
          placeholder={"Idioma"}
          options={this.props.languages}
        />
      </div>
    );
  }

  renderValueColumn() {
    if (this.currentTableToChange.value !== undefined) {
      return (
        <div style={{ ...columnStyle, width: "4rem" }}>
          {renderLabel(dataValue.dataLabel)}
          {renderModifyData(
            dataValue,
            this.currentTableToChange,
            this.props.formatter,
            () => {
              this.forceUpdate();
            }
          )}
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderSectionMainInfo() {
    if (this.props.user.isGod) {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataPrivateName.dataLabel)}
              {renderModifyData(
                dataPrivateName,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataPublicName.dataLabel)}
              {renderModifyData(
                dataPublicName,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataBase.dataLabel)}
              {renderModifyData(
                dataBase,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>{this.renderValueColumn()}</Grid.Column>
        </Grid>
      );
    } else {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataPublicName.dataLabel)}
              {renderModifyData(
                dataPublicName,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>{this.renderValueColumn()}</Grid.Column>
        </Grid>
      );
    }
  }

  renderCreateData(title, contentToAddTo) {
    if (this.props.user.isGod === true) {
      return (
        <div
          style={{
            ...styleCenteredObject,
            width: "100%",
            height: "2rem",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              ...styleCenteredObject,
              height: "100%",
              justifyContent: "flex-start"
            }}
          >
            <ModularText style={{ fontSize: textSizeHeaderSubTitle }}>
              {"CREAR " + title}
            </ModularText>
          </div>
          <div
            style={{
              ...styleCenteredObject,
              height: "100%",
              justifyContent: "flex-end"
            }}
          >
            <Button
              onClick={() => {
                const tempData = {};
                for (let i = 0; i < dataCreateData.length; i++) {
                  const currentData = dataCreateData[i];
                  tempData[currentData.dataName] = "";
                }
                contentToAddTo.push(tempData);
                this.forceUpdate();
              }}
              style={{
                backgroundColor: colors.addColor,
                color: colors.addFrontColor,
                ...styleCenteredObject,
                width: "8rem",
                height: "3rem"
              }}
            >
              {"CREAR"}
            </Button>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderEditPrivateData(data) {
    if (this.props.user.isGod === true) {
      return (
        <div
          style={{
            ...styleCenteredObject,
            flexDirection: "row"
          }}
        >
          <div style={{ width: "1rem" }} />
          <div style={{ width: "12rem" }}>
            {renderModifyData(
              privateDataPrivateName,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
          <div style={{ width: "0.5rem" }} />
          <div style={{ width: "12rem" }}>
            {renderModifyData(
              privateDataPublicName,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
          <div style={{ width: "0.5rem" }} />
          <div style={{ width: "4rem" }}>
            {renderModifyData(
              privateDataPublicNumber,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>{" "}
          <div style={{ width: "0.5rem" }} />
          <div style={{ width: "4rem" }}>
            <Button
              onClick={() => {
                const index = this.totalDataContent.indexOf(data);
                this.totalDataContent.splice(index, 1);
                this.deleteContent.push(data);
                if (data.typeContent === "text") {
                  const findData = this.currentDataContentText.find(
                    dataFind => {
                      return dataFind.id === data.id;
                    }
                  );
                  if (findData !== undefined) {
                    const indexType = this.currentDataContentText.indexOf(
                      findData
                    );
                    this.currentDataContentText.splice(indexType, 1);
                  }
                } else if (data.typeContent === "image") {
                  const findData = this.currentDataContentImage.find(
                    dataFind => {
                      return dataFind.id === data.id;
                    }
                  );
                  if (findData !== undefined) {
                    const indexType = this.currentDataContentImage.indexOf(
                      findData
                    );
                    this.currentDataContentImage.splice(indexType, 1);
                  }
                } else if (data.typeContent === "link") {
                  const findData = this.currentDataContentLink.find(
                    dataFind => {
                      return dataFind.id === data.id;
                    }
                  );
                  if (findData !== undefined) {
                    const indexType = this.currentDataContentLink.indexOf(
                      findData
                    );
                    this.currentDataContentLink.splice(indexType, 1);
                  }
                } else if (data.typeContent === "richtext") {
                  const findData = this.currentDataContentRichText.find(
                    dataFind => {
                      return dataFind.id === data.id;
                    }
                  );
                  if (findData !== undefined) {
                    const indexType = this.currentDataContentRichText.indexOf(
                      findData
                    );
                    this.currentDataContentRichText.splice(indexType, 1);
                  }
                } else if (data.typeContent === "file") {
                  const findData = this.currentDataContentFile.find(
                    dataFind => {
                      return dataFind.id === data.id;
                    }
                  );
                  if (findData !== undefined) {
                    const indexType = this.currentDataContentFile.indexOf(
                      findData
                    );
                    this.currentDataContentFile.splice(indexType, 1);
                  }
                }
                systemLog("DeleteDataContent", this.currentDataContentText);
                systemLog("DeleteDataContent", this.totalDataContent);
                systemLog("DeleteDataContent", this.deleteContent);
                this.forceUpdate();
              }}
              style={{
                ...styleCenteredObject,
                backgroundColor: colors.removeColor,
                color: colors.removeFrontColor
              }}
              icon="cancel"
            />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderInsideData(data) {
    if (data.typeContent === "text") {
      dataText.dataName = dataText.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataText, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    } else if (data.typeContent === "image") {
      dataImage.dataName = dataImage.mainDataName + this.state.currentLanguage;
      return (
        <div style={{ width: "100%" }}>
          {/*renderModifyData(dataTint, data, this.props.formatter, () => {
            this.forceUpdate();
          })*/}
          {renderModifyData(
            dataImage,
            data,
            this.props.formatter,
            returnData => {
              systemLog("DeleteFile", data);
              this.forceUpdate();
            }
          )}
        </div>
      );
    } else if (data.typeContent === "link") {
      dataLink.dataName = dataLink.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataLink, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    } else if (data.typeContent === "richtext") {
      dataRichText.dataName =
        dataRichText.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataRichText, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    } else if (data.typeContent === "file") {
      dataFile.dataName = dataFile.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataFile, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    }
  }

  renderSingleCreateData(data, table) {
    return (
      <Grid columns="equal">
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(privateDataPrivateName.dataLabel)}
            {renderModifyData(
              privateDataPrivateName,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(privateDataPublicName.dataLabel)}
            {renderModifyData(
              privateDataPublicName,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(privateDataPublicNumber.dataLabel)}
            {renderModifyData(
              privateDataPublicNumber,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={2}>
          <div
            style={{
              ...columnStyle,
              width: "100%",
              height: "100%"
            }}
          >
            <div style={{ height: "0.1rem" }} />
            <div
              style={{
                ...styleCenteredObject,
                width: "100%",
                height: "100%"
              }}
            >
              <Button
                onClick={() => {
                  const index = table.indexOf(data);
                  table.splice(index, 1);
                  this.forceUpdate();
                }}
                style={{
                  ...styleCenteredObject,
                  backgroundColor: colors.removeColor,
                  color: colors.removeFrontColor
                }}
                icon="cancel"
              />
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  renderCreateElements() {
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            height: "1rem"
          }}
        />
        {this.renderCreateData("TEXTOS", this.createDataContentText)}
        <div style={{ height: "1rem" }} />
        {this.createDataContentText.map((data, index) => {
          return (
            <div key={index}>
              {this.renderSingleCreateData(data, this.createDataContentText)}
            </div>
          );
        })}
        <div
          style={{
            height: separationTables
          }}
        />
        {this.renderCreateData("IMAGENES", this.createDataContentImage)}
        <div style={{ height: "1rem" }} />
        {this.createDataContentImage.map((data, index) => {
          return (
            <div key={index}>
              {this.renderSingleCreateData(data, this.createDataContentImage)}
            </div>
          );
        })}
        <div
          style={{
            height: separationTables
          }}
        />
        {this.renderCreateData("LINKS", this.createDataContentLink)}
        <div style={{ height: "1rem" }} />
        {this.createDataContentLink.map((data, index) => {
          return (
            <div key={index}>
              {this.renderSingleCreateData(data, this.createDataContentLink)}
            </div>
          );
        })}
        <div
          style={{
            height: separationTables
          }}
        />
        {this.renderCreateData("RICH TEXT", this.createDataContentRichText)}
        <div style={{ height: "1rem" }} />
        {this.createDataContentRichText.map((data, index) => {
          return (
            <div key={index}>
              {this.renderSingleCreateData(
                data,
                this.createDataContentRichText
              )}
            </div>
          );
        })}
        <div
          style={{
            height: separationTables
          }}
        />
        {this.renderCreateData("ARCHIVO", this.createDataContentFile)}
        <div style={{ height: "1rem" }} />
        {this.createDataContentFile.map((data, index) => {
          return (
            <div key={index}>
              {this.renderSingleCreateData(data, this.createDataContentFile)}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const canSave = true;
    return (
      <div>
        {renderTopName(
          "Editar: " + this.props.table.publicName,
          this.renderTopExtraInfo
        )}
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.renderSectionMainInfo()}
          <div style={{ height: "1rem" }} />
          {this.totalDataContent.map((data, index) => {
            let label = data.publicName;
            if (this.props.user.isGod && data.name !== "") {
              label = data.publicName + " (" + data.name + ")";
            }
            return (
              <div key={index}>
                <div style={{ ...columnStyle, justifyContent: "center" }}>
                  <div style={{ ...rowStyle }}>
                    <div style={{ minWidth: "1rem" }}>{renderLabel(label)}</div>
                    {this.renderEditPrivateData(data)}
                  </div>
                  {this.renderInsideData(data)}
                </div>
                <div style={{ height: "0.5rem" }} />
              </div>
            );
          })}
          {this.renderCreateElements()}
          <div
            style={{
              height: "2rem"
            }}
          />
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              width: "100%",
              height: "4rem"
            }}
          >
            <Button
              onClick={() => {
                if (canSave) {
                  this.saveItem();
                }
              }}
              style={{
                backgroundColor: canSave
                  ? colors.buttonColor
                  : colors.disableColor,
                color: colors.colorFrontButton,
                ...styleCenteredObject,
                width: "8rem",
                height: "3rem"
              }}
            >
              {"GUARDAR"}
            </Button>
          </div>
          <div style={{ height: "1rem" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, user, languages } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API, user, languages };
};

const mapDispatchToProps = dispatch => {
  return {
    openFrontLoading: showIt => {
      dispatch(openFrontLoading(showIt));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditContent)
);
