import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setOrganizations } from "../redux/actions";
import * as OrganizationData from "../TablesData/organizationDataTables";
import AdminTable from "../layaouts/AdminTable";

class OrganizationsTable extends Component {
  constructor(props) {
    super(props);
    this.refreshOrganizations = this.refreshOrganizations.bind(this);
  }

  componentDidMount() {
    this.refreshOrganizations();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  refreshOrganizations() {
    this.checkLoading(true);
    this.props.API.GetOrganizations(
      this.props.user.isGod,
      this.props.user.isAdmin,
      this.props.user.organizationId
    )
      .then(responseOrgnization => {
        if (responseOrgnization.error === undefined) {
          this.props.setOrganizations(responseOrgnization);
          this.checkLoading(false);
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.organizations}
        tableViewItemData={OrganizationData.tableViewItemData}
        tableEditItemData={OrganizationData.tableEditItemData}
        tableCreateItemData={OrganizationData.tableCreateItemData}
        tableData={OrganizationData.tableData}
        tableSysName={"Organizations"}
        tablePermName={"Organizaciones"}
        tableName={"Organizaciones"}
        singleName={"Organizaciones"}
        tableSubtitle={"Administrador de Organizaciones"}
        filterTable={OrganizationData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshOrganizations();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { organizations, user } = data;
  return { API, organizations, user };
};

const mapDispatchToProps = dispatch => {
  return {
    setOrganizations: organizations => {
      dispatch(setOrganizations(organizations));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationsTable)
);
