import React, { Component } from "react";
import { Input, Button, Icon } from "semantic-ui-react";
import { styleBasic } from "../style";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSearch: ""
    };
    this.renderCancelButton = this.renderCancelButton.bind(this);
    this.renderSearchButton = this.renderSearchButton.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.inputSearchWidth = "80%";
    if (this.props.showSearch === false) {
      this.inputSearchWidth = "90%";
    }
  }

  renderSearchButton() {
    if (this.props.showSearch !== false) {
      return (
        <div
          style={{
            flexDirection: "column",
            alignSelf: "flex-end",
            alignItems: "center",
            height: "100%",
            padding: this.props.paddingButton || 5,
            width: this.props.buttonWidth || 150
          }}
        >
          <Button
            onClick={() => {
              document.getElementById("searchInput").blur();
              if (this.props.sendSearch !== undefined) {
                this.props.sendSearch(this.state.currentSearch);
              }
            }}
            style={{
              height: "100%",
              width: "100%",
              fontSize: this.props.buttonFontSize || "1em"
            }}
            fluid
            primary
          >
            {this.props.buttonLabel || "BUSCAR"}
          </Button>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderCancelButton() {
    if (this.state.currentSearch !== "") {
      return (
        <Button
          style={{
            ...styleBasic,
            height: "100%",
            width: "100%",
            alignSelf: "center",
            alignItems: "center"
          }}
          onClick={() => {
            this.setState({ currentSearch: "" });
            if (this.props.onChange !== undefined) {
              this.props.onChange("");
            }
          }}
          basic
        >
          <Icon name="cancel" />
        </Button>
      );
    } else {
      return <div />;
    }
  }

  renderIcon() {
    if (this.props.showSearchIcon !== false) {
      return (
        <Button
          onClick={() => {
            document.getElementById("searchInput").focus();
          }}
          style={{
            ...styleBasic,
            height: "100%",
            width: "100%",
            alignSelf: "center",
            alignItems: "center"
          }}
        >
          <Icon name={this.props.iconSearch || "search"} />
        </Button>
      );
    } else {
      return <div />;
    }
  }

  render() {
    let finalPadding = 20;
    if (this.props.leftPadding !== undefined) {
      finalPadding = this.props.leftPadding;
    }
    return (
      <div
        style={{
          borderRadius: this.props.borderRadius || 5,
          display: "flex",
          backgroundColor: this.props.backgroundColor || "white",
          height: this.props.height || 50,
          width: this.props.width || "100%",
          ...this.props.style
        }}
      >
        <div
          style={{
            alignSelf: "center",
            alignItems: "center",
            height: "100%",
            width: "5%"
          }}
        >
          {this.renderIcon()}
        </div>
        <div
          style={{
            paddingLeft: finalPadding,
            height: this.props.height || 50,
            width: this.inputSearchWidth,
            ...this.props.containerStyle
          }}
        >
          <Input
            id={"searchInput"}
            type={this.props.type}
            min={this.props.min}
            onChange={e => {
              this.setState({ currentSearch: e.target.value });
              if (this.props.onChange !== undefined) {
                this.props.onChange(e.target.value);
              }
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                document.getElementById("searchInput").blur();
                if (this.props.sendSearch !== undefined) {
                  this.props.sendSearch(this.state.currentSearch);
                }
              }
            }}
            style={{
              fontSize: "1.11rem",
              height: "100%",
              width: "100%"
            }}
            transparent
            fluid
            placeholder={this.props.placeholder || "Search.."}
            value={this.state.currentSearch}
          />
        </div>
        <div
          style={{
            alignSelf: "flex-end",
            alignItems: "center",
            height: "100%",
            width: "5%"
          }}
        >
          {this.renderCancelButton()}
        </div>
        {this.renderSearchButton()}
      </div>
    );
  }
}

export default SearchBar;
