import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModularText from "../components/ModularText";
import {
  columnViewStyle,
  renderData,
  renderTopName,
  getTitleName,
  checkIfCanShow
} from "../scripts/System";
import { adminSeparation, textSizeHeaderSubTitle } from "../style";
import { systemLog } from "../scripts/General";

class AdminViewItem extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    systemLog(this, this.props.table);
  }

  render() {
    if (this.props.table === undefined) {
      return <div />;
    }

    let finalData = getTitleName(
      this.props.tableData.dataTitle,
      this.props.table
    );

    return (
      <div>
        {renderTopName(finalData)}
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.props.tableData.dataVisual.map((data, index) => {
            let canShowItem = true;
            if (data.onlyShowIf !== undefined) {
              canShowItem = checkIfCanShow(
                data,
                this.props.table,
                this.props.tableData.dataVisual
              );
            }
            if (data.dontShow === true) {
              canShowItem = false;
            }

            if (canShowItem === true) {
              return (
                <div key={index}>
                  <div style={{ ...columnViewStyle }}>
                    <ModularText style={{ fontSize: textSizeHeaderSubTitle }}>
                      {data.dataLabel + ": "}
                    </ModularText>
                    <div style={{ height: "0.1rem" }} />
                    {renderData(
                      data,
                      this.props.table,
                      this.props.permissionData.update,
                      this.props.formatter,
                      () => {
                        this.forceUpdate();
                      },
                      true
                    )}
                  </div>
                  <div style={{ height: "1rem" }} />
                </div>
              );
            } else {
              return <div key={index} />;
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions } = data;
  const { formatter } = api;
  return { formatter, userPermissions };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(AdminViewItem)
);
