import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setSection } from "../../redux/actions";
import ContentSectionTable from "../../BaseTables/ContentSectionTable";
import AdminContent from "../../layaouts/AdminContent";

class SectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingPageTable: true };
  }

  render() {
    return (
      <ContentSectionTable
        sectionId={this.props.sectionId}
        checkLoading={doit => {
          this.setState({ loadingPageTable: doit });
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { section, users, user, roles, organizations } = data;
  return { section, users, user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setSection: section => {
      dispatch(setSection(section));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionPage)
);
