import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PermissionsTable from "../../BaseTables/PermissionsTable";
import AdminContent from "../../layaouts/AdminContent";
import PagePermCheck from "../../layaouts/PagePermCheck";
import { setUsers } from "../../redux/actions";

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
  }

  componentDidMount() {}

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingUserTable}
          pageName={"Administrador"}
          pageSubtitle={"Administrador Principal"}
        >
          <PermissionsTable
            checkLoading={doit => {
              this.setState({ loadingUserTable: doit });
            }}
          />
        </AdminContent>
      </PagePermCheck>
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { users, user, roles, organizations } = data;
  return { users, user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setUsers: users => {
      dispatch(setUsers(users));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminPage)
);
