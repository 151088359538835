import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setUsers } from "../redux/actions";
import * as UserData from "../TablesData/userDataTables";
import AdminTable from "../layaouts/AdminTable";

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.tableParentIdConnect = "organizationId";
    this.tableParentId = this.props.user.organizationId;

    this.specialPermissionsUser = [
      {
        id: this.props.user.id,
        delete: false,
        read: true,
        update: false,
        canBeSelected: false
      }
    ];
    this.refreshUsers = this.refreshUsers.bind(this);
    if (this.props.user.isGod) {
      this.tableParentIdConnect = undefined;
      this.tableParentId = undefined;
    }
  }

  componentDidMount() {
    this.refreshUsers();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  refreshUsers() {
    this.checkLoading(true);
    this.props.API.GetRoles(
      this.props.user.isGod,
      this.props.user.organizationId,
      this.props.user.role.value
    )
      .then(responseRoles => {
        if (responseRoles.error === undefined) {
          const arrayOfId = [];
          for (let i = 0; i < responseRoles.length; i++) {
            arrayOfId.push(responseRoles[i].id);
          }
          const whereRoles = [{ roleId: { inq: arrayOfId } }];
          this.props.API.GetUsers(
            this.props.user.isGod,
            this.props.user.isAdmin,
            this.props.user,
            whereRoles
          )
            .then(responseUsers => {
              if (responseUsers.error === undefined) {
                responseUsers.unshift(this.props.user);
                this.props.setUsers(responseUsers);
                this.checkLoading(false);
              } else {
                this.checkLoading(false);
              }
            })
            .catch(error => {
              this.checkLoading(false);
            });
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.users}
        tableParentIdConnect={this.tableParentIdConnect}
        tableParentId={this.tableParentId}
        specialPermissions={this.specialPermissionsUser}
        tableViewItemData={UserData.tableViewItemData}
        tableEditItemData={UserData.tableEditItemData}
        tableCreateItemData={UserData.tableCreateItemData}
        tableData={UserData.tableData}
        customAfterCreate={(finalObjt, fullObj) => {
          let rolName = "Administrador";
          const rol = this.props.roles.find(data => data.id === fullObj.roleId);
          if (rol !== undefined) {
            rolName = rol.namePublic;
          }
          this.props.API.PostTo("Organizations/sendEmailNewUser", {
            email: fullObj.email,
            pass: fullObj.password,
            name: fullObj.firstName + " " + fullObj.lastName,
            rol: rolName
          });
          this.refreshUsers();
        }}
        tableSysName={"SysUsers"}
        tablePermName={"USUARIOS"}
        tableName={"USUARIOS"}
        singleName={"USUARIO"}
        selectorDataShow={["email", "firstName", "lastName"]}
        tableSubtitle={"Administrador de Usuarios"}
        filterTable={UserData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshUsers();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { users, user, roles, organizations } = data;
  return { users, user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setUsers: users => {
      dispatch(setUsers(users));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserTable)
);
