import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill"; // ES6
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { colorsToSet } from "./QuillColors";
import { setURLType } from "./General";
import { mainIp } from "../MainInfoData";
import ImageResize from "quill-image-resize-module-mended";
import imageUpload from "quill-plugin-image-upload";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageUpload", imageUpload);

const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "align"
];

class QuillTextView extends Component {
  constructor(props) {
    super(props);
    this.state = { text: "" }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
    this.modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" }
        ],
        [{ color: colorsToSet }], // dropdown with defaults from theme
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ],
      imageResize: { modules: ["Resize", "DisplaySize"] },
      imageUpload: {
        upload: file => {
          // return a Promise that resolves in a link to the uploaded image
          return new Promise((resolve, reject) => {
            let addRTIDN = "";
            if (this.props.RTImageDataName !== undefined) {
              addRTIDN = setURLType(
                this.props.table[this.props.RTImageDataName]
              );
            }
            let fileNameSet = "QuillText_" + addRTIDN + "_" + file.name;
            this.props.API.CheckFileExist(fileNameSet)
              .then(fileNameExist => {
                this.props.API.DoUploadFile(file, fileNameSet)
                  .then(responseFile => {
                    const finalURL = mainIp + responseFile.path;
                    var range = this.quillRef.getEditor().getSelection();
                    this.quillRef
                      .getEditor()
                      .insertEmbed(range.index, "image", finalURL, "user");
                    resolve(finalURL);
                  })
                  .catch(reject("Error uploading file"));
              })
              .catch(reject("Error uploading file"));
          });
        }
      }
    };
  }

  componentDidMount() {
    if (this.props.initValue !== undefined) {
      this.setState({
        text: this.props.initValue
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initValue !== undefined) {
      if (this.state.text !== nextProps.initValue) {
        this.setState({
          text: nextProps.initValue
        });
      }
    }
  }

  handleChange(value) {
    this.setState({ text: value });
    if (this.props.onChange !== undefined) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString("html"));
    }
  }

  render() {
    return (
      <div
        style={{
          width: "100%"
        }}
      >
        <script src="/node_modules/quill-image-resize-module/image-resize.min.js" />
        <script src="/node_modules/quill-image-upload/image-upload.min.js" />
        <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css" />
        <ReactQuill
          ref={el => (this.quillRef = el)}
          value={this.state.text}
          style={{
            width: "100%"
          }}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={this.modules}
          formats={formats}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { API } = api;
  return { API };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(QuillTextView)
);
