import React, { Component } from "react";
import { Responsive } from "semantic-ui-react";
import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css"; // only needs to be imported once
import { colors } from "../style";

const spanish = {
  blank: "Selecciona Fecha...",
  headerFormat: "ddd, MMM Do",
  todayLabel: {
    long: "Hoy"
  },
  weekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  weekStartsOn: 1
};

const styleCalendar = {
  accentColor: colors.colorHeaderCalendar,
  floatingNav: {
    background: colors.mainColorAlpha,
    chevron: "transparent",
    color: colors.colorFrontMain
  },
  headerColor: colors.colorHeaderCalendar,
  selectionColor: colors.mainColorLight,
  textColor: {
    active: colors.colorFrontMain,
    default: "#333333"
  },
  todayColor: "#FFA726",
  weekdayColor: colors.mainColorLight
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ date: new Date() });
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <div
            style={{
              width: window.innerWidth * 0.92,
              border: "1px solid" + colors.mainColorDark
            }}
          >
            <InfiniteCalendar
              theme={styleCalendar}
              width={"100%"}
              height={window.innerHeight * 0.6}
              locale={spanish}
              selected={this.state.date}
            />
          </div>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <div
            style={{
              width: "100%",
              border: "1px solid" + colors.mainColorDark
            }}
          >
            <InfiniteCalendar
              theme={styleCalendar}
              width={"100%"}
              height={window.innerHeight * 0.6}
              locale={spanish}
              selected={this.state.date}
            />
          </div>
        </Responsive>
      </div>
    );
  }
}

export default Calendar;
