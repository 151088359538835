import React, { Component } from "react";
import { Code } from "react-content-loader";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Responsive, Loader } from "semantic-ui-react";
import LogoRender from "../components/LogoRender";
import MainHeader from "../components/MainHeader";
import MainSideBar from "../components/MainSideBar";
import ModularText from "../components/ModularText";
import { colors, headerHeight, sideShow, styleCenteredObject } from "../style";

class FakePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: "",
      author: ""
    };
    this.getQuote = this.getQuote.bind(this);
  }

  componentWillMount() {
    //this.getQuote();
  }

  getQuote() {
    fetch("https://talaikis.com/api/quotes/random/")
      .then(response => response.json())
      .then(result => {
        this.setState({ quote: result.quote, author: result.author });
      })
      .catch(error => {});
  }

  handleContextRef = contextRef => this.setState({ contextRef });

  render() {
    let currentSideShow = sideShow;
    if (this.props.verticalMenu === false) {
      currentSideShow = 0;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "flex-end",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          alignItems: "flex-end",
          width: "100%",
          minHeight: window.innerHeight
        }}
        ref={this.handleContextRef}
      >
        <MainSideBar
          contextRef={this.state.contextRef}
          visibleMobileMenu={false}
          currentPath={""}
        />
        <div
          style={{
            zIndex: 200,
            width: window.innerWidth,
            backgroundColor: colors.adminBackgroundColor
          }}
        >
          <MainHeader noInfo={true} />
          <div
            style={{
              ...styleCenteredObject,
              width: "100%",
              height: window.innerHeight - headerHeight
            }}
          >
            <div
              style={{
                ...styleCenteredObject,
                flexDirection: "column",
                width: "100%",
                height: "100%"
              }}
            >
              <div
                style={{
                  ...styleCenteredObject,
                  width: "100%",
                  height: "10rem"
                }}
              >
                <LogoRender
                  aling="center"
                  logoSizeW={"10rem"}
                  logoSizeH={"10rem"}
                  renderName={false}
                />
              </div>
              <div style={{ height: "1.2rem" }} />
              <div
                style={{
                  ...styleCenteredObject,
                  flexDirection: "column",
                  width: "90%"
                }}
              >
                <ModularText style={{ fontSize: "1.3rem" }}>
                  {this.state.quote}
                </ModularText>
                <div style={{ height: "0.7rem" }} />
                <ModularText
                  style={{ fontSize: "1.3rem", color: colors.textColor }}
                >
                  {"- " + this.state.author}
                </ModularText>
              </div>
              <div style={{ height: "1rem" }} />
              <div>
                <Responsive {...Responsive.onlyMobile}>
                  <div
                    style={{
                      ...styleCenteredObject,
                      width: "100%"
                    }}
                  >
                    <Loader active inline="centered" />
                  </div>
                </Responsive>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                  <div
                    style={{
                      ...styleCenteredObject,
                      width: "100%"
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "15rem",
                        height: "12rem",
                        width: "40rem"
                      }}
                    >
                      <Code
                        primaryColor={colors.primaryCenterLoadingColor}
                        secondaryColor={colors.secondaryCenterLoadingColor}
                      />
                    </div>
                  </div>
                </Responsive>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { verticalMenu } = data;
  return { verticalMenu };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(FakePage)
);
