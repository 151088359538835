import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AdminTable from "../layaouts/AdminTable";
import { setLanguages, setPages, setSection } from "../redux/actions";
import * as ContentData from "../TablesData/contentDataTable";
import CreateBaseContent from "./ContentSectionTable/CreateBaseContent";
import CreateBaseContentButton from "./ContentSectionTable/CreateBaseContentButton";
import EditContent from "./ContentSectionTable/EditContent";
import SectionPageCreator from "./ContentSectionTable/SectionPageCreator";

class ContentSectionTable extends Component {
  constructor(props) {
    super(props);
    this.refreshPage = this.refreshPage.bind(this);
    this.sectionId = this.props.sectionId;
    let permissions = {
      read: true,
      update: true,
      delete: true,
      create: true
    };
    if (this.props.user.isGod === false) {
      permissions = {
        read: true,
        update: true,
        delete: false,
        create: false
      };
    }
    this.state = {
      reset: false,
      permissions: permissions,
      section: { contentSections: [] }
    };
  }

  componentDidMount() {
    if (this.sectionId !== "") {
      this.refreshPage();
    }
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.sectionId !== nextProps.sectionId) {
      this.sectionId = nextProps.sectionId;
      this.setState({ reset: !this.state.reset });
      this.refreshPage();
    }
  }

  refreshPage() {
    this.checkLoading(true);
    this.props.API.GetSection(this.sectionId, this.props.user.isGod)
      .then(responseSection => {
        if (responseSection.error === undefined) {
          this.props.API.GetSectionBase(this.sectionId)
            .then(responseSectionBase => {
              if (responseSectionBase.error === undefined) {
                let permissions = this.state.permissions;
                const permissionData = this.props.userPagesPermissions.find(
                  x => {
                    return x.pageId === this.props.page.id;
                  }
                );
                if (
                  this.props.user.isGod === false &&
                  permissionData !== undefined
                ) {
                  permissions.read = permissionData.read;
                  permissions.update = permissionData.update;
                  permissions.delete = permissionData.delete;
                  permissions.create = permissionData.create;
                }
                permissions.create = false;
                permissions.delete = false;
                if (responseSectionBase.length > 0) {
                  permissions.delete = true;
                }
                this.setState({ permissions: permissions });
                this.setState({ section: responseSection });
                this.props.API.GetPages(
                  this.props.user.isGod,
                  this.props.user.organizationId
                )
                  .then(responsePages => {
                    if (responsePages.error === undefined) {
                      this.props.setPages(responsePages);
                      this.props.API.GetLanguages(
                        this.props.user.organizationId,
                        this.props.user.isGod
                      )
                        .then(responseLanguages => {
                          if (responseLanguages.error === undefined) {
                            let finalLanguages = [];
                            for (let i = 0; i < responseLanguages.length; i++) {
                              const currentLanguage = responseLanguages[i];
                              let tempLanguage = {
                                key: currentLanguage.id,
                                value: currentLanguage.id,
                                data: "_" + currentLanguage.name,
                                text: currentLanguage.publicName
                              };
                              finalLanguages.push(tempLanguage);
                            }
                            this.props.setLanguages(finalLanguages);
                            this.checkLoading(false);
                          } else {
                            this.checkLoading(false);
                          }
                        })
                        .catch(error => {
                          this.checkLoading(false);
                        });
                    } else {
                      this.checkLoading(false);
                    }
                  })
                  .catch(error => {
                    this.checkLoading(false);
                  });
                this.checkLoading(false);
              } else {
                this.checkLoading(false);
              }
            })
            .catch(error => {
              this.checkLoading(false);
            });
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    let tableName = "CONTENIDOS";
    if (this.state.section.publicName !== undefined) {
      tableName = this.state.section.publicName;
    }

    let tableSubtitle = "Administrador";
    if (this.state.section.publicName !== undefined) {
      tableSubtitle = "Administrador de " + this.state.section.publicName;
    }

    return (
      <AdminTable
        reset={this.state.reset}
        table={this.state.section.contentSections}
        tableCreateRoute={
          "SectionsPages/" + this.sectionId + "/contentSections"
        }
        tableViewItemData={ContentData.tableViewItemData}
        tableEditItemData={ContentData.tableEditItemData}
        tableCreateItemData={ContentData.tableCreateItemData}
        tableParentIdConnect={"sectionId"}
        tableParentId={this.sectionId}
        tableData={
          this.props.user.isGod
            ? ContentData.tableData
            : ContentData.tableAdminData
        }
        tableSysName={"ContentsSections"}
        focePermissionData={this.state.permissions}
        tablePermName={"web"}
        tableName={tableName}
        singleName={"CONTENIDO"}
        tableSubtitle={tableSubtitle}
        filterTable={ContentData.filterTable}
        filterAllThatApply={false}
        dontScrollTo={true}
        refreshTable={() => {
          this.refreshPage();
        }}
        customButton={CreateBaseContentButton}
        customView={CreateBaseContent}
        customEditor={EditContent}
        customCreator={SectionPageCreator}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const {
    section,
    page,
    userPagesPermissions,
    pages,
    user,
    roles,
    organizations
  } = data;
  return {
    section,
    page,
    userPagesPermissions,
    pages,
    user,
    API,
    organizations,
    roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguages: languages => {
      dispatch(setLanguages(languages));
    },
    setSection: section => {
      dispatch(setSection(section));
    },
    setPages: pages => {
      dispatch(setPages(pages));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContentSectionTable)
);
