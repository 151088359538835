//Types for Api
export const SET_API = "SET_API";
export const LOGOUT = "LOGOUT";
export const RESETALL = "RESETALL";
export const SET_PRICE_FORMARTEER = "SET_PRICE_FORMARTEER";
export const OPEN_FRONTLOADING = "OPEN_FRONTLOADING";
export const CLOSE_FRONTLOADING = "CLOSE_FRONTLOADING";

//Types for Data
export const SET_USER = "SET_USER";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const SET_ROLES = "SET_ROLES";
export const SET_MODULES = "SET_MODULES";
export const SET_ROLE_MODULES = "SET_ROLE_MODULES";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_USERS = "SET_USERS";

//Types for Landing
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGES_TABLE = "SET_LANGUAGES_TABLE";
export const SET_NAV_PAGES = "SET_NAV_PAGES";
export const SET_PAGES = "SET_PAGES";
export const SET_METATAGS = "SET_METATAGS";
export const SET_USERPAGES_PERMISSIONS = "SET_USERPAGES_PERMISSIONS";
export const SET_USERPAGES = "SET_USERPAGES";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";

//Types Blog
export const SET_BLOG_ENTRIES = "SET_BLOG_ENTRIES";
export const SET_BLOG_TAGS = "SET_BLOG_TAGS";
export const SET_BLOG_CATEGORIES = "SET_BLOG_CATEGORIES";

//Types experimental
export const RELOAD_ALL = "RELOAD_ALL";

//Types project
export const SET_CURRENT_EXAMPLE = "SET_CURRENT_EXAMPLE";
export const SET_ANY_DATA = "SET_ANY_DATA";
export const SET_EMAIL_TEMPLATES = "SET_EMAIL_TEMPLATES";

//TEMP
export const SET_EMAILS = "SET_EMAILS";
export const SET_EMAIL_DATA = "SET_EMAIL_DATA";
export const SET_CURRENT_LEADS = "SET_CURRENT_LEADS";
