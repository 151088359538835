import RestClient from "./RestClient";
import moment from "moment";
import { systemLog } from "../scripts/General";
import { mainIp, godOrg, adminOrg } from "../MainInfoData";

export default class API extends RestClient {
  constructor(authToken, godId, adminId) {
    super(mainIp + "/api", {
      headers: {
        // Include as many custom headers as you need
        Authorization: authToken
      }
    });
    this.authToken = authToken;
    this.idOrganizationGod = godId;
    this.idOrganizationAdmin = adminId;
  }

  GetGODOrgID() {
    return this.idOrganizationGod;
  }

  GetAdminOrgID() {
    return this.idOrganizationAdmin;
  }

  GetToken() {
    return this.authToken;
  }

  GetServerToken(id) {
    const finaURL = "/SysUsers/" + id + "/accessTokens";
    return this.GET(finaURL);
  }

  ResetPassword(email) {
    const finalURL = "/SysUsers/resetPwd";
    return this.POST(finalURL, { email: email });
  }

  SetNewPassword(oldPassword, newPassword) {
    const finalURL = "/SysUsers/change-password";
    return this.POST(finalURL, {
      oldPassword: oldPassword,
      newPassword: newPassword
    });
  }

  SingUp(email, password, firstName, lastName) {
    const finaURL = "/SysUsers";
    const data = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      acceptedTerms: true
    };
    return this.POST(finaURL, data);
  }

  Login(email, password, onesignalid) {
    const finaURL = "/SysUsers/login";
    return this.POST(finaURL, {
      email: email,
      password: password
    });
  }

  Logout() {
    const finaURL = "/SysUsers/logout";
    return this.POST(finaURL);
  }

  GetOrganizationUsers(id) {
    const finalURL = "/SysUsers";
    return this.GET(finalURL);
  }

  GetOrganizations(isGod, isAdmin) {
    const finalURL = "/Organizations";
    let filter = {};
    if (isGod) {
      filter = {};
    } else if (isAdmin) {
      filter = {
        where: {
          id: {
            neq: this.idOrganizationGod
          }
        }
      };
    } else {
      filter = {
        where: {
          noreal: true
        }
      };
    }

    return this.GET(finalURL, filter);
  }

  GetUsers(isGod, isAdmin, user, extraWhere) {
    let finalExtraWhere = {};
    if (extraWhere !== null && extraWhere !== undefined) {
      finalExtraWhere = extraWhere;
    }
    if (isGod) {
      return this.GetAllUsers(user.id);
    } else if (isAdmin) {
      //return this.GetAllUsersNoGod(user.id, finalExtraWhere);
      return this.GetAllUsersFrom(
        user.id,
        user.organizationId,
        finalExtraWhere
      );
    } else {
      return this.GetAllUsersFrom(
        user.id,
        user.organizationId,
        finalExtraWhere
      );
    }
  }

  GetAllUsers(id) {
    const finalURL = "/SysUsers";
    const filter = {
      include: ["role", "organization"],
      where: {
        id: { neq: id }
      }
    };
    return this.GET(finalURL, filter);
  }

  GetAllUsersNoGod(id, extraWhere) {
    const finalURL = "/SysUsers";
    const filter = {
      include: ["role", "organization"],
      where: {
        and: [
          { id: { neq: id } },
          { organizationId: { neq: this.idOrganizationGod } },

          ...extraWhere
        ]
      }
    };
    return this.GET(finalURL, filter);
  }

  GetAllUsersFrom(id, organizationId, extraWhere) {
    const finalURL = "/Organizations/" + organizationId + "/sysUsers";
    const filter = {
      include: ["role", "organization"],
      where: {
        and: [{ id: { neq: id } }, ...extraWhere]
      }
    };
    return this.GET(finalURL, filter);
  }

  GetGodOrganization() {
    const finalURL = "/Organizations";
    const filter = {
      where: {
        name: godOrg
      }
    };
    return this.GET(finalURL, filter);
  }

  GetAdminOrganization() {
    const finalURL = "/Organizations";
    const filter = {
      where: {
        name: adminOrg
      }
    };
    return this.GET(finalURL, filter);
  }

  GetMyOrganization(idUser) {
    const finalURL = "/SysUsers/" + idUser + "/organization";
    return this.GET(finalURL);
  }

  GetRoles(isGod, organizationId, value) {
    const finalURL = "/Roles";
    let filter = {};
    if (isGod === false) {
      filter = {
        where: {
          and: [
            {
              value: { gte: value }
            },
            { organizationId: organizationId }
          ]
        }
      };
    }
    return this.GET(finalURL, filter);
  }

  GetModules(isGod, myRoleModules) {
    const finalURL = "/Modules";
    let filter = {};
    if (isGod === false) {
      const finalIds = [];
      for (let i = 0; i < myRoleModules.length; i++) {
        const currentRoleModule = myRoleModules[i];
        if (
          currentRoleModule.create === true ||
          currentRoleModule.read === true ||
          currentRoleModule.update === true ||
          currentRoleModule.delete === true
        ) {
          finalIds.push(currentRoleModule.moduleId);
        }
      }
      filter = {
        where: {
          id: { inq: finalIds }
        }
      };
    }
    filter.include = ["roleModule"];
    return this.GET(finalURL, filter);
  }

  GetRoleModules() {
    const finalURL = "/RolesModules";
    return this.GET(finalURL);
  }

  GetMyRoleModules(id) {
    const finalURL = "/RolesModules";
    const filter = {
      include: ["module"],
      where: {
        roleId: id
      }
    };
    return this.GET(finalURL, filter);
  }

  GetUser(id) {
    const finalURL = "/SysUsers/" + id;
    const filter = {
      include: ["role", "organization"]
    };
    return this.GET(finalURL, filter);
  }

  UploadFile(file, deleteName, name) {
    return this.DeleteFile(deleteName)
      .then(response => {
        systemLog("SystemIMG", response);
        return this.DoUploadFile(file, name);
      })
      .catch(error => {
        systemLog("SystemIMG", error);
        return this.DoUploadFile(file, name);
      });
  }

  DoUploadFile(file, name) {
    systemLog("DoUploadFile", name);
    const savedFile = new File([file], name, { type: file.type });
    systemLog("DoUploadFile", savedFile);
    var fullRoute = this._fullRoute("/files/upload");
    const authToken = this.authToken;
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open("POST", fullRoute, true);
      xhr.setRequestHeader("Authorization", authToken);
      xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) {
          let result = JSON.parse(xhr.responseText);
          systemLog("DoUploadFile", result);
          systemLog("DoUploadFile", JSON.parse(xhr.responseText));
          let tempFile = result !== undefined ? result : undefined;
          systemLog("DoUploadFile", tempFile);
          const resolveResult = {
            name: tempFile ? tempFile.name : "",
            path: tempFile ? "/data/images/" + tempFile.name : ""
          };
          if (tempFile.nextgenimage !== undefined) {
            resolveResult.pathWEBP = tempFile
              ? "/data/images/" + tempFile.nextgenimage
              : "";
          }
          resolve(resolveResult);
        }
      };
      fd.append("file", savedFile);
      xhr.send(fd);
    });
  }

  CheckFileExist(name) {
    const url = "/Tools/fileExists";
    return this.GETPASSVALUE(url, { name: name });
  }

  DeleteFile(name) {
    return this._fetch(
      "/Containers/images/files/" + name,
      "DELETE",
      null,
      true
    );
  }

  GetLanguages(id, isGod) {
    const finalURL = "/Languages";
    let filter = {};
    if (isGod === false) {
      filter = {
        where: {
          organizationId: id
        }
      };
    }
    return this.GET(finalURL, filter);
  }

  DeleteThisFrom(sysDataName, id) {
    const finalURL = "/" + sysDataName + "/" + id;
    return this.DELETE(finalURL);
  }

  SetRelation(sysDataName, id, relationName, relationId) {
    const finalURL =
      "/" + sysDataName + "/" + id + "/" + relationName + "/rel/" + relationId;
    return this.PUT(finalURL, {});
  }

  RemoveRelation(sysDataName, id, relationName, relationId) {
    const finalURL =
      "/" + sysDataName + "/" + id + "/" + relationName + "/rel/" + relationId;
    return this.DELETE(finalURL);
  }

  RemoveRelations(sysDataName, id, relationName) {
    const finalURL = "/" + sysDataName + "/" + id + "/" + relationName;
    return this.DELETE(finalURL);
  }

  PostTo(sysDataName, patchData) {
    const finalURL = "/" + sysDataName;
    return this.POST(finalURL, patchData);
  }

  PatchTo(sysDataName, id, patchData) {
    const finalURL = "/" + sysDataName + "/" + id;
    return this.PATCH(finalURL, patchData);
  }

  GetFrom(dataPath) {
    const finalURL = "/" + dataPath;
    return this.GET(finalURL);
  }

  GetFromOrg(dataPath, isGod, isAdmin, organizationId) {
    const finalURL = "/" + dataPath;
    let filter = {};
    if (isAdmin) {
      filter = {
        where: {
          and: [
            {
              organizationId: {
                neq: this.idOrganizationGod
              }
            }
          ]
        }
      };
    } else {
      filter = {
        where: { and: [{ organizationId: organizationId }] }
      };
    }
    return this.GET(finalURL, filter);
  }

  GetFromInclude(dataPath, include) {
    const finalURL = "/" + dataPath;
    let filter = {
      include: include
    };
    systemLog("API", filter);
    return this.GET(finalURL, filter);
  }

  GetFromWhere(dataPath, where) {
    const finalURL = "/" + dataPath;
    let filter = {
      where: where
    };
    systemLog("API", filter);
    return this.GET(finalURL, filter);
  }

  FindFilterNoLimitOnlyId(sysDataName, and, include) {
    const finalURL = "/" + sysDataName;
    let filter = {
      fields: { id: true },
      include: include,
      where: {
        and: and
      }
    };
    if (and.length === 0) {
      filter = {
        include: include
      };
    }
    return this.GET(finalURL, filter);
  }

  FindFilterNoLimit(sysDataName, and, include) {
    const finalURL = "/" + sysDataName;
    let filter = {
      include: include,
      where: {
        and: and
      }
    };
    if (and.length === 0) {
      filter = {
        include: include
      };
    }
    return this.GET(finalURL, filter);
  }

  FindFilter(sysDataName, and, include, extraWhere, limit, currentPage) {
    let currentSkip = (currentPage - 1) * limit;
    const finalURL = "/" + sysDataName;
    let filter = {
      skip: currentSkip,
      limit: limit,
      include: include
    };
    if (and.length !== 0) {
      if (filter.where === undefined) {
        filter.where = {
          and: []
        };
      }
      filter.where.and = [...filter.where.and, ...and];
    }

    if (extraWhere.length !== 0) {
      if (filter.where === undefined) {
        filter.where = {
          and: []
        };
      }
      filter.where.and = [...filter.where.and, ...extraWhere];
    }
    systemLog("FindFilter", finalURL);
    systemLog("FindFilter", filter);
    return this.GET(finalURL, filter);
  }

  FindFilterPage(sysDataName, and, extraWhere, limit, currentPage) {
    let currentSkip = (currentPage - 1) * limit;
    const finalURL = "/" + sysDataName;
    let filter = {
      skip: currentSkip,
      limit: limit
    };

    if (and.length !== 0) {
      if (filter.where === undefined) {
        filter.where = {
          and: []
        };
      }
      filter.where.and = [...filter.where.and, ...and];
    }

    if (extraWhere.length !== 0) {
      if (filter.where === undefined) {
        filter.where = {
          and: []
        };
      }
      filter.where.and = [...filter.where.and, ...extraWhere];
    }
    systemLog("API", finalURL);
    systemLog("API", filter);
    return this.GET(finalURL, filter);
  }

  GetPages(isGod, id) {
    if (isGod) {
      return this.GetAllPages();
    } else {
      return this.GetOrganizationPages(id);
    }
  }

  GetAllPages() {
    const finalURL = "/Pages";
    const filter = {
      include: ["organization", "sectionPages", "metaTags"]
    };
    return this.GET(finalURL, filter);
  }

  GetOrganizationPages(id) {
    const finalURL = "/Organizations/" + id + "/pages";
    const filter = {
      include: ["organization", "sectionPages", "metaTags"]
    };
    return this.GET(finalURL, filter);
  }

  GetMetatags(pagesId) {
    const finalURL = "/MetaTagss";
    const filter = {
      include: ["page"],
      where: {
        pageId: { inq: pagesId }
      }
    };
    return this.GET(finalURL, filter);
  }

  GetUserPagesPermissions(id) {
    const finalURL = "/UserPages";
    const filter = {
      where: {
        sysUserId: id
      }
    };
    return this.GET(finalURL, filter);
  }

  GetUserPages(isGod, users) {
    const finalURL = "/UserPages";
    let filter = {};
    if (isGod === false) {
      let finalIds = [];
      for (let i = 0; i < users.length; i++) {
        finalIds.push(users[i].id);
      }
      filter = {
        where: {
          sysUserId: {
            inq: finalIds
          }
        }
      };
    }
    return this.GET(finalURL, filter);
  }

  GetPage(id) {
    const finalURL = "/Pages/" + id;
    const filter = {
      include: ["organization", "sectionPages", "metaTags"]
    };
    return this.GET(finalURL, filter);
  }

  GetSectionBase(id) {
    const finalURL = "/SectionsPages/" + id + "/contentSections";
    const filter = {
      include: [
        "dataContentImages",
        "dataContentLinks",
        "dataContentTexts",
        "dataContentRichTexts"
      ],
      where: {
        isBase: true
      }
    };
    return this.GET(finalURL, filter);
  }

  GetSection(id, isGod) {
    const finalURL = "/SectionsPages/" + id;
    let filter = {
      include: [
        {
          relation: "contentSections",
          scope: {
            filter: { order: "name DESC" }
          }
        }
      ]
    };
    if (isGod === false) {
      filter = {
        include: {
          relation: "contentSections",
          scope: {
            where: { isBase: false }
          }
        }
      };
    }
    return this.GET(finalURL, filter);
  }

  GetContentSection(id) {
    const finalURL = "/ContentsSections/" + id;
    const filter = {
      include: [
        "dataContentImages",
        "dataContentLinks",
        "dataContentTexts",
        "dataContentRichTexts",
        "dataContentFiles"
      ]
    };
    return this.GET(finalURL, filter);
  }
}
