import { menuTitle } from "../MainInfoData";

export const tableEditItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "organizationId",
      dataLabel: "Organización",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "organizations",
        key: "id",
        value: "id",
        text: "name"
      },
      dataType: "selector"
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "menuTitle",
      dataLabel: "Menu Interno",
      dataType: "selector",
      selectorData: menuTitle
    },
    {
      dataName: "showOnMenu",
      dataLabel: "Mostrar en Menú",
      dataType: "bool"
    },
    {
      dataName: "active",
      dataLabel: "Pagina Activa",
      dataType: "bool"
    },
    {
      dataName: "order",
      dataLabel: "Orden",
      dataType: "number",
      minValue: 0
    }
  ]
};

export const tableCreateItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "organizationId",
      dataLabel: "Organización",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "organizations",
        key: "id",
        value: "id",
        text: "name"
      },
      dataType: "selector"
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "menuTitle",
      dataLabel: "Menu Interno",
      dataType: "selector",
      selectorData: menuTitle
    },
    {
      dataName: "showOnMenu",
      dataLabel: "Mostrar en Menú",
      dataType: "bool"
    },
    {
      dataName: "active",
      dataLabel: "Pagina Activa",
      dataType: "bool"
    },
    {
      dataName: "order",
      dataLabel: "Orden",
      dataType: "number",
      minValue: 0
    }
  ]
};

export const tableViewItemData = {
  dataTitle: [
    {
      dataName: "email",
      dataLabel: "Correo",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "menuTitle",
      dataLabel: "Menu Interno",
      dataType: "selector",
      selectorData: menuTitle
    },
    {
      dataName: "organization",
      dataType: "insideModule",
      dataLabel: "Organización",
      dataModule: {
        dataName: "name",
        dataLabel: "Organizacion",
        dataType: "string"
      }
    },
    {
      dataName: "showOnMenu",
      dataLabel: "Mostrar en Menú",
      dataType: "bool"
    },
    {
      dataName: "active",
      dataLabel: "Pagina Activa",
      dataType: "bool"
    },
    {
      dataName: "order",
      dataLabel: "Orden",
      dataType: "number",
      minValue: 0
    }
  ]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre Privado",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string"
  },
  {
    dataName: "menuTitle",
    dataLabel: "Menu Interno",
    dataType: "selector",
    selectorData: menuTitle,
    canBeEdited: false
  },
  {
    dataName: "organization",
    dataType: "insideModule",
    dataLabel: "Organizacion",
    dataModule: {
      dataName: "name",
      dataType: "string"
    }
  },
  {
    dataName: "showOnMenu",
    dataLabel: "Mostrar en Menú",
    canBeEdited: true,
    dataType: "bool"
  },
  {
    dataName: "active",
    dataLabel: "Pagina Activa",
    canBeEdited: true,
    dataType: "bool"
  },
  {
    dataName: "order",
    dataLabel: "Orden",
    dataType: "number",
    minValue: 0
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataType: "string"
  },
  {
    dataName: "active",
    dataLabel: "Menu",
    dataType: "bool"
  },
  {
    dataName: "showOnMenu",
    dataLabel: "Activa",
    dataType: "bool"
  },
  {
    dataName: "organization",
    dataType: "insideModule",
    dataLabel: "Organización",
    dataModule: {
      dataName: "name",
      dataType: "string"
    }
  }
];
