import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setRoles, setRoleModules } from "../../redux/actions";
import * as RolesData from "../../TablesData/rolesDataTables";
import RolesCreateItem from "./RolesCreateItem";
import RolesEditItem from "./RolesEditItem";
import AdminTable from "../../layaouts/AdminTable";
import { systemLog } from "../../scripts/General";

class RolesTable extends Component {
  constructor(props) {
    super(props);

    if (this.props.user.isGod) {
      const organizationToSet = [];
      for (let i = 0; i < this.props.organizations.length; i++) {
        const tempOrg = { ...this.props.organizations[i] };
        const newOrg = {};
        newOrg.key = tempOrg.id;
        newOrg.value = tempOrg.id;
        newOrg.text = tempOrg.name;
        organizationToSet.push(newOrg);
      }
      const tempNoOrg = {};
      tempNoOrg.key = "NOORGSHIT";
      tempNoOrg.value = null;
      tempNoOrg.text = "SIN ORGANIZACION";
      organizationToSet.unshift(tempNoOrg);

      const organizationEdit = {
        dataName: "organizationId",
        dataLabel: "Organización",
        selectorData: organizationToSet,
        notRequired: true,
        dataType: "selector"
      };

      const tableEditItemData = RolesData.tableEditItemData.dataVisual.find(
        data => data.dataName === "organizationId"
      );

      if (tableEditItemData === undefined) {
        RolesData.tableEditItemData.dataVisual.unshift(organizationEdit);
      } else {
        tableEditItemData.selectorData = organizationToSet;
      }

      const tableCreateItemData = RolesData.tableCreateItemData.dataVisual.find(
        data => data.dataName === "organizationId"
      );

      if (tableCreateItemData === undefined) {
        RolesData.tableCreateItemData.dataVisual.unshift(organizationEdit);
      } else {
        tableCreateItemData.selectorData = organizationToSet;
      }
    }

    this.specialPermissionsUser = [
      {
        id: this.props.user.role.id,
        delete: false,
        read: false,
        update: false,
        canBeSelected: false
      }
    ];
    this.refreshRoles = this.refreshRoles.bind(this);
  }

  componentDidMount() {
    this.refreshRoles();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  refreshRoles() {
    this.checkLoading(true);
    this.props.API.GetRoles(
      this.props.user.isGod,
      this.props.user.organizationId,
      this.props.user.role.value
    )
      .then(responseRoles => {
        if (responseRoles.error === undefined) {
          this.props.API.GetRoleModules()
            .then(roleModuleResponse => {
              if (roleModuleResponse.error === undefined) {
                this.props.setRoleModules(roleModuleResponse);
                const finalRoles = [];
                for (let i = 0; i < responseRoles.length; i++) {
                  const currentRole = responseRoles[i];
                  const orgSame = this.props.organizations.find(
                    data => data.id === currentRole.organizationId
                  );
                  if (orgSame !== undefined) {
                    currentRole["organization"] = orgSame;
                  }
                  finalRoles.push(currentRole);
                }
                this.props.setRoles(finalRoles);
                this.checkLoading(false);
              } else {
                this.checkLoading(false);
              }
            })
            .catch(error => {
              this.checkLoading(false);
            });
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    if (this.props.user.isGod === true) {
      return (
        <AdminTable
          table={this.props.roles}
          specialPermissions={this.specialPermissionsUser}
          tableViewItemData={RolesData.tableViewItemData}
          tableEditItemData={RolesData.tableEditItemData}
          tableCreateItemData={RolesData.tableCreateItemData}
          tableData={RolesData.tableData}
          tableSysName={"Roles"}
          tablePermName={"ROLES"}
          tableName={"ROLES"}
          singleName={"ROL"}
          selectorDataShow={["name", "description"]}
          tableSubtitle={"Administrador de Roles"}
          filterTable={RolesData.filterTable}
          filterAllThatApply={false}
          refreshTable={() => {
            this.refreshRoles();
          }}
          returnDeleteAllObj={deleteRoles => {
            systemLog(this, deleteRoles);
            let currentDeletedRoles = 0;
            for (let i = 0; i < deleteRoles.length; i++) {
              const currentDelete = deleteRoles[i];
              const where = { roleId: currentDelete.id };
              this.props.API.GetFromWhere("RolesModules", where).then(
                response => {
                  systemLog(this, response);
                  if (response.length > 0) {
                    let insideDel = 0;
                    for (let d = 0; d < response.length; d++) {
                      const currentRoleModule = response[d];
                      this.props.API.DeleteThisFrom(
                        "RolesModules",
                        currentRoleModule.id
                      ).then(responseDel => {
                        systemLog(this, responseDel);
                        insideDel = insideDel + 1;
                        if (insideDel === response.length) {
                          this.props.API.DeleteThisFrom(
                            "Roles",
                            currentDelete.id
                          ).then(responseFinalDel => {
                            systemLog(this, responseFinalDel);
                            currentDeletedRoles = currentDeletedRoles + 1;
                            if (currentDeletedRoles === deleteRoles.length) {
                              this.refreshRoles();
                            }
                          });
                        }
                      });
                    }
                  } else {
                    this.props.API.DeleteThisFrom(
                      "Roles",
                      currentDelete.id
                    ).then(responseFinalDel => {
                      systemLog(this, responseFinalDel);
                      currentDeletedRoles = currentDeletedRoles + 1;
                      if (currentDeletedRoles === deleteRoles.length) {
                        this.refreshRoles();
                      }
                    });
                  }
                }
              );
            }
          }}
          customCreator={RolesCreateItem}
          customEditor={RolesEditItem}
        />
      );
    } else {
      return (
        <AdminTable
          table={this.props.roles}
          tableParentIdConnect={"organizationId"}
          tableParentId={this.props.user.organizationId}
          specialPermissions={this.specialPermissionsUser}
          tableViewItemData={RolesData.tableViewItemData}
          tableEditItemData={RolesData.tableEditItemData}
          tableCreateItemData={RolesData.tableCreateItemData}
          tableData={RolesData.tableData}
          tableSysName={"Roles"}
          tablePermName={"ROLES"}
          tableName={"ROLES"}
          singleName={"ROL"}
          selectorDataShow={["name", "description"]}
          tableSubtitle={"Administrador de Roles"}
          filterTable={RolesData.filterTable}
          filterAllThatApply={false}
          refreshTable={() => {
            this.refreshRoles();
          }}
          returnDeleteAllObj={deleteRoles => {
            systemLog(this, deleteRoles);
            let currentDeletedRoles = 0;
            for (let i = 0; i < deleteRoles.length; i++) {
              const currentDelete = deleteRoles[i];
              const where = { roleId: currentDelete.id };
              this.props.API.GetFromWhere("RolesModules", where).then(
                response => {
                  systemLog(this, response);
                  if (response.length > 0) {
                    let insideDel = 0;
                    for (let d = 0; d < response.length; d++) {
                      const currentRoleModule = response[d];
                      this.props.API.DeleteThisFrom(
                        "RolesModules",
                        currentRoleModule.id
                      ).then(responseDel => {
                        systemLog(this, responseDel);
                        insideDel = insideDel + 1;
                        if (insideDel === response.length) {
                          this.props.API.DeleteThisFrom(
                            "Roles",
                            currentDelete.id
                          ).then(responseFinalDel => {
                            systemLog(this, responseFinalDel);
                            currentDeletedRoles = currentDeletedRoles + 1;
                            if (currentDeletedRoles === deleteRoles.length) {
                              this.refreshRoles();
                            }
                          });
                        }
                      });
                    }
                  } else {
                    this.props.API.DeleteThisFrom(
                      "Roles",
                      currentDelete.id
                    ).then(responseFinalDel => {
                      systemLog(this, responseFinalDel);
                      currentDeletedRoles = currentDeletedRoles + 1;
                      if (currentDeletedRoles === deleteRoles.length) {
                        this.refreshRoles();
                      }
                    });
                  }
                }
              );
            }
          }}
          customCreator={RolesCreateItem}
          customEditor={RolesEditItem}
        />
      );
    }
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { user, roles, organizations } = data;
  return { user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setRoles: roles => {
      dispatch(setRoles(roles));
    },
    setRoleModules: rolesModules => {
      dispatch(setRoleModules(rolesModules));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RolesTable)
);
