import ColorEditor from "color";
import {
  MainColor,
  LoginBackground,
  ButtonColor,
  ColorFrontMain,
  ColorFrontButton
} from "../MainInfoData";
export const headerHeight = 60;
export const margionTopFromPages = 10;
export const contentMarginTop = 15;
export const contentMarginBottom = 15;
export const menuItemsWidth = 110;
export const chevronSize = 20;
export const iconSizeLeft = 29;
export const separationIconLeft = 16;
export const sideShow = iconSizeLeft + separationIconLeft * 2;
export const menuWidth =
  menuItemsWidth + chevronSize + sideShow + separationIconLeft * 2;

export const adminMargin = "1rem";
export const adminPadding = "0.8rem";
export const headerAdminHeight = 60;
export const headerSeparation = "1rem";
export const adminRadius = 5;
export const subHeaderAdminHeight = 70;
export const adminSeparation = "1.3rem";

export const textSizePTable = "0.8rem";

export const textSizeMenuTop = "0.9rem";
export const textSizeSubMenuTop = "0.9rem";
export const textSizeMenu = "0.9rem";
export const textSizeSubMenu = "0.85rem";
export const textSizeHeaderTitle = "1.4rem";
export const textSizeHeaderSubTitle = "1.1rem";
export const textSizeSubHeaderTitle = "1.0rem";
export const textSizePTitle = "0.9rem";
export const textSizePBody = "0.85rem";
export const textSizePTitleSmall = "0.87rem";
export const textSizePBodySmall = "0.8rem";

export const styleBasic = {
  backgroundColor: "transparent",
  border: "0px",
  margin: 0,
  padding: 0,
  elevation: 0,
  borderRadius: 0,
  borderColor: 0,
  borderBottomColor: "transparent",
  borderBottomWidth: 0,
  boxShadow: "0px 0px 0px"
};

export const backgroundCentered = {
  position: "fixed",
  backgroundSize: "cover",
  backgroundPosition: "center"
};

export const styleCenteredObject = {
  display: "-webkit-flex",
  WebkitAlignContent: "center",
  WebkitAlignSelf: "center",
  WebkitAlignItems: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center"
};

export const styleForm = {
  ...styleCenteredObject,
  alignSelf: "flex-start",
  height: "100%",
  padding: ".67857143em 1em",
  background: "white",
  border: "1px solid rgba(34,36,38,.15)",
  color: "rgba(0,0,0,.87)",
  borderRadius: ".28571429rem"
};

const DarkenValue = 0.2;
const DarkerValue = 0.29;
const LightenValue = 0.1;
const LighterValue = 0.15;
const AlphaValue = 0.2;

export const colors = {
  //Main Colors
  mainColor: MainColor,
  colorFrontMain: ColorFrontMain,
  buttonColor: ButtonColor,
  colorFrontButton: ColorFrontButton,
  mainColorDark: ColorEditor(MainColor).darken(DarkenValue),
  mainColorDarker: ColorEditor(MainColor).darken(DarkerValue),
  mainColorLight: ColorEditor(MainColor).lighten(LightenValue),
  mainColorLighter: ColorEditor(MainColor).lighten(LighterValue),

  //Main Colors Alpha
  mainColorAlpha: ColorEditor(MainColor).alpha(AlphaValue),
  mainColorAlphaDark: ColorEditor(MainColor)
    .darken(DarkenValue)
    .alpha(AlphaValue),
  mainColorAlphaLight: ColorEditor(MainColor)
    .lighten(LightenValue)
    .alpha(AlphaValue),

  //Main Colors Selector
  mainColorSelector: ColorEditor(MainColor).darken(0.35),
  mainColorSelectorSecondary: ColorEditor(MainColor).darken(0.3),

  //Logo Colors
  logoColor: "#000000",
  logoColorLetter: "#FFFFFF",

  //Text Color
  textColor: "#717171",

  //Calendar Color
  colorHeaderCalendar: "#000000",

  //Login Colors
  backgroundColorLogin: LoginBackground,
  frontColorLogin: "#FFFFFF",

  //Loading Colors
  primaryCenterLoadingColor: "#AFAFAF",
  secondaryCenterLoadingColor: "#868686",
  primaryTopLoadingColor: ColorEditor(MainColor).darken(0.2),
  secondaryTopLoadingColor: ColorEditor(MainColor).darken(0.5),

  //Action Colors
  webEditorColor: "#7986cb",
  webEditorColorFront: "#FFFFFF",
  sysEditorColor: "#ff7043",
  sysEditorColorFront: "#FFFFFF",
  uploadColor: "#bdbdbd",
  uploadFrontColor: "#424242",
  addColor: "#4caf50",
  addFrontColor: "#FFFFFF",
  changeColor: "#f9a825",
  changeFrontColor: "#FFFFFF",
  removeColor: "#e53935",
  removeFrontColor: "#FFFFFF",
  viewColor: "#03a9f4",
  viewFrontColor: "#FFFFFF",
  linkColor: "#2196f3",
  linkFrontColor: "#FFFFFF",
  tableActionColor: "#333333",
  disableColor: "#D3D3D3",

  //Tables Color
  adminBackgroundColor: "#E9E9E9",
  adminPageBackgroundColor: "#E9E9E9",
  adminTableBackgroundColor: "#FFFFFF",
  adminTableItemMainColor: "#F9F9F9",
  adminTableItemSecondaryColor: "#FFFFFF",
  adminTableItemBorderColor: "#EEEEEE",

  //Editor Data Color
  textColorAdminHeader: "#333333",
  colorAdminSubHeader: "transparent",
  textColorAdminSubHeader: "#333333",
  borderTitleColor: "#bdbdbd",
  borderColor: "#E7E7E7"
};

export const elevation = {
  boxShadow: "0 9px 13px rgba(0,0,0,0.02), 0 9px 13px rgba(0,0,0,0.02)"
};

export const elevationCustom = (less, max, color) => {
  const finalStyle = {
    boxShadow:
      "0" +
      " " +
      less +
      " " +
      max +
      " " +
      color +
      ", " +
      "0" +
      " " +
      less +
      " " +
      max +
      " " +
      color
  };
  return finalStyle;
};
