import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router";
import { replace } from "connected-react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigator from "./Navigator";
import FakePage from "./pages/FakePage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import LoginPage from "./pages/LoginPage";
import {
  resetAll,
  setApi,
  setAuthapiToken,
  setDispatch,
  setModules,
  setOrganization,
  setOrganizations,
  setPriceFormartter,
  setRoleModules,
  setRoles,
  setUser,
  setUserPermissions,
  setPages,
  setUserPagesPermissions
} from "./redux/actions";
import { systemLog } from "./scripts/General";
import { initRoute, adminOrg, godRole, adminRole } from "./MainInfoData";

class SetDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didLoadApis: false,
      didCheckSavedData: false,
      didCheckToken: false,
      thereIsToken: false
    };
    this.API = this.props.API;
    this.formatter = this.props.formatter;
    this.checkSavedData = this.checkSavedData.bind(this);
    this.resetToLogin = this.resetToLogin.bind(this);
    this.resetState = this.resetState.bind(this);
    this.loadLandingData = this.loadLandingData.bind(this);
    this.loadExtraData = this.loadExtraData.bind(this);
    this.finalState = this.finalState.bind(this);
    this.mainRender = this.mainRender.bind(this);
    this.setData = this.setData.bind(this);
    this.resetFullPath = "";
    this.props.setDispatch();
  }

  componentWillMount() {
    this.resetFullPath = "";

    if (this.props.location.pathname.includes("reset-password")) {
      const finalDivide = this.props.location.pathname.split("/");
      if (finalDivide.length === 4) {
        this.resetFullPath = this.props.location.pathname;
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setData();
    }, 600);
  }

  setData() {
    systemLog(this, "DO SET DATA");
    this.props.setApi();
    this.props.setPriceFormartter();
  }

  componentWillReceiveProps(nextProps) {
    systemLog(this, nextProps);
    this.API = nextProps.API;
    this.formatter = nextProps.formatter;
    if (
      this.state.didLoadApis === false &&
      this.state.didCheckSavedData === false
    ) {
      if (this.API !== null && this.formatter !== null) {
        systemLog(this, "WE HAVE API AND FORMATTER");
        this.setState({
          didLoadApis: true
        });
        this.checkSavedData();
      }
    } else if (nextProps.doingLogOut === true) {
      systemLog(this, "WE ARE DOING LOGOUT");
      this.setState({
        didLoadApis: false,
        didCheckSavedData: false,
        didCheckToken: false,
        thereIsToken: false
      });
      this.props.resetAll();
    }
  }

  resetToLogin() {
    if (this.resetFullPath !== "") {
      systemLog(this, "WE HAVE A RESET FULL PATH");
      systemLog(this, this.resetFullPath);
      this.props.replace(this.resetFullPath);
      this.resetFullPath = "";
    } else {
      systemLog(this, "WE MOST GO TO LOGIN");
      this.props.replace("/login");
    }
    this.setState({
      didLoadApis: false,
      didCheckSavedData: true,
      didCheckToken: true,
      thereIsToken: false
    });
  }

  resetState() {
    this.setState({
      didCheckSavedData: false,
      didLoadApis: false,
      didCheckToken: true,
      thereIsToken: false
    });
  }

  checkSavedData() {
    systemLog(this, "WE CHECK SAVED DATA");
    if (this.API === null || this.API === undefined) {
      systemLog(this, "WE DONT HAVE API, RETURN TO LOGIN");
      this.props.setApi();
      this.props.setPriceFormartter();
      this.resetToLogin();
      return;
    }
    if (this.API.GetToken() !== undefined) {
      systemLog(this, "WE HAVE A TOKEN");
      this.API.GetGodOrganization();
      this.props.replace("/" + initRoute);
      const userID = sessionStorage.getItem("id");
      this.API.GetUser(userID)
        .then(userResponse => {
          systemLog("InitData", userResponse);
          systemLog("InitData", userResponse);
          if (userResponse.error !== undefined) {
            this.resetToLogin();
          } else {
            if (
              userResponse.role.name.toUpperCase() === godRole.toUpperCase()
            ) {
              userResponse.isGod = true;
            } else {
              userResponse.isGod = false;
            }
            if (
              userResponse.role.name.toUpperCase() === adminRole.toUpperCase()
            ) {
              userResponse.isAdmin = true;
            } else {
              userResponse.isAdmin = false;
            }
            this.API.GetOrganizations(
              userResponse.isGod,
              userResponse.isAdmin,
              userResponse.organizationId
            )
              .then(organizationsResponse => {
                if (organizationsResponse.error !== undefined) {
                  this.resetToLogin();
                } else {
                  this.API.GetMyRoleModules(userResponse.role.id)
                    .then(roleModulesResponse => {
                      if (roleModulesResponse.error !== undefined) {
                        this.resetToLogin();
                      } else {
                        const finalRoleModules = [];
                        for (let i = 0; i < roleModulesResponse.length; i++) {
                          const currentRoleModule = roleModulesResponse[i];
                          if (
                            currentRoleModule.create === true ||
                            currentRoleModule.read === true ||
                            currentRoleModule.update === true ||
                            currentRoleModule.delete === true
                          ) {
                            finalRoleModules.push(currentRoleModule);
                          }
                        }
                        this.API.GetRoles(
                          userResponse.isGod,
                          userResponse.organizationId,
                          userResponse.role.value
                        )
                          .then(rolesResponse => {
                            if (rolesResponse.error !== undefined) {
                              this.resetToLogin();
                            } else {
                              const finalRoles = [];
                              for (let i = 0; i < rolesResponse.length; i++) {
                                const currentRole = rolesResponse[i];
                                const orgSame = organizationsResponse.find(
                                  data => data.id === currentRole.organizationId
                                );
                                if (orgSame !== undefined) {
                                  currentRole["organization"] = orgSame;
                                }
                                finalRoles.push(currentRole);
                              }
                              this.API.GetModules(
                                userResponse.isGod,
                                finalRoleModules
                              )
                                .then(modulesResponse => {
                                  if (modulesResponse.error !== undefined) {
                                    this.resetToLogin();
                                  } else {
                                    this.API.GetRoleModules()
                                      .then(roleModuleResponse => {
                                        if (
                                          roleModuleResponse.error !== undefined
                                        ) {
                                          this.resetToLogin();
                                        } else {
                                          this.props.setOrganizations(
                                            organizationsResponse
                                          );
                                          this.props.setRoleModules(
                                            roleModuleResponse
                                          );
                                          this.props.setModules(
                                            modulesResponse
                                          );
                                          this.props.setRoles(finalRoles);
                                          this.props.setUser(userResponse);
                                          this.props.setOrganization(
                                            userResponse.organization
                                          );
                                          this.props.setUserPermissions(
                                            finalRoleModules
                                          );
                                          this.resetFullPath = "";
                                          if (
                                            this.props.haveLanding === false
                                          ) {
                                            this.loadExtraData({
                                              user: userResponse
                                            });
                                          } else {
                                            this.loadLandingData({
                                              user: userResponse
                                            });
                                          }
                                        }
                                      })
                                      .catch(error => {
                                        this.resetToLogin();
                                      });
                                  }
                                })
                                .catch(error => {
                                  this.resetToLogin();
                                });
                            }
                          })
                          .catch(error => {
                            this.resetToLogin();
                          });
                      }
                    })
                    .catch(error => {
                      this.resetToLogin();
                    });
                }
              })
              .catch(error => {
                this.resetToLogin();
              });
          }
        })
        .catch(error => {
          this.resetToLogin();
        });
    } else if (this.state.didCheckToken === false) {
      systemLog(this, "WE NEED TOKEN");
      this.setState({
        didCheckToken: true
      });
      const token = sessionStorage.getItem("token");
      const userID = sessionStorage.getItem("id");
      if (token === null || userID === null || token === "" || userID === "") {
        systemLog(this, "WE DONT HAVE SAVED TOKEN");
        systemLog(this, token);
        systemLog(this, userID);
        this.resetToLogin();
      } else {
        systemLog(this, "WE NEED TO GET THE TOKEN");
        this.setState({
          didLoadApis: false,
          didCheckSavedData: false,
          thereIsToken: true
        });
        this.props.setAuthapiToken(token);
      }
    } else {
      systemLog(this, "WE RETURN TO LOGIN");
      this.resetToLogin();
    }
  }

  finalState() {
    this.setState({
      didCheckSavedData: true,
      didLoadApis: true,
      didCheckToken: true,
      thereIsToken: true
    });
  }

  loadLandingData({ user }) {
    this.API.GetPages(user.isGod, user.organizationId)
      .then(responsePage => {
        if (responsePage.error !== undefined) {
          this.resetToLogin();
        } else {
          this.API.GetUserPagesPermissions(user.id)
            .then(userPagesPermissionsResponse => {
              if (userPagesPermissionsResponse.error !== undefined) {
                this.resetToLogin();
              } else {
                this.props.setPages(responsePage);
                this.props.setUserPagesPermissions(
                  userPagesPermissionsResponse
                );
                this.loadExtraData({ user });
              }
            })
            .catch(error => {
              this.resetToLogin();
            });
        }
      })
      .catch(error => {
        this.resetToLogin();
      });
  }

  loadExtraData({ user }) {
    this.finalState();
  }
  mainRender() {
    if (
      this.state.didLoadApis === true &&
      this.state.thereIsToken === true &&
      this.state.didCheckSavedData === true
    ) {
      return <Navigator />;
    }
    if (
      this.state.didCheckToken === true &&
      this.state.thereIsToken === false
    ) {
      return (
        <Switch>
          <Route exact path="/system">
            <FakePage />
          </Route>
          <Route exact path="/landing">
            <FakePage />
          </Route>
          <Route exact path="/">
            <FakePage />
          </Route>
          <Route exact path="/reset-password/:token/:id">
            <ResetPasswordPage
              clickOnReset={() => {
                this.resetState();
              }}
            />
          </Route>
          <Route exact path="/login">
            <LoginPage
              clickOnLogin={() => {
                this.resetState();
              }}
            />
          </Route>
        </Switch>
      );
    }
    return <FakePage />;
  }

  render() {
    return (
      <div>
        {this.mainRender()}
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          autoClose={4500}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { haveLanding } = data;
  const { API, onLoading, formatter, doingLogOut } = api;
  return {
    API,
    onLoading,
    formatter,
    doingLogOut,
    haveLanding
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDispatch: () => {
      dispatch(setDispatch(dispatch));
    },
    setApi: () => {
      dispatch(setApi());
    },
    setAuthapiToken: token => {
      dispatch(setAuthapiToken(token));
    },
    setPriceFormartter: () => {
      dispatch(setPriceFormartter());
    },
    resetAll: () => {
      dispatch(resetAll());
    },
    setOrganization: organization => {
      dispatch(setOrganization(organization));
    },
    setOrganizations: organizations => {
      dispatch(setOrganizations(organizations));
    },
    setUser: userData => {
      dispatch(setUser(userData));
    },
    setUserPermissions: userPermissions => {
      dispatch(setUserPermissions(userPermissions));
    },
    setModules: modules => {
      dispatch(setModules(modules));
    },
    setRoles: roles => {
      dispatch(setRoles(roles));
    },
    setRoleModules: rolesModules => {
      dispatch(setRoleModules(rolesModules));
    },
    setPages: pages => {
      dispatch(setPages(pages));
    },
    setUserPagesPermissions: userPagesPermissions => {
      dispatch(setUserPagesPermissions(userPagesPermissions));
    },
    replace: (location, state) => {
      systemLog("SetDataComponent", location);
      dispatch(replace(location, state));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetDataComponent)
);
