import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Loader,
  Segment
} from "semantic-ui-react";
import LogoRender from "../components/LogoRender";
import { setAuthapiToken, setDispatch } from "../redux/actions";
import { validateEmail } from "../scripts/General";
import { colors, styleCenteredObject } from "../style";
import ModalResetPasswordLogin from "../layaouts/ModalResetPasswordLogin";
import { adminSysName } from "../MainInfoData";

const loginFailGeneral = "Hubo un error, volver a intentar";
const loginFailText = "Correo y/o contraseña invalidos";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginCheck: false,
      currentError: "",
      currentErrorCode: "",
      currentEmail: "",
      currentPassword: "",
      errorEmail: false,
      errorPassword: false,
      showGetPass: false
    };
    this.API = this.props.API;
    this.login = this.login.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderCode = this.renderCode.bind(this);
    this.props.setDispatch();
  }

  componentWillReceiveProps(nextProps) {
    this.API = nextProps.API;
  }

  login() {
    this.setState({
      loginCheck: true,
      currentError: "",
      currentErrorCode: "",
      errorEmail: false,
      errorPassword: false
    });
    let errorEmail = false;
    let errorPassword = false;
    let currentError = "";
    if (validateEmail(this.state.currentEmail) === false) {
      errorEmail = true;
      currentError = "Colocar correo valido";
    }
    if (this.state.currentPassword === "") {
      errorPassword = true;
      if (errorEmail === true) {
        currentError = "Colocar correo y contraseña validos";
      } else {
        currentError = "Colocar contraseña valida";
      }
    }

    if (errorEmail === true || errorPassword === true) {
      this.setState({
        currentError: currentError,
        errorEmail: errorEmail,
        errorPassword: errorPassword,
        loginCheck: false
      });
      return;
    }

    this.setState({ currentError });
    this.API.Login(this.state.currentEmail, this.state.currentPassword)
      .then(response => {
        if (response.error !== undefined) {
          if (response.error.message !== undefined) {
            if (response.error.code.includes("LOGIN_FAILED")) {
              this.setState({
                currentError: loginFailText,
                currentErrorCode: response.error.statusCode,
                errorEmail: true,
                errorPassword: true,
                loginCheck: false
              });
              return;
            }
          }
          this.setState({
            currentError: loginFailGeneral,
            currentErrorCode: "SIN CODIGO",
            errorEmail: true,
            errorPassword: true,
            loginCheck: false
          });
        } else {
          sessionStorage.setItem("token", response.id);
          sessionStorage.setItem("id", response.userId);
          this.props.setAuthapiToken(response.id);
        }
      })
      .catch(error => {
        if (error.message !== undefined) {
          if (error.code.includes("LOGIN_FAILED")) {
            this.setState({
              currentError: loginFailText,
              currentErrorCode: error.statusCode,
              errorEmail: true,
              errorPassword: true,
              loginCheck: false
            });
            return;
          }
        }
        this.setState({
          currentError: loginFailGeneral,
          currentErrorCode: "SIN CODIGO",
          errorEmail: true,
          errorPassword: true,
          loginCheck: false
        });
      });
  }

  renderCode() {
    if (this.state.currentErrorCode !== "") {
      return (
        <div>
          <div style={{ height: "0.2rem" }} />
          <div style={{ color: colors.removeColor }}>
            {"CODIGO: " + this.state.currentErrorCode}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderError() {
    if (this.state.currentError !== "") {
      return (
        <div>
          <div style={{ height: "1rem" }} />
          <div style={{ color: colors.removeColor }}>
            {this.state.currentError}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            backgroundColor: colors.backgroundColorLogin,
            height: "100%",
            width: "100%"
          }}
          className="login-form"
        >
          <Dimmer active={this.state.loginCheck}>
            <Loader active={this.state.loginCheck} />
          </Dimmer>
          <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 450 }}>
              <div style={{ ...styleCenteredObject }}>
                <LogoRender
                  useNormalLogo={true}
                  logoSizeW={"20rem"}
                  logoSizeH={"10rem"}
                  logoWidthContainer={"16rem"}
                  renderName={false}
                  aling={"center"}
                />
              </div>
              <Header
                as="h2"
                style={{
                  marginTop: "0px",
                  color: colors.frontColorLogin,
                  fontWeight: "normal"
                }}
                textAlign="center"
              >
                {adminSysName}
              </Header>
              <Form size="large">
                <Segment stacked>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    type={"email"}
                    name={"email"}
                    placeholder="Correo electronico"
                    error={this.state.errorEmail}
                    onChange={e => {
                      this.setState({ currentEmail: e.target.value });
                    }}
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Contraseña"
                    type={"password"}
                    name={"password"}
                    error={this.state.errorPassword}
                    onChange={e => {
                      this.setState({ currentPassword: e.target.value });
                    }}
                  />
                  <Button
                    onClick={() => {
                      this.props.clickOnLogin();
                      this.login();
                    }}
                    style={{
                      backgroundColor: colors.buttonColor,
                      color: colors.colorFrontButton
                    }}
                    fluid
                    size="large"
                  >
                    Entrar
                  </Button>
                  {this.renderError()}
                  {this.renderCode()}
                  <div style={{ height: "1rem" }} />
                  <div
                    style={{
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.setState({ showGetPass: true });
                    }}
                  >
                    Recuperar contraseña
                  </div>
                </Segment>
              </Form>
              {/*
          <Message>
            New to us? <a href="#">Sign Up</a>
          </Message>
            */}
            </Grid.Column>
          </Grid>
        </div>
        <ModalResetPasswordLogin
          showModal={this.state.showGetPass}
          closeModal={() => {
            this.setState({ showGetPass: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { API } = api;
  return { API };
};

const mapDispatchToProps = dispatch => {
  return {
    setDispatch: () => {
      dispatch(setDispatch(dispatch));
    },
    setAuthapiToken: token => {
      dispatch(setAuthapiToken(token));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginPage)
);
