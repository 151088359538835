export const tableData = [
  {
    dataName: "create",
    dataLabel: "Crear",
    dataType: "bool",
    canBeEdited: true
  },
  {
    dataName: "read",
    dataLabel: "Ver",
    dataType: "bool",
    canBeEdited: true
  },
  {
    dataName: "update",
    dataLabel: "Editar",
    dataType: "bool",
    canBeEdited: true
  },
  {
    dataName: "delete",
    dataLabel: "Eliminar",
    dataType: "bool",
    canBeEdited: true
  }
];

export const comparatorDataName = {
  dataName: "publicName",
  dataLabel: "Nombre de la Pagina",
  dataType: "string"
};

export const filterTable = [
  {
    dataName: "publicName",
    dataType: "string"
  }
];
