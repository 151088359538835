const dataTitle = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

const dataVisualNecesary = [
  {
    dataName: "organizationId",
    dataLabel: "Organizacion",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "organizations",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  },
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string",
    canChangeLanguage: true
  }
];

const dataVisualNecesaryView = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

export const tableCreateItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesary]
};

export const tableEditItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesary]
};

export const tableViewItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesaryView]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string",
    dataFilterType: "like"
  }
];
