// Import package from node modules
import * as types from "../types";

export const setUser = user => {
  return {
    type: types.SET_USER,
    user: user
  };
};

export const setUsers = users => {
  return {
    type: types.SET_USERS,
    users: users
  };
};

export const setOrganizations = organizations => {
  return {
    type: types.SET_ORGANIZATIONS,
    organizations: organizations
  };
};

export const setOrganization = organization => {
  return {
    type: types.SET_ORGANIZATION,
    organization: organization
  };
};

export const setModules = modules => {
  return {
    type: types.SET_MODULES,
    modules: modules
  };
};

export const setRoleModules = roleModules => {
  return {
    type: types.SET_ROLE_MODULES,
    roleModules: roleModules
  };
};

export const setRoles = roles => {
  return {
    type: types.SET_ROLES,
    roles: roles
  };
};

export const setUserPermissions = userPermissions => {
  return {
    type: types.SET_USER_PERMISSIONS,
    userPermissions: userPermissions
  };
};

export const setLanguages = languages => {
  return {
    type: types.SET_LANGUAGES,
    languages: languages
  };
};

export const setLanguagesTable = languagesTable => {
  return {
    type: types.SET_LANGUAGES_TABLE,
    languagesTable: languagesTable
  };
};

export const setNavPages = navigationPages => {
  return {
    type: types.SET_NAV_PAGES,
    navigationPages: navigationPages
  };
};

export const setPages = pages => {
  return {
    type: types.SET_PAGES,
    pages: pages
  };
};

export const setMetatags = metatags => {
  return {
    type: types.SET_METATAGS,
    metatags: metatags
  };
};

export const setUserPagesPermissions = userPagesPermissions => {
  return {
    type: types.SET_USERPAGES_PERMISSIONS,
    userPagesPermissions: userPagesPermissions
  };
};

export const setUserPages = userPages => {
  return {
    type: types.SET_USERPAGES,
    userPages: userPages
  };
};

export const setPage = page => {
  return {
    type: types.SET_CURRENT_PAGE,
    page: page
  };
};

export const setSection = section => {
  return {
    type: types.SET_CURRENT_SECTION,
    section: section
  };
};

export const setBlogTags = blogTags => {
  return {
    type: types.SET_BLOG_TAGS,
    blogTags: blogTags
  };
};

export const setBlogCategories = blogCategories => {
  return {
    type: types.SET_BLOG_CATEGORIES,
    blogCategories: blogCategories
  };
};

export const setBlogEntries = blogEntries => {
  return {
    type: types.SET_BLOG_ENTRIES,
    blogEntries: blogEntries
  };
};
