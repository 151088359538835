import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Grid, Icon, Pagination } from "semantic-ui-react";
import ModularText from "../components/ModularText";
import SearchBar from "../components/SearchBar";
import { StickyContainer, Sticky } from "react-sticky";
import {
  renderData,
  TableInFilter,
  sortTable,
  GetFilterInArray,
  SearchInFilterArrayBefore,
  setANDToAdd
} from "../scripts/System";
import {
  adminSeparation,
  colors,
  styleCenteredObject,
  textSizePTable,
  styleBasic
} from "../style";
import ModalATDeleteItems from "./ModalATDeleteItems";
import { notable } from "../assets/index";
import { systemLog, checkValueBool } from "../scripts/General";

const buttonLeftMargin = "0.3rem";

const columnMainStyle = {
  ...styleCenteredObject,
  margin: 0,
  padding: 0,
  justifyContent: "flex-start",
  height: "1.8rem",
  marginLeft: "0.5rem"
};

const columnSize = "1rem";
const columnStyle = {
  ...styleCenteredObject,
  margin: 0,
  padding: 0,
  justifyContent: "flex-start",
  minHeight: columnSize,
  marginLeft: "0.5rem"
};

const deletingContents = "SE ESTAN ELIMINANDO LOS ELEMENTOS...";
const deletedContentsC = "¡SE ELIMINARON LOS ELEMENTOS!";
const deletedContentsB = "HUBO UN ERROR ELIMINANDO LOS ELEMENTOS";

const patchContent = "SE ESTA MODIFICANDO EL ELEMENTO...";
const patchedContentC = "¡SE MODIFICO EL ELEMENTO!";
const patchedContentB = "HUBO UN ERROR AL MODIFICAR EL ELEMENTO";

const deleteContent = "SE ESTA ELIMINANDO EL ELEMENTO...";
const deletedContentC = "¡SE ELIMINO EL ELEMENTO!";
const deletedContentB = "HUBO UN ERROR AL ELIMINAR EL ELEMENTO";

const searchTime = 400;

class AdminViewTable extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    const currentSelectedItems = [];

    if (this.props.table !== undefined) {
      for (let i = 0; i < this.props.table.length; i++) {
        const currentTable = this.props.table[i];
        if (currentTable.selected === true) {
          currentSelectedItems.push(currentTable);
        }
      }
    }
    this.state = {
      activePage: 1,
      totalPages: 1,
      currentSortData: {},
      currentSortItem: "",
      currentSortUp: true,
      currentFilter: "",
      showModalDeleteItems: false,
      currentSelectedItems: currentSelectedItems,
      currentTable: this.props.table
    };
    this.showModalDeleteItems = this.showModalDeleteItems.bind(this);
    this.renderHeaderGrid = this.renderHeaderGrid.bind(this);
    this.renderNoTable = this.renderNoTable.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.renderFilterBar = this.renderFilterBar.bind(this);
    this.renderFilterSearch = this.renderFilterSearch.bind(this);
    this.renderExtraInfoFilter = this.renderExtraInfoFilter.bind(this);
    this.renderCustomSelectorAction = this.renderCustomSelectorAction.bind(
      this
    );
    this.renderSelectorEditItem = this.renderSelectorEditItem.bind(this);
    this.renderSelectorViewItem = this.renderSelectorViewItem.bind(this);
    this.renderSelectorRemoveAction = this.renderSelectorRemoveAction.bind(
      this
    );
    this.renderPagination = this.renderPagination.bind(this);
    this.doDeleteAll = this.doDeleteAll.bind(this);
    this.checkDeleteAll = this.checkDeleteAll.bind(this);
    this.deleteTable = this.deleteTable.bind(this);
    this.patchTable = this.patchTable.bind(this);
    this.returnClickElement = this.returnClickElement.bind(this);
    this.removeSelectedItems = this.removeSelectedItems.bind(this);
    this.tempDeletedAll = 0;
    this.dirToModify = this.props.tableSysName;

    this.checkFilterInBD = this.checkFilterInBD.bind(this);
    this.doSearchInBD = this.doSearchInBD.bind(this);
    this.changePageSearchBD = this.changePageSearchBD.bind(this);
    this.checkTotalPages = this.checkTotalPages.bind(this);
    this.checkChangeView = this.checkChangeView.bind(this);
    this.activePage = 1;
    this.timeOutSearcTalbe = null;
    this.searchOtherTable = [];
    this.searchOnTable = [];

    if (
      this.props.tableParentSysName !== undefined &&
      this.props.tableParentId !== undefined
    ) {
      this.dirToModify =
        this.props.tableParentSysName +
        "/" +
        this.props.tableParentId +
        "/" +
        this.props.tableSysName;
    }
  }

  componentWillMount() {
    if (
      this.props.dontShowAction === true ||
      this.props.tableShowAction === false
    ) {
      this.tableGridSize = 97;
      this.selectorSize = 3;
      this.editorsSize = 0;
      this.tableButtonsSize = 0;
      this.tableLeftRightSize = 0;
      this.tableSideFullSize = 0;
    } else {
      this.tableGridSize = 94;
      this.selectorSize = 3;
      this.editorsSize = 20;
      this.tableButtonsSize = 0.25;
      this.tableLeftRightSize = 6.5;
      this.tableSideFullSize =
        this.tableLeftRightSize * 2 + this.tableButtonsSize;
    }

    if (this.props.dontShowSelector === true) {
      this.selectorSize = 0;
    }

    if (this.props.bdConnected === true) {
      this.startSearchBD();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.bdDoRefresh !== nextProps.bdDoRefresh) {
      if (this.props.bdConnected === true) {
        this.startSearchBD();
      }
    }
    if (nextProps.table !== undefined) {
      if (
        nextProps.table !== this.state.currentTable ||
        nextProps.table.length !== this.state.currentTable.length
      ) {
        this.setState({ currentTable: nextProps.table });
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let sortList = false;
    if (nextState.currentSortItem !== this.state.currentSortItem) {
      sortList = true;
    } else {
      if (nextState.currentSortUp !== this.state.currentSortUp) {
        sortList = true;
      }
    }
    if (sortList === true) {
      const newTable = sortTable(
        nextState.currentSortData,
        nextState.currentTable,
        nextState.currentSortUp
      );
      this.setState({ currentTable: newTable });
    }
  }

  checkFilterInBD() {
    if (this.timeOutSearcTalbe !== null) {
      clearTimeout(this.timeOutSearcTalbe);
    }
    this.timeOutSearcTalbe = setTimeout(() => {
      this.activePage = 1;
      this.setState({ activePage: 1, totalPages: 1 });
      this.startSearchBD();
    }, this.props.bdTimeSearch || searchTime);
  }

  startSearchBD() {
    if (this.searchOtherTable.length > 0) {
      let didAddAllSearch = 0;
      for (let i = 0; i < this.searchOtherTable.length; i++) {
        const currentSearch = this.searchOtherTable[i];
        const andToSearch = [];
        const dataToAdd = setANDToAdd(currentSearch.dataModule);
        andToSearch.push(dataToAdd);
        this.props.API.FindFilterNoLimit(
          currentSearch.tableSysName,
          andToSearch,
          []
        ).then(response => {
          //systemLog(this, response);
          const finalResponse = [];
          for (let r = 0; r < response.length; r++) {
            const currentRes = response[r];
            finalResponse.push(currentRes[currentSearch.responseGetValue]);
          }
          const valueToAdd = {
            dataName: currentSearch.dataName,
            dataFilterType: currentSearch.dataFilterType,
            value: finalResponse
          };
          const finalDataToAdd = setANDToAdd(valueToAdd);
          this.searchOnTable.push(finalDataToAdd);
          didAddAllSearch = didAddAllSearch + 1;
          if (didAddAllSearch >= this.searchOtherTable.length) {
            this.doSearchInBD();
          }
        });
      }
    } else {
      this.doSearchInBD();
    }
  }

  changePageSearchBD() {
    if (this.timeOutSearcTalbe !== null) {
      clearTimeout(this.timeOutSearcTalbe);
    }
    this.timeOutSearcTalbe = setTimeout(() => {
      this.doSearchInBD();
    }, this.props.bdTimeSearch || searchTime);
  }

  doSearchInBD() {
    systemLog(this, this.searchOnTable);
    this.props.API.FindFilter(
      this.dirToModify,
      this.searchOnTable,
      this.props.bdConnectInclude,
      this.props.bdWhere || [],
      this.props.bdLimitItems,
      this.activePage
    ).then(response => {
      this.removeSelectedItems();
      if (this.props.bdConnectedReturn !== undefined) {
        this.props.bdConnectedReturn(response);
      }
      const nextPageCheck = this.activePage + 1;
      this.checkTotalPages(0, nextPageCheck);
      systemLog(this, response);
    });
  }

  checkTotalPages(currentPage, pageCheck) {
    if (currentPage < 5) {
      this.props.API.FindFilterPage(
        this.dirToModify,
        this.searchOnTable,
        this.props.bdWhere || [],
        this.props.bdLimitItems,
        pageCheck
      ).then(response => {
        if (response.length > 0) {
          const nextPage = currentPage + 1;
          const nextPageCheck = pageCheck + 1;
          this.checkTotalPages(nextPage, nextPageCheck);
        } else {
          this.setState({ totalPages: pageCheck - 1 });
        }
      });
    } else {
      this.setState({ totalPages: pageCheck - 1 });
    }
  }

  checkChangeView() {
    if (this.props.bdConnected === true) {
      this.startSearchBD();
    }
    if (this.props.clickOnChange !== undefined) {
      this.props.clickOnChange();
    }
  }

  removeSelectedItems() {
    for (let i = 0; i < this.state.currentSelectedItems.length; i++) {
      const currentTable = this.state.currentSelectedItems[i];
      currentTable.selected = false;
    }
    this.setState({ currentSelectedItems: [] });
  }

  showModalDeleteItems(doit) {
    this.setState({ showModalDeleteItems: doit });
  }

  checkDeleteAll(toastDelete) {
    this.tempDeletedAll = this.tempDeletedAll + 1;
    if (this.tempDeletedAll >= this.state.currentSelectedItems.length) {
      if (toastDelete !== null && toast.isActive(toastDelete)) {
        toast.update(toastDelete, {
          render: deletedContentsC,
          autoClose: true,
          type: toast.TYPE.SUCCESS
        });
      } else {
        toast.success(deletedContentsC);
      }

      this.removeSelectedItems();
      this.checkChangeView();
    }
  }

  doDeleteAll() {
    if (this.props.returnDeleteAllObj !== undefined) {
      this.props.returnDeleteAllObj(this.state.currentSelectedItems);
      this.removeSelectedItems();
      return;
    }

    this.tempDeletedAll = 0;
    let toastDelete = null;
    if (this.props.deleteElement === undefined) {
      toastDelete = toast.info(deletingContents, {
        autoClose: false
      });
    }

    for (let i = 0; i < this.state.currentSelectedItems.length; i++) {
      const currentTable = this.state.currentSelectedItems[i];
      const filesToDelete = [];
      const propsCurrentTable = Object.getOwnPropertyNames(currentTable);
      for (let p = 0; p < propsCurrentTable.length; p++) {
        const currentData = currentTable[propsCurrentTable[p]];
        if (currentData !== undefined && currentData !== null) {
          if (
            currentData.fileName !== undefined &&
            currentData.fileName !== null
          ) {
            filesToDelete.push(currentData.fileName);
          } else if (currentData.length !== undefined) {
            for (let ip = 0; ip < currentData.length; ip++) {
              const currentInsideData = currentData[ip];
              if (
                currentInsideData !== undefined &&
                currentInsideData !== null &&
                currentInsideData.fileName !== undefined &&
                currentInsideData.fileName !== null
              ) {
                filesToDelete.push(currentInsideData.fileName);
              }
            }
          }
        }
      }
      for (let f = 0; f < filesToDelete.length; f++) {
        const fileToDelete = filesToDelete[f];
        this.props.API.DeleteFile(fileToDelete);
      }
      this.props.API.DeleteThisFrom(this.dirToModify, currentTable.id)
        .then(response => {
          this.checkDeleteAll(toastDelete);
        })
        .catch(error => {
          this.checkDeleteAll(toastDelete);
        });
    }
  }

  deleteTable(data) {
    if (this.props.returnDeleteObj !== undefined) {
      this.props.returnDeleteObj(data);
      return;
    }

    let deletedContent = deletedContentC;
    if (this.props.deleteElement !== undefined) {
      this.props.deleteElement(data);
      toast.success(deletedContent);
    } else {
      const toastDelete = toast.info(deleteContent, {
        autoClose: false
      });

      this.props.API.DeleteThisFrom(this.dirToModify, data.id)
        .then(response => {
          let thereIsError = false;
          if (response.error !== undefined) {
            deletedContent = deletedContentB;
            thereIsError = true;
          }
          if (toast.isActive(toastDelete)) {
            toast.update(toastDelete, {
              render: deletedContent,
              autoClose: true,
              type: thereIsError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
            });
          } else {
            if (thereIsError) {
              toast.error(deletedContent);
            } else {
              toast.success(deletedContent);
            }
          }
          if (thereIsError === false) {
            this.checkChangeView();
          }
        })
        .catch(error => {});
    }
  }

  returnClickElement(data, table) {
    if (this.props.returnClickElement !== undefined) {
      this.props.returnClickElement(data, table);
    }
  }

  patchTable(data, dataName, dataChange) {
    const dataToChange = {};
    dataToChange[dataName] = dataChange;

    if (data[dataName] === dataChange) {
      return;
    }

    if (this.props.returnEditViewObj !== undefined) {
      this.props.returnEditViewObj(data, dataToChange, dataName);
      return;
    }

    const toastPatch = toast.info(patchContent, {
      autoClose: false
    });

    let patchedContent = patchedContentC;

    this.props.API.PatchTo(this.dirToModify, data.id, dataToChange)
      .then(response => {
        //systemLog(this, response);
        let thereIsError = false;
        if (response.error !== undefined) {
          patchedContent = patchedContentB;
          thereIsError = true;
        }
        if (toast.isActive(toastPatch)) {
          toast.update(toastPatch, {
            render: patchedContent,
            autoClose: true,
            type: thereIsError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
          });
        } else {
          if (thereIsError) {
            toast.error(patchedContent);
          } else {
            toast.success(patchedContent);
          }
        }
        if (thereIsError === false) {
          this.checkChangeView();
          if (this.props.afterEditObj !== undefined) {
            this.props.afterEditObj(response);
          }
        }
      })
      .catch(error => {
        //systemLog(this, error);
      });
  }

  renderHeaderGrid() {
    let allSelected = true;
    for (let i = 0; i < this.state.currentTable.length; i++) {
      const currentTable = this.state.currentTable[i];
      let canBeSelected = true;
      if (this.props.specialPermissions !== undefined) {
        const isOnSpecialTable = this.props.specialPermissions.find(
          tempTable => currentTable.id === tempTable.id
        );
        if (isOnSpecialTable !== undefined && isOnSpecialTable !== null) {
          if (isOnSpecialTable.canBeSelected !== undefined) {
            canBeSelected = isOnSpecialTable.canBeSelected;
          }
        }
      }
      if (
        canBeSelected === true &&
        (currentTable.selected === undefined || currentTable.selected === false)
      ) {
        allSelected = false;
        break;
      }
    }
    return (
      <div
        style={{
          width: "100%",
          ...styleCenteredObject,
          justifyContent: "flex-start"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            minHeight: columnSize,
            width: this.selectorSize.toString() + "%",
            overflowX: this.selectorSize === 0 ? "hidden" : "visible"
          }}
        >
          <Button
            onClick={() => {
              const currentSelectedItems = [];
              for (let i = 0; i < this.state.currentTable.length; i++) {
                const currentTable = this.state.currentTable[i];
                let canBeSelected = true;
                if (this.props.specialPermissions !== undefined) {
                  const isOnSpecialTable = this.props.specialPermissions.find(
                    tempTable => currentTable.id === tempTable.id
                  );
                  if (
                    isOnSpecialTable !== undefined &&
                    isOnSpecialTable !== null
                  ) {
                    if (isOnSpecialTable.canBeSelected !== undefined) {
                      canBeSelected = isOnSpecialTable.canBeSelected;
                    }
                  }
                }
                if (
                  canBeSelected === true &&
                  this.props.forceNotSelect !== true
                ) {
                  currentTable.selected = !allSelected;
                  if (allSelected === false) {
                    currentSelectedItems.push(currentTable);
                  }
                }
              }
              this.setState({ currentSelectedItems });
            }}
            style={{
              ...styleCenteredObject,
              backgroundColor: "transparent",
              width: "100%",
              overflowX: "hidden"
            }}
          >
            <div
              style={{
                ...styleCenteredObject
              }}
            >
              <Icon
                style={{
                  fontSize: "1.5rem",
                  textAlign: "center"
                }}
                name={allSelected ? "check square outline" : "square outline"}
              />
            </div>
          </Button>
        </div>
        <div style={{ width: this.tableGridSize.toString() + "%" }}>
          <Grid
            style={{
              margin: 0,
              padding: 0,
              paddingTop: "1rem",
              paddingBottom: "1rem"
            }}
            columns="equal"
          >
            {this.props.tableData.map((data, index) => {
              let colorToSetUp = colors.disableColor;
              let colorToSetDown = colors.disableColor;
              if (this.state.currentSortItem === data.dataLabel) {
                if (this.state.currentSortUp === true) {
                  colorToSetUp = colors.mainColor;
                } else {
                  colorToSetDown = colors.mainColor;
                }
              }
              return (
                <Grid.Column
                  key={index}
                  width={data.columnSize}
                  style={columnMainStyle}
                >
                  <div
                    style={{
                      ...styleCenteredObject,
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <div
                      style={{
                        ...styleCenteredObject,
                        justifyContent: "flex-start",
                        cursor: "pointer",
                        height: "100%",
                        overflowX: "hidden",
                        textAlign: "left",
                        fontSize: textSizePTable
                      }}
                      onClick={() => {
                        let sortUpTo = true;
                        if (this.state.currentSortItem === data.dataLabel) {
                          sortUpTo = !this.state.currentSortUp;
                        }
                        this.setState({
                          currentSortData: data,
                          currentSortItem: data.dataLabel,
                          currentSortUp: sortUpTo
                        });
                      }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: textSizePTable,
                          wordBreak: "break-word",
                          wordWrap: "break-word"
                        }}
                      >
                        {data.dataLabel}
                      </p>
                    </div>
                    <div style={{ width: "0.2rem" }} />
                    <div
                      style={{
                        ...styleCenteredObject,
                        flexDirection: "column",
                        height: "100%"
                      }}
                    >
                      <Button
                        fluid
                        icon
                        style={{
                          ...styleBasic,
                          ...styleCenteredObject,
                          paddingTop: "0.4rem",
                          height: "50%"
                        }}
                        onClick={() => {
                          this.setState({
                            currentSortData: data,
                            currentSortItem: data.dataLabel,
                            currentSortUp: true
                          });
                        }}
                      >
                        <Icon
                          name="angle up"
                          style={{
                            ...styleBasic,
                            ...styleCenteredObject,
                            fontSize: "1rem",
                            color: colorToSetUp
                          }}
                        />
                      </Button>
                      <Button
                        fluid
                        icon
                        style={{
                          ...styleBasic,
                          ...styleCenteredObject,
                          paddingBottom: "0.4rem",
                          height: "50%"
                        }}
                        onClick={() => {
                          this.setState({
                            currentSortData: data,
                            currentSortItem: data.dataLabel,
                            currentSortUp: false
                          });
                        }}
                      >
                        <Icon
                          name="angle down"
                          style={{
                            ...styleBasic,
                            ...styleCenteredObject,
                            fontSize: "1rem",
                            color: colorToSetDown
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid>
        </div>
        <div
          style={{
            width: this.tableSideFullSize.toString() + "%"
          }}
        />
      </div>
    );
  }

  renderActionButtons(table) {
    if (
      this.props.dontShowAction === true ||
      this.props.tableShowAction === false
    ) {
      return <div />;
    }
    let canViewTable = this.props.permissionData.read || false;
    let canEditTable = this.props.permissionData.update || false;
    let canDeleteTable = this.props.permissionData.delete || false;

    if (this.props.specialPermissions !== undefined) {
      const isOnSpecialTable = this.props.specialPermissions.find(
        tempTable => table.id === tempTable.id
      );
      if (isOnSpecialTable !== undefined && isOnSpecialTable !== null) {
        if (isOnSpecialTable.delete !== undefined) {
          canDeleteTable = isOnSpecialTable.delete;
        }
        if (isOnSpecialTable.update !== undefined) {
          canEditTable = isOnSpecialTable.update;
        }
        if (isOnSpecialTable.read !== undefined) {
          canViewTable = isOnSpecialTable.read;
        }
      }
    }

    return (
      <div
        style={{
          width: this.tableButtonsSize.toString() + "%",
          ...styleCenteredObject,
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <div style={{ width: "0.4rem" }} />
        <Button
          onClick={() => {
            if (canViewTable === true) {
              this.props.onClickViewItem(table);
            }
          }}
          style={{
            width: this.editorsSize,
            margin: 0,
            padding: 0,
            backgroundColor: "transparent"
          }}
        >
          <div
            style={{
              ...styleCenteredObject
            }}
          >
            <Icon
              style={{
                fontSize: this.editorsSize,
                textAlign: "center",
                color: canViewTable
                  ? colors.tableActionColor
                  : colors.disableColor
              }}
              name={"eye"}
            />
          </div>
        </Button>
        <Button
          onClick={() => {
            if (canEditTable === true) {
              this.props.onClickEditItem(table);
            }
          }}
          style={{
            width: this.editorsSize,
            margin: 0,
            padding: 0,
            backgroundColor: "transparent"
          }}
        >
          <div
            style={{
              ...styleCenteredObject
            }}
          >
            <Icon
              style={{
                fontSize: this.editorsSize,
                textAlign: "center",
                color: canEditTable
                  ? colors.tableActionColor
                  : colors.disableColor
              }}
              name={"pencil"}
            />
          </div>
        </Button>
        <Button
          onClick={() => {
            if (canDeleteTable === true) {
              this.deleteTable(table);
            }
          }}
          style={{
            width: this.editorsSize,
            margin: 0,
            padding: 0,
            backgroundColor: "transparent"
          }}
        >
          <div
            style={{
              ...styleCenteredObject
            }}
          >
            <Icon
              style={{
                fontSize: this.editorsSize,
                textAlign: "center",
                color: canDeleteTable ? colors.removeColor : colors.disableColor
              }}
              name={"cancel"}
            />
          </div>
        </Button>
      </div>
    );
  }

  renderTitle() {
    if (this.props.customNoTable === undefined) {
      return (
        <div>
          <ModularText as={"h3"}>{"SIN DATOS"}</ModularText>
          <div style={{ height: "0.5rem" }} />
          <ModularText as={"h5"}>
            {"LA TABLA NO CONTIENE DATOS DISPONIBLES"}
          </ModularText>
        </div>
      );
    } else {
      return this.props.customNoTable();
    }
  }

  renderNoTable() {
    return (
      <div
        style={{
          ...styleBasic,
          ...styleCenteredObject,
          width: "100%",
          height: "20rem"
        }}
      >
        <div
          style={{
            ...styleBasic,
            ...styleCenteredObject,
            width: "100%",
            height: "18rem",
            backgroundImage: "url(" + notable + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center"
          }}
        >
          <div style={{ marginTop: "11rem" }}>{this.renderTitle()}</div>
        </div>
      </div>
    );
  }

  renderTable() {
    if (this.state.currentTable !== undefined) {
      if (this.state.currentTable.length === 0) {
        return this.renderNoTable();
      }
      return (
        <div>
          {this.state.currentTable.map((table, indexT) => {
            let canBeSelected = true;
            if (this.props.specialPermissions !== undefined) {
              const isOnSpecialTable = this.props.specialPermissions.find(
                tempTable => table.id === tempTable.id
              );
              if (isOnSpecialTable !== undefined && isOnSpecialTable !== null) {
                if (isOnSpecialTable.canBeSelected !== undefined) {
                  canBeSelected = isOnSpecialTable.canBeSelected;
                }
              }
            }

            let styleExtraGrid = {
              backgroundColor: colors.adminTableItemMainColor,
              borderTop: "2px solid " + colors.adminTableItemBorderColor,
              borderBottom: "2px solid " + colors.adminTableItemBorderColor
            };
            if ((indexT + 1) % 2 === 0) {
              styleExtraGrid = {
                backgroundColor: colors.adminTableItemSecondaryColor
              };
            }
            let canBeRender = true;
            if (
              this.props.customFilterRender !== true &&
              this.props.bdConnected !== true
            ) {
              canBeRender = TableInFilter(
                table,
                this.props.filterTable,
                this.state.currentFilter,
                this.props.filterAllThatApply
              );
            }
            if (canBeRender === false) {
              return <div key={indexT} />;
            }
            return (
              <div
                key={indexT}
                style={{
                  width: "100%",
                  ...styleCenteredObject,
                  ...styleExtraGrid,
                  justifyContent: "flex-start"
                }}
              >
                <div
                  style={{
                    ...styleCenteredObject,
                    minHeight: columnSize,
                    width: this.selectorSize.toString() + "%",
                    overflowX: this.selectorSize === 0 ? "hidden" : "visible"
                  }}
                >
                  <Button
                    onClick={() => {
                      if (
                        canBeSelected === true &&
                        this.props.forceNotSelect !== true
                      ) {
                        const currentSelectedItems = this.state
                          .currentSelectedItems;
                        if (table.selected === undefined) {
                          currentSelectedItems.push(table);
                          table.selected = true;
                        } else {
                          if (table.selected === true) {
                            const indexTable = currentSelectedItems.indexOf(
                              table
                            );
                            if (indexTable > -1) {
                              currentSelectedItems.splice(indexTable, 1);
                            }
                          } else {
                            currentSelectedItems.push(table);
                          }
                          table.selected = !table.selected;
                        }
                        this.setState({ currentSelectedItems });
                      }
                    }}
                    style={{
                      ...styleCenteredObject,
                      backgroundColor: "transparent",
                      width: "100%",
                      overflowX: "hidden"
                    }}
                  >
                    <div
                      style={{
                        ...styleCenteredObject
                      }}
                    >
                      <Icon
                        style={{
                          fontSize: "1.5rem",
                          textAlign: "center",
                          color: canBeSelected ? "black" : colors.disableColor
                        }}
                        name={
                          table.selected
                            ? "check square outline"
                            : "square outline"
                        }
                      />
                    </div>
                  </Button>
                </div>
                <div style={{ width: this.tableGridSize.toString() + "%" }}>
                  <Grid
                    style={{
                      width: "100%",
                      margin: 0,
                      padding: 0,
                      paddingTop: "1rem",
                      paddingBottom: "1rem"
                    }}
                    columns="equal"
                  >
                    {this.props.tableData.map((data, indexData) => {
                      return (
                        <Grid.Column
                          key={indexData}
                          width={data.columnSize}
                          style={columnStyle}
                        >
                          <div
                            style={{
                              wordBreak: "break-word",
                              wordWrap: "break-word",
                              width: "100%"
                            }}
                          >
                            {renderData(
                              data,
                              table,
                              this.props.permissionData.update,
                              this.props.formatter,
                              this.patchTable,
                              false,
                              this.returnClickElement
                            )}
                          </div>
                        </Grid.Column>
                      );
                    })}
                  </Grid>
                </div>
                <div
                  style={{ width: this.tableLeftRightSize.toString() + "%" }}
                />
                {this.renderActionButtons(table)}
                <div
                  style={{ width: this.tableLeftRightSize.toString() + "%" }}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderExtraInfoFilter() {
    if (
      this.state.currentFilter !== "" &&
      this.props.customFilterExtraInfo !== undefined
    ) {
      const CustomFilterExtraInfo = this.props.customFilterExtraInfo;
      return (
        <CustomFilterExtraInfo
          currentTable={this.state.currentTable}
          currentFilter={this.state.currentFilter}
          filterTable={this.props.filterTable}
          filterAllThatApply={this.props.filterAllThatApply}
        />
      );
    } else {
      return <div />;
    }
  }

  renderCustomSelectorAction() {
    const showButton =
      this.state.currentSelectedItems.length > 0 ? true : false;
    if (showButton === true && this.props.customSelectorAction !== undefined) {
      const CustomSelectorAction = this.props.customSelectorAction;
      return (
        <CustomSelectorAction
          table={this.props.table}
          currentSelectedItems={this.state.currentSelectedItems}
          tableSysName={this.props.tableSysName}
          clickOnFunction={() => {
            this.removeSelectedItems();
            this.checkChangeView();
          }}
          goToEdit={() => {
            this.props.onClickEditItem(this.state.currentSelectedItems[0]);
            this.removeSelectedItems();
          }}
          goToView={() => {
            this.props.onClickViewItem(this.state.currentSelectedItems[0]);
            this.removeSelectedItems();
          }}
        />
      );
    } else {
      return <div />;
    }
  }

  renderSelectorViewItem() {
    let canViewItem = this.props.permissionData.read || false;
    let showButton = this.state.currentSelectedItems.length > 0 ? true : false;
    if (this.state.currentSelectedItems.length > 1) {
      showButton = false;
    }
    if (
      this.props.showViewButton !== true &&
      this.props.tableShowAction === true
    ) {
      showButton = false;
    }
    if (showButton === true && canViewItem === true) {
      return (
        <Button
          onClick={() => {
            this.props.onClickViewItem(this.state.currentSelectedItems[0]);
            this.removeSelectedItems();
          }}
          style={{
            backgroundColor: colors.viewColor,
            color: colors.viewFrontColor,
            marginLeft: buttonLeftMargin
          }}
          content={"VER"}
          icon="eye"
          labelPosition="left"
        />
      );
    } else {
      return <div />;
    }
  }

  renderSelectorEditItem() {
    let canEditItem = this.props.permissionData.update || false;
    let showButton = this.state.currentSelectedItems.length > 0 ? true : false;
    if (this.state.currentSelectedItems.length > 1) {
      showButton = false;
    }
    if (
      this.props.showEditButton !== true &&
      this.props.tableShowAction === true
    ) {
      showButton = false;
    }
    if (showButton === true && canEditItem === true) {
      return (
        <Button
          onClick={() => {
            this.props.onClickEditItem(this.state.currentSelectedItems[0]);
            this.removeSelectedItems();
          }}
          style={{
            backgroundColor: colors.changeColor,
            color: colors.changeFrontColor,
            marginLeft: buttonLeftMargin
          }}
          content={"EDITAR"}
          icon="pencil"
          labelPosition="left"
        />
      );
    } else {
      return <div />;
    }
  }

  renderSelectorRemoveAction() {
    let canDeleteTable = this.props.permissionData.delete || false;
    const showButton =
      this.state.currentSelectedItems.length > 0 ? true : false;
    if (showButton === true && canDeleteTable === true) {
      return (
        <Button
          onClick={() => {
            this.showModalDeleteItems(true);
          }}
          style={{
            backgroundColor: colors.removeColor,
            color: colors.removeFrontColor,
            marginLeft: buttonLeftMargin
          }}
          content={"ELIMINAR"}
          icon="cancel"
          labelPosition="left"
        />
      );
    } else {
      return <div />;
    }
  }

  renderFilterSearch() {
    if (this.props.dontRenderFilter === true) {
      return <div />;
    }
    return (
      <div
        style={{
          ...styleCenteredObject,
          flexDirection: "row"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            marginLeft: adminSeparation,
            paddingLeft: "0.7rem",
            width: "25rem",
            borderBottom: "1px solid " + colors.borderColor
          }}
        >
          <SearchBar
            onChange={data => {
              this.setState({ currentFilter: data });
              if (this.props.bdConnected === true) {
                this.searchOtherTable = [];
                this.searchOnTable = [];
                const searchArray = GetFilterInArray(data);
                for (let i = 0; i < this.props.filterTable.length; i++) {
                  const currentFilter = this.props.filterTable[i];
                  const filterInArray = SearchInFilterArrayBefore(
                    searchArray,
                    currentFilter.dataLabel,
                    ":"
                  );
                  if (filterInArray !== undefined) {
                    const valueOfFilter = filterInArray.split(":")[1].trim();
                    const valueToAdd = JSON.parse(
                      JSON.stringify(currentFilter)
                    );
                    if (currentFilter.dataType === "insideModule") {
                      valueToAdd.dataModule.value = valueOfFilter;
                      this.searchOtherTable.push(valueToAdd);
                    } else {
                      valueToAdd.value = valueOfFilter;
                      systemLog(this, valueToAdd);
                      const dataToAdd = setANDToAdd(valueToAdd);
                      this.searchOnTable.push(dataToAdd);
                    }
                  }
                  this.checkFilterInBD();
                }
              }
              if (this.props.returnFilter !== undefined) {
                this.props.returnFilter(data);
              }
            }}
            leftPadding={6}
            style={{ backgroundColor: "transparent" }}
            placeholder={"Filtro"}
            showSearch={false}
          />
        </div>
        {this.renderExtraInfoFilter()}
      </div>
    );
  }
  renderFilterBar() {
    if (this.props.dontRenderBar === true) {
      return <div />;
    }
    return (
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <div style={{ height: "1rem" }} />
        <div
          style={{
            width: "100%",
            height: "3rem",
            backgroundColor: colors.colorAdminSubHeader,
            ...styleCenteredObject,
            justifyContent: "space-between"
          }}
        >
          {this.renderFilterSearch()}
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              paddingRight: adminSeparation
            }}
          >
            {this.renderCustomSelectorAction()}
            {this.renderSelectorViewItem()}
            {this.renderSelectorEditItem()}
            {this.renderSelectorRemoveAction()}
          </div>
        </div>
        <div style={{ height: "1.6rem" }} />
      </div>
    );
  }

  renderPagination() {
    if (
      this.props.doPagination !== undefined ||
      this.props.doPagination === true
    ) {
      return (
        <Pagination
          activePage={this.props.currentPage || this.state.activePage}
          onPageChange={(e, { activePage }) => {
            this.setState({ activePage });
            this.activePage = activePage;
            if (this.props.bdConnected === true) {
              this.changePageSearchBD();
            }
            if (this.props.doPagination !== true) {
              this.props.doPagination(activePage);
            }
          }}
          totalPages={this.state.totalPages || 1000}
          siblingRange={1}
          boundaryRange={0}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
        />
      );
    } else {
      return <div />;
    }
  }

  render() {
    if (this.state.currentTable === undefined) {
      return <div />;
    }
    return (
      <StickyContainer style={{ width: "100%" }}>
        <Sticky topOffset={-50}>
          {({
            style,

            // the following are also available but unused in this example
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight
          }) => (
            <header
              style={{
                ...style,
                zIndex: 100,
                marginTop: isSticky === true ? 60 : 0
              }}
            >
              {this.renderFilterBar()}
            </header>
          )}
        </Sticky>
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.renderHeaderGrid()}
          {this.renderTable()}
          {this.renderPagination()}
          <div style={{ height: "20px" }} />
        </div>
        <ModalATDeleteItems
          tableData={this.props.tableData}
          itemsToDelete={this.state.currentSelectedItems}
          doDeleteAll={() => {
            this.showModalDeleteItems(false);
            this.doDeleteAll();
          }}
          closeModal={() => {
            this.removeSelectedItems();
            this.showModalDeleteItems(false);
          }}
          showModal={this.state.showModalDeleteItems}
        />
      </StickyContainer>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, tableShowAction } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API, tableShowAction };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(AdminViewTable)
);
