import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Grid, Dropdown } from "semantic-ui-react";
import ModularText from "../../components/ModularText";
import { openFrontLoading } from "../../redux/actions";
import {
  renderTopName,
  renderLabel,
  renderModifyData,
  ImagesRes
} from "../../scripts/System";
import {
  adminSeparation,
  colors,
  styleCenteredObject,
  textSizeHeaderSubTitle,
  textSizeSubHeaderTitle
} from "../../style";
import {
  systemLog,
  setURLType,
  setBaseFileNameDataCheck
} from "../../scripts/General";
import { BasedataImageNameSet } from "../../MainInfoData";

const saveContent = "SE ESTA CREANDO EL ELEMENTO";
const saveContentC = "¡SE CREO EL ELEMENTO";
const saveContentB = "HUBO UN ERROR AL CREAR EL NUEVO ELEMENTO";

const dataPrivateName = {
  dataLabel: "Nombre",
  dataType: "string",
  dataName: "name",
  dataInsideSize: "100%"
};

const dataPublicName = {
  dataLabel: "Nombre Publico",
  dataType: "string",
  dataName: "publicName",
  dataInsideSize: "100%"
};
const dataBase = {
  dataLabel: "Es Base",
  dataType: "bool",
  dataName: "isBase",
  dataInsideSize: "100%"
};

const dataValue = {
  dataLabel: "Orden",
  dataType: "string",
  dataName: "value",
  dataInsideSize: "100%"
};

const dataText = {
  dataLabel: "Contenido",
  dataType: "string",
  dataName: "content",
  mainDataName: "content"
};

const dataLink = {
  dataLabel: "Link",
  dataType: "string",
  dataName: "link",
  mainDataName: "link"
};

const dataImage = {
  dataLabel: "Imagen",
  dataType: "image",
  dataName: "b64",
  mainDataName: "b64"
};

const dataTint = {
  dataLabel: "Tinta",
  dataType: "color",
  dataName: "tintColor",
  mainDataName: "tintColor"
};

const dataRichText = {
  dataLabel: "Texto Rico",
  dataType: "richText",
  dataName: "content",
  mainDataName: "content"
};

const dataFile = {
  dataLabel: "Archivo",
  dataType: "file",
  dataName: "file",
  mainDataName: "file"
};

const columnSize = "3rem";
const columnStyle = {
  ...styleCenteredObject,
  width: "100%",
  margin: 0,
  padding: 0,
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: columnSize,
  marginLeft: "0.5rem"
};

const rowStyle = {
  ...styleCenteredObject,
  width: "100%",
  margin: 0,
  padding: 0,
  marginTop: "1rem",
  marginBottom: "0.6rem",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "flex-start",
  flexDirection: "row",
  minHeight: "0.1rem",
  marginLeft: "0.5rem"
};

class CreateBaseContent extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");

    let tempLanguage = "";
    let tempLanguageId = "";

    if (this.props.languages.length > 0) {
      tempLanguageId = this.props.languages[0].value;
      tempLanguage = this.props.languages[0].data;
    }

    this.state = {
      currentLanguage: tempLanguage,
      currentLanguageId: tempLanguageId
    };

    let currentValue = this.props.table.value;
    this.currentTableToChange = {
      name: this.props.table.name + currentValue,
      publicName: this.props.table.publicName + " " + (currentValue + 1),
      value: currentValue,
      isBase: false
    };

    systemLog("CreateBaseContent", this.props.table);
    this.saveItem = this.saveItem.bind(this);
    this.renderCreateData = this.renderCreateData.bind(this);
    this.renderSectionMainInfo = this.renderSectionMainInfo.bind(this);
    this.renderInsideData = this.renderInsideData.bind(this);
    this.renderValueColumn = this.renderValueColumn.bind(this);
    this.getCurrentDataContent = this.getCurrentDataContent.bind(this);
    this.renderTopExtraInfo = this.renderTopExtraInfo.bind(this);

    this.uploadDataContentText = this.uploadDataContentText.bind(this);
    this.uploadDataContentLink = this.uploadDataContentLink.bind(this);
    this.uploadDataContentRichText = this.uploadDataContentRichText.bind(this);
    this.uploadDataContentImages = this.uploadDataContentImages.bind(this);
    this.uploadDataContentFiles = this.uploadDataContentFiles.bind(this);
    this.checkDidUploadAll = this.checkDidUploadAll.bind(this);

    this.currentDataContentText = [];
    this.currentDataContentImage = [];
    this.currentDataContentLink = [];
    this.currentDataContentRichText = [];
    this.currentDataContentFile = [];
    this.totalDataContent = [];

    this.dirToModify = this.props.tableSysName;
    if (this.props.tableCreateRoute !== undefined) {
      this.dirToModify = this.props.tableCreateRoute;
    }

    systemLog("FileCheck", this.props.languages);
  }

  componentWillMount() {
    this.getCurrentDataContent();
  }

  componentDidMount() {
    if (this.props.languages.length > 0) {
      let tempLanguage = "";
      let tempLanguageId = "";
      tempLanguageId = this.props.languages[0].value;
      tempLanguage = this.props.languages[0].data;
      this.setState({
        currentLanguage: tempLanguage,
        currentLanguageId: tempLanguageId
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (nextProps.languages.length > 0) {
        let tempLanguage = "";
        let tempLanguageId = "";
        tempLanguageId = nextProps.languages[0].value;
        tempLanguage = nextProps.languages[0].data;
        this.setState({
          currentLanguage: tempLanguage,
          currentLanguageId: tempLanguageId
        });
      }
    }
  }

  getCurrentDataContent() {
    this.props.API.GetContentSection(this.props.table.id).then(response => {
      this.totalDataContent = [];
      const responseCopy = JSON.parse(JSON.stringify(response));
      this.currentDataContentText = responseCopy.dataContentTexts;
      this.currentDataContentImage = responseCopy.dataContentImages;
      this.currentDataContentLink = responseCopy.dataContentLinks;
      this.currentDataContentRichText = responseCopy.dataContentRichTexts;
      this.currentDataContentFile = responseCopy.dataContentFiles;

      for (let i = 0; i < this.currentDataContentText.length; i++) {
        this.currentDataContentText[i].typeContent = "text";
        this.totalDataContent.push(this.currentDataContentText[i]);
      }
      for (let i = 0; i < this.currentDataContentImage.length; i++) {
        this.currentDataContentImage[i].typeContent = "image";
        this.totalDataContent.push(this.currentDataContentImage[i]);
      }
      for (let i = 0; i < this.currentDataContentLink.length; i++) {
        this.currentDataContentLink[i].typeContent = "link";
        this.totalDataContent.push(this.currentDataContentLink[i]);
      }
      for (let i = 0; i < this.currentDataContentRichText.length; i++) {
        this.currentDataContentRichText[i].typeContent = "richtext";
        this.totalDataContent.push(this.currentDataContentRichText[i]);
      }
      for (let i = 0; i < this.currentDataContentFile.length; i++) {
        this.currentDataContentFile[i].typeContent = "file";
        this.totalDataContent.push(this.currentDataContentFile[i]);
      }

      this.totalDataContent.sort((a, b) => {
        return a.orden - b.orden;
      });
      this.forceUpdate();
    });
  }

  saveItem() {
    const currentDataContentText = [];
    const currentDataContentImage = [];
    const currentDataContentLink = [];
    const currentDataContentRichText = [];
    const currentDataContentFile = [];

    /*
    if (
      this.currentDataContentText.length === 0 &&
      this.currentDataContentImage.length === 0 &&
      this.currentDataContentLink.length === 0 &&
      this.currentDataContentRichText.length === 0
    ) {
      toast.error("Agrega minimo un dato a la seccion");
      return;
    }
    */

    for (let i = 0; i < this.currentDataContentText.length; i++) {
      const dataCheck = this.currentDataContentText[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["content" + currentLanguage.data] =
          dataCheck["content" + currentLanguage.data];

        if (newData["content" + currentLanguage.data] === undefined) {
          newData["content" + currentLanguage.data] = "";
        }
      }
      currentDataContentText.push(newData);
    }

    for (let i = 0; i < this.currentDataContentImage.length; i++) {
      const dataCheck = this.currentDataContentImage[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["b64" + currentLanguage.data] =
          dataCheck["b64" + currentLanguage.data];
        newData["tintColor" + currentLanguage.data] =
          dataCheck["tintColor" + currentLanguage.data];

        if (newData["tintColor" + currentLanguage.data] === undefined) {
          newData["tintColor" + currentLanguage.data] =
            "rgba(255, 255, 255, 1)";
        }
        if (newData["b64" + currentLanguage.data] === undefined) {
          newData["b64" + currentLanguage.data] = {};
        }
      }
      currentDataContentImage.push(newData);
    }

    for (let i = 0; i < this.currentDataContentLink.length; i++) {
      const dataCheck = this.currentDataContentLink[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["link" + currentLanguage.data] =
          dataCheck["link" + currentLanguage.data];

        if (newData["link" + currentLanguage.data] === undefined) {
          newData["link" + currentLanguage.data] = "";
        }
      }
      currentDataContentLink.push(newData);
    }

    for (let i = 0; i < this.currentDataContentRichText.length; i++) {
      const dataCheck = this.currentDataContentRichText[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["content" + currentLanguage.data] =
          dataCheck["content" + currentLanguage.data];

        if (newData["content" + currentLanguage.data] === undefined) {
          newData["content" + currentLanguage.data] = "";
        }
      }
      currentDataContentRichText.push(newData);
    }

    for (let i = 0; i < this.currentDataContentFile.length; i++) {
      const dataCheck = this.currentDataContentFile[i];
      const newData = {};
      newData.id = dataCheck.id;
      newData.name = dataCheck.name;
      newData.publicName = dataCheck.publicName;
      newData.orden = dataCheck.orden;
      for (let l = 0; l < this.props.languages.length; l++) {
        const currentLanguage = this.props.languages[l];
        newData["file" + currentLanguage.data] =
          dataCheck["file" + currentLanguage.data];

        if (newData["file" + currentLanguage.data] === undefined) {
          newData["file" + currentLanguage.data] = {};
        }
      }
      currentDataContentFile.push(newData);
    }

    let savedContent = saveContentC + "!";
    const toastSave = toast.info(saveContent, {
      autoClose: false
    });

    let finalObjectSave = { ...this.currentTableToChange };
    if (
      this.props.tableParentIdConnect !== undefined &&
      this.props.tableParentId
    ) {
      finalObjectSave[
        this.props.tableParentIdConnect
      ] = this.props.tableParentId;
    }
    finalObjectSave.isBase = false;

    systemLog("CreateBaseContent", currentDataContentText);

    systemLog("CreateBaseContent", currentDataContentLink);

    systemLog("CreateBaseContent", currentDataContentRichText);

    systemLog("CreateBaseContent", currentDataContentImage);

    systemLog("CreateBaseContent", currentDataContentFile);
    this.props.API.PostTo(this.dirToModify, finalObjectSave)
      .then(response => {
        systemLog("CreateBaseContent", response);
        let thereIsError = false;
        if (response.error !== undefined) {
          savedContent = saveContentB;
          thereIsError = true;
        }

        if (thereIsError) {
          toast.error(savedContent);
        }

        if (thereIsError === false) {
          this.props.openFrontLoading(true);
          this.uploadDataContentText(
            response,
            currentDataContentText,
            currentDataContentLink,
            currentDataContentRichText,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      })
      .catch(error => {
        systemLog("CreateBaseContent", error);
        savedContent = saveContentB;
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: toast.TYPE.ERROR
          });
        } else {
          toast.error(savedContent);
        }
      });
  }

  uploadDataContentText(
    contentSectionRes,
    currentDataContentText,
    currentDataContentLink,
    currentDataContentRichText,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentText");
    if (currentDataContentText.length === 0) {
      this.uploadDataContentLink(
        contentSectionRes,
        currentDataContentLink,
        currentDataContentRichText,
        currentDataContentImage,
        currentDataContentFile
      );
    }

    let countUpload = 0;
    for (let i = 0; i < currentDataContentText.length; i++) {
      const dataCheck = currentDataContentText[i];
      delete dataCheck.id;
      dataCheck["contentSectionId"] = contentSectionRes.id;
      this.props.API.PostTo("DataContentTexts", dataCheck).then(response => {
        countUpload = countUpload + 1;
        if (countUpload >= currentDataContentText.length) {
          this.uploadDataContentLink(
            contentSectionRes,
            currentDataContentLink,
            currentDataContentRichText,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      });
    }
  }

  uploadDataContentLink(
    contentSectionRes,
    currentDataContentLink,
    currentDataContentRichText,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentLink");
    if (currentDataContentLink.length === 0) {
      this.uploadDataContentRichText(
        contentSectionRes,
        currentDataContentRichText,
        currentDataContentImage,
        currentDataContentFile
      );
    }

    let countUpload = 0;
    for (let i = 0; i < currentDataContentLink.length; i++) {
      const dataCheck = currentDataContentLink[i];
      delete dataCheck.id;
      dataCheck["contentSectionId"] = contentSectionRes.id;
      this.props.API.PostTo("DataContentLinks", dataCheck).then(response => {
        countUpload = countUpload + 1;
        if (countUpload >= currentDataContentLink.length) {
          this.uploadDataContentRichText(
            contentSectionRes,
            currentDataContentRichText,
            currentDataContentImage,
            currentDataContentFile
          );
        }
      });
    }
  }

  uploadDataContentRichText(
    contentSectionRes,
    currentDataContentRichText,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentRichText");
    if (currentDataContentRichText.length === 0) {
      this.uploadDataContentImages(
        contentSectionRes,
        currentDataContentImage,
        currentDataContentFile
      );
    }

    let countUpload = 0;
    for (let i = 0; i < currentDataContentRichText.length; i++) {
      const dataCheck = currentDataContentRichText[i];
      delete dataCheck.id;
      dataCheck["contentSectionId"] = contentSectionRes.id;
      systemLog("CreateBaseContent", dataCheck);
      this.props.API.PostTo("DataContentRichTexts", dataCheck).then(
        response => {
          countUpload = countUpload + 1;
          if (countUpload >= currentDataContentRichText.length) {
            this.uploadDataContentImages(
              contentSectionRes,
              currentDataContentImage,
              currentDataContentFile
            );
          }
        }
      );
    }
  }

  uploadDataContentImages(
    contentSectionRes,
    currentDataContentImage,
    currentDataContentFile
  ) {
    systemLog("UploadTest", "uploadDataContentImages");
    if (currentDataContentImage.length === 0) {
      this.uploadDataContentFiles(contentSectionRes, currentDataContentFile);
    } else {
      this.doUploadImages(
        contentSectionRes,
        currentDataContentImage,
        "DataContentImages",
        "b64",
        0,
        0,
        0,
        () => {
          this.uploadDataContentFiles(
            contentSectionRes,
            currentDataContentFile
          );
        }
      );
    }
  }

  uploadDataContentFiles(contentSectionRes, currentDataContentFile) {
    systemLog("UploadTest", "uploadDataContentFiles");
    if (currentDataContentFile.length === 0) {
      this.checkDidUploadAll();
    } else {
      this.doUploadFiles(
        contentSectionRes,
        currentDataContentFile,
        "DataContentFiles",
        "file",
        0,
        0,
        () => {
          this.checkDidUploadAll();
        }
      );
    }
  }

  doUploadImages(
    contentSectionRes,
    currentDataUpload,
    uploadTo,
    dataName,
    curretnIndex,
    currentIndexLanguage,
    currentIndexImages,
    callBack
  ) {
    this.checkUploadingImages(
      currentDataUpload,
      curretnIndex,
      currentIndexLanguage,
      currentIndexImages,
      dataName,
      (dataCheck, returnIndex) => {
        dataCheck["contentSectionId"] = contentSectionRes.id;
        const nextIndexImages = currentIndexImages + 1;
        if (nextIndexImages >= ImagesRes.length) {
          const nextLanguageIndex = currentIndexLanguage + 1;
          if (nextLanguageIndex >= this.props.languages.length) {
            systemLog("UploadTestImages", dataCheck);
            this.props.API.PostTo(uploadTo, dataCheck).then(response => {
              const nextIndex = curretnIndex + 1;
              if (nextIndex >= currentDataUpload.length) {
                callBack();
              } else {
                this.doUploadImages(
                  contentSectionRes,
                  currentDataUpload,
                  uploadTo,
                  dataName,
                  nextIndex,
                  0,
                  0,
                  () => {
                    callBack();
                  }
                );
              }
            });
          } else {
            this.doUploadImages(
              contentSectionRes,
              currentDataUpload,
              uploadTo,
              dataName,
              curretnIndex,
              nextLanguageIndex,
              0,
              () => {
                callBack();
              }
            );
          }
        } else {
          this.doUploadImages(
            contentSectionRes,
            currentDataUpload,
            uploadTo,
            dataName,
            curretnIndex,
            currentIndexLanguage,
            nextIndexImages,
            () => {
              callBack();
            }
          );
        }
      }
    );
  }

  checkUploadingImages(
    array,
    currentIndex,
    currentIndexLanguage,
    currentIndexImages,
    dataName,
    callback
  ) {
    if (currentIndex < array.length) {
      const dataCheck = array[currentIndex];
      let addDataName = "";
      if (this.props.languages.length > currentIndexLanguage) {
        addDataName = this.props.languages[currentIndexLanguage].data;
      }
      delete dataCheck.id;
      systemLog("UploadTestImages", currentIndexImages);
      systemLog(
        "UploadTestImages",
        dataCheck[dataName + addDataName][
          ImagesRes[currentIndexImages].dataName
        ]
      );
      if (
        dataCheck[dataName + addDataName][
          ImagesRes[currentIndexImages].dataName
        ] !== undefined
      ) {
        if (
          dataCheck[dataName + addDataName][
            ImagesRes[currentIndexImages].dataName
          ].name !== undefined
        ) {
          dataCheck[dataName + addDataName][
            ImagesRes[currentIndexImages].dataName
          ].name = setBaseFileNameDataCheck(
            BasedataImageNameSet,
            dataCheck[dataName + addDataName][
              ImagesRes[currentIndexImages].dataName
            ].name
          );
          this.props.API.CheckFileExist(
            dataCheck[dataName + addDataName][
              ImagesRes[currentIndexImages].dataName
            ].name
          ).then(exist => {
            let canChangeName = true;
            systemLog(
              "UploadTestImages",
              dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ]
            );
            if (dataCheck[dataName + addDataName].fileName !== undefined) {
              canChangeName =
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName ===
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].name
                  ? false
                  : true;
            }
            if (exist === true && canChangeName === true) {
              const splitData = dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].name.split(".");
              const extension = splitData[splitData.length - 1];
              const name =
                setURLType(splitData[0]) +
                "_" +
                moment()
                  .unix()
                  .toString() +
                "." +
                extension;
              dataCheck[dataName + addDataName][
                ImagesRes[currentIndexImages].dataName
              ].name = name;
            }
            if (dataCheck[dataName + addDataName].fileName !== undefined) {
              this.props.API.UploadFile(
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].result,
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].fileName,
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].name
              )
                .then(response => {
                  systemLog("UploadTestImages", response);
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].isNewFile;
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].b64;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].fileName = response.name;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].preview = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].result = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].resultWEBP = response.pathWEBP;
                  setTimeout(() => {
                    callback(dataCheck, currentIndex);
                  }, 620);
                })
                .catch(error => {
                  systemLog("UploadTestImages", error);
                  callback({}, currentIndex);
                });
            } else {
              this.props.API.DoUploadFile(
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].result,
                dataCheck[dataName + addDataName][
                  ImagesRes[currentIndexImages].dataName
                ].name
              )
                .then(response => {
                  systemLog("UploadTestImages", response);
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].isNewFile;
                  delete dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].b64;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].fileName = response.name;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].preview = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].result = response.path;
                  dataCheck[dataName + addDataName][
                    ImagesRes[currentIndexImages].dataName
                  ].resultWEBP = response.pathWEBP;
                  setTimeout(() => {
                    callback(dataCheck, currentIndex);
                  }, 620);
                })
                .catch(error => {
                  systemLog("UploadTestImages", error);
                  callback(dataCheck, currentIndex);
                });
            }
          });
        } else {
          callback(dataCheck, currentIndex);
        }
      } else {
        callback(dataCheck, currentIndex);
      }
    } else {
      callback({}, currentIndex);
    }
  }

  doUploadFiles(
    contentSectionRes,
    currentDataUpload,
    uploadTo,
    dataName,
    curretnIndex,
    currentIndexLanguage,
    callBack
  ) {
    this.checkUploadingOfFile(
      currentDataUpload,
      curretnIndex,
      currentIndexLanguage,
      dataName,
      (dataCheck, returnIndex) => {
        dataCheck["contentSectionId"] = contentSectionRes.id;
        const nextLanguageIndex = currentIndexLanguage + 1;
        if (nextLanguageIndex >= this.props.languages.length) {
          systemLog("doUploadFiles", dataCheck);
          this.props.API.PostTo(uploadTo, dataCheck)
            .then(response => {
              const nextIndex = curretnIndex + 1;
              if (nextIndex >= currentDataUpload.length) {
                callBack();
              } else {
                this.doUploadFiles(
                  contentSectionRes,
                  currentDataUpload,
                  uploadTo,
                  dataName,
                  nextIndex,
                  0,
                  () => {
                    callBack();
                  }
                );
              }
            })
            .catch(error => {
              const nextIndex = curretnIndex + 1;
              if (nextIndex >= currentDataUpload.length) {
                callBack();
              } else {
                this.doUploadFiles(
                  contentSectionRes,
                  currentDataUpload,
                  uploadTo,
                  dataName,
                  nextIndex,
                  0,
                  () => {
                    callBack();
                  }
                );
              }
            });
        } else {
          this.doUploadFiles(
            contentSectionRes,
            currentDataUpload,
            uploadTo,
            dataName,
            curretnIndex,
            nextLanguageIndex,
            () => {
              callBack();
            }
          );
        }
      }
    );
  }

  checkUploadingOfFile(
    array,
    currentIndex,
    currentIndexLanguage,
    dataName,
    callback
  ) {
    if (currentIndex < array.length) {
      const dataCheck = array[currentIndex];
      systemLog("doUploadFiles", dataCheck);
      let addDataName = "";
      if (this.props.languages.length > currentIndexLanguage) {
        addDataName = this.props.languages[currentIndexLanguage].data;
        systemLog("FILE_UPLOAD_CHECK", addDataName);
      }
      delete dataCheck.id;
      if (dataCheck[dataName + addDataName].name !== undefined) {
        dataCheck[dataName + addDataName].name = setBaseFileNameDataCheck(
          BasedataImageNameSet,
          dataCheck[dataName + addDataName].name
        );
        this.props.API.CheckFileExist(dataCheck[dataName + addDataName].name)
          .then(exist => {
            systemLog("doUploadFiles", exist);
            if (exist === true) {
              const splitData = dataCheck[dataName + addDataName].name.split(
                "."
              );
              const extension = splitData[splitData.length - 1];
              const name =
                setURLType(splitData[0]) +
                "_" +
                moment()
                  .unix()
                  .toString() +
                "." +
                extension;
              dataCheck[dataName + addDataName].name = name;
            }
            this.props.API.DoUploadFile(
              dataCheck[dataName + addDataName].result,
              dataCheck[dataName + addDataName].name
            )
              .then(response => {
                systemLog("doUploadFiles", response);
                delete dataCheck[dataName + addDataName].isNewFile;
                delete dataCheck[dataName + addDataName].b64;
                dataCheck[dataName + addDataName].fileName = response.name;
                dataCheck[dataName + addDataName].preview = response.path;
                dataCheck[dataName + addDataName].result = response.path;
                dataCheck[dataName + addDataName].resultWEBP =
                  response.pathWEBP;
                setTimeout(() => {
                  callback(dataCheck, currentIndex);
                }, 620);
              })
              .catch(error => {
                systemLog("doUploadFiles", error);
                callback(dataCheck, currentIndex);
              });
          })
          .catch(error => {
            systemLog("doUploadFiles", error);
            callback(dataCheck, currentIndex);
          });
      } else {
        callback(dataCheck, currentIndex);
      }
    } else {
      systemLog("doUploadFiles", "PURA MAMADA");
      callback({}, currentIndex);
    }
  }

  checkDidUploadAll() {
    systemLog("UploadTest", "checkDidUploadAll");
    this.props.openFrontLoading(false);
    if (this.props.clickOnSave !== undefined) {
      this.props.clickOnSave();
      toast.success(saveContentC);
    }
  }

  renderCreateData(title) {
    return (
      <div
        style={{
          ...styleCenteredObject,
          width: "100%",
          height: "2rem",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            height: "100%",
            justifyContent: "flex-start"
          }}
        >
          <ModularText style={{ fontSize: textSizeHeaderSubTitle }}>
            {title}
          </ModularText>
        </div>
      </div>
    );
  }

  renderValueColumn() {
    return (
      <div style={{ ...columnStyle, width: "4rem" }}>
        {renderLabel(dataValue.dataLabel, "")}
        {renderModifyData(
          dataValue,
          this.currentTableToChange,
          this.props.formatter,
          () => {
            this.forceUpdate();
          }
        )}
      </div>
    );
  }

  renderSectionMainInfo() {
    if (this.props.user.isGod) {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataPrivateName.dataLabel)}
              {renderModifyData(
                dataPrivateName,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataPublicName.dataLabel, "")}
              {renderModifyData(
                dataPublicName,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataBase.dataLabel, "")}
              {renderModifyData(
                dataBase,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>{this.renderValueColumn()}</Grid.Column>
        </Grid>
      );
    } else {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <div style={{ ...columnStyle }}>
              {renderLabel(dataPublicName.dataLabel, "")}
              {renderModifyData(
                dataPublicName,
                this.currentTableToChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Grid.Column>
          <Grid.Column>{this.renderValueColumn()}</Grid.Column>
        </Grid>
      );
    }
  }

  renderInsideData(data) {
    if (data.typeContent === "text") {
      dataText.dataName = dataText.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataText, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    } else if (data.typeContent === "image") {
      dataImage.dataName = dataImage.mainDataName + this.state.currentLanguage;
      return (
        <div style={{ width: "100%" }}>
          {/*renderModifyData(dataTint, data, this.props.formatter, () => {
            this.forceUpdate();
          })*/}
          {renderModifyData(dataImage, data, this.props.formatter, table => {
            systemLog("CreateBaseContent", table);
            this.forceUpdate();
          })}
        </div>
      );
    } else if (data.typeContent === "link") {
      dataLink.dataName = dataLink.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataLink, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    } else if (data.typeContent === "richtext") {
      dataRichText.dataName =
        dataRichText.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataRichText, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    } else if (data.typeContent === "file") {
      dataFile.dataName = dataFile.mainDataName + this.state.currentLanguage;
      return renderModifyData(dataFile, data, this.props.formatter, () => {
        this.forceUpdate();
      });
    }
  }

  renderTopExtraInfo() {
    return (
      <div
        style={{
          ...styleCenteredObject,
          justifyContent: "flex-end",
          marginRight: adminSeparation,
          padding: "0.7rem",
          height: "100%",
          width: "10rem",
          border: "1px solid " + colors.borderColor
        }}
      >
        <Dropdown
          fluid
          search
          onChange={(event, changedData) => {
            const checkIdiom = changedData.value;
            const languageCheck = this.props.languages.find(language => {
              return language.value === checkIdiom;
            });
            this.setState({
              currentLanguageId: checkIdiom,
              currentLanguage: languageCheck.data
            });
            this.forceUpdate();
          }}
          value={this.state.currentLanguageId}
          placeholder={"Idioma"}
          options={this.props.languages}
        />
      </div>
    );
  }

  render() {
    const canSave = true;
    return (
      <div>
        {renderTopName(
          "Crear Nuevo: " + this.props.table.publicName,
          this.renderTopExtraInfo
        )}
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.renderSectionMainInfo()}
          <div style={{ height: "1rem" }} />
          {this.totalDataContent.map((data, index) => {
            let label = data.publicName;
            if (this.props.user.isGod && data.name !== "") {
              label = data.publicName + " (" + data.name + ")";
            }
            return (
              <div key={index}>
                <div style={{ ...columnStyle, justifyContent: "center" }}>
                  <div style={{ ...rowStyle }}>
                    <div style={{ minWidth: "1rem" }}>{renderLabel(label)}</div>
                  </div>
                  {this.renderInsideData(data)}
                </div>
                <div style={{ height: "0.5rem" }} />
              </div>
            );
          })}
          <div
            style={{
              height: "2rem"
            }}
          />
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              width: "100%",
              height: "4rem"
            }}
          >
            <Button
              onClick={() => {
                if (canSave) {
                  this.saveItem();
                }
              }}
              style={{
                backgroundColor: canSave
                  ? colors.buttonColor
                  : colors.disableColor,
                color: colors.colorFrontButton,
                ...styleCenteredObject,
                width: "8rem",
                height: "3rem"
              }}
            >
              {"GUARDAR"}
            </Button>
          </div>
          <div style={{ height: "1rem" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, user, languages } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API, user, languages };
};

const mapDispatchToProps = dispatch => {
  return {
    openFrontLoading: showIt => {
      dispatch(openFrontLoading(showIt));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateBaseContent)
);
