export const tableEditItemData = {
  dataTitle: [
    {
      dataName: "name",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre",
      dataType: "string"
    },
    {
      dataName: "description",
      dataLabel: "Descripcion",
      dataType: "string"
    }
  ]
};

export const tableCreateItemData = {
  dataTitle: [
    {
      dataName: "name",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre",
      dataType: "string"
    },
    {
      dataName: "description",
      dataLabel: "Descripcion",
      dataType: "string"
    }
  ]
};

export const tableViewItemData = {
  dataTitle: [
    {
      dataName: "name",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre",
      dataType: "string"
    },
    {
      dataName: "description",
      dataLabel: "Descripcion",
      dataType: "string"
    }
  ]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "description",
    dataLabel: "Descripcion",
    dataType: "string"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataType: "string"
  },
  {
    dataName: "description",
    dataType: "string"
  }
];
