import * as types from "../types";

const INITIAL_STATE = {
  API: null,
  doingLogOut: false,
  onLoading: false,
  formatter: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESETALL: {
      return { ...INITIAL_STATE, API: action.api, formatter: action.formatter };
    }
    case types.LOGOUT: {
      return { ...state, doingLogOut: true };
    }
    case types.SET_API: {
      return { ...state, API: action.api };
    }
    case types.SET_PRICE_FORMARTEER: {
      return { ...state, formatter: action.formatter };
    }
    case types.OPEN_FRONTLOADING: {
      return { ...state, onLoading: true };
    }
    case types.CLOSE_FRONTLOADING: {
      return { ...state, onLoading: false };
    }
    default: {
      return state;
    }
  }
};
