import React, { Component } from "react";
import Calendar from "../../components/Calendar";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { showDash: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Calendar />
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { user, userPermissions } = data;
  return { userPermissions, user };
};
export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(HomePage)
);
