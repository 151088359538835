import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setAnyData, setOrganizations } from "../redux/actions";
import { renderModifyData, renderLabel } from "../scripts/System";
import BaseTable from "./Project/BaseTable";

class EmailTemplateDataTable extends Component {
  constructor(props) {
    super(props);
    this.versionsData = {
      dataName: "templateVersionId",
      dataLabel: "Id Version Template (SendGrid)",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "versions",
        key: "id",
        value: "id",
        text: "name"
      },
      dataType: "selector"
    };
    this.customRenderData = {};
    this.renderSendGridId = this.renderSendGridId.bind(this);
    this.renderVersionId = this.renderVersionId.bind(this);
    this.customRenderData = {
      templateId: this.renderSendGridId,
      templateVersionId: this.renderVersionId
    };
  }

  renderSendGridId(data, currentTableToChange, formatter, forceUpdate) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          alignItems: "flex-start",
          textAlign: "left"
        }}
      >
        {renderLabel(data.dataLabel, data.notRequired)}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            width: "100%",
            justifyContent: "flex-start",
            alignSelf: "flex-start",
            alignItems: "flex-start",
            textAlign: "flex-start"
          }}
        >
          <div style={{ width: "100%" }}>
            {renderModifyData(
              data,
              currentTableToChange,
              formatter,
              returnData => {
                const templateSendGrid = data.selectorData.find(template => {
                  return template.key === returnData;
                });
                const versions = templateSendGrid.data.versions;
                const selectorData = [];
                for (let i = 0; i < versions.length; i++) {
                  const currentVersion = versions[i];
                  const keyValueAdd = {
                    key: currentVersion.id,
                    value: currentVersion.id,
                    text: currentVersion.name
                  };
                  selectorData.push(keyValueAdd);
                }
                currentTableToChange[this.versionsData.dataName] = "";
                this.versionsData.selectorData = selectorData;
                forceUpdate();
              }
            )}
          </div>
        </div>
      </div>
    );
  }

  renderVersionId(data, currentTableToChange, formatter, forceUpdate) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          alignItems: "flex-start",
          textAlign: "left"
        }}
      >
        {renderLabel(data.dataLabel, data.notRequired)}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            width: "100%",
            justifyContent: "flex-start",
            alignSelf: "flex-start",
            alignItems: "flex-start",
            textAlign: "flex-start"
          }}
        >
          <div style={{ width: "100%" }}>
            {renderModifyData(
              this.versionsData,
              currentTableToChange,
              formatter,
              returnData => {
                forceUpdate();
              }
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <BaseTable
        {...this.props}
        customCreateRenderData={this.customRenderData}
        customEditRenderData={this.customRenderData}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  return { API };
};

const mapDispatchToProps = dispatch => {
  return {
    setAnyData: (key, payload) => {
      dispatch(setAnyData(key, payload));
    },
    setOrganizations: organizations => {
      dispatch(setOrganizations(organizations));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmailTemplateDataTable)
);
