import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  columnEditStyle,
  renderLabel,
  renderModifyData
} from "../scripts/System";
import ModalComponent from "../layaouts/ModalComponent";
import { colors, styleCenteredObject } from "../style";

const newEmail = {
  dataName: "email",
  asData: "email",
  dataSingle: "Correo",
  dataLabel: "Ingresa tu Correo",
  dataType: "string",
  needConfirm: false,
  autoCompleteConfirm: "off"
};

class ModalResetPasswordLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.currentEmailChange = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showModal !== this.state.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
  }

  render() {
    return (
      <ModalComponent
        width={"70%"}
        onOpen={() => {}}
        closeModal={() => {
          this.props.closeModal();
        }}
        showModal={this.state.showModal}
      >
        <Modal.Header>
          <h1>{"Recuperar contraseña"}</h1>
          <p>{"Coloca tu correo para poder recuperar tu contraseña"}</p>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ ...columnEditStyle }}>
              {renderLabel(newEmail.dataLabel)}
              {renderModifyData(
                newEmail,
                this.currentEmailChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Content>
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              flexDirection: "row"
            }}
          >
            <Button
              onClick={() => {
                this.props.closeModal();
              }}
              style={{
                backgroundColor: colors.removeColor,
                color: colors.removeFrontColor
              }}
              content={"CANCELAR"}
            />
            <div style={{ width: "1rem" }} />
            <Button
              onClick={() => {
                this.props.API.PostTo("SysUsers/resetPwd", {
                  email: this.currentEmailChange.email
                })
                  .then(response => {
                    if (response.error === undefined) {
                      toast.success(
                        "¡El correo con las instrucciones se envio correctamente!"
                      );
                      this.props.closeModal();
                    } else {
                      toast.error("Error al enviar el correo");
                    }
                  })
                  .catch(error => {
                    toast.error("Error al enviar el correo");
                  });
              }}
              style={{
                backgroundColor: colors.buttonColor,
                color: colors.colorFrontButton
              }}
              content={"ACEPTAR"}
            />
          </div>
        </Modal.Content>
      </ModalComponent>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { user } = data;
  const { formatter, API } = api;
  return {
    formatter,
    API,
    user
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ModalResetPasswordLogin)
);
