import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import { Motion, spring } from "react-motion";
import { Icon, Menu, Button, Responsive } from "semantic-ui-react";
import LetterGoogleStyle from "../components/LetterGoogleStyle";
import LogoRender from "../components/LogoRender";
import { sytemRoutes as routesLanding } from "../routes";
import { landingRoutes as routesSystem } from "../routes";
import {
  chevronSize,
  colors,
  elevation,
  headerHeight,
  iconSizeLeft,
  menuItemsWidth,
  menuWidth,
  separationIconLeft,
  styleBasic,
  styleCenteredObject,
  textSizeMenu,
  textSizeSubMenu
} from "../style";

const menuHeight = 50;
const centeredMenuItem = isActive => {
  let specialTextStyle = {};
  if (isActive === true) {
    specialTextStyle = { ...elevation };
    specialTextStyle.fontWeight = "bold";
    specialTextStyle.backgroundColor = colors.mainColorSelector;
  }
  const finalStyle = {
    ...styleCenteredObject,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: separationIconLeft,
    paddingRight: separationIconLeft,
    width: "100%",
    height: menuHeight,
    textAlign: "left",
    justifyContent: "flex-start",
    ...specialTextStyle
  };

  return finalStyle;
};

const subMenuHeight = 40;
const centeredSubMenuItem = isActive => {
  let specialTextStyle = {};
  if (isActive === true) {
    specialTextStyle.fontWeight = "bold";
    specialTextStyle.backgroundColor = colors.mainColorSelectorSecondary;
  }
  const finalStyle = {
    ...styleCenteredObject,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: separationIconLeft,
    paddingRight: separationIconLeft,
    width: "100%",
    height: subMenuHeight,
    textAlign: "left",
    ...specialTextStyle
  };
  return finalStyle;
};

class MainSideBar extends Component {
  constructor(props) {
    super(props);
    const activeItem = this.props.currentPath;
    this.state = {
      activeItem: activeItem,
      routes: routesSystem()
    };
    this.renderMovilMenu = this.renderMovilMenu.bind(this);
    this.renderDesktopMenu = this.renderDesktopMenu.bind(this);
    this.renderMenuPermCheck = this.renderMenuPermCheck.bind(this);
    this.renderSingleItem = this.renderSingleItem.bind(this);
    this.renderChangeRouter = this.renderChangeRouter.bind(this);
    this.renderPages = this.renderPages.bind(this);
    this.renderMenuPage = this.renderMenuPage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.activeItem !== nextProps.currentPath) {
      this.setState({ activeItem: nextProps.currentPath });
    }
  }

  renderSingleItem(itemName, showChevron, visibleChevron) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: iconSizeLeft,
            height: "100%",
            ...styleCenteredObject
          }}
        >
          <div
            style={{
              width: "2rem",
              height: "2rem",
              ...styleCenteredObject
            }}
          >
            <LetterGoogleStyle
              letter={itemName[0].toUpperCase()}
              fontSize={"1rem"}
            />
          </div>
        </div>
        <div
          style={{
            height: "100%",
            width: separationIconLeft
          }}
        />
        <div
          style={{
            width: menuItemsWidth,
            height: "100%",
            ...styleCenteredObject,
            justifyContent: "flex-start"
          }}
        >
          <p
            style={{
              textAlign: "left",
              fontSize: textSizeMenu,
              color: "white"
            }}
          >
            {itemName.toUpperCase()}
          </p>
        </div>
        <div
          style={{
            height: "100%",
            width: separationIconLeft
          }}
        />
        <div
          style={{
            width: showChevron ? chevronSize : 0,
            height: "100%",
            overflow: "hidden",
            ...styleCenteredObject
          }}
        >
          <Icon
            name={visibleChevron ? "chevron down" : "chevron right"}
            style={{
              color: "white",
              fontSize: textSizeMenu,
              textAlign: "center"
            }}
          />
        </div>
        <div
          style={{
            height: "100%",
            width: separationIconLeft
          }}
        />
      </div>
    );
  }

  renderMenuPage(page, indexpage) {
    const { activeItem } = this.state;
    let pageSections = [];
    if (page.sectionPages !== undefined) {
      pageSections = page.sectionPages;
    }
    let showChevron = false;
    if (pageSections.length > 0) {
      showChevron = true;
    }
    let isActivePage = false;
    if (activeItem.includes("/landing/page/" + page.id) === true) {
      isActivePage = true;
    }

    let isActiveSubPage = false;
    if (activeItem === "/landing/page/" + page.id) {
      isActiveSubPage = true;
    }

    const permissionData = this.props.userPagesPermissions.find(x => {
      return x.pageId === page.id;
    });
    if (
      this.props.user.isGod === false &&
      (permissionData === undefined || permissionData.read === false)
    ) {
      return <div key={indexpage} />;
    }
    return (
      <div key={page.id}>
        <Menu.Item
          name={page.name}
          active={isActivePage}
          onClick={(e, { name }) => {
            this.props.push("/landing/page/" + page.id);
            if (window.innerWidth < Responsive.onlyTablet.minWidth) {
              if (showChevron === false) {
                this.props.closeMenu();
              }
            } else {
              this.props.onClickItem();
            }
          }}
          style={centeredMenuItem(isActivePage)}
        >
          {this.renderSingleItem(page.publicName, showChevron, isActivePage)}
        </Menu.Item>
        <Motion
          style={{
            x: spring(
              isActivePage && this.props.visibleMobileMenu
                ? pageSections.length * subMenuHeight
                : 0
            )
          }}
        >
          {({ x }) => {
            return (
              <div
                style={{
                  ...styleBasic,
                  height: x,
                  overflow: "hidden"
                }}
              >
                <Menu.Item
                  as={Button}
                  name={page.name}
                  active={isActiveSubPage}
                  onClick={(e, { name }) => {
                    this.props.push("/landing/page/" + page.id);
                    if (window.innerWidth < Responsive.onlyTablet.minWidth) {
                      this.props.closeMenu();
                    }
                  }}
                  style={centeredSubMenuItem(isActiveSubPage)}
                >
                  <div style={{ width: iconSizeLeft }} />
                  <div style={{ width: separationIconLeft * 2 }} />
                  <div style={{ width: 6 }} />
                  <div
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: textSizeSubMenu,
                        color: "white"
                      }}
                    >
                      {"EDITOR DE LA PAGINA"}
                    </p>
                  </div>
                </Menu.Item>
                {pageSections.map(section => {
                  let isActiveSection = false;
                  if (activeItem.includes("/section/" + section.id) === true) {
                    isActiveSection = true;
                  }
                  return (
                    <Menu.Item
                      key={section.id}
                      as={Button}
                      name={section.name}
                      active={isActiveSection}
                      onClick={(e, { name }) => {
                        this.props.push(
                          "/landing/page/" + page.id + "/section/" + section.id
                        );
                        if (
                          window.innerWidth < Responsive.onlyTablet.minWidth
                        ) {
                          this.props.closeMenu();
                        }
                      }}
                      style={centeredSubMenuItem(isActiveSection)}
                    >
                      <div style={{ width: iconSizeLeft }} />
                      <div style={{ width: separationIconLeft * 2 }} />
                      <div style={{ width: 6 }} />
                      <div
                        style={{
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: textSizeSubMenu,
                            color: "white"
                          }}
                        >
                          {section.publicName.toUpperCase()}
                        </p>
                      </div>
                    </Menu.Item>
                  );
                })}
              </div>
            );
          }}
        </Motion>
      </div>
    );
  }

  renderPages() {
    if (this.state.activeItem.includes("landing") === false) {
      return <div />;
    } else {
      return (
        <div>
          {this.props.pages.map((pages, index) => {
            return <div key={index}>{this.renderMenuPage(pages, index)}</div>;
          })}
        </div>
      );
    }
  }

  renderMenuPermCheck(screen) {
    const { name, route, perm, pages, showOnMenu, onlyIfGod } = screen;
    const { activeItem } = this.state;
    let canShowItem = false;
    const userperm = this.props.userPermissions.find(permission => {
      if (permission.module === undefined) {
        return false;
      }
      return permission.module.name.toUpperCase() === perm.toUpperCase();
    });
    if (userperm !== undefined) {
      if (userperm.read === true) {
        canShowItem = true;
      }
    }
    if (perm === "DASHBOARD") {
      canShowItem = true;
    }

    if (this.props.user.isGod === true) {
      canShowItem = true;
    } else {
      if (onlyIfGod === true) {
        canShowItem = false;
      }
    }

    if (showOnMenu === false) {
      canShowItem = false;
    }

    let showChevron = false;
    if (canShowItem === true) {
      let isActiveItem = false;
      if (activeItem === route) {
        isActiveItem = true;
      }
      let finalPages = [];
      if (pages !== undefined) {
        for (let x = 0; x < pages.length; x++) {
          const currentPage = pages[x];
          const userInidePagePerm = this.props.userPermissions.find(
            permission => {
              if (permission.module === undefined) {
                return false;
              }
              return (
                permission.module.name.toUpperCase() ===
                currentPage.perm.toUpperCase()
              );
            }
          );
          if (userInidePagePerm !== undefined) {
            if (
              userInidePagePerm.read === true ||
              this.props.user.isGod === true
            ) {
              finalPages.push(currentPage);
              if (activeItem === currentPage.route) {
                isActiveItem = true;
              }
            }
          } else if (this.props.user.isGod === true) {
            finalPages.push(currentPage);
            if (activeItem === currentPage.route) {
              isActiveItem = true;
            }
          }
        }
      }
      if (finalPages.length > 0) {
        showChevron = true;
      }
      return (
        <div>
          <Menu.Item
            name={name}
            onClick={(e, { name }) => {
              this.props.push(route);
              if (window.innerWidth < Responsive.onlyTablet.minWidth) {
                if (showChevron === false) {
                  this.props.closeMenu();
                }
              } else {
                this.props.onClickItem();
              }
            }}
            style={centeredMenuItem(isActiveItem)}
          >
            {this.renderSingleItem(name, showChevron, isActiveItem)}
          </Menu.Item>
          <Motion
            style={{
              x: spring(
                isActiveItem && this.props.visibleMobileMenu
                  ? finalPages.length * subMenuHeight
                  : 0
              )
            }}
          >
            {({ x }) => {
              return (
                <div
                  style={{
                    ...styleBasic,
                    height: x,
                    overflow: "hidden"
                  }}
                >
                  {finalPages.map((currentPage, insideIndex) => {
                    let isActiveInsidePage = false;
                    if (activeItem === currentPage.route) {
                      isActiveInsidePage = true;
                    }
                    return (
                      <Menu.Item
                        key={insideIndex}
                        as={Button}
                        name={currentPage.name}
                        active={isActiveInsidePage}
                        onClick={(e, { name }) => {
                          this.props.push(currentPage.route);
                          if (
                            window.innerWidth < Responsive.onlyTablet.minWidth
                          ) {
                            this.props.closeMenu();
                          }
                        }}
                        style={centeredSubMenuItem(isActiveInsidePage)}
                      >
                        <div style={{ width: iconSizeLeft }} />
                        <div style={{ width: separationIconLeft * 2 }} />
                        <div style={{ width: 6 }} />
                        <div
                          style={{
                            width: "100%",
                            height: "100%"
                          }}
                        >
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: textSizeSubMenu,
                              color: "white"
                            }}
                          >
                            {currentPage.name.toUpperCase()}
                          </p>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </div>
              );
            }}
          </Motion>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderChangeRouter() {
    if (this.props.haveLanding === false) {
      return <div />;
    }
    let backgroundColor = colors.webEditorColor;
    let color = colors.webEditorColorFront;
    let name = "Landing";
    let icon = "edit outline";
    if (this.state.activeItem.includes("landing")) {
      backgroundColor = colors.sysEditorColor;
      color = colors.sysEditorColorFront;
      name = "Sistema";
      icon = "database";
    }

    return (
      <Menu.Item
        as={"div"}
        onClick={(e, { name }) => {}}
        style={{
          ...centeredMenuItem,
          bottom: 0,
          position: "absolute",
          width: "100%",
          height: "5rem"
        }}
      >
        <Button
          onClick={() => {
            if (this.state.activeItem.includes("landing")) {
              this.setState({ routes: routesSystem() });
              this.props.push("/system");
              if (window.innerWidth >= Responsive.onlyTablet.minWidth) {
                this.props.onClickItem();
              }
            } else {
              this.setState({ routes: routesLanding() });
              this.props.push("/landing");
              if (window.innerWidth >= Responsive.onlyTablet.minWidth) {
                this.props.onClickItem();
              }
            }
          }}
          style={{
            backgroundColor: "transparent",
            ...styleCenteredObject,
            backgroundColor: backgroundColor,
            color: color,
            width: "100%",
            height: "100%"
          }}
          icon
          labelPosition="left"
        >
          {name}
          <Icon name={icon} />
        </Button>
      </Menu.Item>
    );
  }

  renderDesktopMenu() {
    if (this.props.verticalMenu === false) {
      return <div />;
    }
    const { activeItem } = this.state;
    return (
      <Menu
        style={{
          ...styleBasic,
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: 100,
          width: menuWidth,
          height: window.innerHeight,
          backgroundColor: colors.mainColor,
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            width: menuWidth,
            heigth: "100%"
          }}
        >
          <Menu.Item
            as={"div"}
            onClick={(e, { name }) => {}}
            style={{
              ...centeredMenuItem,
              padding: 0,
              height: headerHeight
            }}
          >
            <LogoRender
              logoSizeW={headerHeight * 0.8}
              logoSizeH={headerHeight * 0.8}
              marginLeftIcon={separationIconLeft * 0.5}
              separationFromIcon={separationIconLeft * 0.3}
              renderName={true}
              nameToRender={this.props.organization.name}
            />
          </Menu.Item>
          <div
            style={{ height: "84%", overflowY: "scroll", overflowX: "hidden" }}
          >
            {this.state.routes.map((screen, index) => {
              return <div key={index}>{this.renderMenuPermCheck(screen)}</div>;
            })}
            {this.renderPages()}
          </div>
          {this.renderChangeRouter()}
        </div>
      </Menu>
    );
  }

  renderMovilMenu() {
    return (
      <Menu
        style={{
          ...styleBasic,
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: this.props.visibleMobileMenu ? 600 : 100,
          width: window.innerWidth,
          height: window.innerHeight,
          backgroundColor: colors.mainColor
        }}
      >
        <div
          style={{
            width: window.innerWidth,
            heigth: "100%"
          }}
        >
          <Menu.Item
            as={"div"}
            onClick={(e, { name }) => {}}
            style={{
              ...centeredMenuItem,
              paddingLeft: 0,
              height: headerHeight
            }}
          >
            <LogoRender
              logoSizeW={"11.5rem"}
              logoSizeH={headerHeight}
              separationFromIcon={separationIconLeft}
              renderName={false}
            />
          </Menu.Item>
          <div
            style={{ height: "80%", overflowY: "scroll", overflowX: "hidden" }}
          >
            {this.state.routes.map((screen, index) => {
              return <div key={index}>{this.renderMenuPermCheck(screen)}</div>;
            })}
            {this.renderPages()}
          </div>
          {this.renderChangeRouter()}
        </div>
      </Menu>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderMovilMenu()}
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          {this.renderDesktopMenu()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const {
    verticalMenu,
    organization,
    userPermissions,
    user,
    pages,
    userPagesPermissions,
    haveLanding
  } = data;
  return {
    verticalMenu,
    organization,
    userPermissions,
    user,
    pages,
    userPagesPermissions,
    haveLanding
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainSideBar)
);
