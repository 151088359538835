import HomePage from "../pages/Landing/HomePage";
import LanguagePage from "../pages/Landing/LanguagePage";
import AdminPage from "../pages/Landing/AdminPage";
import AdminPagePerm from "../pages/Landing/AdminPagePerm";
import CreatorPage from "../pages/Landing/CreatorPage";
import SingleMenuPage from "../pages/Landing/SingleMenuPage";
import SinglePage from "../pages/Landing/SinglePage";
import SectionPage from "../pages/Landing/SectionPage";
import AdminGodPage from "../pages/System/AdminPage";
import ModulesPage from "../pages/System/ModulesPage";
import AdminRoles from "../pages/System/AdminRoles";
import OrganizationPage from "../pages/System/OrganizationPage";

import BlogCategoriesPage from "../pages/Blog/BlogCategoriesPage";
import BlogTagsPage from "../pages/Blog/BlogTagsPage";
import BlogEntriesPage from "../pages/Blog/BlogEntriesPage";

import BasePage from "../pages/Project/BasePage";
import * as EmailTemplatesDataTable from "../TablesData/emailTemplatesDataTable";
import EmailTemplateDatTable from "../BaseTables/EmailTemplateDatTable";
import * as LeadsDataTable from "../TablesData/leadsDataTable";

const insideAdminGod = [
  {
    route: "/landing/administratorgod",
    name: "UsuariosGOD",
    perm: "Usuarios",
    onlyIfGod: true,
    page: AdminGodPage
  },
  {
    route: "/landing/adminrolesgod",
    name: "RolesGOD",
    perm: "Roles",
    onlyIfGod: true,
    page: AdminRoles
  },
  {
    route: "/landing/modulesgod",
    name: "MódulosGOD",
    perm: "Módulos",
    onlyIfGod: true,
    page: ModulesPage
  },
  {
    route: "/system/organizationsgod",
    name: "OrganizacionesGOD",
    perm: "Organizaciones",
    onlyIfGod: true,
    page: OrganizationPage
  }
];

const insideBlog = [
  {
    route: "/landing/blogentries",
    name: "Entradas",
    perm: "Blog",
    page: BlogEntriesPage
  },
  {
    route: "/landing/blogcategories",
    name: "Categorias",
    perm: "Blog",
    page: BlogCategoriesPage
  },
  {
    route: "/landing/blogtags",
    name: "Tags",
    perm: "Blog",
    page: BlogTagsPage
  }
];

const insideAdminPages = [
  {
    route: "/landing/admin",
    name: "Usuarios",
    perm: "Usuarios",
    page: AdminPage
  },
  {
    route: "/landing/pageperm",
    name: "Permisos",
    perm: "Privilegios Web",
    page: AdminPagePerm
  }
];
const mainScreensToShow = [
  {
    route: "/landing",
    name: "Dashboard",
    perm: "DASHBOARD",
    page: HomePage
  },
  {
    route: "/landing/modulesgod",
    name: "MódulosGOD",
    perm: "Módulos",
    onlyIfGod: true,
    page: ModulesPage,
    pages: insideAdminGod
  },
  {
    route: "/landing/admin",
    name: "Administrador",
    perm: "Usuarios",
    page: AdminPage,
    pages: insideAdminPages
  },
  {
    route: "/landing/blogentries",
    name: "Blog",
    perm: "Blog",
    page: BlogEntriesPage,
    pages: insideBlog
  },
  {
    route: "/landing/email-templates",
    name: "Plantillas de Correo",
    perm: "Web",
    showOnMenu: true,
    dataTable: EmailTemplatesDataTable,
    baseTable: EmailTemplateDatTable,
    page: BasePage
  },
  {
    route: "/landing/languages",
    name: "Idiomas",
    perm: "Organizaciones",
    onlyIfGod: true,
    page: LanguagePage
  },
  {
    route: "/landing/pagecreator",
    name: "Creador",
    perm: "Organizaciones",
    onlyIfGod: true,
    page: CreatorPage
  },
  {
    route: "/landing/menupage/:id",
    name: "",
    perm: "Web",
    showOnMenu: false,
    page: SingleMenuPage
  },
  {
    route: "/landing/menupage/:id/page/:id",
    name: "",
    perm: "Web",
    showOnMenu: false,
    page: SinglePage
  },
  {
    route: "/landing/page/:id/section/:id",
    name: "",
    perm: "Web",
    showOnMenu: false,
    page: SectionPage
  },
  {
    route: "/landing/leads",
    name: "Leads",
    perm: "web",
    dataTable: LeadsDataTable,
    page: BasePage,
    showOnMenu: true
  }
];

export default mainScreensToShow;
