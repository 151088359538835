export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const parseQueryString = () => {
  const str = window.location.search;
  let objURL = {};

  str.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"), function(
    $0,
    $1,
    $2,
    $3
  ) {
    objURL[$1] = $3;
  });
  return objURL;
};

export const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== null && b[propName] !== null) {
      if (
        a[propName].length !== undefined &&
        b[propName].length !== undefined
      ) {
        if (a[propName].length !== null && b[propName].length !== null) {
          if (a[propName].length !== b[propName].length) {
            return false;
          }
        }
      }
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const getSelectorFromArray = (
  arrayData,
  keyName,
  textName,
  dontAddMySelf = true,
  keyMySelf = "ADD THIS",
  noDataSelector = false,
  noDataText = "SIN DATO"
) => {
  const newSelectorData = [];
  const selectorDataToSet = [...arrayData];
  if (noDataSelector === true) {
    const dataToAdd = {};
    dataToAdd.key = null;
    dataToAdd.value = null;
    dataToAdd.text = noDataText;
    dataToAdd.data = {};
    newSelectorData.push(dataToAdd);
  }
  for (let d = 0; d < selectorDataToSet.length; d++) {
    const newData = selectorDataToSet[d];
    const dataToAdd = {};
    let addData = true;
    if (dontAddMySelf === true) {
      if (keyMySelf === newData.id) {
        addData = false;
      }
    }
    if (addData === true) {
      dataToAdd.key = newData[keyName];
      dataToAdd.value = newData[keyName];
      systemLog("General", textName);
      if (typeof textName !== "string") {
        if (textName.fromSelector !== undefined) {
          dataToAdd.text = returnTextFromSelector(
            newData[textName.dataName],
            textName.fromSelector,
            true
          );
        } else {
          dataToAdd.text = "";
          for (let ct = 0; ct < textName.length; ct++) {
            const ctDataName = textName[ct];
            let finalDataAdd = ctDataName;
            systemLog("General", ctDataName);
            if (newData[ctDataName] !== undefined) {
              finalDataAdd = newData[ctDataName];
            }
            dataToAdd.text = dataToAdd.text + finalDataAdd + " ";
          }
        }
      } else {
        dataToAdd.text = newData[textName];
      }
      dataToAdd.data = newData;
      newSelectorData.push(dataToAdd);
    }
  }
  return newSelectorData;
};

export const normalizeString = data => {
  return data.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const returnTextFromSelector = (value, selector, exact) => {
  systemLog("General", value);
  systemLog("General", selector);
  let returnData = "";
  const item = selector.find(data => {
    if (exact === true) {
      return (
        normalizeString(data.value).toUpperCase() ===
        normalizeString(value).toUpperCase()
      );
    } else {
      return normalizeString(data.value)
        .toUpperCase()
        .includes(normalizeString(value).toUpperCase());
    }
  });
  if (item !== undefined) {
    returnData = item.text;
  }
  return returnData;
};

export const returnValueFromSelector = (value, selector, exact) => {
  systemLog("General", value);
  systemLog("General", selector);
  let returnData = "";
  const item = selector.find(data => {
    if (exact === true) {
      return (
        normalizeString(data.text).toUpperCase() ===
        normalizeString(value).toUpperCase()
      );
    } else {
      return normalizeString(data.text)
        .toUpperCase()
        .includes(normalizeString(value).toUpperCase());
    }
  });
  if (item !== undefined) {
    returnData = item.value;
  }
  return returnData;
};

export const checkValueBool = data => {
  let returnData = false;
  if (
    parseInt(data) === 1 ||
    normalizeString(data).toUpperCase() ===
      normalizeString("true").toUpperCase() ||
    normalizeString(data).toUpperCase() ===
      normalizeString("cierto").toUpperCase() ||
    normalizeString(data).toUpperCase() === normalizeString("si").toUpperCase()
  ) {
    returnData = true;
  }
  return returnData;
};

const doLog = true;
const currentNameToLog = ["OriginalFiles", "DeleteFile", "AddFile"];
export const systemLog = (script, logData) => {
  let scriptName = "";
  if (typeof script === "string") {
    scriptName = script;
  } else {
    scriptName = script.constructor.name;
  }
  let canLog = false;
  if (doLog === true) {
    if (currentNameToLog.length > 0) {
      for (let i = 0; i < currentNameToLog.length; i++) {
        let scriptData = currentNameToLog[i];
        if (scriptName === scriptData) {
          canLog = true;
          break;
        }
      }
    } else {
      canLog = true;
    }
  }

  if (canLog === true) {
    console.log(scriptName, logData);
  }
};

export const openPayErrorHandler = error => {
  let errorToShow = "";
  switch (error.error_code) {
    case 3001:
      {
        errorToShow = "La tarjeta fue rechazada";
      }
      break;
    case 3002:
      {
        errorToShow = "La tarjeta ha expirado.";
      }
      break;
    case 3003:
      {
        errorToShow = "La tarjeta no tiene fondos suficientes.";
      }
      break;
    case 3004:
      {
        errorToShow =
          "La tarjeta ha sido identificada como una tarjeta robada.";
      }
      break;
    case 3005:
      {
        errorToShow =
          "La tarjeta ha sido rechazada por el sistema antifraudes.";
      }
      break;
    case 3006:
      {
        errorToShow = "El pedido no puede exceder $30,000";
      }
      break;
    default: {
      errorToShow =
        "Hubo un error, con open pay, por favor revisa tu datos antes de realizar el pago";
    }
  }
  return errorToShow;
};

export const isEmptyObj = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const setFileNameDataCheck = (
  extension,
  dataCheck,
  mainTable,
  arrayIndex,
  extraData
) => {
  let fileName = "";
  if (dataCheck.dataImageNameSet !== undefined) {
    const currentDINS = dataCheck.dataImageNameSet;
    if (currentDINS.nameSet !== undefined && currentDINS.nameSet.length > 0) {
      const currentNameSet = currentDINS.nameSet;
      const currentSeparationText = currentDINS.separationText;
      let currentName = "";
      for (let ns = 0; ns < currentNameSet.length; ns++) {
        const currentNS = currentNameSet[ns];
        if (currentNS.from === "text") {
          currentName += setURLType(currentNS.value);
        } else if (currentNS.from === "object") {
          if (currentNS.insideObject !== undefined) {
            if (currentNS.insideObject.insideKey !== undefined) {
              currentName += setURLType(
                mainTable[currentNS.key][currentNS.insideObject.key][
                  currentNS.insideObject.insideKey
                ]
              );
            } else {
              currentName += setURLType(
                mainTable[currentNS.key][currentNS.insideObject.key]
              );
            }
          } else {
            currentName += setURLType(mainTable[currentNS.key]);
          }
        }
        if (
          currentSeparationText !== undefined &&
          ns < currentNameSet.length - 1
        ) {
          currentName += currentSeparationText;
        }
      }

      if (extraData !== undefined) {
        currentName += currentSeparationText + extraData.toString();
      }

      if (arrayIndex !== undefined) {
        currentName += currentSeparationText + arrayIndex.toString();
      }

      if (extension !== undefined) {
        currentName += "." + extension.toString();
      }

      fileName = currentName;
    }
  }
  return fileName;
};

export const setBaseFileNameDataCheck = (dataImageNameSet, name) => {
  let fileName = "";
  const currentDINS = dataImageNameSet;
  if (currentDINS.nameSet !== undefined && currentDINS.nameSet.length > 0) {
    const currentNameSet = currentDINS.nameSet;
    const currentSeparationText = currentDINS.separationText;
    let currentName = "";
    for (let ns = 0; ns < currentNameSet.length; ns++) {
      const currentNS = currentNameSet[ns];
      if (currentNS.from === "text") {
        currentName += setURLType(currentNS.value);
      }
      if (currentSeparationText !== undefined) {
        currentName += currentSeparationText;
      }
    }
    fileName = currentName;
  }
  fileName += name;
  return fileName;
};

export const checkObjectFiles = dataObject => {
  let imgDataName = [];
  for (let i = 0; i < dataObject.length; i++) {
    const currentDataObject = dataObject[i];
    if (
      currentDataObject.dataType === "image" ||
      currentDataObject.dataType === "file"
    ) {
      imgDataName.push(currentDataObject);
    }
  }

  return imgDataName;
};

export const setURLType = urlCheck => {
  let returnURL = urlCheck;
  returnURL = returnURL
    .toLowerCase()
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ñ/g, "n")
    .replace(/\s/g, "-")
    .replace(/[^a-zA-Z 0-9\-]/g, "");
  return returnURL;
};
