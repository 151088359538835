import React, { Component } from "react";
import { Header } from "semantic-ui-react";

class ModularText extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          ...this.props.containerStyle,
          paddingBottom: this.props.separation || 0
        }}
      >
        <Header
          onClick={() => {
            if (this.props.onClick !== undefined) {
              this.props.onClick();
            }
          }}
          as={this.props.as}
          style={this.props.style}
        >
          {this.props.children}
        </Header>
      </div>
    );
  }
}

export default ModularText;
