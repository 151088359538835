export const tableEditItemData = {
  dataTitle: [
    {
      dataName: 'email',
      dataLabel: 'Correo',
      dataType: 'string'
    }
  ],
  dataVisual: [
    {
      dataName: 'organizationId',
      dataLabel: 'Organización',
      selectorData: [],
      selectorDataFrom: {
        dataFrom: 'props',
        dataName: 'organizations',
        key: 'id',
        value: 'id',
        text: 'name'
      },
      dataType: 'selector'
    },
    {
      dataName: 'roleId',
      dataLabel: 'Rol',
      selectorData: [],
      selectorDataFrom: {
        dataFrom: 'props',
        dataName: 'roles',
        key: 'id',
        value: 'id',
        text: 'namePublic'
      },
      dataType: 'selector'
    },
    {
      dataName: 'firstName',
      dataLabel: 'Nombre',
      asData: 'fullname',
      dataType: 'string'
    },
    {
      dataName: 'lastName',
      dataLabel: 'Apellido',
      asData: 'fullname',
      dataType: 'string'
    },
    {
      dataName: 'email',
      dataLabel: 'Correo',
      asData: 'email',
      dataType: 'string'
    },
    {
      dataName: 'password',
      asData: 'password',
      dataSingle: 'Contraseña',
      dataLabel: 'Cambiar contraseña',
      dataType: 'string',
      placeholder: 'Contraseña',
      needConfirm: true,
      notRequired: true
    }
  ]
};

export const tableCreateItemData = {
  dataTitle: [
    {
      dataName: 'email',
      dataLabel: 'Correo',
      dataType: 'string'
    }
  ],
  dataVisual: [
    {
      dataName: 'organizationId',
      dataLabel: 'Organización',
      selectorData: [],
      selectorDataFrom: {
        dataFrom: 'props',
        dataName: 'organizations',
        key: 'id',
        value: 'id',
        text: 'name'
      },
      dataType: 'selector'
    },
    {
      dataName: 'roleId',
      dataLabel: 'Rol',
      selectorData: [],
      selectorDataFrom: {
        dataFrom: 'props',
        dataName: 'roles',
        key: 'id',
        value: 'id',
        text: 'namePublic'
      },
      dataType: 'selector'
    },
    {
      dataName: 'firstName',
      dataLabel: 'Nombre',
      asData: 'fullname',
      dataType: 'string'
    },
    {
      dataName: 'lastName',
      dataLabel: 'Apellido',
      asData: 'fullname',
      dataType: 'string'
    },
    {
      dataName: 'email',
      dataLabel: 'Correo',
      asData: 'email',
      dataType: 'string'
    },
    {
      dataName: 'password',
      asData: 'password',
      dataSingle: 'Contraseña',
      dataLabel: 'Contraseña',
      dataType: 'string',
      placeholder: 'Contraseña',
      needConfirm: true,
      notRequired: true
    }
  ]
};

export const tableViewItemData = {
  dataTitle: [
    {
      dataName: 'email',
      dataLabel: 'Correo',
      dataType: 'string'
    }
  ],
  dataVisual: [
    {
      dataName: 'firstName',
      dataLabel: 'Nombre',
      dataType: 'string'
    },
    {
      dataName: 'lastName',
      dataLabel: 'Apellido',
      dataType: 'string'
    },
    {
      dataName: 'email',
      dataLabel: 'Correo',
      asData: 'email',
      dataType: 'string'
    },
    {
      dataName: 'emailVerified',
      dataLabel: 'Correo Verificado',
      dataType: 'bool'
    },
    {
      dataName: 'organization',
      dataType: 'insideModule',
      dataLabel: 'Organización',
      dataModule: {
        dataName: 'name',
        dataLabel: 'Organizacion',
        dataType: 'string'
      }
    },
    {
      dataName: 'role',
      dataType: 'insideModule',
      dataLabel: 'Rol',
      dataModule: {
        dataName: 'namePublic',
        dataType: 'string'
      }
    }
  ]
};

export const tableData = [
  {
    dataName: 'firstName',
    dataLabel: 'Nombre',
    dataType: 'string'
  },
  {
    dataName: 'lastName',
    dataLabel: 'Apellido',
    dataType: 'string'
  },
  {
    dataName: 'email',
    dataLabel: 'Correo',
    dataType: 'string'
  },
  {
    dataName: 'organization',
    dataType: 'insideModule',
    dataLabel: 'Organizacion',
    dataModule: {
      dataName: 'name',
      dataType: 'string'
    }
  },
  {
    dataName: 'role',
    dataType: 'insideModule',
    dataLabel: 'Rol',
    dataModule: {
      dataName: 'namePublic',
      dataType: 'string'
    }
  }
];

export const filterTable = [
  {
    dataName: 'firstName',
    dataType: 'string'
  },
  {
    dataName: 'lastName',
    dataType: 'string'
  },
  {
    dataName: 'email',
    dataType: 'string'
  },
  {
    dataName: 'organization',
    dataType: 'insideModule',
    dataModule: {
      dataName: 'name',
      dataType: 'string'
    }
  },
  {
    dataName: 'role',
    dataType: 'insideModule',
    dataModule: {
      dataName: 'namePublic',
      dataType: 'string'
    }
  }
];
