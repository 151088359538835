import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setUsers, setPages, setUserPages } from "../redux/actions";
import * as UserPagesData from "../TablesData/userPagesDataTables";
import AdminTableComparation from "../layaouts/AdminTableComparation";

class PermissionsTable extends Component {
  constructor(props) {
    super(props);

    this.specialPermissionsUser = [
      {
        id: this.props.user.id,
        delete: false,
        read: true,
        update: true,
        canBeSelected: false
      }
    ];
    this.refreshUsers = this.refreshUsers.bind(this);
  }

  componentDidMount() {
    this.refreshUsers();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  refreshUsers() {
    this.checkLoading(true);
    this.props.API.GetUsers(
      this.props.user.isGod,
      this.props.user.isAdmin,
      this.props.user
    )
      .then(responseUsers => {
        if (responseUsers.error === undefined) {
          responseUsers.unshift(this.props.user);
          this.props.setUsers(responseUsers);
          this.props.API.GetUserPages(this.props.user.isGod, responseUsers)
            .then(responsesUserPages => {
              if (responsesUserPages.error === undefined) {
                this.checkLoading(false);
                this.props.setUserPages(responsesUserPages);
              } else {
                this.checkLoading(false);
              }
            })
            .catch(error => {
              this.checkLoading(false);
            });
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });

    this.props.API.GetPages(
      this.props.user.isGod,
      this.props.user.organizationId
    )
      .then(responsePages => {
        if (responsePages.error === undefined) {
          this.props.setPages(responsePages);
        }
      })
      .catch(error => {});
  }

  render() {
    return (
      <AdminTableComparation
        tableSysName={"UserPages"}
        tableName={"PERMISOS"}
        tablePermName={"Privilegios Web"}
        tableSubtitle={"Administrador de Permisos"}
        tableSelector={this.props.users}
        selectorRelation={"sysUserId"}
        tableSelectorDataName={"email"}
        tableSelectorPlaceholder={"Selecciona un Usuario"}
        tableComparator={this.props.pages}
        tableComparatorDataName={UserPagesData.comparatorDataName}
        comparatorRelation={"pageId"}
        tableRelation={this.props.userPages}
        tableData={UserPagesData.tableData}
        filterAllThatApply={false}
        filterTable={UserPagesData.filterTable}
        clickOnSave={() => {
          this.refreshUsers();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { userPages, users, user, roles, organizations, pages } = data;
  return { userPages, users, user, API, organizations, roles, pages };
};

const mapDispatchToProps = dispatch => {
  return {
    setUsers: users => {
      dispatch(setUsers(users));
    },
    setPages: pages => {
      dispatch(setPages(pages));
    },
    setUserPages: userPages => {
      dispatch(setUserPages(userPages));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PermissionsTable)
);
