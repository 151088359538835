import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import MetatagsTable from "../../BaseTables/MetatagsTable";
import AdminContent from "../../layaouts/AdminContent";
import PagePermCheck from "../../layaouts/PagePermCheck";

class SingleMenuPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingLanguageTable: true };
  }

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingLanguageTable}
          pageName={"Creador de Metatags"}
          pageSubtitle={"Administrador de Metatags"}
        >
          <MetatagsTable
            checkLoading={doit => {
              this.setState({ loadingLanguageTable: doit });
            }}
          />
        </AdminContent>
      </PagePermCheck>
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { users, user, roles, organizations } = data;
  return { users, user, API, organizations, roles };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(SingleMenuPage)
);
