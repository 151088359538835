import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Responsive } from "semantic-ui-react";
import ModularText from "../components/ModularText";
import { setLanguages } from "../redux/actions";
import {
  adminSeparation,
  colors,
  elevationCustom,
  styleCenteredObject,
  subHeaderAdminHeight,
  textSizePTitle,
  textSizeSubHeaderTitle
} from "../style";
import CenteredContent from "./CenteredContent";
import AdminViewTable from "./AdminViewTable";
import AdminViewItem from "./AdminViewItem";
import AdminEditItem from "./AdminEditItem";
import AdminCreateItem from "./AdminCreateItem";
import { MultipleLanguages } from "../MainInfoData";

class AdminTable extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    this.state = {
      permissionData: undefined,
      readPer: false,
      updatePer: false,
      deletePer: false,
      createPer: false,
      currentTable: {},
      currentView: "viewTable"
    };
    this.renderHeader = this.renderHeader.bind(this);
    this.renderTopButtons = this.renderTopButtons.bind(this);
    this.renderAddData = this.renderAddData.bind(this);
    this.renderAdminViewTable = this.renderAdminViewTable.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.returnToView = this.returnToView.bind(this);
    this.renderCustomButton = this.renderCustomButton.bind(this);
    this.finalRender = this.finalRender.bind(this);
  }

  componentDidMount() {
    this.scrollMain = document.getElementById("MAIN_SCREN_DIV");
  }

  componentWillMount() {
    if (MultipleLanguages === true) {
      this.props.API.GetLanguages(
        this.props.user.organizationId,
        this.props.user.isGod
      )
        .then(responseLanguages => {
          if (responseLanguages.error === undefined) {
            let finalLanguages = [];
            for (let i = 0; i < responseLanguages.length; i++) {
              const currentLanguage = responseLanguages[i];
              let tempLanguage = {
                key: currentLanguage.id,
                value: currentLanguage.id,
                data: "_" + currentLanguage.name,
                text: currentLanguage.publicName
              };
              finalLanguages.push(tempLanguage);
            }
            this.props.setLanguages(finalLanguages);
          }
        })
        .catch(error => {});
    }

    if (this.props.user.isGod === true) {
      this.setState({
        permissionData: {
          create: true,
          read: true,
          update: true,
          delete: true
        },
        readPer: true,
        updatePer: true,
        deletePer: true,
        createPer: true
      });
    } else {
      let perData = {};
      if (this.props.focePermissionData !== undefined) {
        perData = this.props.focePermissionData;
      } else {
        perData = this.props.userPermissions.find(permission => {
          if (permission.module === undefined) {
            return false;
          }
          return (
            permission.module.name.toUpperCase() ===
            this.props.tablePermName.toUpperCase()
          );
        });
      }
      if (perData !== undefined && perData !== null) {
        this.setState({
          permissionData: perData,
          readPer: perData.read,
          updatePer: perData.update,
          deletePer: perData.delete,
          createPer: perData.create
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.reset !== nextProps.reset) {
      this.returnToView();
    }

    if (nextProps.user.isGod === true) {
      this.setState({
        permissionData: {
          create: true,
          read: true,
          update: true,
          delete: true
        },
        readPer: true,
        updatePer: true,
        deletePer: true,
        createPer: true
      });
    } else if (this.props.focePermissionData !== nextProps.focePermissionData) {
      let perData = {};
      perData = nextProps.focePermissionData;
      if (perData !== undefined && perData !== null) {
        this.setState({
          permissionData: perData,
          readPer: perData.read,
          updatePer: perData.update,
          deletePer: perData.delete,
          createPer: perData.create
        });
      }
    }
  }

  renderCustomButton() {
    if (this.props.customButton !== undefined) {
      const CustomButton = this.props.customButton;
      return (
        <CustomButton
          changeState={(state, table) => {
            this.setState({ currentView: state, currentTable: table });
            if (
              this.scrollMain !== undefined &&
              this.scrollMain !== null &&
              this.props.dontScrollTo !== true
            ) {
              this.scrollMain.scrollTo(0, 0);
            }
          }}
          table={this.props.table}
          tableParentId={this.props.tableParentId}
        />
      );
    } else {
      return <div />;
    }
  }

  renderTopButtons() {
    if (this.state.currentView === "viewTable") {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-end",
            paddingRight: adminSeparation
          }}
        >
          {this.renderCustomButton()}
          {this.renderAddData()}
        </div>
      );
    } else {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-end",
            paddingRight: adminSeparation
          }}
        >
          <Button
            onClick={() => {
              this.setState({ currentView: "viewTable", currentTable: {} });
              if (
                this.scrollMain !== undefined &&
                this.scrollMain !== null &&
                this.props.dontScrollTo !== true
              ) {
                this.scrollMain.scrollTo(0, 0);
              }
            }}
            style={{
              backgroundColor: colors.buttonColor,
              color: colors.colorFrontButton
            }}
            content={"REGRESAR"}
            icon="angle left"
            labelPosition="left"
          />
        </div>
      );
    }
  }

  renderAddData() {
    if (this.state.createPer === true) {
      return (
        <Button
          onClick={() => {
            if (this.state.createPer === true) {
              this.setState({
                currentView: "createItem",
                currentTable: {}
              });
              if (
                this.scrollMain !== undefined &&
                this.scrollMain !== null &&
                this.props.dontScrollTo !== true
              ) {
                this.scrollMain.scrollTo(0, 0);
              }
            }
          }}
          style={{
            backgroundColor: this.state.createPer
              ? colors.addColor
              : colors.disableColor,
            color: colors.addFrontColor
          }}
          content={this.props.singleName || "CREAR"}
          icon="add"
          labelPosition="left"
        />
      );
    } else {
      return <div />;
    }
  }

  renderHeader() {
    return (
      <div
        style={{
          width: "100%",
          height: subHeaderAdminHeight,
          backgroundColor: colors.colorAdminSubHeader,
          paddingTop: adminSeparation,
          paddingBottom: adminSeparation,
          borderBottom: "2px solid " + colors.borderColor,
          ...styleCenteredObject,
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            textAlign: "left",
            flexDirection: "column",
            paddingLeft: adminSeparation
          }}
        >
          <ModularText
            containerStyle={{
              ...styleCenteredObject,
              alignSelf: "flex-start",
              textAlign: "left"
            }}
            style={{
              textAlign: "left",
              color: colors.textColorAdminSubHeader,
              fontSize: textSizeSubHeaderTitle
            }}
          >
            {this.props.tableName}
          </ModularText>
          <div style={{ height: "0.4rem" }} />
          <ModularText
            containerStyle={{
              ...styleCenteredObject,
              alignSelf: "flex-start",
              textAlign: "left"
            }}
            style={{
              textAlign: "left",
              fontWeight: "normal",
              color: colors.textColorAdminSubHeader,
              fontSize: textSizePTitle
            }}
          >
            {this.props.tableSubtitle}
          </ModularText>
        </div>
        {this.renderTopButtons()}
      </div>
    );
  }

  returnToView() {
    this.setState({ currentView: "viewTable", currentTable: {} });
    if (this.props.refreshTable !== undefined) {
      this.props.refreshTable();
    }
    if (
      this.scrollMain !== undefined &&
      this.scrollMain !== null &&
      this.props.dontScrollTo !== true
    ) {
      this.scrollMain.scrollTo(0, 0);
    }
  }

  renderAdminViewTable() {
    return (
      <AdminViewTable
        bdConnected={this.props.bdConnected}
        bdDoRefresh={this.props.bdDoRefresh}
        bdConnectInclude={this.props.bdConnectInclude}
        bdWhere={this.props.bdWhere}
        bdConnectedReturn={this.props.bdConnectedReturn}
        bdLimitItems={this.props.bdLimitItems}
        doPagination={this.props.doPagination}
        returnFilter={this.props.returnFilter}
        currentPage={this.props.currentPage}
        customFilterRender={this.props.customFilterRender}
        customFilterExtraInfo={this.props.customFilterExtraInfo}
        customSelectorAction={this.props.customSelectorAction}
        returnClickElement={this.props.returnClickElement}
        dontShowAction={this.props.dontShowAction}
        customNoTable={this.props.customNoTable}
        forceNotSelect={this.props.forceNotSelect}
        onClickViewItem={item => {
          this.setState({ currentView: "viewItem", currentTable: item });
          if (
            this.scrollMain !== undefined &&
            this.scrollMain !== null &&
            this.props.dontScrollTo !== true
          ) {
            this.scrollMain.scrollTo(0, 0);
          }
        }}
        onClickEditItem={item => {
          this.setState({ currentView: "editItem", currentTable: item });
          if (
            this.scrollMain !== undefined &&
            this.scrollMain !== null &&
            this.props.dontScrollTo !== true
          ) {
            this.scrollMain.scrollTo(0, 0);
          }
        }}
        clickOnChange={() => {
          this.returnToView();
        }}
        afterEditObj={this.props.afterEditObj}
        returnEditViewObj={this.props.returnEditViewObj}
        deleteElement={this.props.deleteElement}
        tableParentIdConnect={this.props.tableParentIdConnect}
        tableParentId={this.props.tableParentId}
        tableSysName={this.props.tableSysName}
        permissionData={this.state.permissionData}
        table={this.props.table}
        specialPermissions={this.props.specialPermissions}
        tableData={this.props.tableData}
        selectorDataShow={this.props.selectorDataShow}
        filterTable={this.props.filterTable}
        filterAllThatApply={this.props.filterAllThatApply}
        returnDeleteAllObj={this.props.returnDeleteAllObj}
        returnDeleteObj={this.props.returnDeleteObj}
        dontShowSelector={this.props.dontShowSelector}
        dontRenderBar={this.props.dontRenderBar}
        dontRenderFilter={this.props.dontRenderFilter}
      />
    );
  }

  renderSwitch() {
    switch (this.state.currentView) {
      case "viewTable": {
        return this.renderAdminViewTable();
      }
      case "viewItem": {
        if (this.props.customViewItem !== undefined) {
          const CustomViewItem = this.props.customViewItem;
          return (
            <CustomViewItem
              table={this.state.currentTable}
              tableData={this.props.tableViewItemData}
              permissionData={this.state.permissionData}
              tableParentIdConnect={this.props.tableParentIdConnect}
              tableParentId={this.props.tableParentId}
              tableSysName={this.props.tableSysName}
              tableCreateRoute={this.props.tableCreateRoute}
              clickOnSave={() => {
                this.returnToView();
              }}
            />
          );
        }
        return (
          <AdminViewItem
            table={this.state.currentTable}
            tableData={this.props.tableViewItemData}
            permissionData={this.state.permissionData}
          />
        );
      }
      case "editItem": {
        if (this.props.customEditor !== undefined) {
          const CustomEditor = this.props.customEditor;
          return (
            <CustomEditor
              table={this.state.currentTable}
              customRenderData={this.props.customEditRenderData}
              checkBeforeSave={this.props.checkBeforeSave}
              showLanguage={this.props.showLanguage}
              tableEditRoute={this.props.tableEditRoute}
              tableData={this.props.tableEditItemData}
              tableParentIdConnect={this.props.tableParentIdConnect}
              tableParentId={this.props.tableParentId}
              tableSysName={this.props.tableSysName}
              deleteElement={this.props.deleteElement}
              clickOnSave={() => {
                this.returnToView();
              }}
              afterEditObj={this.props.afterEditObj}
              returnEditObj={this.props.returnEditObj}
            />
          );
        }
        return (
          <AdminEditItem
            table={this.state.currentTable}
            customRenderData={this.props.customEditRenderData}
            checkBeforeSave={this.props.checkBeforeSave}
            showLanguage={this.props.showLanguage}
            tableEditRoute={this.props.tableEditRoute}
            tableData={this.props.tableEditItemData}
            tableParentIdConnect={this.props.tableParentIdConnect}
            tableParentId={this.props.tableParentId}
            tableSysName={this.props.tableSysName}
            deleteElement={this.props.deleteElement}
            clickOnSave={() => {
              this.returnToView();
            }}
            afterEditObj={this.props.afterEditObj}
            returnEditObj={this.props.returnEditObj}
          />
        );
      }
      case "createItem": {
        if (this.props.customCreator !== undefined) {
          const CustomCreator = this.props.customCreator;
          return (
            <CustomCreator
              tableData={this.props.tableCreateItemData}
              customRenderData={this.props.customCreateRenderData}
              createDefaultData={this.props.createDefaultData}
              checkBeforeSave={this.props.checkBeforeSave}
              showLanguage={this.props.showLanguage}
              tableCreateRoute={this.props.tableCreateRoute}
              tableParentIdConnect={this.props.tableParentIdConnect}
              tableParentId={this.props.tableParentId}
              tableSysName={this.props.tableSysName}
              singleName={this.props.singleName}
              deleteElement={this.props.deleteElement}
              customAfterCreate={(finalObjt, fullObj) => {
                if (this.props.customAfterCreate !== undefined) {
                  this.setState({ currentView: "viewTable", currentTable: {} });
                  this.props.customAfterCreate(finalObjt, fullObj);
                  if (
                    this.scrollMain !== undefined &&
                    this.scrollMain !== null &&
                    this.props.dontScrollTo !== true
                  ) {
                    this.scrollMain.scrollTo(0, 0);
                  }
                } else {
                  this.returnToView();
                }
              }}
              clickOnSave={() => {
                this.returnToView();
              }}
              returnCreateObj={this.props.returnCreateObj}
            />
          );
        }
        return (
          <AdminCreateItem
            tableData={this.props.tableCreateItemData}
            customRenderData={this.props.customCreateRenderData}
            createDefaultData={this.props.createDefaultData}
            checkBeforeSave={this.props.checkBeforeSave}
            showLanguage={this.props.showLanguage}
            tableCreateRoute={this.props.tableCreateRoute}
            tableParentIdConnect={this.props.tableParentIdConnect}
            tableParentId={this.props.tableParentId}
            tableSysName={this.props.tableSysName}
            singleName={this.props.singleName}
            deleteElement={this.props.deleteElement}
            customAfterCreate={(finalObjt, fullObj) => {
              if (this.props.customAfterCreate !== undefined) {
                this.setState({ currentView: "viewTable", currentTable: {} });
                this.props.customAfterCreate(finalObjt, fullObj);
                if (
                  this.scrollMain !== undefined &&
                  this.scrollMain !== null &&
                  this.props.dontScrollTo !== true
                ) {
                  this.scrollMain.scrollTo(0, 0);
                }
              } else {
                this.returnToView();
              }
            }}
            clickOnSave={() => {
              this.returnToView();
            }}
            returnCreateObj={this.props.returnCreateObj}
          />
        );
      }
      case "customView": {
        if (this.props.customView !== undefined) {
          const CustomView = this.props.customView;
          return (
            <CustomView
              table={this.state.currentTable}
              tableParentIdConnect={this.props.tableParentIdConnect}
              tableParentId={this.props.tableParentId}
              tableSysName={this.props.tableSysName}
              tableCreateRoute={this.props.tableCreateRoute}
              clickOnSave={() => {
                this.returnToView();
              }}
            />
          );
        }
        return <div />;
      }
      default: {
        return <div />;
      }
    }
  }

  finalRender() {
    return (
      <CenteredContent>
        <div
          style={{
            backgroundColor: colors.adminTableBackgroundColor,
            ...elevationCustom("10px", "20px", "rgba(0,0,0,0.04)"),
            width: "100%",
            marginBottom: "2rem"
          }}
        >
          {this.renderHeader()}
          {this.renderSwitch()}
        </div>
      </CenteredContent>
    );
  }

  render() {
    if (this.state.readPer === false) {
      return <div />;
    }
    switch (this.state.currentView) {
      case "viewItem":
      case "editItem":
      case "createItem": {
        return (
          <div>
            <Responsive
              style={{ width: window.innerWidth }}
              {...Responsive.onlyMobile}
            >
              {this.finalRender()}
            </Responsive>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              {this.finalRender()}
            </Responsive>
          </div>
        );
      }
      default: {
        return this.finalRender();
      }
    }
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, user } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, user, API };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguages: languages => {
      dispatch(setLanguages(languages));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminTable)
);
