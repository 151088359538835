import React, { Component } from "react";
import PagePermCheck from "../../layaouts/PagePermCheck";
import BlogCategoriesTable from "../../BaseTables/Blog/BlogCategoriesTable";
import AdminContent from "../../layaouts/AdminContent";

class BlogCategoriesPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
  }

  componentDidMount() {}

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingUserTable}
          pageName={"Categorias de Blog"}
          pageSubtitle={"Administrador de Categorias de Blog"}
        >
          <BlogCategoriesTable
            checkLoading={doit => {
              this.setState({ loadingUserTable: doit });
            }}
          />
        </AdminContent>
      </PagePermCheck>
    );
  }
}

export default BlogCategoriesPage;
