import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setBlogTags, setOrganizations } from "../../redux/actions";
import * as BlogTagsData from "../../TablesData/Blog/BlogTagDataTable";
import AdminTable from "../../layaouts/AdminTable";

class BlogTagsTable extends Component {
  constructor(props) {
    super(props);
    this.refreshStates = this.refreshStates.bind(this);
    this.checkData = this.checkData.bind(this);
    this.tableParentIdConnect = undefined;
    this.tableParentId = undefined;
    this.whereBDData = [{ organizationId: this.props.user.organizationId }];
    if (this.props.user.isGod) {
      this.whereBDData = [];
    }
    this.state = {
      refreshDB: true
    };
  }

  componentDidMount() {
    this.refreshStates();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  checkData() {
    this.tableParentIdConnect = "organizationId";
    this.tableParentId = this.props.user.organizationId;
    if (this.props.user.isAdmin === true || this.props.user.isGod === true) {
      this.tableParentIdConnect = undefined;
      this.tableParentId = undefined;
    }
  }

  refreshStates() {
    this.checkLoading(true);
    this.props.API.GetOrganizations(
      this.props.user.isGod,
      this.props.user.isAdmin,
      this.props.user.organizationId
    )
      .then(responseOrganizations => {
        if (responseOrganizations.error === undefined) {
          this.props.setOrganizations(responseOrganizations);
          this.setState({ refreshDB: !this.state.refreshDB });
          this.checkData();
          this.checkLoading(false);
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.blogTags}
        bdConnectedReturn={response => {
          this.props.setBlogTags(response);
        }}
        showLanguage={true}
        bdConnected={true}
        bdWhere={this.whereBDData}
        bdConnectInclude={["organization"]}
        bdLimitItems={30}
        bdDoRefresh={this.state.refreshDB}
        doPagination={true}
        tableParentIdConnect={this.tableParentIdConnect}
        tableParentId={this.tableParentId}
        tableViewItemData={BlogTagsData.tableViewItemData}
        tableEditItemData={BlogTagsData.tableEditItemData}
        tableCreateItemData={BlogTagsData.tableCreateItemData}
        tableData={BlogTagsData.tableData}
        tableSysName={"BlogTags"}
        tablePermName={"Blog"}
        tableName={"Tags de Blog"}
        singleName={"Tag de Blog"}
        tableSubtitle={"Administrador de Tags de Blog"}
        filterTable={BlogTagsData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshStates();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { blogTags, user, organizations, userPermissions } = data;
  return { API, blogTags, user, organizations, userPermissions };
};

const mapDispatchToProps = dispatch => {
  return {
    setBlogTags: blogTags => {
      dispatch(setBlogTags(blogTags));
    },
    setOrganizations: organizations => {
      dispatch(setOrganizations(organizations));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BlogTagsTable)
);
