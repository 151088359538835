import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  columnEditStyle,
  renderLabel,
  renderModifyData
} from "../scripts/System";
import ModalComponent from "../layaouts/ModalComponent";
import { adminSysName } from "../MainInfoData";
import { colors, styleCenteredObject } from "../style";

const newPass = {
  dataName: "password",
  asData: "password",
  dataSingle: "Contraseña",
  dataLabel: "Cambiar contraseña",
  dataType: "string",
  needConfirm: true
};

class ModalResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.currentPassChange = {};
  }

  componentDidMount() {
    if (this.props.user.firstTimeEnter === true) {
      this.setState({ showModal: true });
    }
  }

  render() {
    return (
      <ModalComponent
        width={"70%"}
        onOpen={() => {}}
        closeModal={() => {
          this.setState({ showModal: false });
        }}
        style={{ minHeight: "10rem" }}
        showModal={this.state.showModal}
      >
        <Modal.Header>
          <h1>{"Bienvenido a " + adminSysName}</h1>
          <p>{"Configura una nueva contraseña para poder entrar al sistema"}</p>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ ...columnEditStyle }}>
              {renderLabel(newPass.dataLabel)}
              {renderModifyData(
                newPass,
                this.currentPassChange,
                this.props.formatter,
                () => {
                  this.forceUpdate();
                }
              )}
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Content>
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              flexDirection: "row"
            }}
          >
            <Button
              onClick={() => {
                this.setState({ showModal: false });
              }}
              style={{
                backgroundColor: colors.removeColor,
                color: colors.removeFrontColor
              }}
              content={"CANCELAR"}
            />
            <div style={{ width: "1rem" }} />
            <Button
              onClick={() => {
                if (
                  this.currentPassChange.password !==
                  this.currentPassChange.confirmpassword
                ) {
                  toast.error("La contraseña no son iguales");
                } else {
                  this.props.API.PatchTo("SysUsers", this.props.user.id, {
                    password: this.currentPassChange.password,
                    firstTimeEnter: false
                  })
                    .then(response => {
                      if (response.error === undefined) {
                        toast.success(
                          "¡La nueva contraseña se cambio correctamente!"
                        );
                        this.setState({ showModal: false });
                      } else {
                        toast.error("Error al salvar la nueva contraseña");
                      }
                    })
                    .catch(error => {
                      toast.error("Error al salvar la nueva contraseña");
                    });
                }
              }}
              style={{
                backgroundColor: colors.buttonColor,
                color: colors.colorFrontButton
              }}
              content={"ACEPTAR"}
            />
          </div>
        </Modal.Content>
      </ModalComponent>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { user } = data;
  const { formatter, API } = api;
  return {
    formatter,
    API,
    user
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ModalResetPassword)
);
