import React, { Component } from "react";
import { smallLogo, logo } from "../assets/index";
import { colors, styleBasic, styleCenteredObject } from "../style/index";
import ModularText from "./ModularText";
import LetterGoogleStyle from "./LetterGoogleStyle";

const logoSize = "2.5rem";

class LogoRender extends Component {
  constructor(props) {
    super(props);
    this.renderSpace = this.renderSpace.bind(this);
    this.renderLogo = this.renderLogo.bind(this);
    this.renderName = this.renderName.bind(this);
  }

  renderName() {
    if (this.props.renderName !== false) {
      let finalName = "Organizacion";
      if (
        this.props.nameToRender !== undefined &&
        this.props.nameToRender !== ""
      ) {
        finalName = this.props.nameToRender;
      }
      return (
        <div
          style={{
            ...styleBasic,
            ...styleCenteredObject,
            justifyContent: this.props.aling || "flex-start",
            height: "100%"
          }}
        >
          <p style={{ fontSize: "1.5rem", color: colors.logoColorLetter }}>
            {finalName}
          </p>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderSpace() {
    if (this.props.renderName !== false) {
      return (
        <div
          style={{
            width: this.props.separationFromIcon,
            height: "100%"
          }}
        />
      );
    } else {
      return <div />;
    }
  }

  renderLogo() {
    let finalLogo = smallLogo;
    let logoSizeW = this.props.logoSizeW || this.props.logoSize || logoSize;
    let logoSizeH = this.props.logoSizeH || this.props.logoSize || logoSize;
    if (this.props.useNormalLogo === true) {
      finalLogo = logo;
    }
    if (finalLogo !== "") {
      return (
        <div
          style={{
            ...styleBasic,
            ...styleCenteredObject,
            marginLeft: this.props.marginLeftIcon || 0,
            height: logoSizeH,
            width: logoSizeW
          }}
        >
          <div
            style={{
              ...styleBasic,
              ...styleCenteredObject,
              height: logoSizeH,
              width: logoSizeW,
              backgroundImage: "url(" + finalLogo + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center"
            }}
          />
        </div>
      );
    } else {
      let finalLetter = "G";
      if (
        this.props.nameToRender !== undefined &&
        this.props.nameToRender !== ""
      ) {
        finalLetter = this.props.nameToRender[0];
      }
      return (
        <div
          style={{
            ...styleBasic,
            ...styleCenteredObject,
            height: logoSizeH,
            width: logoSizeW
          }}
        >
          <div
            style={{
              ...styleBasic,
              ...styleCenteredObject,
              marginLeft: this.props.marginLeftIcon || 0,
              height: logoSizeH,
              width: logoSizeW
            }}
          >
            <LetterGoogleStyle letter={finalLetter} />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: this.props.aling || "flex-start",
          alignItems: this.props.aling || "flex-start",
          alignSelf: this.props.aling || "flex-start"
        }}
      >
        {this.renderLogo()}
        {this.renderSpace()}
        {this.renderName()}
      </div>
    );
  }
}

export default LogoRender;
