import React, { Component } from "react";
import PagePermCheck from "../../layaouts/PagePermCheck";
import ModulesTable from "../../BaseTables/ModulesTable";
import AdminContent from "../../layaouts/AdminContent";

class ModulesPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
  }

  componentDidMount() {}

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingUserTable}
          pageName={"Modulos"}
          pageSubtitle={"Administrador Modulos"}
        >
          <ModulesTable
            checkLoading={doit => {
              this.setState({ loadingUserTable: doit });
            }}
          />
        </AdminContent>
      </PagePermCheck>
    );
  }
}

export default ModulesPage;
