import AdminPage from "../pages/System/AdminPage";
import AdminPerm from "../pages/System/AdminPerm";
import AdminRoles from "../pages/System/AdminRoles";
import HomePage from "../pages/System/HomePage";
import ModulesPage from "../pages/System/ModulesPage";
import OrganizationPage from "../pages/System/OrganizationPage";

const insideAdminPages = [
  {
    route: "/system/administrator",
    name: "Usuarios",
    perm: "Usuarios",
    page: AdminPage
  },
  {
    route: "/system/adminroles",
    name: "Roles",
    perm: "Roles",
    page: AdminRoles
  }
];

const mainScreensToShow = [
  {
    route: "/system",
    name: "Dashboard",
    perm: "DASHBOARD",
    page: HomePage
  },
  {
    route: "/system/administrator",
    name: "Administrador",
    perm: "Usuarios",
    page: AdminPage,
    pages: insideAdminPages
  },
  {
    route: "/system/organizations",
    name: "Organizaciones",
    perm: "Organizaciones",
    page: OrganizationPage
  },
  {
    route: "/system/modules",
    name: "Módulos",
    perm: "Módulos",
    page: ModulesPage
  }
];

export default mainScreensToShow;
