import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import ModularText from "../components/ModularText";
import {
  adminPadding,
  styleBasic,
  colors,
  headerAdminHeight,
  headerSeparation,
  styleCenteredObject,
  textSizeHeaderSubTitle,
  textSizeHeaderTitle
} from "../style";

class AdminContent extends Component {
  constructor(props) {
    super(props);
    this.renderTop = this.renderTop.bind(this);
  }

  renderTop() {
    if (this.props.showHeader === false) {
      return <div />;
    } else {
      return (
        <div style={{ width: "100%" }}>
          <div
            style={{
              zIndex: 100,
              width: "100%",
              marginLeft: "0.2rem",
              height: headerAdminHeight,
              backgroundColor: "transparent",
              ...styleCenteredObject,
              justifyContent: "flex-start"
            }}
          >
            <div
              style={{
                ...styleCenteredObject,
                justifyContent: "flex-start",
                textAlign: "left",
                flexDirection: "column"
              }}
            >
              <ModularText
                containerStyle={{
                  ...styleCenteredObject,
                  alignSelf: "flex-start",
                  textAlign: "left"
                }}
                style={{
                  fontSize: textSizeHeaderTitle,
                  color: colors.textColorAdminHeader,
                  textAlign: "left"
                }}
              >
                {(this.props.pageName || "Administrador").toUpperCase()}
              </ModularText>
              <div style={{ height: "0.4rem" }} />
              <ModularText
                containerStyle={{
                  ...styleCenteredObject,
                  alignSelf: "flex-start",
                  textAlign: "left"
                }}
                style={{
                  fontSize: textSizeHeaderSubTitle,
                  color: colors.textColorAdminHeader,
                  fontWeight: "normal",
                  textAlign: "left"
                }}
              >
                {this.props.pageSubtitle || "Administra algo"}
              </ModularText>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              borderRadius: 60,
              height: "1px",
              backgroundColor: colors.borderTitleColor
            }}
          />
          <div
            style={{
              height: headerSeparation
            }}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <Dimmer.Dimmable
        as={"div"}
        dimmed={this.props.onLoading}
        style={{
          ...styleBasic,
          width: "100%",
          backgroundColor: colors.adminPageBackgroundColor,
          padding: adminPadding
        }}
      >
        <Dimmer active={this.props.onLoading}>
          <Loader active={this.props.onLoading} />
        </Dimmer>
        {this.renderTop()}
        {this.props.children}
      </Dimmer.Dimmable>
    );
  }
}

export default AdminContent;
