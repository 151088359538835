import { createMuiTheme } from "@material-ui/core";
import ColorEditor from "color";
import saveAs from "file-saver";
import JSZip from "jszip";
import _ from "lodash";
import { DatePicker, TimePicker } from "material-ui-pickers";
import "moment/locale/es";
import moment from "moment/min/moment-with-locales.js";
import React from "react";
import { SketchPicker } from "react-color";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import Dropzone from "react-dropzone";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import reactCSS from "reactcss";
import {
  Button,
  Dropdown,
  Icon,
  Input,
  Label,
  Reveal,
  Segment
} from "semantic-ui-react";
import ModularText from "../components/ModularText";
import { SET_PRICE_FORMARTEER } from "../redux/types";
import {
  adminSeparation,
  colors,
  styleBasic,
  styleCenteredObject,
  styleForm,
  textSizeHeaderSubTitle,
  textSizePTable,
  textSizeSubHeaderTitle
} from "../style";
import "../style/PickerDayStyle.css";
import QuillTextView from "./QuillTextView";
import {
  systemLog,
  normalizeString,
  checkValueBool,
  returnValueFromSelector,
  isEmptyObj,
  setURLType
} from "./General";
import { mainIp } from "../MainInfoData";

const getURL = url => {
  return mainIp + url;
};

const renderDatadataInsideSize = "60%";
const columnEditSize = "3rem";
export const columnEditStyle = {
  ...styleCenteredObject,
  width: "100%",
  margin: 0,
  padding: 0,
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: columnEditSize,
  marginTop: "10px",
  marginLeft: "0.5rem"
};

const columnViewSize = "3rem";
export const columnViewStyle = {
  ...styleCenteredObject,
  margin: 0,
  padding: 0,
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: columnViewSize,
  marginTop: "10px",
  marginLeft: "0.5rem",
  borderBottom: "2px dotted " + colors.adminTableItemBorderColor
};

let datePicker = {};
let yearPicker = {};
let timePicker = {};
let colorPicker = {};

const calendarPickerStyle = {
  TodayButton: {
    color: colors.mainColor
  },
  selected: {
    color: colors.colorFrontMain,
    backgroundColor: colors.mainColor
  }
};
const primary = {
  500: colors.mainColor
};

export const datePickerTheme = createMuiTheme({
  palette: {
    primary
  },
  typography: {
    useNextVariants: true,
    subtitle1: {
      color: "#667A89"
    }
  }
});

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadFiles = (files, zipName) => {
  var zip = new JSZip();
  for (let i = 0; i < files.length; i++) {
    const currentFile = files[i];
    const splitFile = currentFile.data.split("base64,");
    const blob = b64toBlob(splitFile[1]);
    zip.file(currentFile.name, blob);
  }
  zip.generateAsync({ type: "blob" }).then(content => {
    // Force down of the Zip file
    saveAs(content, zipName);
  });
};

export const downloadFolders = (folders, zipName) => {
  var zip = new JSZip();
  let mainFolder = zip;
  if (folders.length > 1) {
    mainFolder = zip.folder(zipName);
  }
  for (let f = 0; f < folders.length; f++) {
    const folder = folders[f];
    const files = folder.files;
    const currentFolder = mainFolder.folder(folder.name);
    for (let i = 0; i < files.length; i++) {
      const currentFile = files[i];
      const splitFile = currentFile.data.split("base64,");
      const blob = b64toBlob(splitFile[1]);
      currentFolder.file(currentFile.name, blob);
    }
  }
  zip.generateAsync({ type: "blob" }).then(content => {
    // Force down of the Zip file
    saveAs(content, zipName);
  });
};

export const ImagesRes = [
  {
    dataName: "desktop",
    dataTitle: "Escritorio"
  },
  {
    dataName: "tablet",
    dataTitle: "Tableta"
  },
  {
    dataName: "mobile",
    dataTitle: "Movil"
  }
];

export const renderTopTitle = title => {
  return (
    <div>
      <div style={{ height: "1rem" }} />
      <div
        style={{
          width: "100%",
          height: "3rem",
          backgroundColor: colors.colorAdminSubHeader,
          ...styleCenteredObject,
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            marginLeft: adminSeparation,
            paddingLeft: "0.7rem",
            height: "100%",
            borderBottom: "1px solid " + colors.borderColor
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontSize: "1.5rem",
              textWeight: "bold"
            }}
          >
            {title}
          </div>
        </div>
      </div>
      <div style={{ height: "1rem" }} />
    </div>
  );
};

export const renderTitleName = (name, height, fontSize) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: height || "3rem",
          backgroundColor: colors.colorAdminSubHeader,
          ...styleCenteredObject,
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            marginLeft: adminSeparation,
            paddingLeft: "0.7rem",
            height: "100%",
            borderBottom: "1px solid " + colors.borderColor
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontSize: fontSize || "1.5rem",
              textWeight: "bold"
            }}
          >
            {name}
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderTopName = (name, extraInfo) => {
  let currentExtraInfo = () => {
    return <div />;
  };

  if (extraInfo !== undefined && extraInfo !== null) {
    currentExtraInfo = extraInfo;
  }
  return (
    <div>
      <div style={{ height: "1rem" }} />
      <div
        style={{
          width: "100%",
          height: "3rem",
          backgroundColor: colors.colorAdminSubHeader,
          ...styleCenteredObject,
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            marginLeft: adminSeparation,
            paddingLeft: "0.7rem",
            height: "100%",
            borderBottom: "1px solid " + colors.borderColor
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontSize: "1.5rem",
              textWeight: "bold"
            }}
          >
            {name}
          </div>
        </div>
        {currentExtraInfo()}
      </div>
      <div style={{ height: "1rem" }} />
    </div>
  );
};

export const renderLabel = (dataLabel, notRequired, addDots, addHeight) => {
  if (dataLabel !== undefined && dataLabel !== "") {
    let finalLabel = dataLabel;
    if (notRequired !== true) {
      finalLabel = dataLabel + "*";
    }
    return (
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
          textAlign: "left"
        }}
      >
        <ModularText
          style={{
            fontSize: textSizeHeaderSubTitle,
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            textAlign: "left"
          }}
        >
          {finalLabel + (addDots === false ? "" : ": ")}
        </ModularText>
        {addHeight !== false && <div style={{ height: "0.5rem" }} />}
      </div>
    );
  } else {
    return <div />;
  }
};

export const renderButtons = (table, data, file, patchTable, dataImageRes) => {
  if (file !== undefined && _.isEmpty(file) === false) {
    return (
      <div
        style={{
          width: "100%",
          ...styleCenteredObject,
          justifyContent: "flex-end"
        }}
      >
        <Button
          disabled={file.isNewFile}
          icon
          labelPosition="right"
          style={{
            backgroundColor: colors.viewColor,
            color: colors.viewFrontColor
          }}
          href={getURL(file.preview)}
          target="_blank"
        >
          Ver
          <Icon name="eye" />
        </Button>
        <div style={{ width: "0.5rem" }} />
        <Button
          icon
          labelPosition="right"
          style={{
            backgroundColor: colors.removeColor,
            color: colors.removeFrontColor
          }}
          onClick={() => {
            if (data.dataName === undefined) {
              if (dataImageRes === undefined) {
                table = {};
              } else {
                table[dataImageRes] = {};
              }
              patchTable(table);
            } else {
              if (dataImageRes === undefined) {
                table[data.dataName] = {};
              } else {
                table[data.dataName][dataImageRes] = {};
              }
              patchTable(table[data.dataName]);
            }
          }}
        >
          Quitar
          <Icon name="cancel" />
        </Button>
      </div>
    );
  } else {
    return <div />;
  }
};

export const renderViewArchive = (table, data, file, patchTable) => {
  if (file !== undefined) {
    return (
      <Label
        as={"a"}
        disabled={file.isNewFile}
        href={getURL(file.preview)}
        target="_blank"
        corner="left"
        color="blue"
        style={{
          zIndex: 100
        }}
        icon={"eye"}
      />
    );
  } else {
    return <div />;
  }
};

export const renderRemoveArchive = (table, data, file, patchTable) => {
  if (file !== undefined) {
    return (
      <Label
        as={"a"}
        onClick={() => {
          table[data.dataName] = {};
          patchTable(table[data.dataName]);
        }}
        corner="right"
        color="red"
        style={{
          zIndex: 100
        }}
        icon={"cancel"}
      />
    );
  } else {
    return <div />;
  }
};

const renderUploadedData = (data, file, isImage, imageDataName) => {
  if (file !== undefined && _.isEmpty(file) === false) {
    if (isImage) {
      systemLog("ImageCheck", file);
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            ...styleCenteredObject
          }}
        >
          <div
            style={{
              ...styleCenteredObject,
              backgroundImage:
                "url(" +
                (file.isNewFile === true ? file.b64 : getURL(file.preview)) +
                ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              width: "100%",
              height: "100%"
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            ...styleCenteredObject,
            flexDirection: "column",
            width: "100%",
            height: "100%",
            backgroundColor: colors.addColor
          }}
        >
          <Icon
            name={"file"}
            style={{ color: colors.addFrontColor, fontSize: "3rem" }}
          />
          <ModularText
            style={{
              color: colors.addFrontColor,
              fontSize: textSizeSubHeaderTitle
            }}
          >
            {file.name || "Existe un Archivo"}
          </ModularText>
        </div>
      );
    }
  } else {
    let finalText = data.placeholder || "Selecciona o Arrastra";
    finalText += data.placeholderDataType || " Archivo";
    return (
      <div
        style={{
          ...styleCenteredObject,
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: colors.uploadColor
        }}
      >
        <Icon
          name={"upload"}
          style={{ color: colors.uploadFrontColor, fontSize: "3rem" }}
        />
        <ModularText
          style={{
            color: colors.uploadFrontColor,
            fontSize: textSizeSubHeaderTitle
          }}
        >
          {finalText}
        </ModularText>
      </div>
    );
  }
};
export const renderModifyData = (
  data,
  table,
  formatter,
  patchTable,
  onClickElement
) => {
  if (data.canBeEdited === false) {
    return renderData(
      data,
      table,
      false,
      formatter,
      patchTable,
      true,
      onClickElement
    );
  } else if (data.needConfirm === true) {
    const tempData = { ...data };
    tempData.needConfirm = false;
    const tempDataConfirm = { ...data };
    tempDataConfirm.needConfirm = false;
    tempDataConfirm.dataName = "confirm" + tempDataConfirm.dataName;
    tempDataConfirm.autoComplete = data.autoCompleteConfirm;
    return (
      <div
        style={{
          ...styleCenteredObject,
          alignItems: "flex-start",
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          flexDirection: "column"
        }}
      >
        {renderModifyData(
          tempData,
          table,
          formatter,
          patchTable,
          onClickElement
        )}
        <div style={{ height: "0.5rem" }} />
        {renderModifyData(
          tempDataConfirm,
          table,
          formatter,
          patchTable,
          onClickElement
        )}
      </div>
    );
  } else if (data.dataType === "insideModule") {
    let finalModule = {};
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalModule = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalModule = table[data.dataName];
      }
    }
    return renderModifyData(
      data.dataModule,
      finalModule,
      formatter,
      patchTable,
      onClickElement
    );
  } else if (data.dataType === "file" || data.dataType === "image") {
    let finalData = undefined;

    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalData = table;
      } else {
        table = {};
        finalData = table;
        patchTable(table);
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      } else {
        table[data.dataName] = {};
        finalData = table[data.dataName];
        patchTable(table[data.dataName]);
      }
    }

    let finalText = data.placeholder || "Selecciona o Arrastra ";
    finalText += data.placeholderDataType || " Archivo";

    let isImage = false;
    if (data.dataType === "image") {
      isImage = true;
    }

    let currentFileAccepet = data.fileAccpet || "";
    if (isImage) {
      currentFileAccepet = "image/jpeg, image/png, image/jpg";
    }

    return (
      <Segment
        style={{
          ...styleBasic,
          height: "100%",
          width: data.dataInsideSize || renderDatadataInsideSize,
          margin: "0",
          padding: "0"
        }}
      >
        {isImage === true && (
          <div style={{ ...styleCenteredObject, flexDirection: "row" }}>
            {ImagesRes.map((dataImageRes, index) => {
              return (
                <div style={{ width: "100%" }}>
                  {renderTitleName(dataImageRes.dataTitle, "2rem", "0.9rem")}
                  <Dropzone
                    style={{
                      height: "100%",
                      width: "100%"
                    }}
                    accept={currentFileAccepet}
                    onDrop={(accepted, rejected) => {
                      if (accepted.length > 0) {
                        const reader = new FileReader();
                        reader.readAsDataURL(accepted[0]);
                        reader.onload = () => {
                          const splitData = accepted[0].name.split(".");
                          const extension = splitData[splitData.length - 1];
                          const name =
                            setURLType(splitData[0]) +
                            "_" +
                            dataImageRes.dataTitle +
                            "." +
                            extension;
                          let fileName = "";
                          if (finalData !== undefined) {
                            fileName = finalData.fileName;
                          }
                          let finalFile = {
                            fileName: fileName,
                            isNewFile: true,
                            result: accepted[0],
                            preview: accepted[0].preview,
                            name: name,
                            b64: reader.result
                          };
                          systemLog("SystemIMG", finalFile);
                          if (data.dontShowSucces !== true) {
                            toast.success("El archivo se cargo correctamente");
                          }

                          if (data.dataName === undefined) {
                            table[dataImageRes.dataName] = { ...finalFile };
                            patchTable(table);
                          } else {
                            table[data.dataName][
                              dataImageRes.dataName
                            ] = finalFile;
                            patchTable(table[data.dataName]);
                          }
                        };
                        reader.onerror = error => {
                          if (data.dataName === undefined) {
                            table[dataImageRes.dataName] = undefined;
                            patchTable(table);
                          } else {
                            table[data.dataName][
                              dataImageRes.dataName
                            ] = undefined;
                            patchTable(table[data.dataName]);
                          }
                          toast.error("Error al subir el archivo");
                        };
                      } else {
                        toast.error("Archivo invalido o corrupto");
                      }
                    }}
                  >
                    <Reveal
                      style={{
                        ...styleCenteredObject,
                        flexDirection: "column",
                        backgroundColor: "white",
                        border: "2px solid " + colors.borderColor,
                        height: "10rem",
                        width: "100%"
                      }}
                      animated="fade"
                    >
                      <Reveal.Content
                        style={{
                          height: "100%",
                          width: "100%"
                        }}
                        visible
                      >
                        {renderUploadedData(
                          data,
                          finalData[dataImageRes.dataName],
                          isImage
                        )}
                      </Reveal.Content>
                      <Reveal.Content
                        style={{
                          height: "100%",
                          width: "100%"
                        }}
                        hidden
                      >
                        <div
                          style={{
                            ...styleCenteredObject,
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                            backgroundColor: colors.uploadColor
                          }}
                        >
                          <Icon
                            name={"upload"}
                            style={{
                              color: colors.uploadFrontColor,
                              fontSize: "3rem"
                            }}
                          />
                          <ModularText
                            style={{
                              color: colors.uploadFrontColor,
                              fontSize: textSizeSubHeaderTitle
                            }}
                          >
                            {finalText}
                          </ModularText>
                        </div>
                      </Reveal.Content>
                    </Reveal>
                  </Dropzone>
                  {renderButtons(
                    table,
                    data,
                    finalData[dataImageRes.dataName],
                    patchTable,
                    dataImageRes.dataName
                  )}
                </div>
              );
            })}
          </div>
        )}
        {isImage === false && (
          <div>
            <Dropzone
              style={{
                height: "100%",
                width: "100%"
              }}
              accept={currentFileAccepet}
              onDrop={(accepted, rejected) => {
                if (accepted.length > 0) {
                  const reader = new FileReader();
                  reader.readAsDataURL(accepted[0]);
                  reader.onload = () => {
                    const splitData = accepted[0].name.split(".");
                    const extension = splitData[splitData.length - 1];
                    const name = setURLType(splitData[0]) + "." + extension;
                    let fileName = "";
                    if (finalData !== undefined) {
                      fileName = finalData.fileName;
                    }
                    let finalFile = {
                      fileName: fileName,
                      isNewFile: true,
                      result: accepted[0],
                      preview: accepted[0].preview,
                      name: name,
                      b64: reader.result
                    };
                    systemLog("SystemIMG", finalFile);
                    if (data.dontShowSucces !== true) {
                      toast.success("El archivo se cargo correctamente");
                    }

                    if (data.dataName === undefined) {
                      table = { ...finalFile };
                      patchTable(table);
                    } else {
                      table[data.dataName] = finalFile;
                      patchTable(table[data.dataName]);
                    }
                  };
                  reader.onerror = error => {
                    if (data.dataName === undefined) {
                      table = undefined;
                      patchTable(table);
                    } else {
                      table[data.dataName] = undefined;
                      patchTable(table[data.dataName]);
                    }
                    toast.error("Error al subir el archivo");
                  };
                } else {
                  toast.error("Archivo invalido o corrupto");
                }
              }}
            >
              <Reveal
                style={{
                  ...styleCenteredObject,
                  flexDirection: "column",
                  backgroundColor: "white",
                  border: "2px solid " + colors.borderColor,
                  height: "10rem",
                  width: "100%"
                }}
                animated="fade"
              >
                <Reveal.Content
                  style={{
                    height: "100%",
                    width: "100%"
                  }}
                  visible
                >
                  {renderUploadedData(data, finalData, isImage, "")}
                </Reveal.Content>
                <Reveal.Content
                  style={{
                    height: "100%",
                    width: "100%"
                  }}
                  hidden
                >
                  <div
                    style={{
                      ...styleCenteredObject,
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      backgroundColor: colors.uploadColor
                    }}
                  >
                    <Icon
                      name={"upload"}
                      style={{
                        color: colors.uploadFrontColor,
                        fontSize: "3rem"
                      }}
                    />
                    <ModularText
                      style={{
                        color: colors.uploadFrontColor,
                        fontSize: textSizeSubHeaderTitle
                      }}
                    >
                      {finalText}
                    </ModularText>
                  </div>
                </Reveal.Content>
              </Reveal>
            </Dropzone>
            {renderButtons(table, data, finalData, patchTable)}
          </div>
        )}
      </Segment>
    );
  } else if (data.dataType === "string") {
    let finalData = "";
    systemLog("System", data);
    systemLog("System", table);
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalData = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      }
    }

    if (data.mask !== undefined) {
      return (
        <Input
          children={
            <MaskedInput
              mask={data.mask}
              placeholder={data.placeholder || data.dataLabel || "Dato"}
              style={{
                height: "100%",
                width: "100%"
              }}
            />
          }
          onChange={e => {
            if (data.dataName === undefined) {
              table = e.target.value;
              patchTable(table);
            } else {
              table[data.dataName] = e.target.value;
              patchTable(table[data.dataName]);
            }
          }}
          style={{
            fontSize: "1.11rem",
            height: "100%",
            width: data.dataInsideSize || renderDatadataInsideSize,
            borderBottom: "2px"
          }}
          placeholder={data.placeholder || data.dataLabel || "Dato"}
          value={finalData}
        />
      );
    } else {
      return (
        <Input
          disabled={data.disable || false}
          type={data.asData}
          name={data.asData}
          autoComplete={data.autoComplete || "on"}
          placeholder={data.placeholder || data.dataLabel || "Dato"}
          onChange={e => {
            if (data.dataName === undefined) {
              table = e.target.value;
              patchTable(table);
            } else {
              table[data.dataName] = e.target.value;
              patchTable(table[data.dataName]);
            }
          }}
          style={{
            fontSize: "1.11rem",
            height: "100%",
            width: data.dataInsideSize || renderDatadataInsideSize,
            borderBottom: "2px"
          }}
          placeholder={data.placeholder || data.dataLabel || "Dato"}
          value={finalData}
        />
      );
    }
  } else if (data.dataType === "number") {
    let finalData = "";
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalData = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      }
    }

    if (data.mask !== undefined) {
      return (
        <Input
          children={
            <MaskedInput
              mask={[...data.mask]}
              placeholder={data.placeholder || data.dataLabel || "Dato"}
              style={{
                height: "100%",
                width: "100%"
              }}
            />
          }
          onChange={e => {
            let finalTarget = "";
            if (e.target.value !== "") {
              if (data.useFloat === true) {
                finalTarget = parseFloat(e.target.value);
              } else {
                finalTarget = parseInt(e.target.value);
              }
              if (data.minValue !== undefined) {
                if (finalTarget < data.minValue) {
                  finalTarget = data.minValue;
                }
              }
              if (data.maxValue !== undefined) {
                if (finalTarget > data.maxValue) {
                  finalTarget = data.maxValue;
                }
              }
            }
            if (data.dataName === undefined) {
              table = finalTarget;
              patchTable(table);
            } else {
              table[data.dataName] = finalTarget;
              patchTable(table[data.dataName]);
            }
          }}
          value={finalData}
        />
      );
    } else {
      return (
        <Input
          type={data.useFloat !== true ? "tel" : "number"}
          min={data.minValue}
          max={data.maxValue}
          name="number"
          onChange={e => {
            let finalTarget = "";
            if (e.target.value !== "") {
              if (data.useFloat === true) {
                finalTarget = parseFloat(e.target.value);
              } else {
                finalTarget = parseInt(e.target.value);
              }
              if (data.minValue !== undefined) {
                if (finalTarget < data.minValue) {
                  finalTarget = data.minValue;
                }
              }
              if (data.maxValue !== undefined) {
                if (finalTarget > data.maxValue) {
                  finalTarget = data.maxValue;
                }
              }
            }
            if (data.dataName === undefined) {
              table = finalTarget;
              patchTable(table);
            } else {
              table[data.dataName] = finalTarget;
              patchTable(table[data.dataName]);
            }
          }}
          style={{
            fontSize: "1.11rem",
            height: "100%",
            width: data.dataInsideSize || renderDatadataInsideSize,
            borderBottom: "2px"
          }}
          placeholder={data.placeholder || data.dataLabel || "Dato"}
          value={finalData}
        />
      );
    }
  } else if (data.dataType === "bool") {
    let finalCheck = false;

    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalCheck = table;
      } else {
        table = false;
        patchTable(table);
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalCheck = table[data.dataName];
      } else {
        table[data.dataName] = false;
        patchTable(table[data.dataName]);
      }
    }

    return (
      <div
        style={{
          ...styleForm,
          width: data.dataInsideSize || renderDatadataInsideSize
        }}
      >
        <Dropdown
          fluid
          search
          onChange={(event, changedData) => {
            if (data.dataName === undefined) {
              table = changedData.value;
              patchTable(table);
            } else {
              table[data.dataName] = changedData.value;
              patchTable(table[data.dataName]);
            }
          }}
          value={finalCheck}
          placeholder={data.placeholder || data.dataLabel || "Selecciona"}
          options={YesNoData}
        />
      </div>
    );
  } else if (data.dataType === "price") {
    let finalCostFormat = "";

    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalCostFormat = table.toString();
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalCostFormat = table[data.dataName].toString();
      }
    }
    return (
      <Input
        style={{
          width: data.dataInsideSize || renderDatadataInsideSize
        }}
      >
        <NumberFormat
          onValueChange={e => {
            let finalTarget = "";
            if (e.value !== "") {
              finalTarget = parseFloat(e.value);
            }

            if (data.dataName === undefined) {
              table = finalTarget;
              patchTable(table);
            } else {
              table[data.dataName] = finalTarget;
              patchTable(table[data.dataName]);
            }
          }}
          prefix={"$"}
          thousandSeparator=","
          decimalSeparator="."
          value={finalCostFormat}
          placeholder={data.placeholder || data.dataLabel || "Precio"}
        />
      </Input>
    );
  } else if (data.dataType === "date") {
    let finalDate = new Date();
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalDate = moment(table).format("DD-MM-YYYY");
      } else {
        table = moment()
          .endOf("day")
          .toJSON();
        patchTable(table);
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalDate = moment(table[data.dataName]).format("DD-MM-YYYY");
      } else {
        table[data.dataName] = moment()
          .endOf("day")
          .toJSON();
        patchTable(table[data.dataName]);
      }
    }

    return (
      <div
        style={{
          ...styleForm,
          height: "3.4rem",
          justifyContent: "flex-start",
          width: data.dataInsideSize || renderDatadataInsideSize
        }}
      >
        <DayPickerInput
          ref={node => {
            if (data.index !== undefined) {
              datePicker[data.dataName + "_" + data.index] = node;
            } else {
              datePicker[data.dataName] = node;
            }
          }}
          style={{ width: "2rem" }}
          formatDate={formatDate}
          parseDate={parseDate}
          format="DD-MM-YYYY"
          placeholder={data.placeholder || data.dataLabel || "Selecciona Fecha"}
          value={finalDate}
          onDayChange={date => {
            if (data.dataName === undefined) {
              table = moment(date)
                .endOf("day")
                .toJSON();
              patchTable(table);
            } else {
              table[data.dataName] = moment(date)
                .endOf("day")
                .toJSON();
              patchTable(table[data.dataName]);
            }
          }}
          dayPickerProps={{
            locale: "es",
            localeUtils: MomentLocaleUtils,
            todayButton: "Hoy",
            modifiersStyles: calendarPickerStyle,
            onCaptionClick: () => {
              if (data.index !== undefined) {
                if (yearPicker[data.dataName + "_" + data.index] !== null) {
                  yearPicker[data.dataName + "_" + data.index].open();
                }
              } else {
                if (yearPicker[data.dataName] !== null) {
                  yearPicker[data.dataName].open();
                }
              }
            }
          }}
        />
        <div style={{ width: 0, heigh: 0, overflow: "hidden" }}>
          <DatePicker
            ref={node => {
              if (data.index !== undefined) {
                yearPicker[data.dataName + "_" + data.index] = node;
              } else {
                yearPicker[data.dataName] = node;
              }
            }}
            cancelLabel=""
            format="DD MM YYYY"
            animateYearScrolling={false}
            openToYearSelection={true}
            value={finalDate}
            onChange={date => {
              if (data.index !== undefined) {
                if (datePicker[data.dataName + "_" + data.index] !== null) {
                  setTimeout(() => {
                    datePicker[
                      data.dataName + "_" + data.index
                    ].hideDayPicker();
                  }, 400);
                }
              } else {
                if (datePicker[data.dataName] !== null) {
                  setTimeout(() => {
                    datePicker[data.dataName].hideDayPicker();
                  }, 400);
                }
              }

              if (data.dataName === undefined) {
                table = moment(date)
                  .endOf("day")
                  .toJSON();
                patchTable(table);
              } else {
                table[data.dataName] = moment(date)
                  .endOf("day")
                  .toJSON();
                patchTable(table[data.dataName]);
              }
            }}
          />
        </div>
      </div>
    );
  } else if (data.dataType === "time") {
    let finalDate = moment().format("h:mm a");
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalDate = moment(table).format("h:mm a");
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalDate = moment(table[data.dataName]).format("h:mm a");
      }
    }

    return (
      <div
        style={{
          ...styleForm,
          justifyContent: "flex-start",
          width: data.dataInsideSize || renderDatadataInsideSize,
          cursor: "pointer"
        }}
      >
        <div
          onClick={() => {
            if (data.index !== undefined) {
              if (timePicker[data.dataName + "_" + data.index] !== null) {
                timePicker[data.dataName + "_" + data.index].open();
              }
            } else {
              if (timePicker[data.dataName] !== null) {
                timePicker[data.dataName].open();
              }
            }
          }}
        >
          {finalDate}
        </div>
        <div style={{ width: 0, height: 0, overflow: "hidden" }}>
          <TimePicker
            ref={node => {
              if (data.index !== undefined) {
                timePicker[data.dataName + "_" + data.index] = node;
              } else {
                timePicker[data.dataName] = node;
              }
            }}
            cancelLabel=""
            value={finalDate}
            onChange={date => {
              if (data.dataName === undefined) {
                table = moment(date).toJSON();
                patchTable(table);
              } else {
                table[data.dataName] = moment(date).toJSON();
                patchTable(table[data.dataName]);
              }
            }}
          />
        </div>
      </div>
    );
  } else if (data.dataType === "selector") {
    let finalSelector = "";
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalSelector = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalSelector = table[data.dataName];
        const dataFromValue = data.selectorData.find(data => {
          return data.value === finalSelector;
        });
        if (dataFromValue !== undefined) {
          if (dataFromValue.data !== undefined) {
            table[data.dataName + "TempDataValue"] = dataFromValue.data;
          } else {
            table[data.dataName + "TempDataValue"] = dataFromValue;
          }
        }
      }
    }
    if (data.selectorData !== undefined && data.selectorData.length > 0) {
      return (
        <div
          style={{
            ...styleForm,
            width: data.dataInsideSize || renderDatadataInsideSize
          }}
        >
          <Dropdown
            fluid
            search
            onChange={(event, changedData) => {
              const dataFromValue = data.selectorData.find(data => {
                return data.value === changedData.value;
              });
              if (data.dataName === undefined) {
                table = changedData.value;
                patchTable(table);
              } else {
                table[data.dataName] = changedData.value;
                if (dataFromValue.data !== undefined) {
                  table[data.dataName + "TempDataValue"] = dataFromValue.data;
                } else {
                  table[data.dataName + "TempDataValue"] = dataFromValue;
                }
                patchTable(table[data.dataName]);
              }
            }}
            value={finalSelector}
            placeholder={data.placeholder || data.dataLabel || "Selecciona"}
            options={data.selectorData}
          />
        </div>
      );
    } else {
      return <div />;
    }
  } else if (data.dataType === "richText") {
    let finalEditorState = "";
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalEditorState = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalEditorState = table[data.dataName];
      }
    }
    return (
      <div
        style={{
          ...styleCenteredObject,
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          alignSelf: "flex-start",
          width: data.dataInsideSize || renderDatadataInsideSize
        }}
      >
        <QuillTextView
          initValue={finalEditorState}
          onChange={value => {
            if (data.dataName === undefined) {
              table = value;
              patchTable(table);
            } else {
              table[data.dataName] = value;
              patchTable(table[data.dataName]);
            }
          }}
        />
      </div>
    );
  } else if (data.dataType === "color") {
    let currentColor = "rgba(255,255,255,1)";
    if (data.dataName === undefined) {
      if (table !== undefined) {
        currentColor = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        currentColor = table[data.dataName];
      }
    }
    const styles = reactCSS({
      default: {
        color: {
          ...styleForm,
          width: "100%",
          background: currentColor,
          color: ColorEditor(currentColor).negate(),
          fontSize: "1rem"
        },
        swatch: {
          width: data.dataInsideSize || renderDatadataInsideSize,
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer"
        },
        popover: {
          position: "absolute",
          zIndex: "600"
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px"
        }
      }
    });
    let displayColorPicker = false;
    if (data.index !== undefined) {
      if (colorPicker[data.dataName + "_" + data.index] === undefined) {
        colorPicker[data.dataName + "_" + data.index] = false;
      }
      displayColorPicker = colorPicker[data.dataName + "_" + data.index];
    } else {
      if (colorPicker[data.dataName] === undefined) {
        colorPicker[data.dataName] = false;
      }
      displayColorPicker = colorPicker[data.dataName];
    }
    return (
      <div
        style={{
          ...styleCenteredObject,
          width: "100%",
          justifyContent: "flex-start"
        }}
      >
        <div
          style={styles.swatch}
          onClick={() => {
            if (data.index !== undefined) {
              if (colorPicker[data.dataName + "_" + data.index] === undefined) {
                colorPicker[data.dataName + "_" + data.index] = false;
              } else {
                colorPicker[data.dataName + "_" + data.index] = !colorPicker[
                  data.dataName + "_" + data.index
                ];
              }
            } else {
              if (colorPicker[data.dataName] === undefined) {
                colorPicker[data.dataName] = false;
              } else {
                colorPicker[data.dataName] = !colorPicker[data.dataName];
              }
            }
            if (data.dataName === undefined) {
              patchTable(table);
            } else {
              patchTable(table[data.dataName]);
            }
          }}
        >
          <div style={styles.color}>{"SELECCIONAR COLOR"}</div>
        </div>
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div
              style={styles.cover}
              onClick={() => {
                if (data.index !== undefined) {
                  colorPicker[data.dataName + "_" + data.index] = false;
                } else {
                  colorPicker[data.dataName] = false;
                }
                if (data.dataName === undefined) {
                  patchTable(table);
                } else {
                  patchTable(table[data.dataName]);
                }
              }}
            />
            <SketchPicker
              color={currentColor}
              onChange={color => {
                table[data.dataName] = `rgba(${color.rgb.r}, ${color.rgb.g}, ${
                  color.rgb.b
                }, ${color.rgb.a})`;
                if (data.dataName === undefined) {
                  patchTable(table);
                } else {
                  patchTable(table[data.dataName]);
                }
              }}
            />
          </div>
        ) : null}
      </div>
    );
  } else if (data.dataType === "object") {
    let finalObject = {};
    if (data.dataName === undefined) {
      if (table !== undefined) {
        finalObject = table;
      } else {
        table = {};
        patchTable(table);
      }
    } else {
      if (table[data.dataName] !== undefined) {
        finalObject = table[data.dataName];
      } else {
        table[data.dataName] = {};
        patchTable(table[data.dataName]);
      }
    }

    return (
      <div
        style={{
          ...styleCenteredObject,
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          alignItems: "flex-start",
          width: "100%"
        }}
      >
        {data.dataObject.map((dataObject, index) => {
          const finalDataObject = { ...dataObject };
          finalDataObject.dataInsideSize = "100%";
          finalDataObject.index = index;
          if (data.index !== undefined) {
            finalDataObject.index = data.index;
          }
          return (
            <div
              style={{
                width: dataObject.dataInsideSize || renderDatadataInsideSize,
                marginRight: "0.7rem"
              }}
            >
              {renderModifyData(
                finalDataObject,
                finalObject,
                formatter,
                patchData => {
                  if (data.dataName === undefined) {
                    if (table === undefined) {
                      table = {};
                      table[dataObject.dataName] = patchData;
                      patchTable(table);
                    } else {
                      table[dataObject.dataName] = patchData;
                      patchTable(table);
                    }
                  } else {
                    if (table[data.dataName] === undefined) {
                      table[data.dataName] = {};
                      table[data.dataName][dataObject.dataName] = patchData;
                      patchTable(table[data.dataName]);
                    } else {
                      table[data.dataName][dataObject.dataName] = patchData;
                      patchTable(table[data.dataName]);
                    }
                  }
                },
                onClickElement
              )}
            </div>
          );
        })}
      </div>
    );
  } else if (data.dataType === "array") {
    let finalArray = [];
    if (table[data.dataName] !== undefined) {
      finalArray = table[data.dataName];
    } else {
      table[data.dataName] = [];
      patchTable(table[data.dataName]);
    }
    let canRenderButton = true;
    if (data.limitArray !== undefined) {
      if (finalArray.length < data.limitArray) {
        canRenderButton = true;
      } else {
        canRenderButton = false;
      }
    }
    if (data.limitBy !== undefined) {
      if (finalArray.length < table[data.limitBy.dataName]) {
        canRenderButton = true;
      } else {
        canRenderButton = false;
      }
    }
    return (
      <div
        style={{
          ...styleCenteredObject,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          alignItems: "flex-start",
          width: "100%"
        }}
      >
        {canRenderButton && (
          <Button
            icon
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              alignItems: "flex-start",
              height: "3rem",
              backgroundColor: colors.addColor,
              color: colors.addFrontColor
            }}
            onClick={() => {
              table[data.dataName].push({});
              patchTable(table[data.dataName]);
            }}
            labelPosition="left"
          >
            {"Agregar: " + data.arrayFileAdd}
            <Icon name={"add"} />
          </Button>
        )}
        {finalArray.map((finalObject, index) => {
          const dataObject = { ...data.arrayDataType };
          dataObject.index = index;
          return (
            <div
              style={{
                ...styleCenteredObject,
                marginTop: "0.4rem",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
                alignItems: "flex-start",
                width: "100%"
              }}
            >
              <Label
                circular
                style={{
                  ...styleCenteredObject,
                  position: "absolute",
                  zIndex: 600,
                  left: 0,
                  cursor: "pointer",
                  alignSelf: "flex-start",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "2rem",
                  width: "2rem",
                  backgroundColor: colors.removeColor,
                  color: colors.removeFrontColor
                }}
                onClick={() => {
                  if (data.dataName === undefined) {
                    const indexToRemove = table.indexOf(finalObject);
                    table.splice(indexToRemove, 1);
                    patchTable(table);
                  } else {
                    const indexToRemove = table[data.dataName].indexOf(
                      finalObject
                    );
                    table[data.dataName].splice(indexToRemove, 1);
                    patchTable(table[data.dataName]);
                  }
                }}
                pointing="right"
              >
                <Icon
                  style={{
                    ...styleCenteredObject,
                    fontSize: "1.1rem",
                    marginLeft: "0.55rem"
                  }}
                  name={"cancel"}
                />
              </Label>
              {renderModifyData(
                dataObject,
                finalObject,
                formatter,
                patchData => {
                  let finalPatch = patchData;
                  if (data.arrayUniqueValue === true) {
                    if (data.dataName !== undefined) {
                      let isUnique = table[data.dataName].find(data => {
                        return data === patchData;
                      });
                      if (dataObject.dataType === "object") {
                        const checkDataName = dataObject.dataObject[0].dataName;
                        isUnique = table[data.dataName].find(data => {
                          return (
                            data[checkDataName] === patchData[checkDataName]
                          );
                        });
                      }
                      const indexCheck = table[data.dataName].indexOf(isUnique);
                      if (isUnique !== undefined && indexCheck !== index) {
                        finalPatch = "";
                        if (dataObject.dataType === "object") {
                          const checkDataName =
                            dataObject.dataObject[0].dataName;
                          finalPatch = {};
                          finalPatch[checkDataName] = "";
                        }
                      }
                    } else {
                      let isUnique = table.find(data => {
                        return data === patchData;
                      });
                      if (dataObject.dataType === "object") {
                        const checkDataName = dataObject.dataObject[0].dataName;
                        isUnique = table.find(data => {
                          return (
                            data[checkDataName] === patchData[checkDataName]
                          );
                        });
                      }
                      const indexCheck = table.indexOf(isUnique);
                      if (isUnique !== undefined && indexCheck !== index) {
                        finalPatch = "";
                        if (dataObject.dataType === "object") {
                          const checkDataName =
                            dataObject.dataObject[0].dataName;
                          finalPatch = {};
                          finalPatch[checkDataName] = "";
                        }
                      }
                    }
                  }
                  if (dataObject.dataName !== undefined) {
                    const newFinalPatch = {};
                    newFinalPatch[dataObject.dataName] = patchData;
                    finalPatch = newFinalPatch;
                  }
                  if (data.dataName === undefined) {
                    table[index] = finalPatch;
                    patchTable(table);
                  } else {
                    table[data.dataName][index] = finalPatch;
                    patchTable(table[data.dataName]);
                  }
                },
                onClickElement
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div />;
  }
};

export const copyToClipboard = data => {
  var textField = document.createElement("textarea");
  textField.innerText = data;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

const combineStyles = styles => {
  let finalStyle = {};
  for (let i = 0; i < styles.length; i++) {
    finalStyle = { ...finalStyle, ...styles[i] };
  }
  return finalStyle;
};

export const renderData = (
  data,
  table,
  canEdit,
  formatter,
  patchTable,
  useYesNo,
  onClickElement
) => {
  let customStyleOnly = { ...data.customStyle };
  if (data.customStyleOnly !== undefined) {
    const stylesToAdd = [];
    for (let i = 0; i < data.customStyleOnly.length; i++) {
      const styleValueCheck = data.customStyleOnly[i];
      const styleToAdd = styleValueCheck.customStyle;
      let tempAddStyle = false;
      if (styleValueCheck.exactCheck === true) {
        tempAddStyle = true;
      }
      for (let c = 0; c < styleValueCheck.dataCheck.length; c++) {
        const currentCheck = styleValueCheck.dataCheck[c];
        if (styleValueCheck.exactCheck === true) {
          if (table[currentCheck.dataName] !== currentCheck.data) {
            tempAddStyle = false;
            break;
          }
        } else {
          if (table[currentCheck.dataName] === currentCheck.data) {
            tempAddStyle = true;
            break;
          }
        }
      }
      if (tempAddStyle === true) {
        stylesToAdd.push(styleToAdd);
      }
    }
    customStyleOnly = {
      ...data.customStyle,
      ...combineStyles(stylesToAdd)
    };
  }

  if (data.changeValue !== undefined) {
    let valueChange = "";
    let changeValue = false;
    for (let i = 0; i < data.changeValue.length; i++) {
      const changeValueCheck = data.changeValue[i];
      valueChange = changeValueCheck.valueChange;
      let tempChangeValue = true;
      for (let c = 0; c < changeValueCheck.dataCheck.length; c++) {
        const currentCheck = changeValueCheck.dataCheck[c];
        if (table[currentCheck.dataName] !== currentCheck.data) {
          tempChangeValue = false;
          break;
        }
      }
      if (tempChangeValue === true) {
        changeValue = true;
        break;
      }
    }
    if (changeValue === true) {
      return (
        <div
          style={{
            textAlign: "left",
            fontSize: textSizePTable,
            ...customStyleOnly
          }}
          onClick={() => {
            if (data.dontCopyData !== true) {
              copyToClipboard(valueChange || "");
            }
            if (data.checkClickElement === true) {
              if (onClickElement !== undefined) {
                onClickElement(data, table);
              }
            }
          }}
        >
          {valueChange || ""}
        </div>
      );
    }
  }

  if (data.onlyShow !== undefined) {
    let canShow = false;
    for (let i = 0; i < data.onlyShow.dataCheck.length; i++) {
      const currentCheck = data.onlyShow.dataCheck[i];
      if (table[currentCheck.dataName] === currentCheck.data) {
        canShow = true;
        break;
      }
    }

    if (canShow === false) {
      return (
        <div
          style={{
            textAlign: "left",
            fontSize: textSizePTable,
            ...customStyleOnly
          }}
          onClick={() => {
            if (data.dontCopyData !== true) {
              copyToClipboard(data.onlyShow.defaultValue || "");
            }
            if (data.checkClickElement === true) {
              if (onClickElement !== undefined) {
                onClickElement(data, table);
              }
            }
          }}
        >
          {data.onlyShow.defaultValue || ""}
        </div>
      );
    }
  }

  if (data.dataType === "insideModule") {
    let finalModule = {};
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalModule = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalModule = table[data.dataName];
      }
    }
    return renderData(
      data.dataModule,
      finalModule,
      canEdit,
      formatter,
      patchTable,
      useYesNo,
      onClickElement
    );
  } else if (data.dataType === "file" || data.dataType === "image") {
    let dontShowUpload = false;
    if (data.canUploadOnly !== undefined) {
      dontShowUpload = true;
      for (let i = 0; i < data.canUploadOnly.dataCheck.length; i++) {
        const currentCheck = data.canUploadOnly.dataCheck[i];
        if (table[currentCheck.dataName] === currentCheck.data) {
          dontShowUpload = false;
          break;
        }
      }
    }

    let dontShowDownload = false;
    if (data.canDownloadOnly !== undefined) {
      dontShowDownload = true;
      for (let i = 0; i < data.canDownloadOnly.dataCheck.length; i++) {
        const currentCheck = data.canDownloadOnly.dataCheck[i];
        if (table[currentCheck.dataName] === currentCheck.data) {
          dontShowDownload = false;
          break;
        }
      }
    }

    let dontShowView = false;
    if (data.canViewOnly !== undefined) {
      dontShowView = true;
      for (let i = 0; i < data.canViewOnly.dataCheck.length; i++) {
        const currentCheck = data.canViewOnly.dataCheck[i];
        if (table[currentCheck.dataName] === currentCheck.data) {
          dontShowView = false;
          break;
        }
      }
    }

    let dontShowRemove = false;
    if (data.canRemoveOnly !== undefined) {
      dontShowRemove = true;
      for (let i = 0; i < data.canRemoveOnly.dataCheck.length; i++) {
        const currentCheck = data.canRemoveOnly.dataCheck[i];
        if (table[currentCheck.dataName] === currentCheck.data) {
          dontShowRemove = false;
          break;
        }
      }
    }

    let finalData = undefined;
    let dataToView = "";

    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalData = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      }
    }
    if (
      finalData !== undefined &&
      finalData.result !== undefined &&
      finalData !== null
    ) {
      if (finalData.result.includes("base64")) {
        const splitFile = finalData.result.split("base64,");
        const blob = b64toBlob(splitFile[1], data.fileView);
        dataToView = URL.createObjectURL(blob);
      } else {
        if (finalData.preview !== undefined) {
          dataToView = getURL(finalData.preview);
        }
        if (finalData.isNewFile === true && finalData.b64 !== undefined) {
          dataToView = finalData.b64;
        }
      }
    } else if (
      data.simpleURL === true &&
      finalData !== undefined &&
      finalData !== null
    ) {
      dataToView = getURL(finalData);
    }

    let viewWidth = 32;
    let downloadWidth = 32;
    let uploadWidth = 32;
    if (data.showViewIcon === false || dontShowView === true) {
      viewWidth = 0;
      downloadWidth = 45;
      uploadWidth = 45;
    }

    if (data.showDownloadIcon === false || dontShowDownload === true) {
      downloadWidth = 0;
      if (data.showViewIcon === false || dontShowView === true) {
        viewWidth = 0;
        uploadWidth = 98;
      } else {
        viewWidth = 45;
        uploadWidth = 45;
      }
    }

    if (!(data.canUploadFile === true && data.alwaysShowUpload === true)) {
      uploadWidth = 0;
      if (data.showViewIcon === false || dontShowView === true) {
        viewWidth = 0;
        downloadWidth = 98;
      } else if (data.showDownloadIcon === false || dontShowDownload === true) {
        viewWidth = 98;
        downloadWidth = 0;
      } else {
        viewWidth = 45;
        downloadWidth = 45;
      }
    }

    const renderIcon = icon => {
      return <Icon name={icon} />;
    };

    const renderUploadFile = isFull => {
      let finalWidth = uploadWidth;
      let currentShowUploadIcon = data.showUploadIcon;
      if (isFull === true) {
        finalWidth = 100;
      } else {
        currentShowUploadIcon = true;
      }
      if (!isFull) {
        if (!(data.canUploadFile === true && data.alwaysShowUpload === true)) {
          return <div />;
        }
      } else {
        if (data.canUploadFile !== true) {
          return <div />;
        }
      }

      if (dontShowUpload === true) {
        return <div />;
      }

      if (data.smallVersion) {
        return (
          <Segment
            style={{
              ...styleBasic,
              height: "100%",
              width: finalWidth.toString() + "%",
              margin: "0",
              padding: "0"
            }}
          >
            <Dropzone
              style={{
                ...styleCenteredObject,
                height: "100%",
                width: "100%"
              }}
              accept={data.fileAccpet}
              onDrop={(accepted, rejected) => {
                if (accepted.length > 0) {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const splitData = accepted[0].name.split(".");
                    const extension = splitData[splitData.length - 1];
                    const name = setURLType(splitData[0]) + "." + extension;
                    let fileName = "";
                    if (finalData !== undefined) {
                      fileName = finalData.fileName;
                    }
                    let finalFile = {
                      fileName: fileName,
                      isNewFile: true,
                      result: accepted[0],
                      preview: accepted[0].preview,
                      name: name,
                      b64: reader.result
                    };
                    systemLog("SystemIMG", finalFile);
                    if (data.dontShowSucces !== true) {
                      toast.success("El archivo se cargo correctamente");
                    }
                    patchTable(table, data.dataName, finalFile);
                  };
                  reader.onerror = error => {
                    patchTable(table, data.dataName, undefined);
                    toast.error("Error al subir el archivo");
                  };
                } else {
                  toast.error("Archivo invalido o corrupto");
                }
              }}
            >
              <Button
                icon
                style={{
                  ...styleCenteredObject,
                  height: "100%",
                  width: "100%",
                  backgroundColor: colors.uploadColor,
                  color: colors.uploadFrontColor
                }}
              >
                {renderIcon(data.customUploadIcon || "upload")}
              </Button>
            </Dropzone>
          </Segment>
        );
      } else {
        return (
          <Segment
            style={{
              ...styleBasic,
              height: "100%",
              width: finalWidth.toString() + "%",
              margin: "0",
              padding: "0"
            }}
          >
            <Dropzone
              style={{
                ...styleCenteredObject,
                height: "100%",
                width: "98%"
              }}
              accept={data.fileAccpet}
              onDrop={(accepted, rejected) => {
                if (accepted.length > 0) {
                  const reader = new FileReader();
                  reader.readAsDataURL(accepted[0]);
                  reader.onload = () => {
                    const splitData = accepted[0].name.split(".");
                    const extension = splitData[splitData.length - 1];
                    const name = setURLType(splitData[0]) + "." + extension;
                    let fileName = "";
                    if (finalData !== undefined) {
                      fileName = finalData.fileName;
                    }
                    let finalFile = {
                      fileName: fileName,
                      isNewFile: true,
                      result: accepted[0],
                      preview: accepted[0].preview,
                      name: name
                    };
                    systemLog("SystemIMG", finalFile);
                    if (data.dontShowSucces !== true) {
                      toast.success("El archivo se cargo correctamente");
                    }
                    patchTable(table, data.dataName, finalFile);
                  };
                  reader.onerror = error => {
                    patchTable(table, data.dataName, undefined);
                    toast.error("Error al subir el archivo");
                  };
                } else {
                  toast.error("Archivo invalido o corrupto");
                }
              }}
            >
              <Button
                icon
                style={{
                  ...styleCenteredObject,
                  height: "100%",
                  width: "100%",
                  backgroundColor: colors.uploadColor,
                  color: colors.uploadFrontColor
                }}
              >
                {data.customUploadText || "Subir"}
                <Icon name={data.customUploadIcon || "upload"} />
              </Button>
            </Dropzone>
          </Segment>
        );
      }
    };

    const renderViewFile = () => {
      if (data.showViewIcon === false || dontShowView === true) {
        return <div />;
      }
      if (data.smallVersion) {
        return (
          <Button
            icon
            style={{
              ...styleCenteredObject,
              height: "100%",
              width: viewWidth.toString() + "%",
              backgroundColor: colors.viewColor,
              color: colors.viewFrontColor
            }}
            href={dataToView}
            target="_blank"
          >
            {renderIcon(data.customViewIcon || "eye")}
          </Button>
        );
      } else {
        return (
          <Button
            icon
            labelPosition="right"
            style={{
              height: "100%",
              width: viewWidth.toString() + "%",
              backgroundColor: colors.viewColor,
              color: colors.viewFrontColor
            }}
            href={dataToView}
            target="_blank"
          >
            {data.customViewText || "Ver"}
            <Icon name={data.customViewIcon || "eye"} />
          </Button>
        );
      }
    };

    const renderDownloadFile = () => {
      if (data.showDownloadIcon === false || dontShowDownload === true) {
        return <div />;
      }
      if (data.smallVersion) {
        return (
          <Button
            icon
            style={{
              ...styleCenteredObject,
              height: "100%",
              width: downloadWidth.toString() + "%",
              backgroundColor: colors.addColor,
              color: colors.addFrontColor
            }}
            href={dataToView}
            download={finalData.name}
            target="_blank"
          >
            {renderIcon(data.customDownloadIcon || "download")}
          </Button>
        );
      } else {
        return (
          <Button
            icon
            labelPosition="right"
            style={{
              backgroundColor: colors.addColor,
              height: "100%",
              width: downloadWidth.toString() + "%",
              color: colors.addFrontColor
            }}
            href={dataToView}
            download={finalData.name}
            target="_blank"
          >
            {data.customDownloadText || "Descargar"}
            <Icon name={data.customDownloadIcon || "download"} />
          </Button>
        );
      }
    };

    const renderRemoveFile = () => {
      if (data.showRemoveFile === false || dontShowRemove === true) {
        return <div />;
      }
      if (data.smallVersion) {
        return (
          <div
            style={{
              ...styleCenteredObject,
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
              color: colors.linkColor,
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.7rem"
            }}
            onClick={() => {
              patchTable(table, data.dataName, {});
            }}
          >
            Quitar
          </div>
        );
      } else {
        return (
          <Button
            icon
            labelPosition="right"
            style={{
              height: "100%",
              width: "32%",
              backgroundColor: colors.removeColor,
              color: colors.removeFrontColor
            }}
            onClick={() => {
              patchTable(table, data.dataName, {});
            }}
          >
            Quitar
            <Icon name={data.customRemoveIcon || "cancel"} />
          </Button>
        );
      }
    };

    if (finalData === undefined) {
      if (data.canUploadFile !== true) {
        return (
          <div
            style={{
              textAlign: "left",
              fontSize: textSizePTable,
              ...customStyleOnly
            }}
          >
            {"SIN ARCHIVOS"}
          </div>
        );
      } else {
        if (data.dataName === undefined) {
          if (table !== undefined && table !== null) {
            finalData = table;
          }
        } else {
          if (
            table[data.dataName] !== undefined &&
            table[data.dataName] !== null
          ) {
            finalData = table[data.dataName];
          }
        }

        return (
          <div
            style={{
              height: "3rem",
              width: "100%",
              ...styleCenteredObject
            }}
          >
            {renderUploadFile(true)}
          </div>
        );
      }
    } else if (data.smallVersion) {
      let topSize = "80%";
      let bottomSize = "10%";
      let separationSize = "0.3rem";
      if (data.showRemoveFile === false || dontShowRemove === true) {
        topSize = "100%";
        bottomSize = 0;
        separationSize = 0;
      }
      return (
        <div
          style={{
            height: "3rem",
            width: "100%",
            ...styleCenteredObject,
            flexDirection: "column"
          }}
        >
          <div
            style={{
              height: topSize,
              width: "100%",
              ...styleCenteredObject
            }}
          >
            {renderViewFile()}
            {renderDownloadFile()}
            {renderUploadFile()}
          </div>
          <div style={{ height: separationSize }} />
          <div
            style={{
              height: bottomSize,
              width: "100%",
              ...styleCenteredObject
            }}
          >
            {renderRemoveFile()}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            marginTop: "0.4rem",
            height: "100%",
            width: "35%",
            ...styleCenteredObject,
            alignSelf: "flex-start",
            alignItems: "flex-start",
            justifyContent: "flex-start"
          }}
        >
          {renderViewFile()}
          {renderDownloadFile()}
          {renderUploadFile()}
        </div>
      );
    }
  } else if (data.dataType === "string") {
    let finalData = "SIN DATO";
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalData = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      }
    }

    if (data.dataSuffix !== undefined) {
      finalData = finalData + data.dataSuffix;
    }
    if (data.dataPrefix !== undefined) {
      finalData = data.dataPrefix + finalData;
    }
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: textSizePTable,
          ...customStyleOnly
        }}
        onClick={() => {
          if (data.dontCopyData !== true) {
            copyToClipboard(finalData);
          }
          if (data.checkClickElement === true) {
            if (onClickElement !== undefined) {
              onClickElement(data, table);
            }
          }
        }}
      >
        {finalData}
      </div>
    );
  } else if (data.dataType === "number") {
    let finalData = 0;
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalData = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      }
    }

    if (data.useFloat === true) {
      finalData = parseFloat(finalData);
      if (data.floatFixed !== undefined) {
        finalData = finalData.toFixed(data.floatFixed);
      }
    } else {
      finalData = parseInt(finalData);
    }
    finalData = finalData.toString();
    if (data.dataSuffix !== undefined) {
      finalData = finalData + data.dataSuffix;
    }
    if (data.dataPrefix !== undefined) {
      finalData = data.dataPrefix + finalData;
    }
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: textSizePTable,
          ...customStyleOnly
        }}
        onClick={() => {
          if (data.dontCopyData !== true) {
            copyToClipboard(finalData);
          }
          if (data.checkClickElement === true) {
            if (onClickElement !== undefined) {
              onClickElement(data, table);
            }
          }
        }}
      >
        {finalData}
      </div>
    );
  } else if (data.dataType === "bool") {
    let finalCheck = false;
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalCheck = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalCheck = table[data.dataName];
      }
    }
    if (useYesNo === true) {
      return (
        <div
          style={{
            textAlign: "left",
            fontSize: textSizeSubHeaderTitle,
            ...customStyleOnly
          }}
          onClick={() => {
            if (data.dontCopyData !== true) {
              copyToClipboard(
                data.dataLabel + ": " + (finalCheck ? "Sí" : "No")
              );
            }
            if (data.checkClickElement === true) {
              if (onClickElement !== undefined) {
                onClickElement(data, table);
              }
            }
          }}
        >
          {finalCheck ? "Sí" : "No"}
        </div>
      );
    } else if (data.canBeEdited === false || canEdit === false) {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            marginLeft: "0.4rem",
            ...customStyleOnly
          }}
          onClick={() => {
            if (data.dontCopyData !== true) {
              copyToClipboard(
                data.dataLabel + ": " + (finalCheck ? "Sí" : "No")
              );
            }
            if (data.checkClickElement === true) {
              if (onClickElement !== undefined) {
                onClickElement(data, table);
              }
            }
          }}
        >
          <Icon
            style={{
              fontSize: "1.5rem",
              textAlign: "center"
            }}
            name={finalCheck ? "check square outline" : "square outline"}
          />
        </div>
      );
    } else {
      return (
        <Button
          onClick={() => {
            patchTable(table, data.dataName, !finalCheck);
          }}
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            marginLeft: "0.4rem",
            color: "black",
            backgroundColor: "transparent"
          }}
        >
          <Icon
            style={{
              color: "black",
              fontSize: "1.5rem",
              textAlign: "center"
            }}
            name={finalCheck ? "check square outline" : "square outline"}
          />
        </Button>
      );
    }
  } else if (data.dataType === "price") {
    let finalCostFormat = "SIN DATO";
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalCostFormat = table;
        finalCostFormat = formatter.format(finalCostFormat).toString();
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalCostFormat = table[data.dataName];
        finalCostFormat = formatter.format(finalCostFormat).toString();
      }
    }
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: textSizePTable,
          ...customStyleOnly
        }}
        onClick={() => {
          if (data.dontCopyData !== true) {
            copyToClipboard(finalCostFormat);
          }
          if (data.checkClickElement === true) {
            if (onClickElement !== undefined) {
              onClickElement(data, table);
            }
          }
        }}
      >
        {finalCostFormat}
      </div>
    );
  } else if (data.dataType === "date") {
    let finalDate = "SIN DATO";
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalDate = table;
        finalDate = moment(finalDate).format("D/M/YY");
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalDate = table[data.dataName];
        finalDate = moment(finalDate).format("D/M/YY");
      }
    }
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: textSizePTable,
          ...customStyleOnly
        }}
        onClick={() => {
          if (data.dontCopyData !== true) {
            copyToClipboard(finalDate);
          }
          if (data.checkClickElement === true) {
            if (onClickElement !== undefined) {
              onClickElement(data, table);
            }
          }
        }}
      >
        {finalDate}
      </div>
    );
  } else if (data.dataType === "time") {
    let finalDate = "SIN DATO";
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalDate = table;
        finalDate = moment(table[data.dataName]).format("h:mm a");
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalDate = table[data.dataName];
        finalDate = moment(table[data.dataName]).format("h:mm a");
      }
    }
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: textSizePTable,
          ...customStyleOnly
        }}
        onClick={() => {
          if (data.dontCopyData !== true) {
            copyToClipboard(finalDate);
          }
          if (data.checkClickElement === true) {
            if (onClickElement !== undefined) {
              onClickElement(data, table);
            }
          }
        }}
      >
        {finalDate}
      </div>
    );
  } else if (data.dataType === "selector") {
    let finalSelector = "";
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalSelector = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalSelector = table[data.dataName];
      }
    }
    if (data.selectorData !== undefined) {
      if (data.canBeEdited === false || canEdit === false) {
        let finalData = "SIN DATO";
        if (finalSelector !== "") {
          const selectorFind = data.selectorData.find(selector => {
            return selector.value === finalSelector;
          });
          if (selectorFind !== undefined) {
            finalData = selectorFind.text;
          }
        }
        return (
          <div
            style={{
              textAlign: "left",
              fontSize: textSizePTable,
              ...customStyleOnly
            }}
            onClick={() => {
              if (data.dontCopyData !== true) {
                copyToClipboard(finalData);
              }
              if (data.checkClickElement === true) {
                if (onClickElement !== undefined) {
                  onClickElement(data, table);
                }
              }
            }}
          >
            {finalData}
          </div>
        );
      } else {
        return (
          <div
            style={{
              ...styleForm,
              width: "100%"
            }}
          >
            <Dropdown
              fluid
              search
              onChange={(event, changedData) => {
                patchTable(table, data.dataName, changedData.value);
              }}
              value={finalSelector}
              placeholder={data.placeholder || data.dataLabel || "Selecciona"}
              options={data.selectorData}
            />
          </div>
        );
      }
    } else {
      return <div />;
    }
  } else if (data.dataType === "object") {
    let finalObject = {};
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalObject = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalObject = table[data.dataName];
      }
    }
    return (
      <div
        style={{
          ...styleCenteredObject,
          flexDirection: data.showInRow !== false ? "row" : "column",
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          alignItems: "flex-start"
        }}
      >
        {data.dataObject.map((dataObject, index) => {
          let divider = () => {
            return <div style={{ width: "0px" }} />;
          };
          if (index < data.dataObject.length - 1) {
            if (dataObject.addSeparator !== false) {
              divider = () => {
                if (dataObject.customSeparator !== undefined) {
                  return (
                    <div
                      style={{
                        ...styleCenteredObject,
                        width: dataObject.customSeparator.separation || "1rem"
                      }}
                    >
                      {dataObject.customSeparator.text || "|"}
                    </div>
                  );
                } else {
                  return (
                    <div style={{ ...styleCenteredObject, width: "1rem" }}>
                      {"|"}
                    </div>
                  );
                }
              };
            }
          } else {
            if (data.endingSeparator !== undefined) {
              divider = () => {
                return (
                  <div
                    style={{
                      ...styleCenteredObject,
                      width: data.endingSeparator.separation || "1rem"
                    }}
                  >
                    {data.endingSeparator.text || "|"}
                  </div>
                );
              };
            }
          }
          return (
            <div
              key={index}
              style={{
                display: "flex",
                ...styleCenteredObject,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
                alignItems: "flex-start"
              }}
            >
              {dataObject.dataLabel !== undefined && (
                <div
                  style={{
                    textAlign: "left",
                    fontSize: textSizePTable,
                    ...customStyleOnly
                  }}
                >
                  {dataObject.dataLabel + ":"}
                </div>
              )}
              <div
                style={{
                  width:
                    dataObject.dataLabel !== undefined ? "0.4rem" : "0.2rem"
                }}
              />
              {renderData(
                dataObject,
                finalObject,
                canEdit,
                formatter,
                patchData => {
                  patchTable(table, data.dataName, patchData);
                },
                useYesNo,
                onClickElement
              )}
              {divider()}
            </div>
          );
        })}
      </div>
    );
  } else if (data.dataType === "array") {
    let finalArray = [];
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalArray = table;
      } else {
        table = [];
        patchTable(table);
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalArray = table[data.dataName];
      } else {
        table[data.dataName] = [];
        patchTable(table[data.dataName]);
      }
    }
    if (data.renderCount === true) {
      return (
        <div
          style={{
            textAlign: "left",
            fontSize: textSizePTable,
            ...customStyleOnly
          }}
          onClick={() => {
            if (data.dontCopyData !== true) {
              copyToClipboard(finalArray.length);
            }
            if (data.checkClickElement === true) {
              if (onClickElement !== undefined) {
                onClickElement(data, table);
              }
            }
          }}
        >
          {finalArray.length}
        </div>
      );
    } else {
      let flexDir = data.showInRow === true ? "row" : "column";

      return (
        <div
          style={{
            ...styleCenteredObject,
            flexDirection: data.showInRow === true ? "row" : "column",
            justifyContent: "flex-start",
            alignSelf: "flex-start",
            alignItems: "flex-start",
            width: "100%"
          }}
        >
          {finalArray.map((finalObject, index) => {
            const dataObject = data.arrayDataType;
            let itemWidth = { width: "100%" };
            if (data.showInRow === true) {
              itemWidth = {};
            }
            return (
              <div
                style={{
                  ...styleCenteredObject,
                  flexDirection: "row",
                  marginTop: data.showInRow === true ? "0px" : "0.4rem",
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                  ...itemWidth
                }}
              >
                {data.showNumber !== false && index + 1 + ".- "}
                <div
                  style={{
                    ...styleCenteredObject,
                    width: data.showNumber !== false ? "0.4rem" : "0px"
                  }}
                />
                {renderData(
                  dataObject,
                  finalObject,
                  canEdit,
                  formatter,
                  patchData => {
                    const indexToPatch = table[data.dataName].indexOf(
                      finalObject
                    );
                    table[data.dataName][indexToPatch] = patchData;
                    patchTable(table[data.dataName]);
                  },
                  useYesNo,
                  onClickElement
                )}
              </div>
            );
          })}
        </div>
      );
    }
  } else if (data.dataType === "richText") {
    let finalData = "SIN DATO";
    if (data.dataName === undefined) {
      if (table !== undefined && table !== null) {
        finalData = table;
      }
    } else {
      if (table[data.dataName] !== undefined && table[data.dataName] !== null) {
        finalData = table[data.dataName];
      }
    }
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: textSizePTable,
          ...customStyleOnly
        }}
        onClick={() => {
          if (data.dontCopyData !== true) {
            copyToClipboard(finalData);
          }
          if (data.checkClickElement === true) {
            if (onClickElement !== undefined) {
              onClickElement(data, table);
            }
          }
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: finalData
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
};

export const sortTable = (currentData, table, up) => {
  let finalCompareTo = function compareUp(a, b) {
    return 0;
  };

  let data = { ...currentData };

  if (data.dontSort === true) {
    const newSort = table.slice();
    return newSort;
  }
  let checkInside = false;
  if (data.dataType === "insideModule") {
    checkInside = true;
    data = { ...currentData.dataModule };
  }

  if (data.dataType === "file") {
    const newSort = table.slice();
    return newSort;
  } else if (data.dataType === "string") {
    finalCompareTo = function compareUp(a, b) {
      let aData = a[data.dataName];
      let bData = b[data.dataName];
      if (checkInside === true) {
        if (a[currentData.dataName] !== undefined) {
          aData = a[currentData.dataName][data.dataName];
        } else {
          aData = "zzzzzzzzzzzz";
        }

        if (b[currentData.dataName] !== undefined) {
          bData = b[currentData.dataName][data.dataName];
        } else {
          bData = "zzzzzzzzzzzz";
        }
      }
      const aValue = aData;
      const bValue = bData;
      if (aValue < bValue) {
        let valueReturn = -1;
        if (up === false) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      if (aValue > bValue) {
        let valueReturn = 1;
        if (up === false) {
          valueReturn = -1;
        }
        return valueReturn;
      }
      return 0;
    };
  } else if (data.dataType === "number") {
    finalCompareTo = function compareUp(a, b) {
      let aValue = parseInt(a[data.dataName]);
      let bValue = parseInt(b[data.dataName]);
      if (data.useFloat === true) {
        aValue = parseFloat(a[data.dataName]);
        bValue = parseFloat(b[data.dataName]);
      }
      if (aValue < bValue) {
        let valueReturn = -1;
        if (up === false) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      if (aValue > bValue) {
        let valueReturn = 1;
        if (up === false) {
          valueReturn = -1;
        }
        return valueReturn;
      }
      return 0;
    };
  } else if (data.dataType === "bool") {
    finalCompareTo = function compareUp(a, b) {
      let aValue = a[data.dataName];
      let bValue = b[data.dataName];
      if (aValue !== bValue) {
        let valueReturn = -1;
        if (aValue === up) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      return 0;
    };
  } else if (data.dataType === "price") {
    finalCompareTo = function compareUp(a, b) {
      let aValue = parseFloat(a[data.dataName]);
      let bValue = parseFloat(b[data.dataName]);
      if (aValue < bValue) {
        let valueReturn = -1;
        if (up === false) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      if (aValue > bValue) {
        let valueReturn = 1;
        if (up === false) {
          valueReturn = -1;
        }
        return valueReturn;
      }
      return 0;
    };
  } else if (data.dataType === "date") {
    finalCompareTo = function compareUp(a, b) {
      let aValue = moment(a[data.dataName]);
      let bValue = moment(b[data.dataName]);
      if (aValue.isBefore(bValue)) {
        let valueReturn = -1;
        if (up === false) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      if (aValue.isAfter(bValue)) {
        let valueReturn = 1;
        if (up === false) {
          valueReturn = -1;
        }
        return valueReturn;
      }
      return 0;
    };
  } else if (data.dataType === "selector") {
    finalCompareTo = function compareUp(a, b) {
      let aValue = a[data.dataName];
      let bValue = b[data.dataName];
      if (aValue < bValue) {
        let valueReturn = -1;
        if (up === false) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      if (aValue > bValue) {
        let valueReturn = 1;
        if (up === false) {
          valueReturn = -1;
        }
        return valueReturn;
      }
      return 0;
    };
  } else if (data.dataType === "array") {
    finalCompareTo = function compareUp(a, b) {
      let aValue = a[data.dataName].length;
      let bValue = b[data.dataName].length;
      if (aValue < bValue) {
        let valueReturn = -1;
        if (up === false) {
          valueReturn = 1;
        }
        return valueReturn;
      }
      if (aValue > bValue) {
        let valueReturn = 1;
        if (up === false) {
          valueReturn = -1;
        }
        return valueReturn;
      }
      return 0;
    };
  }
  let newSort = table.slice();
  newSort.sort(function(a, b) {
    return finalCompareTo(a, b);
  });
  return newSort;
};

export const YesNoData = [
  { key: true, value: true, text: "Sí" },
  { key: false, value: false, text: "No" }
];

export const TableInFilter = (
  table,
  filterTable,
  currentFilterCompuest,
  specificSearch
) => {
  let inFilter = true;
  if (filterTable !== undefined && currentFilterCompuest !== "") {
    inFilter = false;
    const arrayCheckFilter = [];
    let arrayOfSearch = currentFilterCompuest.split(",");
    arrayOfSearch = arrayOfSearch.filter(
      data => data !== undefined && data !== null && data.trim() !== ""
    );
    let doSpecificSearch = false;
    if (specificSearch !== undefined) {
      doSpecificSearch = specificSearch;
    }
    for (
      let searchIndex = 0;
      searchIndex < arrayOfSearch.length;
      searchIndex++
    ) {
      const currentFilter = arrayOfSearch[searchIndex].trim();
      for (let i = 0; i < filterTable.length; i++) {
        const tempFilter = filterTable[i];
        if (tempFilter.dataType === "insideModule") {
          let finalModule = {};
          if (table[tempFilter.dataName] !== undefined) {
            finalModule = table[tempFilter.dataName];
          }
          const singleModuleFilter = [];
          singleModuleFilter.push(tempFilter.dataModule);
          let isInside = TableInFilter(
            finalModule,
            singleModuleFilter,
            currentFilterCompuest,
            false
          );
          if (isInside === true) {
            if (
              arrayCheckFilter.find(check => {
                return check === tempFilter.dataName;
              }) === undefined
            ) {
              arrayCheckFilter.push(tempFilter.dataName);
            }
          }
        } else if (tempFilter.dataType === "string") {
          let dataCheck = "";
          if (table[tempFilter.dataName] !== undefined) {
            dataCheck = table[tempFilter.dataName];
          }
          if (
            dataCheck
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                currentFilter
                  .toUpperCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )
          ) {
            if (
              arrayCheckFilter.find(check => {
                return check === tempFilter.dataName;
              }) === undefined
            ) {
              arrayCheckFilter.push(tempFilter.dataName);
            }
          }
        } else if (tempFilter.dataType === "selector") {
          let dataCheck = "";
          if (table[tempFilter.dataName] !== undefined) {
            dataCheck = table[tempFilter.dataName];
          }
          const item = tempFilter.selectorData.find(
            data => data.value === dataCheck
          );
          let textToCompare = "";
          if (item !== null && item !== undefined) {
            textToCompare = item.text;
          }
          if (
            textToCompare
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                currentFilter
                  .toUpperCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              )
          ) {
            if (
              arrayCheckFilter.find(check => {
                return check === tempFilter.dataName;
              }) === undefined
            ) {
              arrayCheckFilter.push(tempFilter.dataName);
            }
          }
        } else if (tempFilter.dataType === "price") {
          let dataProx = 20;
          if (tempFilter.dataAprox !== undefined) {
            dataProx = tempFilter.dataAprox;
          }
          const currentDataCheck = parseInt(currentFilter, 10);
          if (
            currentDataCheck !== undefined &&
            currentDataCheck !== null &&
            currentDataCheck !== "" &&
            isNaN(currentDataCheck) !== true
          ) {
            if (table[tempFilter.dataName] !== undefined) {
              const dataCheck = table[tempFilter.dataName];
              const minNumber = (dataCheck * (100 - dataProx)) / 100;
              const maxNumber = (dataCheck * (100 + dataProx)) / 100;
              if (
                currentDataCheck >= minNumber &&
                currentDataCheck <= maxNumber
              ) {
                if (
                  arrayCheckFilter.find(check => {
                    return check === tempFilter.dataName;
                  }) === undefined
                ) {
                  arrayCheckFilter.push(tempFilter.dataName);
                }
              }
            }
          }
        } else if (tempFilter.dataType === "date") {
          let dataProx = 2;
          if (tempFilter.dataAprox !== undefined) {
            dataProx = tempFilter.dataAprox;
          }

          let currentDataCheck = null;
          if (currentFilter.includes("-") === false) {
            const filterNumber = parseInt(currentFilter, 10);
            if (
              filterNumber !== undefined &&
              filterNumber !== null &&
              filterNumber !== "" &&
              isNaN(filterNumber) !== true
            ) {
              if (filterNumber > 12) {
                if (moment(filterNumber).isValid() === true) {
                  currentDataCheck = moment(currentFilter);
                }
              }
            }
          } else if (moment(currentFilter, "DD-MM-YYYY").isValid() === true) {
            currentDataCheck = moment(currentFilter, "DD-MM-YYYY");
          }
          if (
            currentDataCheck !== undefined &&
            currentDataCheck !== null &&
            currentDataCheck !== "" &&
            isNaN(currentDataCheck) !== true
          ) {
            let dataCheck = moment();
            if (table[tempFilter.dataName] !== undefined) {
              if (moment(table[tempFilter.dataName]).isValid() === true) {
                dataCheck = moment(table[tempFilter.dataName]);
              }
            }
            let currentAccord = "day";
            if (tempFilter.checkAccordTo !== undefined) {
              currentAccord = tempFilter.checkAccordTo;
            }
            const duration = moment.duration(currentDataCheck.diff(dataCheck));
            let diff = 0;
            if (currentAccord === "day" || currentAccord === "d") {
              diff = duration.asDays();
            } else if (currentAccord === "month" || currentAccord === "m") {
              diff = duration.asMonths();
            } else if (currentAccord === "year" || currentAccord === "y") {
              diff = duration.asYears();
            }
            if (diff >= dataProx * -1 && diff <= dataProx) {
              if (
                arrayCheckFilter.find(check => {
                  return check === tempFilter.dataName;
                }) === undefined
              ) {
                arrayCheckFilter.push(tempFilter.dataName);
              }
            }
          }
        } else if (tempFilter.dataType === "bool") {
          let dataCheck = false;
          if (table[tempFilter.dataName] !== undefined) {
            dataCheck = table[tempFilter.dataName];
          }

          let finalFilter = currentFilter.toUpperCase().replace(/\s/g, "");
          const checkCode =
            tempFilter.dataLabel.toUpperCase() +
            ":" +
            dataCheck
              .toString()
              .toUpperCase()
              .replace(/\s/g, "");

          let humanYNES = "no";
          if (dataCheck === true) {
            humanYNES = "si";
          }
          const checkHumanES =
            tempFilter.dataLabel.toUpperCase() +
            ":" +
            humanYNES
              .toString()
              .toUpperCase()
              .replace(/\s/g, "");

          let humanYNEN = "no";
          if (dataCheck === true) {
            humanYNEN = "yes";
          }
          const checkHumanEN =
            tempFilter.dataLabel.toUpperCase() +
            ":" +
            humanYNEN
              .toString()
              .toUpperCase()
              .replace(/\s/g, "");

          if (
            checkCode === finalFilter ||
            checkHumanES === finalFilter ||
            checkHumanEN === finalFilter
          ) {
            if (
              arrayCheckFilter.find(check => {
                return check === tempFilter.dataName;
              }) === undefined
            ) {
              arrayCheckFilter.push(tempFilter.dataName);
            }
          }
        }
      }
      if (doSpecificSearch === false) {
        if (arrayCheckFilter.length > 0) {
          inFilter = true;
          break;
        }
      }
    }

    if (doSpecificSearch === true) {
      if (arrayCheckFilter.length === arrayOfSearch.length) {
        inFilter = true;
      }
    }
  }
  return inFilter;
};

export const setANDToAdd = dataCheck => {
  if (dataCheck.dataType === "bool") {
    dataCheck.value = checkValueBool(dataCheck.value);
  } else if (dataCheck.dataType === "selector") {
    dataCheck.value = returnValueFromSelector(
      dataCheck.value,
      dataCheck.selectorData
    );
  }
  const dataToAdd = {};
  if (dataCheck.dataFilterType === "like") {
    dataToAdd[dataCheck.dataName] = {
      like: dataCheck.value,
      options: "i"
    };
  } else if (dataCheck.dataFilterType === "between") {
    const dataMin = ((100 - dataCheck.dataMin) * dataCheck.value) / 100;
    const dataMax = ((100 + dataCheck.dataMax) * dataCheck.value) / 100;
    dataToAdd[dataCheck.dataName] = {
      between: [dataMin, dataMax]
    };
  } else if (dataCheck.dataFilterType === "inq") {
    dataToAdd[dataCheck.dataName] = {
      inq: dataCheck.value
    };
  } else {
    dataToAdd[dataCheck.dataName] = dataCheck.value;
  }
  return dataToAdd;
};

export const GetFilterInArray = filter => {
  let arrayOfSearch = filter.split(",");
  arrayOfSearch = arrayOfSearch.filter(
    data => data !== undefined && data !== null && data.trim() !== ""
  );
  return arrayOfSearch;
};

export const SearchInFilterArray = (arrayOfSearch, searchItemName) => {
  const returnData = arrayOfSearch.find(search => {
    return normalizeString(search)
      .toUpperCase()
      .includes(normalizeString(searchItemName).toUpperCase());
  });
  return returnData;
};

export const SearchInFilterArrayBefore = (
  arrayOfSearch,
  searchItemName,
  before
) => {
  const returnData = arrayOfSearch.find(search => {
    if (search.includes(before) === true) {
      const valueOfFilter = search.split(before)[0].trim();
      return normalizeString(valueOfFilter)
        .toUpperCase()
        .includes(normalizeString(searchItemName).toUpperCase());
    }
    return false;
  });
  return returnData;
};

export const getFileContentAsBase64 = (url, callback) => {
  var image = new Image();

  image.onload = () => {
    var canvas = document.createElement("canvas");
    canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext("2d").drawImage(image, 0, 0);

    callback(canvas.toDataURL("image/png"));
  };

  image.src = url;
};

export const getTitleName = (dataTitle, table) => {
  let finalData = "";
  systemLog("System", dataTitle);
  systemLog("System", table);
  for (let i = 0; i < dataTitle.length; i++) {
    const data = dataTitle[i];
    if (table[data.dataName] !== undefined) {
      if (data.dataType === "object") {
        systemLog("System", data);
        for (let d = 0; d < data.dataObject.length; d++) {
          const currentDataObject = data.dataObject[d];
          systemLog("System", currentDataObject);
          if (currentDataObject.dataType === "string") {
            finalData += " " + table[data.dataName][currentDataObject.dataName];
          }
        }
      } else if (data.dataType === "string") {
        finalData += " " + table[data.dataName];
      }
    }
  }
  return finalData;
};

export const checkIfCanShow = (data, tableCheck, currentDataVisual) => {
  let canShowItem = false;
  for (let i = 0; i < data.onlyShowIf.length; i++) {
    const currentOnlyShow = data.onlyShowIf[i];
    if (currentOnlyShow.value !== undefined) {
      if (currentOnlyShow.dataType === "selector") {
        const findDataVisual = currentDataVisual.find(dataVisual => {
          return dataVisual.dataName === currentOnlyShow.dataName;
        });

        if (findDataVisual !== undefined) {
          systemLog("CanShow", findDataVisual);
          const valueCompare = getValueFromSelector(
            currentOnlyShow.value,
            findDataVisual.selectorData
          );

          if (tableCheck[currentOnlyShow.dataName] === valueCompare) {
            canShowItem = true;
            break;
          }
        }
      }
      if (tableCheck[currentOnlyShow.dataName] === currentOnlyShow.value) {
        canShowItem = true;
        break;
      }
    } else if (currentOnlyShow.valueObject !== undefined) {
      if (
        tableCheck[currentOnlyShow.dataName][
          currentOnlyShow.valueObject.dataName
        ] === currentOnlyShow.valueObject.value
      ) {
        canShowItem = true;
        break;
      }
    }
  }
  return canShowItem;
};

export const getValueFromSelector = (dataFind, selector) => {
  let finalData = "";
  const selectorFind = selector.find(data => {
    return data.text === dataFind;
  });
  if (selectorFind !== undefined) {
    finalData = selectorFind.value;
  }
  return finalData;
};

export const getTextFromSelector = (dataFind, selector) => {
  let finalData = "";
  const selectorFind = selector.find(data => {
    return data.value === dataFind;
  });
  if (selectorFind !== undefined) {
    finalData = selectorFind.text;
  }
  return finalData;
};
