import { push } from "connected-react-router";
import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import { Motion, spring } from "react-motion";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Icon, Menu, Responsive } from "semantic-ui-react";
import LogoRender from "../components/LogoRender";
import ContentBackground from "../layaouts/ContentBackground";
import { doLogOut, openFrontLoading } from "../redux/actions";
import {
  SystemName,
  LandingName,
  initRoute,
  showChangeButton,
  minWidthView
} from "../MainInfoData";
import { landingRoutes as routesLanding } from "../routes";
import { sytemRoutes as routesSystem } from "../routes";
import {
  colors,
  headerHeight,
  styleBasic,
  styleCenteredObject,
  textSizeMenuTop,
  textSizeSubMenuTop
} from "../style";
const subMenuHeight = 3;

const centeredMenuItem = () => {
  let specialTextStyle = {};
  const finalStyle = {
    ...styleCenteredObject,
    height: "100%",
    ...specialTextStyle,
    cursor: "pointer"
  };

  return finalStyle;
};

const lineContainer = isActive => {
  let specialTextStyle = {};
  if (isActive === true) {
    specialTextStyle.borderBottom = "1px solid " + colors.colorFrontMain;
  }

  return specialTextStyle;
};

class MainHeader extends Component {
  constructor(props) {
    super(props);
    const activeItem = this.props.currentPath;
    this.didInitData = false;
    this.state = {
      activeItem: activeItem || "",
      routes: routesSystem(),
      isShowingPages: false
    };
    this.initHeader = this.initHeader.bind(this);
    this.renderMenuButton = this.renderMenuButton.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderDesktopMenu = this.renderDesktopMenu.bind(this);
    this.renderChangeRouter = this.renderChangeRouter.bind(this);
    this.renderMenuPermCheck = this.renderMenuPermCheck.bind(this);
    this.renderSingleItem = this.renderSingleItem.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.renderAdmin = this.renderAdmin.bind(this);
    this.renderPages = this.renderPages.bind(this);
    this.renderDesktopLogo = this.renderDesktopLogo.bind(this);
    this.renderRightInfo = this.renderRightInfo.bind(this);
    this.renderInsideData = this.renderInsideData.bind(this);
    this.renderMenuButtonFake = this.renderMenuButtonFake.bind(this);
    this.renderMenuPage = this.renderMenuPage.bind(this);

    this.items = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (
      this.state.activeItem !== nextProps.currentPath &&
      nextProps.currentPath !== undefined
    ) {
      this.setState({ activeItem: nextProps.currentPath });
    }
    if (this.props.API !== null && this.props.API !== undefined) {
      if (
        this.props.API.GetToken() !== undefined &&
        this.didInitData === false
      ) {
        this.initHeader();
        this.didInitData = true;
      }
    }
  }

  initHeader() {
    if (initRoute === "system") {
      this.setState({ routes: routesSystem(), isShowingPages: false });
      this.props.push("/system");
    } else {
      let goLanding = "/landing";
      if (this.props.navigationPages.length > 0) {
        if (this.props.navigationPages[0].pages.length > 0) {
          goLanding =
            "/landing/menupage/" +
            this.props.navigationPages[0].name +
            "/page/" +
            this.props.navigationPages[0].pages[0].id;
        } else {
          goLanding = "/landing/menupage/" + this.props.navigationPages[0].name;
        }
      }
      this.setState({ routes: routesLanding(), isShowingPages: true });
      this.props.push(goLanding);
    }
  }

  renderSingleItem(itemName, visibleChevron, route, customFunc) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          onClick={() => {
            if (customFunc !== undefined) {
              customFunc();
            } else {
              this.props.push(route);
            }
            if (window.innerWidth < Responsive.onlyTablet.minWidth) {
              this.props.closeMenu();
            } else {
              this.props.onClickItem();
            }
          }}
          style={{
            width: "100%",
            height: "100%",
            ...styleCenteredObject
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: textSizeMenuTop,
              color: colors.colorFrontMain
            }}
          >
            {itemName}
            <div
              style={{
                marginTop: "0.5rem",
                ...lineContainer(visibleChevron)
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDropDown(name, finalPages, activeItem, isLandingEditor, pageId) {
    if (finalPages.length === 0) {
      return <div />;
    }
    if (this.items[name + "_menuitem"] === undefined) {
      this.items[name + "_menuitem"] = false;
    }
    const menuElement = document.getElementById(name + "_menuitem");
    if (menuElement !== null) {
      menuElement.addEventListener("mouseover", () => {
        if (this.items[name + "_menuitem"] !== true) {
          this.items[name + "_menuitem"] = true;
          this.forceUpdate();
        }
      });

      menuElement.addEventListener("mouseout", () => {
        if (this.items[name + "_menuitem"] !== false) {
          this.items[name + "_menuitem"] = false;
          this.forceUpdate();
        }
      });
    }

    const showMotion = this.items[name + "_menuitem"];
    let finalHeight = subMenuHeight * finalPages.length;
    if (isLandingEditor === true) {
      finalHeight = subMenuHeight * finalPages.length + 1;
    }
    return (
      <Motion
        style={{
          zIndex: 1000,
          x: spring(showMotion ? finalHeight : 0)
        }}
      >
        {({ x }) => {
          return (
            <div
              style={{
                ...styleCenteredObject,
                zIndex: 1000,
                flexDirection: "column",
                left: 0,
                overflowY: "hidden",
                top: headerHeight * 0.8,
                position: "absolute",
                height: x.toString() + "rem",
                borderBottomLeftRadius: "0.3rem",
                borderBottomRightRadius: "0.3rem",
                backgroundColor: colors.mainColorDark
              }}
            >
              {finalPages.map((currentPage, insideIndex) => {
                let name = currentPage.name;
                if (isLandingEditor === true) {
                  name = currentPage.publicName;
                }
                let isActiveInsidePage = false;
                if (activeItem === currentPage.route) {
                  isActiveInsidePage = true;
                }
                return (
                  <div
                    style={{
                      padding: 10,
                      minWidth: "100%",
                      height: subMenuHeight.toString() + "rem",
                      backgroundColor: colors.mainColorDark
                    }}
                    key={insideIndex}
                    as={Button}
                    name={currentPage.name}
                    active={isActiveInsidePage}
                    onClick={() => {
                      let currentRoute = currentPage.route;
                      if (isLandingEditor === true) {
                        currentRoute =
                          "/landing/menupage/" +
                          pageId +
                          "/page/" +
                          currentPage.id;
                      }
                      this.props.push(currentRoute);
                      if (window.innerWidth < Responsive.onlyTablet.minWidth) {
                        this.props.closeMenu();
                      }
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                        fontSize: textSizeSubMenuTop,
                        color: colors.colorFrontMain
                      }}
                    >
                      {name}
                    </div>
                  </div>
                );
              })}
              {isLandingEditor === true && (
                <div
                  style={{
                    padding: 10,
                    minWidth: "100%",
                    height: subMenuHeight.toString() + "rem",
                    backgroundColor: colors.mainColorDark
                  }}
                  as={Button}
                  name={"MetaTags"}
                  active={activeItem === "/landing/menupage/" + pageId}
                  onClick={() => {
                    let currentRoute = "/landing/menupage/" + pageId;
                    this.props.push(currentRoute);
                    if (window.innerWidth < Responsive.onlyTablet.minWidth) {
                      this.props.closeMenu();
                    }
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                      fontSize: textSizeSubMenuTop,
                      color: colors.colorFrontMain
                    }}
                  >
                    {"MetaTags"}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Motion>
    );
  }

  renderMenuPermCheck(screen) {
    const { name, route, perm, pages, showOnMenu, onlyIfGod } = screen;
    const { activeItem } = this.state;
    let canShowItem = false;

    const userperm = this.props.userPermissions.find(permission => {
      if (permission.module === undefined) {
        return false;
      }
      return permission.module.name.toUpperCase() === perm.toUpperCase();
    });

    if (userperm !== undefined) {
      if (userperm.read === true) {
        canShowItem = true;
      }
    }
    if (perm === "DASHBOARD") {
      canShowItem = true;
    }

    if (this.props.user.isGod === true) {
      canShowItem = true;
    } else {
      if (onlyIfGod === true) {
        canShowItem = false;
      }
    }

    if (showOnMenu === false) {
      canShowItem = false;
    }

    if (canShowItem === true) {
      let isActiveItem = false;
      if (activeItem === route) {
        isActiveItem = true;
      }
      let finalPages = [];
      if (pages !== undefined) {
        for (let x = 0; x < pages.length; x++) {
          const currentPage = pages[x];
          const userInidePagePerm = this.props.userPermissions.find(
            permission => {
              if (permission.module === undefined) {
                return false;
              }
              return (
                permission.module.name.toUpperCase() ===
                currentPage.perm.toUpperCase()
              );
            }
          );

          if (userInidePagePerm !== undefined) {
            if (
              userInidePagePerm.read === true ||
              this.props.user.isGod === true
            ) {
              finalPages.push(currentPage);
              if (activeItem === currentPage.route) {
                isActiveItem = true;
              }
            }
          } else if (this.props.user.isGod === true) {
            finalPages.push(currentPage);
            if (activeItem === currentPage.route) {
              isActiveItem = true;
            }
          }
        }
      }
      return (
        <Menu.Item
          id={name + "_menuitem"}
          name={name}
          style={centeredMenuItem()}
        >
          {this.renderSingleItem(name, isActiveItem, route)}
          {this.renderDropDown(name, finalPages, activeItem, false)}
        </Menu.Item>
      );
    } else {
      return <div />;
    }
  }

  renderAdmin() {
    return this.state.routes.map((screen, index) => {
      return <div key={index}>{this.renderMenuPermCheck(screen)}</div>;
    });
  }

  renderMenuPage(page, indexpage) {
    const { activeItem } = this.state;
    const { name, publicName } = page;

    let route = "/landing/menupage/" + name;

    let pages = [];
    if (page.pages !== undefined) {
      pages = page.pages;
    }

    if (pages.length > 0) {
      route = "/landing/menupage/" + name + "/page/" + pages[0].id;
    }

    let isActiveItem = false;
    if (activeItem.includes(route) === true) {
      isActiveItem = true;
    }

    return (
      <Menu.Item
        key={indexpage}
        id={name + "_menuitem"}
        name={name}
        style={centeredMenuItem()}
      >
        {this.renderSingleItem(publicName, isActiveItem, route)}
        {this.renderDropDown(name, pages, activeItem, true, name)}
      </Menu.Item>
    );
  }

  renderPages() {
    if (this.state.isShowingPages === false) {
      return <div />;
    }
    return this.props.navigationPages.map((page, index) => {
      return <div key={index}>{this.renderMenuPage(page, index)}</div>;
    });
  }

  renderDesktopMenu(isDesktop) {
    if (this.props.verticalMenu === true || isDesktop === false) {
      return <div />;
    }
    return (
      <Menu
        style={{
          ...styleBasic,
          ...styleCenteredObject,
          zIndex: 100,
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          justifyContent: "flex-start"
        }}
        vertical={false}
      >
        <Menu.Item
          as={"div"}
          onClick={(e, { name }) => {}}
          style={{
            ...centeredMenuItem(),
            padding: 0,
            paddingRight: "1rem",
            height: headerHeight
          }}
        >
          <LogoRender
            logoSizeW={headerHeight * 0.8}
            logoSizeH={headerHeight * 0.8}
            renderName={false}
          />
        </Menu.Item>
        {this.renderAdmin()}
        {this.renderPages()}
        {/*this.renderChangeToPages()*/}
      </Menu>
    );
  }

  renderDesktopLogo(isDesktop) {
    if (this.props.verticalMenu === true || isDesktop === false) {
      return <div />;
    }
    return (
      <Menu
        style={{
          ...styleBasic,
          ...styleCenteredObject,
          zIndex: 100,
          height: "100%",
          backgroundColor: "transparent",
          justifyContent: "flex-start"
        }}
        vertical={false}
      >
        <Menu.Item
          as={"div"}
          onClick={(e, { name }) => {}}
          style={{
            ...centeredMenuItem(),
            padding: 0,
            paddingRight: "1rem",
            height: headerHeight
          }}
        >
          <LogoRender
            logoSizeW={headerHeight * 0.8}
            logoSizeH={headerHeight * 0.8}
            renderName={false}
          />
        </Menu.Item>
      </Menu>
    );
  }

  renderChangeRouter(isDesktop) {
    if (showChangeButton === false) {
      return <div />;
    }
    if (this.props.haveLanding === false) {
      return <div />;
    }

    if (this.props.verticalMenu === true || isDesktop === false) {
      return <div />;
    }

    let backgroundColor = LandingName.backgroundColor;
    let color = LandingName.color;
    let name = LandingName.name;
    let icon = LandingName.icon;
    if (this.state.activeItem.includes("landing")) {
      backgroundColor = SystemName.backgroundColor;
      color = SystemName.color;
      name = SystemName.name;
      icon = SystemName.icon;
    }

    return (
      <div
        style={{
          ...styleCenteredObject,
          marginRight: "1rem",
          width: "7rem",
          height: "100%"
        }}
      >
        <Button
          onClick={() => {
            if (this.state.activeItem.includes("landing")) {
              this.setState({ routes: routesSystem(), isShowingPages: false });
              this.props.push("/system");
            } else {
              let goLanding = "/landing";
              if (this.props.navigationPages.length > 0) {
                if (this.props.navigationPages[0].pages.length > 0) {
                  goLanding =
                    "/landing/menupage/" +
                    this.props.navigationPages[0].name +
                    "/page/" +
                    this.props.navigationPages[0].pages[0].id;
                } else {
                  goLanding =
                    "/landing/menupage/" + this.props.navigationPages[0].name;
                }
              }
              this.setState({ routes: routesLanding(), isShowingPages: true });
              this.props.push(goLanding);
            }
          }}
          style={{
            backgroundColor: "transparent",
            ...styleCenteredObject,
            backgroundColor: backgroundColor,
            color: color,
            width: "100%",
            height: "100%"
          }}
          icon
          labelPosition="left"
        >
          {name}
          <Icon name={icon} />
        </Button>
      </div>
    );
  }

  renderMenuButtonFake(isDesktop) {
    if (this.props.verticalMenu === false && isDesktop === true) {
      return <div />;
    }

    return (
      <div
        style={{
          ...styleCenteredObject,
          height: "3.5rem",
          width: "3.5rem"
        }}
      >
        <Button
          style={{
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: "transparent",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center"
          }}
        >
          <Icon
            name={"bars"}
            style={{
              padding: 0,
              margin: 0,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              fontSize: "2rem",
              color: colors.colorFrontMain
            }}
          />
        </Button>
      </div>
    );
  }

  renderMenuButton(isDesktop) {
    if (this.props.verticalMenu === false && isDesktop === true) {
      return <div />;
    }

    return (
      <div
        style={{
          ...styleCenteredObject,
          height: "3.5rem",
          width: "3.5rem"
        }}
      >
        <Button
          onClick={() => {
            this.props.onClickMenuSideBar();
          }}
          style={{
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: "transparent",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center"
          }}
        >
          <Icon
            name={"bars"}
            style={{
              padding: 0,
              margin: 0,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              fontSize: "2rem",
              color: colors.colorFrontMain
            }}
          />
        </Button>
      </div>
    );
  }

  renderRightInfo() {
    return (
      <div
        style={{
          ...styleCenteredObject,
          height: "100%",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            width: "3rem",
            height: "100%"
          }}
        >
          <Button
            onClick={() => {
              this.props.push("/profile");
            }}
            style={{
              backgroundColor: "transparent",
              ...styleCenteredObject,
              width: "100%",
              height: "100%"
            }}
            icon
          >
            <Icon
              style={{
                fontSize: "1.8rem",
                color: colors.colorFrontMain,
                ...styleCenteredObject
              }}
              name="cog"
            />
          </Button>
        </div>
        <div style={{ width: "0.6rem" }} />
        <div
          style={{
            ...styleCenteredObject,
            width: "10rem",
            height: "100%"
          }}
        >
          <Button
            onClick={() => {
              this.props.openFrontLoading(true);
              this.props.doLogOut();
            }}
            style={{
              ...styleCenteredObject,
              padding: 0,
              margin: 0,
              backgroundColor: "transparent",
              border: "1px solid " + colors.colorFrontMain,
              borderRadius: 10,
              width: "100%",
              height: "2.3rem",
              fontWeight: "normal",
              color: colors.colorFrontMain
            }}
          >
            {"Cerrar Sesión"}
          </Button>
        </div>
      </div>
    );
  }

  renderInsideData(isDesktop) {
    if (this.props.noInfo === true) {
      return (
        <div
          style={{
            ...styleCenteredObject,
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
            height: "100%"
          }}
        >
          {this.renderMenuButtonFake(isDesktop)}
          {this.renderDesktopLogo(isDesktop)}
          <div
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              <ContentLoader
                height={13}
                speed={2}
                primaryColor={colors.primaryTopLoadingColor}
                secondaryColor={colors.secondaryTopLoadingColor}
              >
                {/* Pure SVG */}
                <rect x="5" y="3" rx="5" ry="5" width="200" height="8" />
                <rect x="312" y="3" rx="4" ry="4" width="33" height="8" />
                <rect x="349" y="3" rx="3" ry="3" width="49" height="8" />
              </ContentLoader>
            </Responsive>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            height: "100%"
          }}
        >
          {this.renderMenuButton(isDesktop)}
          {this.renderDesktopMenu(isDesktop)}
          {this.renderChangeRouter(isDesktop)}
          {this.renderRightInfo()}
        </div>
      );
    }
  }

  renderMenu(isDesktop) {
    return (
      <ContentBackground
        useGrid={false}
        contentAlign={"flex-start"}
        height={headerHeight}
        backgroundColor={colors.mainColor}
        viewWidth={16}
        contentStyle={{ paddingRight: "1rem", paddingLeft: "1rem" }}
      >
        {this.renderInsideData(isDesktop)}
      </ContentBackground>
    );
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Responsive style={{ width: "100%" }} {...Responsive.onlyMobile}>
          {this.renderMenu(false)}
        </Responsive>
        <Responsive
          style={{ width: "100%" }}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <div
            style={{
              minWidth: minWidthView
            }}
          >
            {this.renderMenu(true)}
          </div>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { API } = api;
  const {
    organization,
    verticalMenu,
    userPermissions,
    userPagesPermissions,
    user,
    haveLanding,
    navigationPages,
    pages
  } = data;
  return {
    API,
    organization,
    verticalMenu,
    userPermissions,
    userPagesPermissions,
    user,
    haveLanding,
    navigationPages,
    pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openFrontLoading: doit => {
      dispatch(openFrontLoading(doit));
    },
    doLogOut: () => {
      dispatch(doLogOut());
    },
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainHeader)
);
