import _ from "lodash";
import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Dropdown } from "semantic-ui-react";
import { StickyContainer, Sticky } from "react-sticky";
import {
  systemLog,
  validateEmail,
  returnTextFromSelector,
  setFileNameDataCheck,
  setURLType,
  checkObjectFiles
} from "../scripts/General";
import {
  checkIfCanShow,
  columnEditStyle,
  renderLabel,
  renderModifyData,
  renderTopName,
  ImagesRes
} from "../scripts/System";
import { adminSeparation, colors, styleCenteredObject } from "../style";
import { MultipleLanguages } from "../MainInfoData";

const saveContent = "SE ESTA CREANDO EL ELEMENTO";
const saveContentC = "¡SE CREO EL ELEMENTO";
const saveContentB = "HUBO UN ERROR AL CREAR EL NUEVO ELEMENTO";

class AdminCreateItem extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    let tempLanguage = "";
    let tempLanguageId = "";
    if (this.props.languages.length > 0) {
      tempLanguageId = this.props.languages[0].value;
      tempLanguage = this.props.languages[0].data;
    }
    this.state = {
      currentLanguage: tempLanguage,
      currentLanguageId: tempLanguageId
    };
    this.canShowLanguage = false;
    this.renderTopLanguages = this.renderTopLanguages.bind(this);
    this.currentTableToChange = {};
    this.saveItem = this.saveItem.bind(this);
    this.saveFiles = this.saveFiles.bind(this);
    this.postObject = this.postObject.bind(this);
    this.checkRelationToAdd = this.checkRelationToAdd.bind(this);
    this.checkChildrenToAdd = this.checkChildrenToAdd.bind(this);
    this.renderInsideData = this.renderInsideData.bind(this);
    this.dirToModify = this.props.tableSysName;

    if (this.props.tableCreateRoute !== undefined) {
      this.dirToModify = this.props.tableCreateRoute;
    }

    this.currentDataVisual = [];
    for (let i = 0; i < this.props.tableData.dataVisual.length; i++) {
      let currentData = this.props.tableData.dataVisual[i];
      let dataToAdd = JSON.parse(JSON.stringify(currentData));
      if (dataToAdd.canChangeLanguage === true) {
        this.canShowLanguage = true;
      }
      this.currentDataVisual.push(dataToAdd);
    }
  }

  componentWillMount() {
    for (let i = 0; i < this.currentDataVisual.length; i++) {
      let currentData = this.currentDataVisual[i];
      currentData.mainDataName = (
        " " + this.props.tableData.dataVisual[i].dataName
      ).slice(1);
      let dataToCheck = currentData;
      if (this.props.tableParentIdConnect !== undefined) {
        if (currentData.dataName === this.props.tableParentIdConnect) {
          currentData.dontShow = true;
          currentData.notRequired = true;
        }
      }
      if (currentData.dataType === "array") {
        dataToCheck = currentData.arrayDataType;
      }
      if (dataToCheck.selectorDataFrom !== undefined) {
        if (dataToCheck.selectorDataFrom.dataFrom === "props") {
          const newSelectorData = [];
          const selectorDataToSet = this.props.data[
            dataToCheck.selectorDataFrom.dataName
          ];
          if (dataToCheck.selectorDataFrom.addEmptyValue === true) {
            const dataToAdd = {};
            dataToAdd.key = null;
            dataToAdd.value = null;
            dataToAdd.text =
              dataToCheck.selectorDataFrom.emptyPlaceHolder || "SIN DATO";
            newSelectorData.push(dataToAdd);
          }
          for (let d = 0; d < selectorDataToSet.length; d++) {
            const newData = selectorDataToSet[d];
            const dataToAdd = {};
            dataToAdd.key = newData[dataToCheck.selectorDataFrom.key];
            dataToAdd.value = newData[dataToCheck.selectorDataFrom.value];
            if (dataToCheck.selectorDataFrom.customText !== undefined) {
              dataToAdd.text = "";
              for (
                let ct = 0;
                ct < dataToCheck.selectorDataFrom.customText.length;
                ct++
              ) {
                const ctDataName = dataToCheck.selectorDataFrom.customText[ct];
                dataToAdd.text = dataToAdd.text + newData[ctDataName] + " ";
              }
            } else if (
              dataToCheck.selectorDataFrom.fromSelector !== undefined
            ) {
              dataToAdd.text = returnTextFromSelector(
                newData[dataToCheck.selectorDataFrom.text],
                dataToCheck.selectorDataFrom.fromSelector,
                true
              );
            } else {
              dataToAdd.text = newData[dataToCheck.selectorDataFrom.text];
            }
            dataToAdd.data = newData;
            newSelectorData.push(dataToAdd);
          }
          dataToCheck.selectorData = newSelectorData.slice();
        }
      }
    }
  }

  saveItem() {
    let finalTitle = "";
    for (let i = 0; i < this.props.tableData.dataTitle.length; i++) {
      const dataTitle = this.props.tableData.dataTitle[i];
      finalTitle += this.currentTableToChange[dataTitle.dataName] + " ";
    }
    finalTitle = finalTitle.trim();

    let canSave = true;
    let finalObjectSave = {};
    for (let i = 0; i < this.currentDataVisual.length; i++) {
      const dataCheck = this.currentDataVisual[i];
      if (
        dataCheck.needConfirm === true &&
        this.currentTableToChange[dataCheck.dataName] !== undefined &&
        this.currentTableToChange[dataCheck.dataName] !== ""
      ) {
        if (
          this.currentTableToChange["confirm" + dataCheck.dataName] ===
            undefined ||
          this.currentTableToChange["confirm" + dataCheck.dataName] !==
            this.currentTableToChange[dataCheck.dataName]
        ) {
          toast.error(
            "El dato: " +
              (dataCheck.dataSingle || dataCheck.dataLabel) +
              ", debe ser igual"
          );
          canSave = false;
        }
      } else if (
        dataCheck.notRequired !== true &&
        dataCheck.setBaseData === undefined &&
        (this.currentTableToChange[dataCheck.dataName] === undefined ||
          this.currentTableToChange[dataCheck.dataName] === null ||
          this.currentTableToChange[dataCheck.dataName].toString() === "")
      ) {
        toast.error(
          "El dato: " +
            (dataCheck.dataSingle || dataCheck.dataLabel) +
            ", es obligatorio"
        );
        canSave = false;
      } else if (
        typeof this.currentTableToChange[dataCheck.dataName] === "object"
      ) {
        if (
          dataCheck.notRequired !== true &&
          dataCheck.setBaseData === undefined &&
          _.isEmpty(this.currentTableToChange[dataCheck.dataName]) === true
        ) {
          toast.error(
            "El dato: " +
              (dataCheck.dataSingle || dataCheck.dataLabel) +
              ", es obligatorio"
          );
          canSave = false;
        }
      }
      if (dataCheck.asData === "email") {
        if (
          validateEmail(this.currentTableToChange[dataCheck.dataName]) === false
        ) {
          toast.error(
            "El dato: " +
              (dataCheck.dataSingle || dataCheck.dataLabel) +
              ", debe ser un correo valido"
          );
          canSave = false;
        }
      }
    }

    if (canSave) {
      const relationToAdd = [];
      const childrenToAdd = [];
      let filesToAdd = [];
      for (let i = 0; i < this.currentDataVisual.length; i++) {
        const dataCheck = this.currentDataVisual[i];
        if (dataCheck.childrenData !== undefined) {
          if (dataCheck.dataType === "array") {
            if (
              dataCheck.arrayDataType.dataType === "object" ||
              dataCheck.arrayDataType.dataType === "selector"
            ) {
              for (
                let a = 0;
                a < this.currentTableToChange[dataCheck.dataName].length;
                a++
              ) {
                let currentChildrenArray = this.currentTableToChange[
                  dataCheck.dataName
                ][a];
                if (dataCheck.arrayDataType.dataType === "selector") {
                  currentChildrenArray = {
                    id: this.currentTableToChange[dataCheck.dataName][a]
                  };
                }

                let children = {};
                if (dataCheck.arrayDataType.dataName === undefined) {
                  children = {
                    ...currentChildrenArray
                  };
                } else {
                  children = {
                    ...currentChildrenArray[dataCheck.arrayDataType.dataName]
                  };
                }
                children.tableSysName = dataCheck.childrenData.tableSysName;
                children.parentDataName = dataCheck.childrenData.parentDataName;
                if (dataCheck.childrenData.addMeTo !== undefined) {
                  children.addMeTo = dataCheck.childrenData.addMeTo;
                }
                childrenToAdd.push(children);
              }
            }
          } else {
            if (dataCheck.dataType === "object") {
              const children = {
                ...this.currentTableToChange[dataCheck.dataName]
              };
              children.tableSysName = dataCheck.childrenData.tableSysName;
              children.parentDataName = dataCheck.childrenData.parentDataName;
              if (dataCheck.childrenData.addMeTo !== undefined) {
                children.addMeTo = dataCheck.childrenData.addMeTo;
              }
              childrenToAdd.push(children);
            }
          }
        } else if (dataCheck.relation !== undefined) {
          if (dataCheck.dataType === "array") {
            for (
              let a = 0;
              a < this.currentTableToChange[dataCheck.dataName].length;
              a++
            ) {
              const currentRelationArray = this.currentTableToChange[
                dataCheck.dataName
              ][a];
              const relation = {};
              relation.route = dataCheck.relation.relationName;
              if (dataCheck.arrayDataType.dataType === "selector") {
                relation.id = currentRelationArray;
              } else {
                relation.id =
                  currentRelationArray[dataCheck.arrayDataType.dataName];
              }
              relationToAdd.push(relation);
            }
          } else {
            const relation = {};
            relation.route = dataCheck.relation.relationName;
            relation.id = this.currentTableToChange[dataCheck.dataName];
            relationToAdd.push(relation);
          }
        } else if (
          dataCheck.dataType !== "table" &&
          this.currentTableToChange[dataCheck.dataName] !== undefined &&
          dataCheck.canAdd !== false &&
          dataCheck.canBeEdited !== false
        ) {
          for (let l = 0; l < this.props.languages.length; l++) {
            const currentLanguage = this.props.languages[l];
            if (
              this.currentTableToChange[
                dataCheck.dataName + currentLanguage.data
              ] === undefined
            ) {
              continue;
            }

            finalObjectSave[
              dataCheck.dataName + currentLanguage.data
            ] = this.currentTableToChange[
              dataCheck.dataName + currentLanguage.data
            ];

            if (dataCheck.dataType === "file") {
              const currentChildrenFile =
                finalObjectSave[dataCheck.dataName + currentLanguage.data];
              if (currentChildrenFile.isNewFile === true) {
                let newFileName = "";
                const splitData = currentChildrenFile.name.split(".");
                let extension = "";
                if (splitData.length > 0) {
                  extension = splitData[splitData.length - 1];
                  newFileName = setFileNameDataCheck(
                    extension,
                    dataCheck,
                    this.currentTableToChange,
                    undefined,
                    currentLanguage.data !== "" ? currentLanguage.data : ""
                  );
                }
                if (newFileName !== "") {
                  currentChildrenFile.name = newFileName;
                } else {
                  currentChildrenFile.name =
                    setURLType(splitData[0]) + "." + extension;
                }
                filesToAdd.push(currentChildrenFile);
              }
            } else if (dataCheck.dataType === "image") {
              for (let ir = 0; ir < ImagesRes.length; ir++) {
                const currentChildrenFile =
                  finalObjectSave[dataCheck.dataName + currentLanguage.data][
                    ImagesRes[ir].dataName
                  ];
                if (
                  currentChildrenFile !== undefined &&
                  currentChildrenFile.isNewFile === true
                ) {
                  let newFileName = "";
                  const splitData = currentChildrenFile.name.split(".");
                  let extension = "";
                  if (splitData.length > 0) {
                    extension = splitData[splitData.length - 1];
                    newFileName = setFileNameDataCheck(
                      extension,
                      dataCheck,
                      this.currentTableToChange,
                      undefined,
                      ImagesRes[ir].dataName +
                        (currentLanguage.data !== ""
                          ? currentLanguage.data
                          : "")
                    );
                  }
                  if (newFileName !== "") {
                    currentChildrenFile.name = newFileName;
                  } else {
                    currentChildrenFile.name =
                      setURLType(splitData[0]) + "." + extension;
                  }
                  filesToAdd.push(currentChildrenFile);
                }
              }
            } else if (dataCheck.dataType === "object") {
              const filesDataNames = checkObjectFiles(dataCheck.dataObject);
              const currentChildrenFile =
                finalObjectSave[dataCheck.dataName + currentLanguage.data];

              for (let dn = 0; dn < filesDataNames.length; dn++) {
                const currentDataName = filesDataNames[dn];
                const fileCheck = currentChildrenFile[currentDataName];
                if (fileCheck.isNewFile === true) {
                  let newFileName = "";
                  const splitData = fileCheck.name.split(".");
                  let extension = "";
                  if (splitData.length > 0) {
                    extension = splitData[splitData.length - 1];
                    newFileName = setFileNameDataCheck(
                      extension,
                      dataCheck,
                      this.currentTableToChange,
                      undefined,
                      currentLanguage.data !== "" ? currentLanguage.data : ""
                    );
                  }
                  if (newFileName !== "") {
                    fileCheck.name = newFileName;
                  } else {
                    fileCheck.name = setURLType(splitData[0]) + "." + extension;
                  }
                  filesToAdd.push(fileCheck);
                }
              }
            } else if (dataCheck.dataType === "array") {
              if (dataCheck.arrayDataType.dataType === "object") {
                const filesDataNames = checkObjectFiles(
                  dataCheck.arrayDataType.dataObject
                );
                for (
                  let a = 0;
                  a <
                  finalObjectSave[dataCheck.dataName + currentLanguage.data]
                    .length;
                  a++
                ) {
                  const currentChildrenArray =
                    finalObjectSave[dataCheck.dataName + currentLanguage.data][
                      a
                    ];

                  for (let dn = 0; dn < filesDataNames.length; dn++) {
                    const currentDataName = filesDataNames[dn];
                    const fileCheck =
                      currentChildrenArray[currentDataName.dataName];
                    if (fileCheck.isNewFile === true) {
                      let newFileName = "";
                      const splitData = fileCheck.name.split(".");
                      let extension = "";
                      if (splitData.length > 0) {
                        extension = splitData[splitData.length - 1];
                        newFileName = setFileNameDataCheck(
                          extension,
                          currentDataName,
                          this.currentTableToChange,
                          a,
                          currentLanguage.data !== ""
                            ? currentLanguage.data
                            : ""
                        );
                      }
                      if (newFileName !== "") {
                        fileCheck.name = newFileName;
                      } else {
                        fileCheck.name =
                          setURLType(splitData[0]) + "." + extension;
                      }
                      filesToAdd.push(fileCheck);
                    }
                  }
                }
              } else if (dataCheck.arrayDataType.dataType === "file") {
                for (
                  let a = 0;
                  a <
                  finalObjectSave[dataCheck.dataName + currentLanguage.data]
                    .length;
                  a++
                ) {
                  const currentChildrenArray =
                    finalObjectSave[dataCheck.dataName + currentLanguage.data][
                      a
                    ];
                  if (currentChildrenArray.isNewFile === true) {
                    let newFileName = "";
                    const splitData = currentChildrenArray.name.split(".");
                    let extension = "";
                    if (splitData.length > 0) {
                      extension = splitData[splitData.length - 1];
                      newFileName = setFileNameDataCheck(
                        extension,
                        dataCheck.arrayDataType,
                        this.currentTableToChange,
                        a,
                        currentLanguage.data !== "" ? currentLanguage.data : ""
                      );
                    }
                    if (newFileName !== "") {
                      currentChildrenArray.name = newFileName;
                    } else {
                      currentChildrenArray.name =
                        setURLType(splitData[0]) + "." + extension;
                    }
                    filesToAdd.push(currentChildrenArray);
                  }
                }
              } else if (dataCheck.arrayDataType.dataType === "image") {
                for (
                  let a = 0;
                  a <
                  finalObjectSave[dataCheck.dataName + currentLanguage.data]
                    .length;
                  a++
                ) {
                  for (let ir = 0; ir < ImagesRes.length; ir++) {
                    const currentChildrenArray =
                      finalObjectSave[
                        dataCheck.dataName + currentLanguage.data
                      ][a][ImagesRes[ir].dataName];
                    systemLog(this, currentChildrenArray);
                    if (
                      currentChildrenArray !== undefined &&
                      currentChildrenArray.isNewFile === true
                    ) {
                      let newFileName = "";
                      const splitData = currentChildrenArray.name.split(".");
                      let extension = "";
                      if (splitData.length > 0) {
                        extension = splitData[splitData.length - 1];
                        newFileName = setFileNameDataCheck(
                          extension,
                          dataCheck.arrayDataType,
                          this.currentTableToChange,
                          a,
                          ImagesRes[ir].dataName +
                            (currentLanguage.data !== ""
                              ? currentLanguage.data
                              : "")
                        );
                      }
                      if (newFileName !== "") {
                        currentChildrenArray.name = newFileName;
                      } else {
                        currentChildrenArray.name =
                          setURLType(splitData[0]) + "." + extension;
                      }
                      filesToAdd.push(currentChildrenArray);
                    }
                  }
                }
              }
            }
          }
        }

        if (
          dataCheck.setBaseData !== undefined &&
          (finalObjectSave[dataCheck.dataName] === undefined ||
            finalObjectSave[dataCheck.dataName] === "")
        ) {
          let value = null;
          if (dataCheck.setBaseData.dataName !== undefined) {
            value = finalObjectSave[dataCheck.setBaseData.dataName];
          } else if (dataCheck.setBaseData.value !== undefined) {
            value = dataCheck.setBaseData.value;
          }
          if (value !== null) {
            if (dataCheck.dataType === "string") {
              value = value.toString();
            }
            finalObjectSave[dataCheck.dataName] = value;
          }
        }
      }

      if (this.props.createDefaultData !== undefined) {
        for (let i = 0; i < this.props.createDefaultData.length; i++) {
          const defaultData = this.props.createDefaultData[i];
          finalObjectSave[defaultData.dataName] = defaultData.value;
        }
      }

      if (this.props.tableParentIdConnect !== undefined) {
        finalObjectSave[
          this.props.tableParentIdConnect
        ] = this.props.tableParentId;
      }

      if (this.props.returnCreateObj !== undefined) {
        this.props.returnCreateObj(this.currentTableToChange);
        if (this.props.clickOnSave !== undefined) {
          this.props.clickOnSave();
        }
        return;
      }

      let isChecked = true;
      if (this.props.checkBeforeSave !== undefined) {
        isChecked = this.props.checkBeforeSave(
          finalObjectSave,
          this.currentTableToChange
        );
      }
      systemLog(this, finalObjectSave);
      systemLog(this, this.currentTableToChange);
      systemLog(this, filesToAdd);
      systemLog(this, relationToAdd);
      if (isChecked) {
        let savedContent = saveContentC + " " + finalTitle + "!";
        const toastSave = toast.info(saveContent + " " + finalTitle, {
          autoClose: false
        });
        this.saveFiles(
          filesToAdd,
          finalTitle,
          savedContent,
          toastSave,
          finalObjectSave,
          relationToAdd,
          childrenToAdd
        );
      }
    }
  }

  saveFiles(
    filesToAdd,
    finalTitle,
    savedContent,
    toastSave,
    finalObjectSave,
    relationToAdd,
    childrenToAdd
  ) {
    if (filesToAdd.length === 0) {
      this.postObject(
        finalTitle,
        savedContent,
        toastSave,
        finalObjectSave,
        relationToAdd,
        childrenToAdd
      );
    } else {
      let currentSavedFiles = 0;
      for (let f = 0; f < filesToAdd.length; f++) {
        const currentFile = filesToAdd[f];
        if (currentFile.fileName !== undefined) {
          this.props.API.UploadFile(
            currentFile.result,
            currentFile.fileName,
            currentFile.name
          )
            .then(response => {
              systemLog(this, response);
              delete currentFile.b64;
              delete currentFile.isNewFile;
              currentFile.fileName = response.name;
              currentFile.preview = response.path;
              currentFile.result = response.path;
              currentFile.resultWEBP = response.pathWEBP;
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.postObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  childrenToAdd
                );
              }
            })
            .catch(error => {
              systemLog(this, error);
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.postObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  childrenToAdd
                );
              }
            });
        } else {
          this.props.API.DoUploadFile(currentFile.result, currentFile.name)
            .then(response => {
              systemLog(this, response);
              delete currentFile.b64;
              delete currentFile.isNewFile;
              currentFile.fileName = response.name;
              currentFile.preview = response.path;
              currentFile.result = response.path;
              currentFile.resultWEBP = response.pathWEBP;
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.postObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  childrenToAdd
                );
              }
            })
            .catch(error => {
              systemLog(this, error);
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.postObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  childrenToAdd
                );
              }
            });
        }
      }
    }
  }

  postObject(
    finalTitle,
    savedContent,
    toastSave,
    finalObjectSave,
    relationToAdd,
    childrenToAdd
  ) {
    this.props.API.PostTo(this.dirToModify, finalObjectSave)
      .then(response => {
        let thereIsError = false;
        if (response.error !== undefined) {
          savedContent = saveContentB + " " + finalTitle;
          thereIsError = true;
        }
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: thereIsError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
          });
        } else {
          if (thereIsError) {
            toast.error(savedContent);
          } else {
            toast.success(savedContent);
          }
        }
        if (thereIsError === false) {
          this.checkRelationToAdd(response, relationToAdd, childrenToAdd);
        }
      })
      .catch(error => {
        savedContent = saveContentB + " " + finalTitle;
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: toast.TYPE.ERROR
          });
        } else {
          toast.error(savedContent);
        }
      });
  }

  checkRelationToAdd(responseCreateItem, relationToAdd, childrenToAdd) {
    systemLog(this, childrenToAdd);
    if (relationToAdd.length === 0) {
      this.checkChildrenToAdd(responseCreateItem, childrenToAdd);
    } else {
      let relationCount = 0;
      for (let i = 0; i < relationToAdd.length; i++) {
        const currentRel = relationToAdd[i];
        this.props.API.SetRelation(
          this.dirToModify,
          responseCreateItem.id,
          currentRel.route,
          currentRel.id
        )
          .then(response => {
            systemLog(this, response);
            relationCount = relationCount + 1;
            if (relationCount >= relationToAdd.length) {
              this.checkChildrenToAdd(responseCreateItem, childrenToAdd);
            }
          })
          .catch(error => {
            systemLog(this, error);
            relationCount = relationCount + 1;
            if (relationCount >= relationToAdd.length) {
              this.checkChildrenToAdd(responseCreateItem, childrenToAdd);
            }
          });
      }
    }
  }

  checkChildrenToAdd(responseCreateItem, childrenToAdd) {
    if (childrenToAdd.length === 0) {
      this.returnSaved(responseCreateItem);
    } else {
      let childrenCount = 0;
      for (let i = 0; i < childrenToAdd.length; i++) {
        const currentChild = { ...childrenToAdd[i] };
        const addMeTo = childrenToAdd[i].addMeTo;
        delete currentChild.deleteMe;
        delete currentChild.tableSysName;
        delete currentChild.parentDataName;
        delete currentChild.addMeTo;
        delete currentChild.id;
        currentChild[childrenToAdd[i].parentDataName] = responseCreateItem.id;

        if (childrenToAdd[i].id !== undefined) {
          this.props.API.PatchTo(
            childrenToAdd[i].tableSysName,
            childrenToAdd[i].id,
            currentChild
          )
            .then(response => {
              if (addMeTo !== undefined) {
                for (let amt = 0; amt < addMeTo.length; amt++) {
                  const currentAdd = addMeTo[amt];
                  const patchToAdd = {};
                  patchToAdd[currentAdd.dataName] = response.id;
                  this.props.API.PatchTo(
                    currentAdd.tableSysName,
                    currentChild[currentAdd.dataNameId],
                    patchToAdd
                  );
                }
              }
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseCreateItem);
              }
            })
            .catch(error => {
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseCreateItem);
              }
            });
        } else {
          this.props.API.PostTo(childrenToAdd[i].tableSysName, currentChild)
            .then(response => {
              if (addMeTo !== undefined) {
                for (let amt = 0; amt < addMeTo.length; amt++) {
                  const currentAdd = addMeTo[amt];
                  const patchToAdd = {};
                  patchToAdd[currentAdd.dataName] = response.id;
                  this.props.API.PatchTo(
                    currentAdd.tableSysName,
                    currentChild[currentAdd.dataNameId],
                    patchToAdd
                  );
                }
              }
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseCreateItem);
              }
            })
            .catch(error => {
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseCreateItem);
              }
            });
        }
      }
    }
  }

  returnSaved(responseCreateItem) {
    if (this.props.customAfterCreate !== undefined) {
      this.props.customAfterCreate(
        responseCreateItem,
        this.currentTableToChange
      );
    }

    if (this.props.clickOnSave !== undefined) {
      this.props.clickOnSave();
    }
  }

  renderTopLanguages() {
    if (this.canShowLanguage === false || MultipleLanguages === false) {
      return <div />;
    } else {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-end",
            marginRight: adminSeparation,
            padding: "0.7rem",
            height: "100%",
            width: "10rem",
            border: "1px solid " + colors.borderColor
          }}
        >
          <Dropdown
            fluid
            search
            onChange={(event, changedData) => {
              const checkIdiom = changedData.value;
              const languageCheck = this.props.languages.find(language => {
                return language.value === checkIdiom;
              });
              this.setState({
                currentLanguageId: checkIdiom,
                currentLanguage: languageCheck.data
              });
              this.forceUpdate();
            }}
            value={this.state.currentLanguageId}
            placeholder={"Idioma"}
            options={this.props.languages}
          />
        </div>
      );
    }
  }

  renderInsideData() {
    if (this.props.customInsideRender !== undefined) {
      return this.props.customInsideRender(
        this.currentDataVisual,
        this.state.currentLanguage,
        this.currentTableToChange,
        this.props.formatter,
        () => {
          this.forceUpdate();
        }
      );
    } else {
      return this.currentDataVisual.map((data, index) => {
        let canShowItem = true;
        if (data.onlyShowIf !== undefined) {
          canShowItem = checkIfCanShow(
            data,
            this.currentTableToChange,
            this.currentDataVisual
          );
        }
        if (data.dontShow === true) {
          canShowItem = false;
        }

        let renderCustom = false;
        if (this.props.customRenderData !== undefined) {
          if (this.props.customRenderData[data.dataName] !== undefined) {
            renderCustom = true;
          }
        }

        if (data.canChangeLanguage === true) {
          data.dataName = data.mainDataName + this.state.currentLanguage;
        }
        if (canShowItem === true) {
          if (renderCustom === true) {
            return (
              <div key={index}>
                <div style={{ ...columnEditStyle }}>
                  {this.props.customRenderData[data.dataName](
                    data,
                    this.currentTableToChange,
                    this.props.formatter,
                    () => {
                      this.forceUpdate();
                    }
                  )}
                </div>
                <div style={{ height: "1rem" }} />
              </div>
            );
          } else {
            return (
              <div key={index}>
                <div style={{ ...columnEditStyle }}>
                  {renderLabel(data.dataLabel, data.notRequired)}
                  {renderModifyData(
                    data,
                    this.currentTableToChange,
                    this.props.formatter,
                    () => {
                      this.forceUpdate();
                    }
                  )}
                </div>
                <div style={{ height: "1rem" }} />
              </div>
            );
          }
        } else {
          return <div key={index} />;
        }
      });
    }
  }

  render() {
    const canSave = true;
    return (
      <StickyContainer>
        <Sticky topOffset={-50}>
          {({
            style,

            // the following are also available but unused in this example
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight
          }) => (
            <header
              style={{
                ...style,
                backgroundColor: "#FFFFFF",
                zIndex: 100,
                marginTop: isSticky === true ? 60 : 0
              }}
            >
              {renderTopName(
                "Crear " + this.props.singleName.toLowerCase(),
                this.renderTopLanguages
              )}
            </header>
          )}
        </Sticky>
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.renderInsideData()}
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              width: "100%",
              height: "4rem"
            }}
          >
            <Button
              onClick={() => {
                if (canSave) {
                  this.saveItem();
                }
              }}
              style={{
                backgroundColor: canSave
                  ? colors.buttonColor
                  : colors.disableColor,
                color: colors.colorFrontButton,
                ...styleCenteredObject,
                width: "8rem",
                height: "3rem"
              }}
            >
              {"GUARDAR"}
            </Button>
          </div>
          <div style={{ height: "1rem" }} />
        </div>
      </StickyContainer>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, languages } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API, languages, data };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(AdminCreateItem)
);
