import React, { Component } from "react";
import { colors, elevationCustom } from "../style";
import CenteredContent from "./CenteredContent";

class AdminContentView extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
  }

  componentDidMount() {}

  render() {
    return (
      <CenteredContent>
        <div
          style={{
            backgroundColor: colors.adminTableBackgroundColor,
            ...elevationCustom("10px", "20px", "rgba(0,0,0,0.04)"),
            width: "100%",
            marginBottom: "2rem"
          }}
        >
          {this.props.children}
        </div>
      </CenteredContent>
    );
  }
}

export default AdminContentView;
