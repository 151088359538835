export const tableEditItemData = {
  dataTitle: [
    {
      dataName: "namePublic",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "organizationId",
      dataLabel: "Organización",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "organizations",
        key: "id",
        value: "id",
        text: "name"
      },
      dataType: "selector"
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "namePublic",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "description",
      dataLabel: "Descripcion",
      dataType: "string"
    },
    {
      dataName: "value",
      dataLabel: "Importancia (menor es mas importante)",
      minValue: 2,
      dataType: "number"
    },
    {
      dataName: "perm",
      dataType: "string",
      canAdd: false,
      notRequired: true
    }
  ]
};

export const tableCreateItemData = {
  dataTitle: [
    {
      dataName: "namePublic",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "organizationId",
      dataLabel: "Organización",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "organizations",
        key: "id",
        value: "id",
        text: "name"
      },
      dataType: "selector"
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "namePublic",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "description",
      dataLabel: "Descripcion",
      dataType: "string"
    },
    {
      dataName: "value",
      dataLabel: "Importancia (menor es mas importante)",
      minValue: 2,
      dataType: "number"
    },
    {
      dataName: "perm",
      dataType: "string",
      canAdd: false,
      notRequired: true
    }
  ]
};

export const tableViewItemData = {
  dataTitle: [
    {
      dataName: "namePublic",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "namePublic",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "description",
      dataLabel: "Descripcion",
      dataType: "string"
    },
    {
      dataName: "value",
      dataLabel: "Importancia (menor es mas importante)",
      dataType: "string"
    },
    {
      dataName: "organization",
      dataType: "insideModule",
      dataLabel: "Organización",
      dataModule: {
        dataName: "name",
        dataLabel: "Organizacion",
        dataType: "string"
      }
    }
  ]
};

export const tableData = [
  {
    dataName: "namePublic",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "description",
    dataLabel: "Descripcion",
    dataType: "string"
  },
  {
    dataName: "value",
    dataLabel: "Importancia",
    dataType: "string"
  },
  {
    dataName: "organization",
    dataType: "insideModule",
    dataLabel: "Organizacion",
    dataModule: {
      dataName: "name",
      dataType: "string"
    }
  }
];

export const filterTable = [
  {
    dataName: "namePublic",
    dataType: "string"
  },
  {
    dataName: "description",
    dataType: "string"
  },
  {
    dataName: "organization",
    dataType: "insideModule",
    dataModule: {
      dataName: "name",
      dataType: "string"
    }
  }
];
