import { setURLType } from "../../scripts/General";

export const basePageInfo = {
  tableSingleName: "Servicio",
  tablePluralName: "Servicios",
  tablePerm: "Web",
  tableSysName: "Services",
  reducerDataName: "services"
};

const dataTitle = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

export const checkBeforeSave = (finalObjectSave, currentTableToChange) => {
  finalObjectSave.url = setURLType(finalObjectSave.name);
  return true;
};

const dataVisualBelongs = [
  {
    dataName: "organizationId",
    dataLabel: "Organizacion",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "organizations",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  }
];

const dataVisualBelongsView = [];

const baseImageNameSet = [
  {
    from: "text",
    value: "servicios"
  },
  {
    from: "object",
    key: "name"
  }
];

const dataVisualNecesary = [
  {
    dataName: "name",
    dataLabel: "Nombre Privado",
    dataType: "string"
  },
  {
    dataName: "smallTitle",
    dataLabel: "Titulo pequeño",
    dataType: "string",
    canChangeLanguage: true
  },
  {
    dataName: "smallDesc",
    dataLabel: "Descripcion Corta",
    dataType: "string",
    canChangeLanguage: true
  },
  {
    dataName: "thumbnail",
    dataLabel: "Miniatura",
    dataType: "image",
    dataImageNameSet: {
      separationText: "_",
      nameSet: [
        ...baseImageNameSet,
        {
          from: "text",
          value: "thumbnail"
        }
      ]
    }
  },
  {
    dataName: "title",
    dataLabel: "Titulo",
    dataType: "string",
    canChangeLanguage: true
  },
  {
    dataName: "subtitle",
    dataLabel: "Subtitulo",
    dataType: "string",
    canChangeLanguage: true
  },
  {
    dataName: "feature",
    dataLabel: "Caracteristicas",
    dataType: "array",
    arrayFileAdd: "Caracteristica",
    arrayDataType: {
      dataLabel: "Caracteristica",
      dataType: "object",
      dataObject: [
        {
          dataName: "title",
          dataLabel: "Titulo",
          dataType: "string"
        },
        {
          dataName: "body",
          dataLabel: "Cuerpo",
          dataType: "string"
        }
      ]
    },
    canChangeLanguage: true
  },
  {
    dataName: "body",
    dataLabel: "Cuerpo",
    dataType: "richText",
    canChangeLanguage: true
  },
  {
    dataName: "images",
    dataLabel: "Imagenes",
    dataType: "array",
    limitArray: 3,
    arrayFileAdd: "Imagen",
    arrayDataType: {
      dataLabel: "Imagen",
      dataType: "image",
      dataImageNameSet: {
        separationText: "_",
        nameSet: [
          ...baseImageNameSet,
          {
            from: "text",
            value: "thumbnail"
          }
        ]
      }
    }
  },
  {
    dataName: "featured",
    dataLabel: "Destacado",
    dataType: "bool"
  }
];

export const tableCreateItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongs, ...dataVisualNecesary]
};

export const tableEditItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongs, ...dataVisualNecesary]
};

export const tableViewItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongsView, ...dataVisualNecesary]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "featured",
    dataLabel: "Destacado",
    dataType: "bool"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string",
    dataFilterType: "like"
  },
  {
    dataName: "featured",
    dataLabel: "Destacado",
    dataType: "bool"
  }
];
