import React, { Component } from "react";
import { Motion, spring } from "react-motion";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { Dimmer, Loader, Responsive } from "semantic-ui-react";
import MainHeader from "./components/MainHeader";
import MainSideBar from "./components/MainSideBar";
import ModalResetPassword from "./layaouts/ModalResetPassword";
import { StickyContainer, Sticky } from "react-sticky";
import { fullRutes } from "./routes";
import {
  adminMargin,
  colors,
  headerHeight,
  menuWidth,
  sideShow,
  styleBasic
} from "./style/index";
import { minWidthView } from "./MainInfoData";

class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleMobileMenu: false
    };
    this.renderMotionMovil = this.renderMotionMovil.bind(this);
    this.renderMotionDesktop = this.renderMotionDesktop.bind(this);
    this.renderRoutes = this.renderRoutes.bind(this);
    this.navRender = this.navRender.bind(this);
  }

  handleContextRef = contextRef => this.setState({ contextRef });

  renderRoutes() {
    return (
      <Switch>
        {fullRutes(this.props.user).map((routeData, index) => {
          const Page = routeData.page;
          return (
            <Route key={index} exact path={routeData.route}>
              <Page routeData={routeData} />
            </Route>
          );
        })}
      </Switch>
    );
  }

  navRender() {
    return (
      <div style={{ width: "100%" }}>
        <Responsive {...Responsive.onlyMobile}>
          <div
            style={{
              ...styleBasic,
              minWidth: minWidthView,
              marginTop: "0.5rem",
              marginLeft: adminMargin,
              marginRight: adminMargin,
              overflow: "scroll"
            }}
          >
            {this.renderRoutes()}
          </div>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <div
            style={{
              ...styleBasic,
              minWidth: minWidthView,
              marginTop: "0.5rem",
              marginLeft: adminMargin,
              marginRight: adminMargin
            }}
          >
            {this.renderRoutes()}
          </div>
        </Responsive>
      </div>
    );
  }

  renderMotionDesktop() {
    let currentSideShow = sideShow;
    let currentMenuWidth = menuWidth - 6;
    if (this.props.verticalMenu === false) {
      currentSideShow = 0;
      currentMenuWidth = 0;
    }
    return (
      <StickyContainer
        style={{
          zIndex: 200,
          width: "100%",
          backgroundColor: colors.adminBackgroundColor
        }}
      >
        <Sticky>
          {({
            style,

            // the following are also available but unused in this example
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight
          }) => (
            <header style={{ ...style, zIndex: 200 }}>
              <MainHeader
                contextRef={this.state.contextRef}
                currentPath={this.props.location.pathname}
                visibleMobileMenu={this.state.visibleMobileMenu}
                onClickItem={() => {
                  if (this.state.visibleMobileMenu === false) {
                    this.setState({
                      visibleMobileMenu: true
                    });
                  }
                }}
                closeMenu={() => {
                  this.setState({
                    visibleMobileMenu: false
                  });
                }}
                onClickMenuSideBar={() => {
                  window.scrollTo(0, 0);
                  this.setState({
                    visibleMobileMenu: !this.state.visibleMobileMenu
                  });
                }}
              />
            </header>
          )}
        </Sticky>
        <div
          style={{
            zIndex: -10,
            display: "flex",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            alignSelf: "flex-start",
            alignItems: "flex-start",
            minHeight: window.innerHeight
          }}
        >
          {this.navRender()}
        </div>
      </StickyContainer>
    );
  }

  renderMotionMovil() {
    return (
      <Motion
        style={{
          y: spring(this.state.visibleMobileMenu ? window.innerHeight : 0)
        }}
      >
        {({ y }) => {
          return (
            <div
              style={{
                marginTop: y,
                width: window.innerWidth,
                backgroundColor: colors.adminBackgroundColor
              }}
            >
              <MainHeader
                contextRef={this.state.contextRef}
                currentPath={this.props.location.pathname}
                visibleMobileMenu={this.state.visibleMobileMenu}
                onClickItem={() => {
                  if (this.state.visibleMobileMenu === false) {
                    this.setState({
                      visibleMobileMenu: true
                    });
                  }
                }}
                closeMenu={() => {
                  this.setState({
                    visibleMobileMenu: false
                  });
                }}
                onClickMenuSideBar={() => {
                  window.scrollTo(0, 0);
                  this.setState({
                    visibleMobileMenu: !this.state.visibleMobileMenu
                  });
                }}
              />
              <div
                id="MAIN_SCREN_DIV"
                style={{
                  height: window.innerHeight - headerHeight,
                  overflow: "scroll"
                }}
              >
                {this.navRender()}
              </div>
            </div>
          );
        }}
      </Motion>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "flex-end",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          alignItems: "flex-end",
          width: "100%",
          overflow: "scroll"
        }}
        ref={this.handleContextRef}
      >
        <Dimmer active={this.props.onLoading}>
          <Loader active={this.props.onLoading} />
        </Dimmer>
        <MainSideBar
          contextRef={this.state.contextRef}
          currentPath={this.props.location.pathname}
          visibleMobileMenu={this.state.visibleMobileMenu}
          onClickItem={() => {
            if (this.state.visibleMobileMenu === false) {
              this.setState({
                visibleMobileMenu: true
              });
            }
          }}
          closeMenu={() => {
            this.setState({
              visibleMobileMenu: false
            });
          }}
        />
        <Responsive
          style={{
            zIndex: 200
          }}
          {...Responsive.onlyMobile}
        >
          {this.renderMotionMovil()}
        </Responsive>
        <Responsive
          style={{
            width: "100%",
            zIndex: 200
          }}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          {this.renderMotionDesktop()}
        </Responsive>
        <ModalResetPassword />
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { onLoading } = api;
  const { user, verticalMenu } = data;
  return { onLoading, user, verticalMenu };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Navigator)
);
