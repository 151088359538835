// Import package from node modules
import * as types from "../types";
import API from "../../API/API";
import { systemLog } from "../../scripts/General";
let dispatch = null;
let api = new API();

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

export const setDispatch = dis => {
  dispatch = dis;
  return {
    type: "none"
  };
};

const errorGetApi = () => {
  return {
    type: types.RESETALL
  };
};

export const setAuthapiToken = token => {
  systemLog("ApiActions", token);
  api = new API(token);
  return () => {
    api.GetGodOrganization().then(responseGOD => {
      if (responseGOD.error === undefined && responseGOD.length > 0) {
        api.GetAdminOrganization().then(responseAdmin => {
          if (responseAdmin.error === undefined && responseAdmin.length > 0) {
            api = new API(token, responseGOD[0].id, responseAdmin[0].id);
            dispatch(setApi());
          } else {
            dispatch(errorGetApi());
          }
        });
      } else {
        dispatch(errorGetApi());
      }
    });
  };
};

export const setApi = () => {
  return {
    type: types.SET_API,
    api: api
  };
};

export const setPriceFormartter = () => {
  return {
    type: types.SET_PRICE_FORMARTEER,
    formatter: formatter
  };
};

export const openFrontLoading = show => {
  if (show) {
    return {
      type: types.OPEN_FRONTLOADING
    };
  } else {
    return {
      type: types.CLOSE_FRONTLOADING
    };
  }
};

export const doLogOut = () => {
  api.Logout();
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("id");
  return {
    type: types.LOGOUT
  };
};

export const resetAll = () => {
  api = new API();
  return {
    type: types.RESETALL,
    api: api,
    formatter: formatter
  };
};
