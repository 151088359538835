import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Icon, Dropdown, Grid } from "semantic-ui-react";
import SearchBar from "../components/SearchBar";
import { TableInFilter, renderData } from "../scripts/System";
import ModularText from "../components/ModularText";
import { StickyContainer, Sticky } from "react-sticky";
import {
  adminSeparation,
  colors,
  elevationCustom,
  styleCenteredObject,
  subHeaderAdminHeight,
  textSizePTitle,
  textSizeSubHeaderTitle
} from "../style";
import CenteredContent from "./CenteredContent";
import { systemLog } from "../scripts/General";

const columnMainStyle = {
  ...styleCenteredObject,
  margin: 0,
  padding: 0,
  justifyContent: "flex-start",
  height: "1.2rem",
  marginLeft: "0.5rem"
};

const columnSize = "1rem";
const columnStyle = {
  ...styleCenteredObject,
  margin: 0,
  padding: 0,
  justifyContent: "flex-start",
  minHeight: columnSize,
  marginLeft: "0.5rem"
};

const savingContent = "SE ESTAN SALVANDO LOS ELEMENTOS";
const savedContentC = "¡SE SALVARON LOS ELEMENTOS!";

const tableGridSize = 93;

class AdminTableComparation extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    this.state = {
      currentFilter: "",
      currentSelector: "",
      currentSelectorData: {},
      selectorData: [],
      readPer: false,
      updatePer: false,
      deletePer: false,
      createPer: false
    };
    this.tableRelation = [];
    this.renderHeader = this.renderHeader.bind(this);
    this.renderHeaderGrid = this.renderHeaderGrid.bind(this);
    this.renderComparator = this.renderComparator.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.renderFilterBar = this.renderFilterBar.bind(this);
    this.renderSaveButton = this.renderSaveButton.bind(this);

    this.dirToModify = this.props.tableSysName;
    if (this.props.tableEditRoute !== undefined) {
      this.dirToModify = this.props.tableEditRoute;
    }

    this.savedData = 0;
  }

  componentWillMount() {
    if (this.props.user.isGod === true) {
      this.setState({
        permissionData: {
          create: true,
          read: true,
          update: true,
          delete: true
        },
        readPer: true,
        updatePer: true,
        deletePer: true,
        createPer: true
      });
    } else {
      let perData = {};
      if (this.props.focePermissionData !== undefined) {
        perData = this.props.focePermissionData;
      } else {
        perData = this.props.userPermissions.find(permission => {
          if (permission.module === undefined) {
            return false;
          }
          return (
            permission.module.name.toUpperCase() ===
            this.props.tablePermName.toUpperCase()
          );
        });
      }
      if (perData !== undefined && perData !== null) {
        this.setState({
          permissionData: perData,
          readPer: perData.read,
          updatePer: perData.update,
          deletePer: perData.delete,
          createPer: perData.create
        });
      }
    }
  }

  componentDidMount() {
    if (this.state.selectorData.length !== this.props.tableSelector.length) {
      this.tableRelation = [];
      if (this.props.useBase !== true) {
        this.tableRelation = this.props.tableRelation;
      }
      const baseToSet = [];
      for (let i = 0; i < this.props.tableSelector.length; i++) {
        let tempBase = {};
        const currentBase = this.props.tableSelector[i];
        tempBase.text = currentBase[this.props.tableSelectorDataName];
        tempBase.key = currentBase.id;
        tempBase.value = currentBase.id;
        tempBase.data = currentBase;
        baseToSet.push(tempBase);
      }

      let selector = baseToSet[0].value;
      let data = baseToSet[0];

      if (this.props.useBase === true) {
        if (this.props.base !== undefined) {
          const dataFind = baseToSet.find(x => {
            return x.value === this.props.base.id;
          });
          if (dataFind !== undefined) {
            selector = dataFind.value;
            data = dataFind.data;
          }
        } else {
          selector = "";
          data = {};
        }
      }

      this.setState({
        currentSelector: selector,
        currentSelectorData: data,
        selectorData: baseToSet
      });
      systemLog(this, baseToSet);
      systemLog(this, this.props.base);
      systemLog(this, selector);
      systemLog(this, this.props.tableComparator);
      systemLog(this, this.props.tableRelation);

      for (let i = 0; i < this.props.tableComparator.length; i++) {
        const table = this.props.tableComparator[i];
        const findTable = this.tableRelation.find(x => {
          return (
            x[this.props.comparatorRelation] === table.id &&
            x[this.props.selectorRelation] === selector
          );
        });
        if (findTable === undefined) {
          let objectToAdd = {
            create: false,
            delete: false,
            read: false,
            update: false
          };
          objectToAdd[this.props.selectorRelation] = selector;
          objectToAdd[this.props.comparatorRelation] = table.id;
          if (selector !== "") {
            const findSelector = this.props.tableRelation.find(x => {
              return (
                x[this.props.comparatorRelation] === table.id &&
                x[this.props.selectorRelation] === selector
              );
            });
            systemLog(this, findSelector);
            if (findSelector !== undefined) {
              objectToAdd = JSON.parse(JSON.stringify(findSelector));
            }
          }
          this.tableRelation.push(objectToAdd);
        }
      }
      if (this.props.returnValue !== undefined) {
        this.props.returnValue(this.tableRelation);
      }
      this.forceUpdate();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectorData.length !== nextProps.tableSelector.length) {
      this.tableRelation = [];
      if (this.tableRelation.length !== nextProps.tableRelation.length) {
        if (nextProps.useBase !== true) {
          this.tableRelation = nextProps.tableRelation;
        }
      }
      const baseToSet = [];
      for (let i = 0; i < nextProps.tableSelector.length; i++) {
        let tempBase = {};
        const currentBase = nextProps.tableSelector[i];
        tempBase.text = currentBase[nextProps.tableSelectorDataName];
        tempBase.key = currentBase.id;
        tempBase.value = currentBase.id;
        tempBase.data = currentBase;
        baseToSet.push(tempBase);
      }

      let selector = baseToSet[0].value;
      let data = baseToSet[0];
      if (nextProps.useBase === true) {
        if (nextProps.base !== undefined) {
          const dataFind = baseToSet.find(x => x.value === nextProps.base.id)
            .data;
          if (dataFind !== undefined) {
            selector = dataFind.value;
            data = dataFind;
          }
        } else {
          selector = "";
          data = {};
        }
      }

      this.setState({
        currentSelector: selector,
        currentSelectorData: data,
        selectorData: baseToSet
      });

      for (let i = 0; i < nextProps.tableComparator.length; i++) {
        const table = nextProps.tableComparator[i];
        const findTable = this.tableRelation.find(x => {
          return (
            x[nextProps.comparatorRelation] === table.id &&
            x[nextProps.selectorRelation] === selector
          );
        });
        if (findTable === undefined) {
          const objectToAdd = {
            create: false,
            delete: false,
            read: false,
            update: false
          };
          objectToAdd[nextProps.selectorRelation] = selector;
          objectToAdd[nextProps.comparatorRelation] = table.id;
          this.tableRelation.push(objectToAdd);
        }
      }
      if (nextProps.returnValue !== undefined) {
        nextProps.returnValue(this.tableRelation);
      }
      this.forceUpdate();
    }

    if (nextProps.user.isGod === true) {
      this.setState({
        permissionData: {
          create: true,
          read: true,
          update: true,
          delete: true
        },
        readPer: true,
        updatePer: true,
        deletePer: true,
        createPer: true
      });
    } else if (this.props.focePermissionData !== nextProps.focePermissionData) {
      let perData = {};
      perData = nextProps.focePermissionData;
      if (perData !== undefined && perData !== null) {
        this.setState({
          permissionData: perData,
          readPer: perData.read,
          updatePer: perData.update,
          deletePer: perData.delete,
          createPer: perData.create
        });
      }
    }
  }

  checkSavedElement(toastSave) {
    this.savedData++;
    if (this.savedData >= this.tableRelation.length) {
      if (toast.isActive(toastSave)) {
        toast.update(toastSave, {
          render: savedContentC,
          autoClose: true,
          type: toast.TYPE.SUCCESS
        });
      } else {
        toast.success(savedContentC);
      }

      if (this.props.clickOnSave !== undefined) {
        this.props.clickOnSave();
      }
    }
  }

  saveItem() {
    this.savedData = 0;
    const toastSave = toast.info(savingContent, {
      autoClose: false
    });

    for (let i = 0; i < this.tableRelation.length; i++) {
      const currentRelation = this.tableRelation[i];
      if (currentRelation.id !== undefined && currentRelation.id !== "") {
        this.props.API.PatchTo(
          this.dirToModify,
          currentRelation.id,
          currentRelation
        )
          .then(response => {
            this.checkSavedElement(toastSave);
          })
          .catch(error => {
            this.checkSavedElement(toastSave);
          });
      } else {
        this.props.API.PostTo(this.dirToModify, currentRelation)
          .then(response => {
            this.checkSavedElement(toastSave);
          })
          .catch(error => {
            this.checkSavedElement(toastSave);
          });
      }
    }
  }

  renderHeader() {
    return (
      <div
        style={{
          width: "100%",
          height: subHeaderAdminHeight,
          backgroundColor: colors.colorAdminSubHeader,
          paddingTop: adminSeparation,
          paddingBottom: adminSeparation,
          borderBottom: "2px solid " + colors.borderColor,
          ...styleCenteredObject,
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-start",
            textAlign: "left",
            flexDirection: "column",
            paddingLeft: adminSeparation
          }}
        >
          <ModularText
            containerStyle={{
              ...styleCenteredObject,
              alignSelf: "flex-start",
              textAlign: "left"
            }}
            style={{
              textAlign: "left",
              color: colors.textColorAdminSubHeader,
              fontSize: textSizeSubHeaderTitle
            }}
          >
            {this.props.tableName}
          </ModularText>
          <div style={{ height: "0.4rem" }} />
          <ModularText
            containerStyle={{
              ...styleCenteredObject,
              alignSelf: "flex-start",
              textAlign: "left"
            }}
            style={{
              textAlign: "left",
              fontWeight: "normal",
              color: colors.textColorAdminSubHeader,
              fontSize: textSizePTitle
            }}
          >
            {this.props.tableSubtitle}
          </ModularText>
        </div>
      </div>
    );
  }
  renderFilterBar() {
    if (this.props.useBase === true) {
      return <div />;
    }
    return (
      <div>
        <div style={{ height: "1rem" }} />
        <div
          style={{
            width: "100%",
            height: "3rem",
            backgroundColor: colors.colorAdminSubHeader,
            ...styleCenteredObject,
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              ...styleCenteredObject,
              marginLeft: adminSeparation,
              paddingLeft: "0.7rem",
              width: "30%",
              borderBottom: "2px solid " + colors.borderColor
            }}
          >
            <SearchBar
              onChange={data => {
                this.setState({ currentFilter: data });
              }}
              leftPadding={6}
              style={{ backgroundColor: "transparent" }}
              placeholder={"Filtro"}
              showSearch={false}
            />
          </div>
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              marginRight: adminSeparation,
              paddingLeft: "0.7rem",
              paddingRight: "0.7rem",
              width: "30%",
              height: 50,
              border: "1px solid " + colors.borderColor
            }}
          >
            <Dropdown
              fluid
              search
              onChange={(event, changedData) => {
                const data = this.state.selectorData.find(
                  x => x.value === changedData.value
                ).data;
                this.setState({
                  currentSelector: changedData.value,
                  currentSelectorData: data
                });
              }}
              defaultValue={this.state.currentSelector}
              placeholder={
                this.props.tableSelectorPlaceholder || "Selecciona un dato"
              }
              options={this.state.selectorData}
            />
          </div>
        </div>
        <div style={{ height: "1.6rem" }} />
      </div>
    );
  }

  renderHeaderGrid() {
    let allSelected = true;
    for (let i = 0; i < this.props.tableRelation.length; i++) {
      const currentTable = this.props.tableRelation[i];
      let canBeSelected = true;
      if (this.props.specialPermissions !== undefined) {
        const isOnSpecialTable = this.props.specialPermissions.find(
          tempTable => currentTable.id === tempTable.id
        );
        if (isOnSpecialTable !== undefined && isOnSpecialTable !== null) {
          if (isOnSpecialTable.canBeSelected !== undefined) {
            canBeSelected = isOnSpecialTable.canBeSelected;
          }
        }
      }
      if (
        canBeSelected === true &&
        (currentTable.selected === undefined || currentTable.selected === false)
      ) {
        allSelected = false;
        break;
      }
    }
    return (
      <div
        style={{
          width: "100%",
          ...styleCenteredObject,
          justifyContent: "flex-start"
        }}
      >
        <div style={{ width: tableGridSize.toString() + "%" }}>
          <Grid
            style={{
              margin: 0,
              padding: 0,
              paddingTop: "1rem",
              paddingBottom: "1rem"
            }}
            columns="equal"
          >
            <Grid.Column style={columnMainStyle}>
              <div
                style={{
                  width: "100%",
                  overflowX: "hidden",
                  textAlign: "left",
                  fontSize: textSizePTitle
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: textSizePTitle
                  }}
                >
                  {this.props.tableComparatorDataName.dataLabel}
                </p>
              </div>
            </Grid.Column>
            {this.props.tableData.map((data, index) => {
              return (
                <Grid.Column
                  key={index}
                  width={data.columnSize}
                  style={columnMainStyle}
                >
                  <div
                    style={{
                      width: "100%",
                      overflowX: "hidden",
                      textAlign: "left",
                      fontSize: textSizePTitle
                    }}
                  >
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: textSizePTitle
                      }}
                    >
                      {data.dataLabel}
                    </p>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  }

  renderComparator() {
    if (
      this.props.tableComparator !== undefined &&
      (this.state.currentSelector !== "" || this.props.useBase === true)
    ) {
      return (
        <div>
          {this.props.tableComparator.map((table, indexT) => {
            let styleExtraGrid = {
              backgroundColor: colors.adminTableItemMainColor,
              borderTop: "2px solid " + colors.adminTableItemBorderColor,
              borderBottom: "2px solid " + colors.adminTableItemBorderColor
            };
            if ((indexT + 1) % 2 === 0) {
              styleExtraGrid = {
                backgroundColor: colors.adminTableItemSecondaryColor
              };
            }
            let tempTable = {};
            const findTable = this.tableRelation.find(x => {
              return (
                x[this.props.comparatorRelation] === table.id &&
                x[this.props.selectorRelation] === this.state.currentSelector
              );
            });
            if (findTable === undefined) {
              const objectToAdd = {
                create: false,
                delete: false,
                read: false,
                update: false
              };
              objectToAdd[
                this.props.selectorRelation
              ] = this.state.currentSelector;
              objectToAdd[this.props.comparatorRelation] = table.id;

              this.tableRelation.push(objectToAdd);
            } else {
              tempTable = findTable;
            }
            const canBerender = TableInFilter(
              table,
              this.props.filterTable,
              this.state.currentFilter,
              this.props.filterAllThatApply
            );
            if (canBerender === false) {
              return <div key={table.id} />;
            }
            return (
              <div
                key={indexT}
                style={{
                  width: "100%",
                  ...styleCenteredObject,
                  ...styleExtraGrid,
                  justifyContent: "flex-start"
                }}
              >
                <div style={{ width: tableGridSize.toString() + "%" }}>
                  <Grid
                    style={{
                      width: "100%",
                      margin: 0,
                      padding: 0,
                      paddingTop: "1rem",
                      paddingBottom: "1rem"
                    }}
                    columns="equal"
                  >
                    <Grid.Column style={columnMainStyle}>
                      <div
                        style={{
                          width: "100%",
                          overflowX: "hidden",
                          textAlign: "left",
                          fontSize: textSizePTitle
                        }}
                      >
                        <p
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: textSizePTitle
                          }}
                        >
                          {table[this.props.tableComparatorDataName.dataName]}
                        </p>
                      </div>
                    </Grid.Column>
                    {this.props.tableData.map((data, indexData) => {
                      let canSave = true;
                      let findCompare = {};
                      if (this.props.useBase === true) {
                        findCompare = this.props.tableRelation.find(data => {
                          return (
                            data[this.props.selectorRelation] ===
                              this.props.compareWith.id &&
                            data[this.props.comparatorRelation] ===
                              tempTable[this.props.comparatorRelation]
                          );
                        });
                      } else {
                        findCompare = this.tableRelation.find(data => {
                          return (
                            data[this.props.selectorRelation] ===
                              this.props.compareWith.id &&
                            data[this.props.comparatorRelation] ===
                              tempTable[this.props.comparatorRelation]
                          );
                        });
                      }

                      if (findCompare !== undefined) {
                        if (findCompare[data.dataName] !== true) {
                          canSave = false;
                        }
                      }

                      if (canSave === false) {
                        return (
                          <Grid.Column
                            key={indexData}
                            width={data.columnSize}
                            style={columnStyle}
                          >
                            <div
                              style={{
                                wordBreak: "break-all",
                                wordWrap: "break-word",
                                width: "100%"
                              }}
                            >
                              <Button
                                onClick={() => {}}
                                style={{
                                  ...styleCenteredObject,
                                  cursor: "default",
                                  justifyContent: "flex-start",
                                  marginLeft: "0.4rem",
                                  color: colors.disableColor,
                                  backgroundColor: "transparent"
                                }}
                              >
                                <Icon
                                  style={{
                                    color: colors.disableColor,
                                    fontSize: "1.5rem",
                                    textAlign: "center"
                                  }}
                                  name={"square outline"}
                                />
                              </Button>
                            </div>
                          </Grid.Column>
                        );
                      }

                      return (
                        <Grid.Column
                          key={indexData}
                          width={data.columnSize}
                          style={columnStyle}
                        >
                          <div
                            style={{
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                              width: "100%"
                            }}
                          >
                            {renderData(
                              data,
                              tempTable,
                              this.state.updatePer,
                              this.props.formatter,
                              (patchData, dataName, dataChange) => {
                                if (canSave === true) {
                                  tempTable[dataName] = dataChange;
                                }
                                this.forceUpdate();
                                if (this.props.returnValue !== undefined) {
                                  this.props.returnValue(this.tableRelation);
                                }
                              }
                            )}
                          </div>
                        </Grid.Column>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderSaveButton() {
    if (this.props.returnValue !== undefined) {
      return <div />;
    } else {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-end",
            width: "100%",
            height: "4rem"
          }}
        >
          <Button
            onClick={() => {
              if (this.state.updatePer === true) {
                this.saveItem();
              }
            }}
            style={{
              backgroundColor: this.state.updatePer
                ? colors.buttonColor
                : colors.disableColor,
              color: colors.colorFrontButton,
              ...styleCenteredObject,
              width: "8rem",
              height: "3rem"
            }}
          >
            {"GUARDAR"}
          </Button>
        </div>
      );
    }
  }

  render() {
    if (this.state.readPer === false) {
      return <div />;
    }
    return (
      <StickyContainer style={{ width: "100%" }}>
        <CenteredContent>
          <div
            style={{
              backgroundColor: colors.adminTableBackgroundColor,
              ...elevationCustom("10px", "20px", "rgba(0,0,0,0.04)"),
              width: "100%",
              marginBottom: "2rem"
            }}
          >
            {this.renderHeader()}
            <div style={{ width: "100%" }}>
              <Sticky topOffset={-50}>
                {({
                  style,

                  // the following are also available but unused in this example
                  isSticky,
                  wasSticky,
                  distanceFromTop,
                  distanceFromBottom,
                  calculatedHeight
                }) => (
                  <header
                    style={{
                      ...style,
                      zIndex: 100,
                      marginTop: isSticky === true ? 60 : 0
                    }}
                  >
                    {this.renderFilterBar()}
                  </header>
                )}
              </Sticky>
              <div
                style={{
                  paddingLeft: adminSeparation,
                  paddingRight: adminSeparation
                }}
              >
                {this.renderHeaderGrid()}
                {this.renderComparator()}
                {this.renderSaveButton()}
              </div>
            </div>
          </div>
        </CenteredContent>
      </StickyContainer>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, user } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API, user };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(AdminTableComparation)
);
