import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Dropdown } from "semantic-ui-react";
import { setPages, setSection } from "../../redux/actions";
import { colors, styleCenteredObject } from "../../style";
import { systemLog } from "../../scripts/General";

class CreateBaseContentButton extends Component {
  constructor(props) {
    super(props);
    this.table = this.props.table;
    this.tableParentId = this.props.tableParentId;
    this.addOptions = this.addOptions.bind(this);
    this.state = { currentValue: "", currentBase: null, currentOptions: [] };
  }

  componentDidMount() {
    systemLog("CreateBaseContentButton", this.props.table);
    this.addOptions();
  }

  componentWillReceiveProps(nextProps) {
    systemLog("CreateBaseContentButton", nextProps.table);
    this.table = nextProps.table;
    this.tableParentId = nextProps.tableParentId;
    this.addOptions();
  }

  addOptions() {
    if (this.tableParentId !== "") {
      this.props.API.GetSectionBase(this.tableParentId)
        .then(responseSectionBase => {
          if (responseSectionBase.error === undefined) {
            let finalOptions = [];
            for (let i = 0; i < responseSectionBase.length; i++) {
              const currentContent = JSON.parse(
                JSON.stringify(responseSectionBase[i])
              );
              if (currentContent.isBase === true) {
                let finalAdd = {
                  key: currentContent.id,
                  value: currentContent.id,
                  text: currentContent.publicName,
                  data: currentContent
                };
                finalOptions.push(finalAdd);
              }
            }
            let valueSet = "";
            let baseSet = null;
            if (finalOptions.length > 0) {
              valueSet = finalOptions[0].value;
              baseSet = finalOptions[0].data;
            }
            this.setState({
              currentValue: valueSet,
              currentBase: baseSet,
              currentOptions: finalOptions
            });
          } else {
            this.setState({
              currentValue: "",
              currentBase: null,
              currentOptions: []
            });
          }
        })
        .catch(error => {
          this.setState({
            currentValue: "",
            currentBase: null,
            currentOptions: []
          });
        });
    }
  }

  render() {
    if (this.state.currentOptions.length > 0) {
      return (
        <div style={{ ...styleCenteredObject, flexDirection: "row" }}>
          <Button
            onClick={() => {
              if (
                this.props.changeState !== undefined &&
                this.state.currentBase !== null
              ) {
                const value = this.table.filter(x => {
                  return (
                    x.name.includes(this.state.currentBase.name) &&
                    x.isBase !== true
                  );
                }).length;
                const finalBase = this.state.currentBase;
                finalBase.value = value;
                this.props.changeState("customView", finalBase);
                this.setState({ currentValue: "", currentBase: null });
              }
            }}
            style={{
              margin: 0,
              backgroundColor: colors.addColor,
              color: colors.addFrontColor
            }}
            icon="add"
          />
          <Dropdown
            selection
            onChange={(event, changedData) => {
              const changedBase = this.state.currentOptions.find(
                x => x.key === changedData.value
              );
              this.setState({
                currentValue: changedData.value,
                currentBase: changedBase.data
              });
            }}
            value={this.state.currentValue}
            options={this.state.currentOptions}
            placeholder="Conenido Base"
          />
          <div style={{ width: "1rem" }} />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { section, pages, user, roles, organizations } = data;
  return { section, pages, user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setSection: section => {
      dispatch(setSection(section));
    },
    setPages: pages => {
      dispatch(setPages(pages));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateBaseContentButton)
);
