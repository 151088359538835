import * as types from "../types";
import { systemLog } from "../../scripts/General";
import { menuTitle, baseLanguage } from "../../MainInfoData";

const INITIAL_PROJECT_STATE = {
  examples: [],
  emails: [],
  emailData: []
};

const INITIAL_STATE_LANDING = {
  languages: [baseLanguage],
  languagesTable: [],
  pages: [],
  metatags: [],
  navigationPages: [],
  userPagesPermissions: [],
  userPages: [],
  blogTags: [],
  blogCategories: [],
  blogEntries: [],
  page: {},
  section: {},
  leads: [],
  ...INITIAL_PROJECT_STATE
};

const INITIAL_STATE = {
  systemName: "Guaostudio Sistema Administrativo",
  tableShowAction: false,
  verticalMenu: false,
  haveLanding: true,
  organization: {},
  user: {},
  organizations: [],
  roleModules: [],
  modules: [],
  roles: [],
  userPermissions: [],
  users: [],
  ...INITIAL_STATE_LANDING
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESETALL: {
      return { ...state, ...INITIAL_STATE };
    }
    case types.SET_USER_PERMISSIONS: {
      return { ...state, userPermissions: action.userPermissions };
    }
    case types.SET_USER: {
      return { ...state, user: action.user };
    }
    case types.SET_ORGANIZATION: {
      return { ...state, organization: action.organization };
    }
    case types.SET_ORGANIZATIONS: {
      return { ...state, organizations: action.organizations };
    }
    case types.SET_ROLES: {
      return { ...state, roles: action.roles };
    }
    case types.SET_MODULES: {
      return { ...state, modules: action.modules };
    }
    case types.SET_ROLE_MODULES: {
      return { ...state, roleModules: action.roleModules };
    }
    case types.SET_USERS: {
      return { ...state, users: action.users };
    }
    default: {
      return landingData(state, action);
    }
  }
};

const landingData = (state, action) => {
  switch (action.type) {
    case types.SET_LANGUAGES: {
      let newLanguages = action.languages;
      newLanguages.unshift(baseLanguage);
      return { ...state, languages: newLanguages };
    }
    case types.SET_LANGUAGES_TABLE: {
      return { ...state, languagesTable: action.languagesTable };
    }
    case types.SET_PAGES: {
      let currentNavInfo = [];
      for (let i = 0; i < action.pages.length; i++) {
        const currentPage = action.pages[i];
        const findTitle = currentNavInfo.find(navPage => {
          return navPage.name === currentPage.menuTitle;
        });
        if (findTitle === undefined) {
          let findPublicName = menuTitle.find(vnp => {
            return vnp.key === currentPage.menuTitle;
          });
          let newNav = {
            name: currentPage.menuTitle,
            publicName: currentPage.menuTitle,
            pages: [],
            totalPages: []
          };
          if (findPublicName !== undefined) {
            newNav.publicName = findPublicName.text;
          }

          if (currentPage.showOnMenu === true) {
            newNav.pages.push(currentPage);
          }
          newNav.totalPages.push(currentPage);
          currentNavInfo.push(newNav);
        } else {
          const indexOfTitle = currentNavInfo.indexOf(findTitle);
          if (currentPage.showOnMenu === true) {
            currentNavInfo[indexOfTitle].pages.push(currentPage);
          }
          currentNavInfo[indexOfTitle].totalPages.push(currentPage);
        }
      }
      for (let i = 0; i < currentNavInfo.length; i++) {
        currentNavInfo[i].pages.sort((a, b) => {
          return a.order - b.order;
        });
      }
      return { ...state, pages: action.pages, navigationPages: currentNavInfo };
    }
    case types.SET_METATAGS: {
      return { ...state, metatags: action.metatags };
    }
    case types.SET_NAV_PAGES: {
      return { ...state, navigationPages: action.navigationPages };
    }
    case types.SET_USERPAGES: {
      return { ...state, userPages: action.userPages };
    }
    case types.SET_USERPAGES_PERMISSIONS: {
      return { ...state, userPagesPermissions: action.userPagesPermissions };
    }
    case types.SET_CURRENT_PAGE: {
      return { ...state, page: action.page };
    }
    case types.SET_CURRENT_SECTION: {
      return { ...state, section: action.section };
    }
    case types.SET_BLOG_TAGS: {
      return { ...state, blogTags: action.blogTags };
    }
    case types.SET_BLOG_CATEGORIES: {
      return { ...state, blogCategories: action.blogCategories };
    }
    case types.SET_BLOG_ENTRIES: {
      return { ...state, blogEntries: action.blogEntries };
    }
    default: {
      return insideProjectData(state, action);
    }
  }
};

const insideProjectData = (state, action) => {
  switch (action.type) {
    case types.SET_ANY_DATA: {
      const payloadData = action.payload;
      let stateUpdate = {};
      stateUpdate[action.key] = payloadData;
      return { ...state, ...stateUpdate };
    }
    case types.SET_EMAILS: {
      return { ...state, emails: action.emails };
    }
    case types.SET_EMAIL_DATA: {
      return { ...state, emailData: action.emailData };
    }
    case types.SET_CURRENT_LEADS: {
      return { ...state, leads: action.leads };
    }
    default: {
      return state;
    }
  }
};
