import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Dropdown } from "semantic-ui-react";
import { StickyContainer, Sticky } from "react-sticky";
import {
  isEquivalent,
  systemLog,
  validateEmail,
  returnTextFromSelector,
  setFileNameDataCheck,
  setURLType,
  checkObjectFiles
} from "../scripts/General";
import {
  checkIfCanShow,
  columnEditStyle,
  getTitleName,
  renderLabel,
  renderModifyData,
  renderTopName,
  ImagesRes
} from "../scripts/System";
import { adminSeparation, colors, styleCenteredObject } from "../style";
import { MultipleLanguages } from "../MainInfoData";

const saveContent = "SE ESTA MODIFICANDO EL ELEMENTO";
const saveContentC = "¡SE MODIFICO EL ELEMENTO";
const saveContentB = "HUBO UN ERROR AL MODIFICAR EL ELEMENTO";

class AdminEditItem extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");

    let tempLanguage = "";
    let tempLanguageId = "";
    if (this.props.languages.length > 0) {
      tempLanguageId = this.props.languages[0].value;
      tempLanguage = this.props.languages[0].data;
    }
    this.state = {
      currentLanguage: tempLanguage,
      currentLanguageId: tempLanguageId
    };
    this.canShowLanguage = false;
    this.renderTopLanguages = this.renderTopLanguages.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.deleteFiles = this.deleteFiles.bind(this);
    this.saveFiles = this.saveFiles.bind(this);
    this.patchObject = this.patchObject.bind(this);
    this.checkRelationToAdd = this.checkRelationToAdd.bind(this);
    this.checkChildrenToAdd = this.checkChildrenToAdd.bind(this);
    this.dirToModify = this.props.tableSysName;

    if (this.props.tableEditRoute !== undefined) {
      this.dirToModify = this.props.tableEditRoute;
    }

    this.currentDataVisual = [];
    for (let i = 0; i < this.props.tableData.dataVisual.length; i++) {
      let currentData = this.props.tableData.dataVisual[i];
      systemLog(this, currentData);
      let dataToAdd = JSON.parse(JSON.stringify(currentData));
      if (dataToAdd.canChangeLanguage === true) {
        this.canShowLanguage = true;
      }
      this.currentDataVisual.push(dataToAdd);
    }
  }

  componentWillMount() {
    this.currentTableToChange = JSON.parse(JSON.stringify(this.props.table));
    systemLog("OriginalFiles", this.currentTableToChange);
    for (let i = 0; i < this.currentDataVisual.length; i++) {
      let currentData = this.currentDataVisual[i];
      currentData.mainDataName = (
        " " + this.props.tableData.dataVisual[i].dataName
      ).slice(1);
      let dataToCheck = currentData;
      if (this.props.tableParentIdConnect !== undefined) {
        if (currentData.dataName === this.props.tableParentIdConnect) {
          currentData.dontShow = true;
        }
      }
      let isArray = false;
      if (
        currentData.dataType === "array" &&
        this.currentTableToChange[currentData.dataName] !== undefined
      ) {
        systemLog(this, currentData);
        isArray = true;
        dataToCheck = currentData.arrayDataType;
        const tableToGetOriginal = JSON.parse(JSON.stringify(this.props.table));
        for (let l = 0; l < this.props.languages.length; l++) {
          const currentLanguage = this.props.languages[l];
          if (
            this.currentTableToChange[
              currentData.dataName + currentLanguage.data
            ] === undefined
          ) {
            continue;
          }
          if (
            tableToGetOriginal[currentData.dataName + currentLanguage.data] !==
            undefined
          ) {
            if (currentLanguage.data !== "") {
              currentData[
                "originalArray" + currentLanguage.data
              ] = tableToGetOriginal[
                currentData.dataName + currentLanguage.data
              ].slice();
            } else {
              currentData.originalArray = tableToGetOriginal[
                currentData.dataName
              ].slice();
            }
          }
        }
        if (currentData.arrayOrderBy !== undefined) {
          this.currentTableToChange[currentData.dataName].sort((a, b) => {
            return a[currentData.arrayOrderBy] - b[currentData.arrayOrderBy];
          });
        }
      } else if (
        currentData.dataType === "image" &&
        this.currentTableToChange[currentData.dataName] !== undefined
      ) {
        const tableToGetOriginal = JSON.parse(JSON.stringify(this.props.table));
        for (let l = 0; l < this.props.languages.length; l++) {
          const currentLanguage = this.props.languages[l];
          if (
            this.currentTableToChange[
              currentData.dataName + currentLanguage.data
            ] === undefined
          ) {
            continue;
          }
          if (
            tableToGetOriginal[currentData.dataName + currentLanguage.data] !==
            undefined
          ) {
            if (currentLanguage.data !== "") {
              currentData["originalImages" + currentLanguage.data] =
                tableToGetOriginal[currentData.dataName + currentLanguage.data];
            } else {
              currentData.originalImages =
                tableToGetOriginal[currentData.dataName];
            }
          }
        }
      }
      if (dataToCheck.selectorDataFrom !== undefined) {
        if (dataToCheck.selectorDataFrom.dataFrom === "props") {
          const newSelectorData = [];
          const selectorDataToSet = this.props.data[
            dataToCheck.selectorDataFrom.dataName
          ];
          if (dataToCheck.selectorDataFrom.addEmptyValue === true) {
            const dataToAdd = {};
            dataToAdd.key = null;
            dataToAdd.value = null;
            dataToAdd.text =
              dataToCheck.selectorDataFrom.emptyPlaceHolder || "SIN DATO";
            newSelectorData.push(dataToAdd);
          }
          for (let d = 0; d < selectorDataToSet.length; d++) {
            const newData = selectorDataToSet[d];
            const dataToAdd = {};
            let addValue = true;
            if (dataToCheck.selectorDataFrom.notMySelf !== undefined) {
              if (
                newData[dataToCheck.selectorDataFrom.key] ===
                this.currentTableToChange[
                  dataToCheck.selectorDataFrom.notMySelf.dataKey
                ]
              ) {
                addValue = false;
              }
            }
            if (addValue === true) {
              dataToAdd.key = newData[dataToCheck.selectorDataFrom.key];
              dataToAdd.value = newData[dataToCheck.selectorDataFrom.value];
              if (dataToCheck.selectorDataFrom.customText !== undefined) {
                dataToAdd.text = "";
                for (
                  let ct = 0;
                  ct < dataToCheck.selectorDataFrom.customText.length;
                  ct++
                ) {
                  const ctDataName =
                    dataToCheck.selectorDataFrom.customText[ct];
                  dataToAdd.text = dataToAdd.text + newData[ctDataName] + " ";
                }
              } else if (
                dataToCheck.selectorDataFrom.fromSelector !== undefined
              ) {
                dataToAdd.text = returnTextFromSelector(
                  newData[dataToCheck.selectorDataFrom.text],
                  dataToCheck.selectorDataFrom.fromSelector,
                  true
                );
              } else {
                dataToAdd.text = newData[dataToCheck.selectorDataFrom.text];
              }
              dataToAdd.data = newData;
              newSelectorData.push(dataToAdd);
            }
          }
          dataToCheck.selectorData = newSelectorData.slice();
          if (isArray === true) {
            for (let l = 0; l < this.props.languages.length; l++) {
              const currentLanguage = this.props.languages[l];
              if (
                this.currentTableToChange[
                  currentData.dataName + currentLanguage.data
                ] === undefined
              ) {
                continue;
              }
              let newArray = [];
              for (
                let a = 0;
                a < currentData["originalArray" + currentLanguage.data].length;
                a++
              ) {
                const dataInArray =
                  currentData["originalArray" + currentLanguage.data][a];
                newArray.push(dataInArray[dataToCheck.selectorDataFrom.key]);
              }
              this.currentTableToChange[
                currentData.dataName + currentLanguage.data
              ] = newArray;
            }
          }
        }
      }
    }
    systemLog("OriginalFiles", this.currentDataVisual);
  }

  saveItem() {
    let finalTitle = getTitleName(
      this.props.tableData.dataTitle,
      this.currentTableToChange
    );

    let canSave = true;
    let finalObjectSave = {};
    let relationToAdd = [];
    let relationToRemove = [];
    let childrenToAdd = [];
    let childrenToRemove = [];
    let filesToAdd = [];
    let filesToRemove = [];
    for (let i = 0; i < this.currentDataVisual.length; i++) {
      let tempChildrenToRemove = [];
      let isConfirmed = false;
      const dataCheck = this.currentDataVisual[i];
      if (dataCheck.childrenData !== undefined) {
        if (dataCheck.dataType === "array") {
          if (
            dataCheck.arrayDataType.dataType === "object" ||
            dataCheck.arrayDataType.dataType === "selector"
          ) {
            if (dataCheck.originalArray !== undefined) {
              tempChildrenToRemove = dataCheck.originalArray.slice();

              for (let r = 0; r < tempChildrenToRemove.length; r++) {
                if (dataCheck.childrenData.doDeleteItem === true) {
                  tempChildrenToRemove[r].deleteMe = true;
                }
                tempChildrenToRemove[r].tableSysName =
                  dataCheck.childrenData.tableSysName;
                tempChildrenToRemove[r].parentDataName =
                  dataCheck.childrenData.parentDataName;
              }
            }
            for (
              let a = 0;
              a < this.currentTableToChange[dataCheck.dataName].length;
              a++
            ) {
              let currentChildrenArray = this.currentTableToChange[
                dataCheck.dataName
              ][a];
              if (dataCheck.arrayDataType.dataType === "selector") {
                currentChildrenArray = {
                  id: this.currentTableToChange[dataCheck.dataName][a]
                };
              }
              let children = {};
              if (dataCheck.arrayDataType.dataName === undefined) {
                children = {
                  ...currentChildrenArray
                };
              } else {
                children = {
                  ...currentChildrenArray[dataCheck.arrayDataType.dataName]
                };
              }
              if (dataCheck.originalArray !== undefined) {
                if (children.id !== undefined) {
                  const checkIfIsInOriginal = tempChildrenToRemove.find(
                    checkData => {
                      return checkData.id === children.id;
                    }
                  );
                  if (checkIfIsInOriginal !== undefined) {
                    const indexRemove = tempChildrenToRemove.indexOf(
                      checkIfIsInOriginal
                    );
                    if (indexRemove > -1) {
                      tempChildrenToRemove.splice(indexRemove, 1);
                    }
                  }
                }
              }
              children.tableSysName = dataCheck.childrenData.tableSysName;
              children.parentDataName = dataCheck.childrenData.parentDataName;
              if (dataCheck.childrenData.addMeTo !== undefined) {
                children.addMeTo = dataCheck.childrenData.addMeTo;
              }
              childrenToAdd.push(children);
            }
          }
        } else {
          if (dataCheck.dataType === "object") {
            const children = {
              ...this.currentTableToChange[dataCheck.dataName]
            };
            children.tableSysName = dataCheck.childrenData.tableSysName;
            children.parentDataName = dataCheck.childrenData.parentDataName;
            if (dataCheck.childrenData.addMeTo !== undefined) {
              children.addMeTo = dataCheck.childrenData.addMeTo;
            }
            childrenToAdd.push(children);
          }
        }
      } else if (dataCheck.relation !== undefined) {
        relationToRemove.push(dataCheck.relation.relationName);
        if (dataCheck.dataType === "array") {
          for (
            let a = 0;
            a < this.currentTableToChange[dataCheck.dataName].length;
            a++
          ) {
            const currentRelationArray = this.currentTableToChange[
              dataCheck.dataName
            ][a];

            const relation = {};
            relation.route = dataCheck.relation.relationName;
            if (dataCheck.arrayDataType.dataType === "selector") {
              relation.id = currentRelationArray;
            } else {
              relation.id =
                currentRelationArray[dataCheck.arrayDataType.dataName];
            }
            relationToAdd.push(relation);
          }
        } else {
          const relation = {};
          relation.route = dataCheck.relation.relationName;
          relation.id = this.currentTableToChange[dataCheck.dataName];
          relationToAdd.push(relation);
        }
      } else if (
        dataCheck.dataName !== undefined &&
        dataCheck.canAdd !== false &&
        dataCheck.canBeEdited !== false &&
        dataCheck.dataType !== "table"
      ) {
        for (let l = 0; l < this.props.languages.length; l++) {
          const currentLanguage = this.props.languages[l];
          if (
            this.currentTableToChange[
              dataCheck.dataName + currentLanguage.data
            ] === undefined
          ) {
            continue;
          }

          finalObjectSave[
            dataCheck.dataName + currentLanguage.data
          ] = this.currentTableToChange[
            dataCheck.dataName + currentLanguage.data
          ];

          if (dataCheck.dataType === "file") {
            const currentChildrenFile =
              finalObjectSave[dataCheck.dataName + currentLanguage.data];
            if (currentChildrenFile.isNewFile === true) {
              let newFileName = "";
              const splitData = currentChildrenFile.name.split(".");
              let extension = "";
              if (splitData.length > 0) {
                extension = splitData[splitData.length - 1];
                newFileName = setFileNameDataCheck(
                  extension,
                  dataCheck,
                  this.currentTableToChange,
                  undefined,
                  currentLanguage.data !== "" ? currentLanguage.data : ""
                );
              }
              if (newFileName !== "") {
                currentChildrenFile.name = newFileName;
              } else {
                currentChildrenFile.name =
                  setURLType(splitData[0]) + "." + extension;
              }
              filesToAdd.push(currentChildrenFile);
            }
          } else if (dataCheck.dataType === "image") {
            let filesOfImages = [];
            let originalImages = undefined;
            if (currentLanguage.data !== "") {
              originalImages =
                dataCheck["originalImages" + currentLanguage.data];
            } else {
              originalImages = dataCheck.originalImages;
            }
            if (originalImages !== undefined) {
              for (let ir = 0; ir < ImagesRes.length; ir++) {
                const currentOriginal = originalImages[ImagesRes[ir].dataName];
                if (currentOriginal !== undefined) {
                  filesOfImages.push(currentOriginal);
                }
              }
            }
            systemLog(this, filesOfImages);
            filesToRemove = [...filesToRemove, ...filesOfImages.slice()];
            for (let ir = 0; ir < ImagesRes.length; ir++) {
              const currentChildrenFile =
                finalObjectSave[dataCheck.dataName + currentLanguage.data][
                  ImagesRes[ir].dataName
                ];
              if (originalImages !== undefined) {
                if (
                  currentChildrenFile !== undefined &&
                  currentChildrenFile.fileName !== undefined
                ) {
                  const checkIfIsInOriginal = filesToRemove.find(checkData => {
                    return checkData.fileName === currentChildrenFile.fileName;
                  });
                  if (checkIfIsInOriginal !== undefined) {
                    const indexRemove = filesToRemove.indexOf(
                      checkIfIsInOriginal
                    );
                    if (indexRemove > -1) {
                      filesToRemove.splice(indexRemove, 1);
                    }
                  }
                }
              }
              if (
                currentChildrenFile !== undefined &&
                currentChildrenFile.isNewFile === true
              ) {
                let newFileName = "";
                const splitData = currentChildrenFile.name.split(".");
                let extension = "";
                if (splitData.length > 0) {
                  extension = splitData[splitData.length - 1];
                  newFileName = setFileNameDataCheck(
                    extension,
                    dataCheck,
                    this.currentTableToChange,
                    undefined,
                    ImagesRes[ir].dataName +
                      (currentLanguage.data !== "" ? currentLanguage.data : "")
                  );
                }
                if (newFileName !== "") {
                  currentChildrenFile.name = newFileName;
                } else {
                  currentChildrenFile.name =
                    setURLType(splitData[0]) + "." + extension;
                }
                filesToAdd.push(currentChildrenFile);
              }
            }
          } else if (dataCheck.dataType === "object") {
            const filesDataNames = checkObjectFiles(dataCheck.dataObject);
            const currentChildrenFile =
              finalObjectSave[dataCheck.dataName + currentLanguage.data];

            for (let dn = 0; dn < filesDataNames.length; dn++) {
              const currentDataName = filesDataNames[dn];
              const fileCheck = currentChildrenFile[currentDataName.dataName];
              if (fileCheck.isNewFile === true) {
                let newFileName = "";
                const splitData = fileCheck.name.split(".");
                let extension = "";
                if (splitData.length > 0) {
                  extension = splitData[splitData.length - 1];
                  newFileName = setFileNameDataCheck(
                    extension,
                    currentDataName,
                    this.currentTableToChange,
                    undefined,
                    currentLanguage.data !== "" ? currentLanguage.data : ""
                  );
                }
                if (newFileName !== "") {
                  fileCheck.name = newFileName;
                } else {
                  fileCheck.name = setURLType(splitData[0]) + "." + extension;
                }
                filesToAdd.push(fileCheck);
              }
            }
          } else if (dataCheck.dataType === "array") {
            if (dataCheck.arrayDataType.dataType === "object") {
              const filesDataNames = checkObjectFiles(
                dataCheck.arrayDataType.dataObject
              );
              for (
                let a = 0;
                a <
                finalObjectSave[dataCheck.dataName + currentLanguage.data]
                  .length;
                a++
              ) {
                const currentChildrenArray =
                  finalObjectSave[dataCheck.dataName + currentLanguage.data][a];

                for (let dn = 0; dn < filesDataNames.length; dn++) {
                  const currentDataName = filesDataNames[dn];
                  const fileCheck =
                    currentChildrenArray[currentDataName.dataName];
                  if (fileCheck.isNewFile === true) {
                    let newFileName = "";
                    const splitData = fileCheck.name.split(".");
                    let extension = "";
                    if (splitData.length > 0) {
                      extension = splitData[splitData.length - 1];
                      newFileName = setFileNameDataCheck(
                        extension,
                        currentDataName,
                        this.currentTableToChange,
                        a,
                        currentLanguage.data !== "" ? currentLanguage.data : ""
                      );
                    }
                    if (newFileName !== "") {
                      fileCheck.name = newFileName;
                    } else {
                      fileCheck.name =
                        setURLType(splitData[0]) + "." + extension;
                    }
                    filesToAdd.push(fileCheck);
                  }
                }
              }
            } else if (dataCheck.arrayDataType.dataType === "file") {
              filesToRemove = [
                ...filesToRemove,
                ...dataCheck.originalArray.slice()
              ];
              for (
                let a = 0;
                a <
                finalObjectSave[dataCheck.dataName + currentLanguage.data]
                  .length;
                a++
              ) {
                const currentChildrenArray =
                  finalObjectSave[dataCheck.dataName + currentLanguage.data][a];
                if (dataCheck.originalArray !== undefined) {
                  if (currentChildrenArray.fileName !== undefined) {
                    const checkIfIsInOriginal = filesToRemove.find(
                      checkData => {
                        return (
                          checkData.fileName === currentChildrenArray.fileName
                        );
                      }
                    );
                    if (checkIfIsInOriginal !== undefined) {
                      const indexRemove = filesToRemove.indexOf(
                        checkIfIsInOriginal
                      );
                      if (indexRemove > -1) {
                        filesToRemove.splice(indexRemove, 1);
                      }
                    }
                  }
                }
                if (currentChildrenArray.isNewFile === true) {
                  systemLog(this, "IS NEW FILE");
                  let newFileName = "";
                  const splitData = currentChildrenArray.name.split(".");
                  let extension = "";
                  if (splitData.length > 0) {
                    extension = splitData[splitData.length - 1];
                    newFileName = setFileNameDataCheck(
                      extension,
                      dataCheck.arrayDataType,
                      this.currentTableToChange,
                      a,
                      currentLanguage.data !== "" ? currentLanguage.data : ""
                    );
                  }
                  if (newFileName !== "") {
                    currentChildrenArray.name = newFileName;
                  } else {
                    currentChildrenArray.name =
                      setURLType(splitData[0]) + "." + extension;
                  }
                  filesToAdd.push(currentChildrenArray);
                }
              }
            } else if (dataCheck.arrayDataType.dataType === "image") {
              let filesOfImages = [];
              let originalArray = [];
              if (currentLanguage.data !== "") {
                originalArray = dataCheck[
                  "originalArray" + currentLanguage.data
                ].slice();
              } else {
                originalArray = dataCheck.originalArray.slice();
              }
              for (let a = 0; a < originalArray.length; a++) {
                const currentOriginal = originalArray[a];
                if (currentOriginal !== undefined) {
                  for (let ir = 0; ir < ImagesRes.length; ir++) {
                    if (currentOriginal[ImagesRes[ir].dataName] !== undefined) {
                      filesOfImages.push(
                        currentOriginal[ImagesRes[ir].dataName]
                      );
                    }
                  }
                }
              }
              systemLog(this, filesOfImages);
              filesToRemove = [...filesToRemove, ...filesOfImages.slice()];
              for (
                let a = 0;
                a <
                finalObjectSave[dataCheck.dataName + currentLanguage.data]
                  .length;
                a++
              ) {
                for (let ir = 0; ir < ImagesRes.length; ir++) {
                  const currentChildrenArray =
                    finalObjectSave[dataCheck.dataName + currentLanguage.data][
                      a
                    ][ImagesRes[ir].dataName];
                  if (dataCheck.originalArray !== undefined) {
                    if (
                      currentChildrenArray !== undefined &&
                      currentChildrenArray.fileName !== undefined
                    ) {
                      const checkIfIsInOriginal = filesToRemove.find(
                        checkData => {
                          return (
                            checkData.fileName === currentChildrenArray.fileName
                          );
                        }
                      );
                      if (checkIfIsInOriginal !== undefined) {
                        const indexRemove = filesToRemove.indexOf(
                          checkIfIsInOriginal
                        );
                        if (indexRemove > -1) {
                          filesToRemove.splice(indexRemove, 1);
                        }
                      }
                    }
                  }
                  if (
                    currentChildrenArray !== undefined &&
                    currentChildrenArray.isNewFile === true
                  ) {
                    systemLog(this, "IS NEW FILE");
                    let newFileName = "";
                    const splitData = currentChildrenArray.name.split(".");
                    let extension = "";
                    if (splitData.length > 0) {
                      extension = splitData[splitData.length - 1];
                      newFileName = setFileNameDataCheck(
                        extension,
                        dataCheck.arrayDataType,
                        this.currentTableToChange,
                        a,
                        ImagesRes[ir].dataName +
                          (currentLanguage.data !== ""
                            ? currentLanguage.data
                            : "")
                      );
                    }
                    if (newFileName !== "") {
                      currentChildrenArray.name = newFileName;
                    } else {
                      currentChildrenArray.name =
                        setURLType(splitData[0]) + "." + extension;
                    }
                    filesToAdd.push(currentChildrenArray);
                  }
                }
              }
            }
          }
        }
      }

      if (
        dataCheck.setBaseData !== undefined &&
        (finalObjectSave[dataCheck.dataName] === undefined ||
          finalObjectSave[dataCheck.dataName] === "")
      ) {
        let value = null;
        if (dataCheck.setBaseData.dataName !== undefined) {
          value = this.currentTableToChange[dataCheck.setBaseData.dataName];
        } else if (dataCheck.setBaseData.value !== undefined) {
          value = dataCheck.setBaseData.value;
        }
        if (value !== null) {
          if (dataCheck.dataType === "string") {
            value = value.toString();
          }
          finalObjectSave[dataCheck.dataName] = value;
          isConfirmed = true;
        }
      }
      if (
        dataCheck.needConfirm === true &&
        isConfirmed === false &&
        this.currentTableToChange[dataCheck.dataName] !== undefined &&
        this.currentTableToChange[dataCheck.dataName] !== ""
      ) {
        if (
          this.currentTableToChange["confirm" + dataCheck.dataName] ===
            undefined ||
          this.currentTableToChange["confirm" + dataCheck.dataName] !==
            this.currentTableToChange[dataCheck.dataName]
        ) {
          toast.error(
            "El dato: " +
              (dataCheck.dataSingle || dataCheck.dataLabel) +
              ", debe ser igual"
          );
          canSave = false;
        }
      }

      if (dataCheck.asData === "email") {
        if (
          validateEmail(this.currentTableToChange[dataCheck.dataName]) === false
        ) {
          toast.error(
            "El dato: " +
              (dataCheck.dataSingle || dataCheck.dataLabel) +
              ", debe ser un correo valido"
          );
          canSave = false;
        }
      }

      if (
        dataCheck.notRequired !== true &&
        dataCheck.setBaseData === undefined &&
        (this.currentTableToChange[dataCheck.dataName] === undefined ||
          this.currentTableToChange[dataCheck.dataName] === null ||
          this.currentTableToChange[dataCheck.dataName].toString() === "")
      ) {
        toast.error(
          "El dato: " +
            (dataCheck.dataSingle || dataCheck.dataLabel) +
            ", es obligatorio"
        );
        canSave = false;
      }

      childrenToRemove = [...childrenToRemove, ...tempChildrenToRemove];
    }
    systemLog("DeleteChildren", childrenToRemove);

    if (canSave === true && this.props.checkBeforeSave !== undefined) {
      canSave = this.props.checkBeforeSave(
        finalObjectSave,
        this.currentTableToChange
      );
    }
    systemLog(this, finalObjectSave);
    systemLog(this, this.currentTableToChange);
    systemLog("AddFile", filesToAdd);
    systemLog("DeleteFile", filesToRemove);
    if (canSave) {
      if (this.props.tableParentIdConnect !== undefined) {
        finalObjectSave[
          this.props.tableParentIdConnect
        ] = this.props.tableParentId;
      }
      if (this.props.returnEditObj !== undefined) {
        this.props.returnEditObj(this.currentTableToChange);
        if (this.props.clickOnSave !== undefined) {
          this.props.clickOnSave();
        }
        return;
      }

      let savedContent = saveContentC + " " + finalTitle + "!";
      const toastSave = toast.info(saveContent + " " + finalTitle, {
        autoClose: false
      });

      this.deleteFiles(
        filesToAdd,
        filesToRemove,
        finalTitle,
        savedContent,
        toastSave,
        finalObjectSave,
        relationToAdd,
        relationToRemove,
        childrenToAdd,
        childrenToRemove
      );
    }
  }

  async deleteFiles(
    filesToAdd,
    filesToRemove,
    finalTitle,
    savedContent,
    toastSave,
    finalObjectSave,
    relationToAdd,
    relationToRemove,
    childrenToAdd,
    childrenToRemove
  ) {
    if (filesToRemove.length > 0) {
      for (let i = 0; i < filesToRemove.length; i++) {
        const fileToRemove = filesToRemove[i];
        systemLog("DeleteFile", fileToRemove);
        if (fileToRemove.fileName !== undefined) {
          const response = await this.props.API.DeleteFile(
            fileToRemove.fileName
          );
          systemLog("DeleteFile", response);
        }
      }
    }

    this.saveFiles(
      filesToAdd,
      finalTitle,
      savedContent,
      toastSave,
      finalObjectSave,
      relationToAdd,
      relationToRemove,
      childrenToAdd,
      childrenToRemove
    );
  }

  saveFiles(
    filesToAdd,
    finalTitle,
    savedContent,
    toastSave,
    finalObjectSave,
    relationToAdd,
    relationToRemove,
    childrenToAdd,
    childrenToRemove
  ) {
    if (filesToAdd.length === 0) {
      this.patchObject(
        finalTitle,
        savedContent,
        toastSave,
        finalObjectSave,
        relationToAdd,
        relationToRemove,
        childrenToAdd,
        childrenToRemove
      );
    } else {
      let currentSavedFiles = 0;
      for (let f = 0; f < filesToAdd.length; f++) {
        const currentFile = filesToAdd[f];
        if (
          currentFile.fileName !== undefined &&
          currentFile.name !== currentFile.fileName
        ) {
          systemLog(this, "UploadFile");
          this.props.API.UploadFile(
            currentFile.result,
            currentFile.fileName,
            currentFile.name
          )
            .then(response => {
              systemLog(this, response);
              delete currentFile.b64;
              delete currentFile.isNewFile;
              currentFile.fileName = response.name;
              currentFile.preview = response.path;
              currentFile.result = response.path;
              currentFile.resultWEBP = response.pathWEBP;
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.patchObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  relationToRemove,
                  childrenToAdd,
                  childrenToRemove
                );
              }
            })
            .catch(error => {
              systemLog(this, error);
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.patchObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  relationToRemove,
                  childrenToAdd,
                  childrenToRemove
                );
              }
            });
        } else {
          systemLog(this, "DoUploadFile");
          this.props.API.DoUploadFile(currentFile.result, currentFile.name)
            .then(response => {
              systemLog(this, response);
              delete currentFile.b64;
              delete currentFile.isNewFile;
              currentFile.fileName = response.name;
              currentFile.preview = response.path;
              currentFile.result = response.path;
              currentFile.resultWEBP = response.pathWEBP;
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.patchObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  relationToRemove,
                  childrenToAdd,
                  childrenToRemove
                );
              }
            })
            .catch(error => {
              systemLog(this, error);
              currentSavedFiles = currentSavedFiles + 1;
              if (currentSavedFiles >= filesToAdd.length) {
                this.patchObject(
                  finalTitle,
                  savedContent,
                  toastSave,
                  finalObjectSave,
                  relationToAdd,
                  relationToRemove,
                  childrenToAdd,
                  childrenToRemove
                );
              }
            });
        }
      }
    }
  }

  patchObject(
    finalTitle,
    savedContent,
    toastSave,
    finalObjectSave,
    relationToAdd,
    relationToRemove,
    childrenToAdd,
    childrenToRemove
  ) {
    systemLog(this, this.dirToModify);
    systemLog(this, this.currentTableToChange.id);
    systemLog(this, finalObjectSave);
    this.props.API.PatchTo(
      this.dirToModify,
      this.currentTableToChange.id,
      finalObjectSave
    )
      .then(response => {
        systemLog(this, response);
        let thereIsError = false;
        if (response.error !== undefined) {
          savedContent = saveContentB + " " + finalTitle;
          thereIsError = true;
        }
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: thereIsError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
          });
        } else {
          if (thereIsError) {
            toast.error(savedContent);
          } else {
            toast.success(savedContent);
          }
        }
        if (thereIsError === false) {
          this.checkRelationToAdd(
            response,
            relationToAdd,
            relationToRemove,
            childrenToAdd,
            childrenToRemove
          );
        }
      })
      .catch(error => {
        systemLog(this, error);
        savedContent = saveContentB + " " + finalTitle;
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: toast.TYPE.ERROR
          });
        } else {
          toast.error(savedContent);
        }
      });
  }

  checkRelationToAdd(
    responseEditItem,
    relationToAdd,
    relationToRemove,
    childrenToAdd,
    childrenToRemove
  ) {
    systemLog("ChildrenCheck", relationToRemove);
    systemLog("ChildrenCheck", childrenToAdd);
    if (relationToRemove.length > 0) {
      for (let i = 0; i < relationToRemove.length; i++) {
        const currentRelationToRemove = relationToRemove[i];
        this.props.API.RemoveRelations(
          this.dirToModify,
          responseEditItem.id,
          currentRelationToRemove
        );
      }
    }

    if (relationToAdd.length === 0) {
      this.checkChildrenToAdd(
        responseEditItem,
        childrenToAdd,
        childrenToRemove
      );
    } else {
      let relationCount = 0;
      for (let i = 0; i < relationToAdd.length; i++) {
        const currentRel = relationToAdd[i];
        this.props.API.SetRelation(
          this.dirToModify,
          responseEditItem.id,
          currentRel.route,
          currentRel.id
        )
          .then(response => {
            relationCount = relationCount + 1;
            if (relationCount >= relationToAdd.length) {
              this.checkChildrenToAdd(
                responseEditItem,
                childrenToAdd,
                childrenToRemove
              );
            }
          })
          .catch(error => {
            systemLog("ChildrenCheck", error);
            relationCount = relationCount + 1;
            if (relationCount >= relationToAdd.length) {
              this.checkChildrenToAdd(
                responseEditItem,
                childrenToAdd,
                childrenToRemove
              );
            }
          });
      }
    }
  }

  checkChildrenToAdd(responseEditItem, childrenToAdd, childrenToRemove) {
    systemLog("DeleteChildren", childrenToRemove);
    if (childrenToRemove.length > 0) {
      for (let i = 0; i < childrenToRemove.length; i++) {
        const currentChildrenToRemove = childrenToRemove[i];
        if (currentChildrenToRemove.deleteMe === true) {
          delete currentChildrenToRemove.deleteMe;
          systemLog("DeleteChildren", currentChildrenToRemove);
          this.props.API.DeleteThisFrom(
            currentChildrenToRemove.tableSysName,
            currentChildrenToRemove.id
          );
        } else {
          let dataPatch = {};
          dataPatch[currentChildrenToRemove.parentDataName] = null;
          systemLog("Agreements", currentChildrenToRemove);
          systemLog("Agreements", dataPatch);
          this.props.API.PatchTo(
            currentChildrenToRemove.tableSysName,
            currentChildrenToRemove.id,
            dataPatch
          );
        }
      }
    }

    if (childrenToAdd.length === 0) {
      this.returnSaved(responseEditItem);
    } else {
      let childrenCount = 0;

      for (let i = 0; i < childrenToAdd.length; i++) {
        const currentChild = { ...childrenToAdd[i] };
        const addMeTo = childrenToAdd[i].addMeTo;
        systemLog("Relation", childrenToAdd[i]);
        delete currentChild.deleteMe;
        delete currentChild.tableSysName;
        delete currentChild.parentDataName;
        delete currentChild.addMeTo;
        delete currentChild.id;
        currentChild[childrenToAdd[i].parentDataName] = responseEditItem.id;
        systemLog("Relation", currentChild);

        if (childrenToAdd[i].id !== undefined) {
          this.props.API.PatchTo(
            childrenToAdd[i].tableSysName,
            childrenToAdd[i].id,
            currentChild
          )
            .then(response => {
              systemLog("ChildrenCheck", response);
              if (addMeTo !== undefined) {
                for (let amt = 0; amt < addMeTo.length; amt++) {
                  const currentAdd = addMeTo[amt];
                  const patchToAdd = {};
                  patchToAdd[currentAdd.dataName] = response.id;
                  this.props.API.PatchTo(
                    currentAdd.tableSysName,
                    currentChild[currentAdd.dataNameId],
                    patchToAdd
                  );
                }
              }
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseEditItem);
              }
            })
            .catch(error => {
              systemLog("ChildrenCheck", error);
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseEditItem);
              }
            });
        } else {
          this.props.API.PostTo(childrenToAdd[i].tableSysName, currentChild)
            .then(response => {
              systemLog("ChildrenCheck", response);
              if (addMeTo !== undefined) {
                for (let amt = 0; amt < addMeTo.length; amt++) {
                  const currentAdd = addMeTo[amt];
                  const patchToAdd = {};
                  patchToAdd[currentAdd.dataName] = response.id;
                  this.props.API.PatchTo(
                    currentAdd.tableSysName,
                    currentChild[currentAdd.dataNameId],
                    patchToAdd
                  );
                }
              }
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseEditItem);
              }
            })
            .catch(error => {
              systemLog("ChildrenCheck", error);
              childrenCount = childrenCount + 1;
              if (childrenCount >= childrenToAdd.length) {
                this.returnSaved(responseEditItem);
              }
            });
        }
      }
    }
  }

  returnSaved(responseEditItem) {
    if (this.props.afterEditObj !== undefined) {
      this.props.afterEditObj(responseEditItem);
    }
    if (this.props.clickOnSave !== undefined) {
      this.props.clickOnSave();
    }
  }

  renderTopLanguages() {
    if (this.canShowLanguage === false || MultipleLanguages === false) {
      return <div />;
    } else {
      return (
        <div
          style={{
            ...styleCenteredObject,
            justifyContent: "flex-end",
            marginRight: adminSeparation,
            padding: "0.7rem",
            height: "100%",
            width: "10rem",
            border: "1px solid " + colors.borderColor
          }}
        >
          <Dropdown
            fluid
            search
            onChange={(event, changedData) => {
              const checkIdiom = changedData.value;
              const languageCheck = this.props.languages.find(language => {
                return language.value === checkIdiom;
              });
              this.setState({
                currentLanguageId: checkIdiom,
                currentLanguage: languageCheck.data
              });
              this.forceUpdate();
            }}
            value={this.state.currentLanguageId}
            placeholder={"Idioma"}
            options={this.props.languages}
          />
        </div>
      );
    }
  }

  render() {
    if (this.props.table === undefined) {
      return <div />;
    }
    let canSave = !isEquivalent(this.currentTableToChange, this.props.table);
    if (this.props.forceSave === true) {
      canSave = true;
    }
    let finalData = getTitleName(
      this.props.tableData.dataTitle,
      this.props.table
    );
    return (
      <StickyContainer>
        <Sticky topOffset={-50}>
          {({
            style,

            // the following are also available but unused in this example
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight
          }) => (
            <header
              style={{
                ...style,
                backgroundColor: "#FFFFFF",
                zIndex: 100,
                marginTop: isSticky === true ? 60 : 0
              }}
            >
              {renderTopName(finalData, this.renderTopLanguages)}
            </header>
          )}
        </Sticky>
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.currentDataVisual.map((data, index) => {
            let canShowItem = true;
            if (data.onlyShowIf !== undefined) {
              canShowItem = checkIfCanShow(
                data,
                this.currentTableToChange,
                this.currentDataVisual
              );
            }
            if (data.dontShow === true) {
              canShowItem = false;
            }

            let renderCustom = false;
            if (this.props.customRenderData !== undefined) {
              if (this.props.customRenderData[data.dataName] !== undefined) {
                renderCustom = true;
              }
            }

            if (data.canChangeLanguage === true) {
              data.dataName = data.mainDataName + this.state.currentLanguage;
            }

            if (canShowItem === true) {
              if (renderCustom === true) {
                return (
                  <div key={index}>
                    <div style={{ ...columnEditStyle }}>
                      {this.props.customRenderData[data.dataName](
                        data,
                        this.currentTableToChange,
                        this.props.formatter,
                        () => {
                          this.forceUpdate();
                        }
                      )}
                    </div>
                    <div style={{ height: "1rem" }} />
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <div style={{ ...columnEditStyle }}>
                      {renderLabel(data.dataLabel, data.notRequired)}
                      {renderModifyData(
                        data,
                        this.currentTableToChange,
                        this.props.formatter,
                        returnData => {
                          this.forceUpdate();
                        }
                      )}
                    </div>
                    <div style={{ height: "1rem" }} />
                  </div>
                );
              }
            } else {
              return <div key={index} />;
            }
          })}
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              width: "100%",
              height: "4rem"
            }}
          >
            <Button
              onClick={() => {
                if (canSave) {
                  this.saveItem();
                }
              }}
              style={{
                backgroundColor: canSave
                  ? colors.buttonColor
                  : colors.disableColor,
                color: colors.colorFrontButton,
                ...styleCenteredObject,
                width: "8rem",
                height: "3rem"
              }}
            >
              {"GUARDAR"}
            </Button>
          </div>
          <div style={{ height: "1rem" }} />
        </div>
      </StickyContainer>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions, languages } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API, languages, data };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(AdminEditItem)
);
