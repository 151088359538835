import BasePage from "../pages/Project/BasePage";
import * as ServiceTable from "../TablesData/Project/serviceDataTables";

const mainScreensToShow = [
  {
    route: "/landing/services",
    name: "Servicios",
    perm: "Web",
    showOnMenu: true,
    dataTable: ServiceTable,
    page: BasePage
  }
];

export default mainScreensToShow;
