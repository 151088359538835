import moment from "moment/min/moment-with-locales.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Grid } from "semantic-ui-react";
import ModularText from "../../components/ModularText";
import { dataCreateData } from "../../TablesData/sectionDataTable";
import {
  renderTopName,
  renderLabel,
  renderModifyData
} from "../../scripts/System";
import {
  adminSeparation,
  colors,
  styleCenteredObject,
  textSizeHeaderSubTitle
} from "../../style";

const saveContent = "SE ESTA CREANDO EL ELEMENTO";
const saveContentC = "¡SE CREO EL ELEMENTO";
const saveContentB = "HUBO UN ERROR AL CREAR EL NUEVO ELEMENTO";

const dataName = {
  dataLabel: "Nombre",
  dataType: "string",
  dataName: "name",
  dataInsideSize: "100%"
};
const dataPublicName = {
  dataLabel: "Nombre Publico",
  dataType: "string",
  dataName: "publicName",
  dataInsideSize: "100%"
};
const dataPublicNumber = {
  dataLabel: "Orden",
  dataType: "number",
  dataName: "orden",
  dataInsideSize: "100%"
};

const dataBase = {
  dataLabel: "Es Base",
  dataType: "bool",
  dataName: "isBase"
};

const separationTables = "1.5rem";
const renderDataInsideSize = "90%";
const columnSize = "3rem";
const columnStyle = {
  ...styleCenteredObject,
  width: "100%",
  margin: 0,
  padding: 0,
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  minHeight: columnSize,
  marginLeft: "0.5rem"
};

class SectionPageCreator extends Component {
  constructor(props) {
    super(props);
    moment.locale("es");
    this.currentTableToChange = {};
    this.saveItem = this.saveItem.bind(this);
    this.renderCreateData = this.renderCreateData.bind(this);
    this.renderSingleData = this.renderSingleData.bind(this);
    this.currentDataContentText = [];
    this.currentDataContentImage = [];
    this.currentDataContentLink = [];
    this.currentDataContentRichText = [];
    this.currentDataContentFile = [];

    this.dirToModify = this.props.tableSysName;
    if (this.props.tableCreateRoute !== undefined) {
      this.dirToModify = this.props.tableCreateRoute;
    }
  }

  saveItem() {
    if (
      this.currentTableToChange["name"] === undefined &&
      this.currentTableToChange["publicName"] === undefined &&
      this.currentTableToChange["isBase"] === undefined
    ) {
      toast.error("Agrega la informacion de la sección");
      return;
    }

    if (
      this.currentDataContentText.length === 0 &&
      this.currentDataContentImage.length === 0 &&
      this.currentDataContentLink.length === 0 &&
      this.currentDataContentRichText.length === 0 &&
      this.currentDataContentFile.length === 0
    ) {
      toast.error("Agrega minimo un dato a la seccion");
      return;
    }

    let savedContent = saveContentC + "!";
    const toastSave = toast.info(saveContent, {
      autoClose: false
    });

    let finalObjectSave = { ...this.currentTableToChange };
    if (
      this.props.tableParentIdConnect !== undefined &&
      this.props.tableParentId
    ) {
      finalObjectSave[
        this.props.tableParentIdConnect
      ] = this.props.tableParentId;
    }

    this.props.API.PostTo(this.dirToModify, finalObjectSave)
      .then(response => {
        let thereIsError = false;
        if (response.error !== undefined) {
          savedContent = saveContentB;
          thereIsError = true;
        }
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: thereIsError ? toast.TYPE.ERROR : toast.TYPE.SUCCESS
          });
        } else {
          if (thereIsError) {
            toast.error(savedContent);
          } else {
            toast.success(savedContent);
          }
        }
        if (thereIsError === false) {
          for (let i = 0; i < this.currentDataContentText.length; i++) {
            const dataCheck = this.currentDataContentText[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentTexts", dataCheck).then(
              response => {}
            );
          }
          for (let i = 0; i < this.currentDataContentImage.length; i++) {
            const dataCheck = this.currentDataContentImage[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentImages", dataCheck).then(
              response => {}
            );
          }
          for (let i = 0; i < this.currentDataContentLink.length; i++) {
            const dataCheck = this.currentDataContentLink[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentLinks", dataCheck).then(
              response => {}
            );
          }
          for (let i = 0; i < this.currentDataContentRichText.length; i++) {
            const dataCheck = this.currentDataContentRichText[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentRichTexts", dataCheck).then(
              response => {}
            );
          }
          for (let i = 0; i < this.currentDataContentFile.length; i++) {
            const dataCheck = this.currentDataContentFile[i];
            dataCheck["contentSectionId"] = response.id;
            this.props.API.PostTo("DataContentFiles", dataCheck).then(
              response => {}
            );
          }

          if (this.props.clickOnSave !== undefined) {
            this.props.clickOnSave();
          }
        }
      })
      .catch(error => {
        savedContent = saveContentB;
        if (toast.isActive(toastSave)) {
          toast.update(toastSave, {
            render: savedContent,
            autoClose: true,
            type: toast.TYPE.ERROR
          });
        } else {
          toast.error(savedContent);
        }
      });
  }

  renderSectionMainInfo() {
    return (
      <Grid columns="equal">
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(dataName.dataLabel)}
            {renderModifyData(
              dataName,
              this.currentTableToChange,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(dataPublicName.dataLabel)}
            {renderModifyData(
              dataPublicName,
              this.currentTableToChange,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(dataBase.dataLabel)}
            {renderModifyData(
              dataBase,
              this.currentTableToChange,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  renderCreateData(title, contentToAddTo) {
    return (
      <div
        style={{
          ...styleCenteredObject,
          width: "100%",
          height: "2rem",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            ...styleCenteredObject,
            height: "100%",
            justifyContent: "flex-start"
          }}
        >
          <ModularText style={{ fontSize: textSizeHeaderSubTitle }}>
            {"CREAR " + title}
          </ModularText>
        </div>
        <div
          style={{
            ...styleCenteredObject,
            height: "100%",
            justifyContent: "flex-end"
          }}
        >
          <Button
            onClick={() => {
              const tempData = {};
              for (let i = 0; i < dataCreateData.length; i++) {
                const currentData = dataCreateData[i];
                tempData[currentData.dataName] = "";
              }
              contentToAddTo.push(tempData);
              this.forceUpdate();
            }}
            style={{
              backgroundColor: colors.addColor,
              color: colors.addFrontColor,
              ...styleCenteredObject,
              width: "8rem",
              height: "3rem"
            }}
          >
            {"CREAR"}
          </Button>
        </div>
      </div>
    );
  }

  renderSingleData(data, table) {
    return (
      <Grid columns="equal">
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(dataName.dataLabel)}
            {renderModifyData(dataName, data, this.props.formatter, () => {
              this.forceUpdate();
            })}
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(dataPublicName.dataLabel)}
            {renderModifyData(
              dataPublicName,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ ...columnStyle }}>
            {renderLabel(dataPublicNumber.dataLabel)}
            {renderModifyData(
              dataPublicNumber,
              data,
              this.props.formatter,
              () => {
                this.forceUpdate();
              }
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={2}>
          <div
            style={{
              ...columnStyle,
              width: "100%",
              height: "100%"
            }}
          >
            <div style={{ height: "0.1rem" }} />
            <div
              style={{
                ...styleCenteredObject,
                width: "100%",
                height: "100%"
              }}
            >
              <Button
                onClick={() => {
                  const index = table.indexOf(data);
                  table.splice(index, 1);
                  this.forceUpdate();
                }}
                style={{
                  ...styleCenteredObject,
                  backgroundColor: colors.removeColor,
                  color: colors.removeFrontColor
                }}
                icon="cancel"
              />
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  render() {
    const canSave = true;
    return (
      <div>
        {renderTopName("Crear Seccion")}
        <div
          style={{
            paddingLeft: adminSeparation,
            paddingRight: adminSeparation
          }}
        >
          {this.renderSectionMainInfo()}
          <div
            style={{
              height: "1rem"
            }}
          />
          {this.renderCreateData("TEXTOS", this.currentDataContentText)}
          <div style={{ height: "1rem" }} />
          {this.currentDataContentText.map((data, index) => {
            return (
              <div key={index}>
                {this.renderSingleData(data, this.currentDataContentText)}
              </div>
            );
          })}
          <div
            style={{
              height: separationTables
            }}
          />
          {this.renderCreateData("IMAGENES", this.currentDataContentImage)}
          <div style={{ height: "1rem" }} />
          {this.currentDataContentImage.map((data, index) => {
            return (
              <div key={index}>
                {this.renderSingleData(data, this.currentDataContentImage)}
              </div>
            );
          })}
          <div
            style={{
              height: separationTables
            }}
          />
          {this.renderCreateData("LINKS", this.currentDataContentLink)}
          <div style={{ height: "1rem" }} />
          {this.currentDataContentLink.map((data, index) => {
            return (
              <div key={index}>
                {this.renderSingleData(data, this.currentDataContentLink)}
              </div>
            );
          })}
          <div
            style={{
              height: separationTables
            }}
          />
          {this.renderCreateData("RICH TEXT", this.currentDataContentRichText)}
          <div style={{ height: "1rem" }} />
          {this.currentDataContentRichText.map((data, index) => {
            return (
              <div key={index}>
                {this.renderSingleData(data, this.currentDataContentRichText)}
              </div>
            );
          })}
          <div
            style={{
              height: separationTables
            }}
          />
          {this.renderCreateData("ARCHIVO", this.currentDataContentFile)}
          <div style={{ height: "1rem" }} />
          {this.currentDataContentFile.map((data, index) => {
            return (
              <div key={index}>
                {this.renderSingleData(data, this.currentDataContentFile)}
              </div>
            );
          })}
          <div
            style={{
              height: "2rem"
            }}
          />
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              width: "100%",
              height: "4rem"
            }}
          >
            <Button
              onClick={() => {
                if (canSave) {
                  this.saveItem();
                }
              }}
              style={{
                backgroundColor: canSave
                  ? colors.buttonColor
                  : colors.disableColor,
                color: colors.colorFrontButton,
                ...styleCenteredObject,
                width: "8rem",
                height: "3rem"
              }}
            >
              {"GUARDAR"}
            </Button>
          </div>
          <div style={{ height: "1rem" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { userPermissions } = data;
  const { formatter, API } = api;
  return { formatter, userPermissions, API };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(SectionPageCreator)
);
