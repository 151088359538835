export const basePageInfo = {
  tableSingleName: "Email Template",
  tablePluralName: "Email Templates",
  tablePerm: "Web",
  tableSysName: "EmailTemplates",
  reducerDataName: "emaildata",
  tableInclude: [],
  getDataFrom: [
    {
      tableSysName: "SendGrids",
      reducerDataName: "sendgrids"
    },
    {
      reducerDataName: "versions"
    }
  ]
};

export const EmailTypeSelector = [
  {
    key: 1,
    value: "USER_NEW_ACCOUNT_REGISTERED",
    text: "Nuevo Usuario Registrado"
  },
  {
    key: 2,
    value: "USER_ACCOUNT_PASSWORD_RECOVERY",
    text: "Recuperación de Contraseña para Usuario"
  },
  {
    key: 3,
    value: "ADMIN_NEW_ACCOUNT_REGISTERED",
    text: "Nuevo Administrador Registrado"
  },
  {
    key: 4,
    value: "ADMIN_ACCOUNT_PASSWORD_RECOVERY",
    text: "Recuperación de Contraseña para Administrador"
  }
];

const dataTitle = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

const dataVisualBelongs = [
  {
    dataName: "organizationId",
    dataLabel: "Organización",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "organizations",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  },
  {
    dataName: "templateId",
    dataLabel: "Template ID (SendGrid)",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "sendgrids",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  },
  {
    dataName: "emailTemplateId",
    dataLabel: "Plantilla de Email para",
    selectorData: EmailTypeSelector,
    dataType: "selector"
  }
];

const dataVisualBelongsView = [
  {
    dataName: "organization",
    dataType: "insideModule",
    dataLabel: "Organización",
    dataModule: {
      dataName: "name",
      dataType: "string"
    }
  }
];

const dataVisualNecesary = [
  {
    dataName: "templateVersionId",
    dataLabel: "Id Version Template (SendGrid)",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "versions",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  },
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "templateData",
    dataLabel: "Datos de Plantilla",
    dataType: "array",
    arrayFileAdd: "Dato de Plantilla",
    arrayDataType: {
      dataLabel: "Dato",
      dataType: "object",
      dataObject: [
        {
          dataName: "name",
          dataLabel: "Nombre (Sin espacios o carácteres especiales)",
          dataType: "string"
        },
        {
          dataName: "value",
          dataLabel: "Valor",
          dataType: "string"
        }
      ]
    }
  }
];

export const tableCreateItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongs, ...dataVisualNecesary]
};

export const tableEditItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongs, ...dataVisualNecesary]
};

export const tableViewItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongsView, ...dataVisualNecesary]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "emailTemplateId",
    dataLabel: "Email Id",
    dataType: "string"
  },
  {
    dataName: "templateId",
    dataLabel: "Template Id",
    dataType: "string"
  },
  {
    dataName: "templateVersionId",
    dataLabel: "Template Version",
    dataType: "string"
  }
];

export const filterTable = [{}];
