import React, { Component } from "react";
import { Grid, Responsive, Segment } from "semantic-ui-react";
import { styleBasic } from "../style/index";
export default class GridForContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Segment
        style={{
          ...styleBasic,
          ...this.props.style,
          padding: 0
        }}
      >
        <Responsive {...Responsive.onlyMobile}>
          <Grid
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              margin: 0,
              padding: 0
            }}
            columns="equal"
          >
            <Grid.Column
              style={{
                flexDirection: "column",
                ...this.props.contentStyle
              }}
              align={this.props.align || "left"}
              mobile={15}
              tablet={this.props.viewWidth || 12}
              computer={this.props.viewWidth || 12}
            >
              {this.props.children}
            </Grid.Column>
          </Grid>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Grid
            style={{
              color: "transparent",
              width: "100%",
              height: "100%",
              margin: 0,
              padding: 0
            }}
            columns="equal"
          >
            <Grid.Column only="computer tablet" />
            <Grid.Column
              style={{
                flexDirection: "column",
                ...this.props.contentStyle
              }}
              align={this.props.align || "left"}
              mobile={16}
              tablet={this.props.viewWidth || 12}
              computer={this.props.viewWidth || 12}
            >
              {this.props.children}
            </Grid.Column>
            <Grid.Column only="computer tablet" />
          </Grid>
        </Responsive>
      </Segment>
    );
  }
}
