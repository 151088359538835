import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setPage } from "../../redux/actions";
import { push } from "connected-react-router";
import SectionPageTable from "../../BaseTables/SectionPageTable";
import MetatagsTable from "../../BaseTables/MetatagsTable";
import SectionPage from "./SectionPage";
import AdminContent from "../../layaouts/AdminContent";
import { systemLog } from "../../scripts/General";

class SinglePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPageTable: true,
      pageId: "",
      currentSections: []
    };
    this.renderSections = this.renderSections.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentWillMount() {
    let pageId = this.props.match.params.id;
    const indexOfSearch = pageId.indexOf("&");
    pageId = pageId.substring(
      0,
      indexOfSearch !== -1 ? indexOfSearch : pageId.length
    );
    systemLog(this, pageId);
    this.refreshData(pageId);
  }

  componentDidMount() {
    systemLog(this, this.state);
  }

  refreshData(pageId) {
    this.props.API.GetPage(pageId).then(responsePage => {
      if (responsePage.error === undefined) {
        this.setState({
          loadingPageTable: false,
          pageId: pageId,
          currentSections: responsePage.sectionPages
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    let pageId = nextProps.match.params.id;
    const indexOfSearch = pageId.indexOf("&");
    pageId = pageId.substring(
      0,
      indexOfSearch !== -1 ? indexOfSearch : pageId.length
    );
    systemLog(this, pageId);
    if (pageId !== this.state.pageId) {
      this.refreshData(pageId);
    }
  }

  renderSections() {
    if (this.props.user.isGod === true) {
      return (
        <SectionPageTable
          pageId={this.state.pageId}
          checkLoading={doit => {
            this.setState({ loadingPageTable: doit });
          }}
        />
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <AdminContent
        onLoading={this.state.loadingPageTable}
        pageName={"Editor de la pagina: " + this.props.page.publicName}
        pageSubtitle={
          "Administrador de la pagina: " + this.props.page.publicName
        }
      >
        {this.renderSections()}
        {this.state.currentSections.map((section, index) => {
          return <SectionPage key={index} sectionId={section.id} />;
        })}
      </AdminContent>
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { page, users, user, roles, organizations } = data;
  return { page, users, user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setPage: page => {
      dispatch(setPage(page));
    },
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SinglePage)
);
