import { colors } from "../style";

const basePageColors = [
  "#000000",
  "#ffffff",
  colors.mainColor,
  colors.mainColorLight,
  colors.mainColorLighter,
  colors.mainColorDark,
  colors.mainColorDarker
];

const colorRed = [
  "#c62828",
  "#d32f2f",
  "#e53935",
  "#f44336",
  "#ef5350",
  "#e57373",
  "#ef9a9a"
];

const colorPink = [
  "#ad1457",
  "#c2185b",
  "#d81b60",
  "#e91e63",
  "#ec407a",
  "#f06292",
  "#f48fb1"
];

const colorPurpleLight = [
  "#6a1b9a",
  "#7b1fa2",
  "#8e24aa",
  "#9c27b0",
  "#ab47bc",
  "#ba68c8",
  "#ce93d8"
];

const colorDeepPurple = [
  "#4527a0",
  "#512da8",
  "#5e35b1",
  "#673ab7",
  "#7e57c2",
  "#9575cd",
  "#b39ddb"
];

const colorIndigo = [
  "#283593",
  "#303f9f",
  "#3949ab",
  "#3f51b5",
  "#5c6bc0",
  "#7986cb",
  "#9fa8da"
];

const colorBlue = [
  "#1565c0",
  "#1976d2",
  "#1e88e5",
  "#2196f3",
  "#42a5f5",
  "#64b5f6",
  "#90caf9"
];

const colorLightBlue = [
  "#0277bd",
  "#0288d1",
  "#039be5",
  "#03a9f4",
  "#29b6f6",
  "#4fc3f7",
  "#81d4fa"
];

const colorCyan = [
  "#00838f",
  "#0097a7",
  "#00acc1",
  "#00bcd4",
  "#26c6da",
  "#4dd0e1",
  "#80deea"
];

const colorTeal = [
  "#00695c",
  "#00796b",
  "#00897b",
  "#009688",
  "#26a69a",
  "#4db6ac",
  "#80cbc4"
];

const colorGreen = [
  "#2e7d32",
  "#388e3c",
  "#43a047",
  "#4caf50",
  "#66bb6a",
  "#81c784",
  "#a5d6a7"
];

const colorLightGreen = [
  "#558b2f",
  "#689f38",
  "#7cb342",
  "#8bc34a",
  "#9ccc65",
  "#aed581",
  "#c5e1a5"
];

const colorLime = [
  "#9e9d24",
  "#afb42b",
  "#c0ca33",
  "#cddc39",
  "#d4e157",
  "#dce775",
  "#e6ee9c"
];

const colorYellow = [
  "#f9a825",
  "#fbc02d",
  "#fdd835",
  "#ffeb3b",
  "#ffee58",
  "#fff176",
  "#fff59d"
];

const colorAmber = [
  "#ff8f00",
  "#ffa000",
  "#ffb300",
  "#ffc107",
  "#ffca28",
  "#ffd54f",
  "#ffe082"
];

const colorOrange = [
  "#ef6c00",
  "#f57c00",
  "#fb8c00",
  "#ff9800",
  "#ffa726",
  "#ffb74d",
  "#ffcc80"
];

const colorDeepOrange = [
  "#d84315",
  "#e64a19",
  "#f4511e",
  "#ff5722",
  "#ff7043",
  "#ff8a65",
  "#ffab91"
];

const colorBrown = [
  "#4e342e",
  "#5d4037",
  "#6d4c41",
  "#795548",
  "#8d6e63",
  "#a1887f",
  "#bcaaa4"
];

const colorGrey = [
  "#424242",
  "#616161",
  "#757575",
  "#9e9e9e",
  "#bdbdbd",
  "#e0e0e0",
  "#eeeeee"
];

const colorBlueGrey = [
  "#37474f",
  "#455a64",
  "#546e7a",
  "#607d8b",
  "#78909c",
  "#90a4ae",
  "#b0bec5"
];

const colorGayPride = [
  "#FF0007",
  "#FF520A",
  "#FFFF14",
  "#007705",
  "#0000FE",
  "#430076",
  "#B814FE"
];

export const colorsToSet = [
  ...basePageColors,
  ...colorGayPride,
  ...colorRed,
  ...colorPink,
  ...colorPurpleLight,
  ...colorDeepPurple,
  ...colorIndigo,
  ...colorBlue,
  ...colorLightBlue,
  ...colorCyan,
  ...colorTeal,
  ...colorGreen,
  ...colorLightGreen,
  ...colorLime,
  ...colorYellow,
  ...colorAmber,
  ...colorOrange,
  ...colorDeepOrange,
  ...colorBrown,
  ...colorGrey,
  ...colorBlueGrey
];
