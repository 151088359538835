import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AdminTable from "../../layaouts/AdminTable";
import { onlyOneOrganization } from "../../MainInfoData";
import { setAnyData, setOrganizations } from "../../redux/actions";

class BaseTable extends Component {
  constructor(props) {
    super(props);
    this.refreshData = this.refreshData.bind(this);
    this.checkData = this.checkData.bind(this);
    this.getDataFrom = this.getDataFrom.bind(this);
    this.tableParentIdConnect = undefined;
    this.tableParentId = undefined;
    this.whereBDData = [];
    if (onlyOneOrganization === false) {
      this.whereBDData = [{ organizationId: this.props.user.organizationId }];
      if (this.props.user.isGod) {
        this.whereBDData = [];
      }
    }
    this.state = {
      refreshDB: true
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  checkData() {
    this.tableParentIdConnect = "organizationId";
    this.tableParentId = this.props.user.organizationId;

    if (onlyOneOrganization === false) {
      if (this.props.user.isAdmin === true || this.props.user.isGod === true) {
        this.tableParentIdConnect = undefined;
        this.tableParentId = undefined;
      }
    } else {
      if (this.props.user.isGod === true) {
        this.tableParentIdConnect = undefined;
        this.tableParentId = undefined;
      }
    }
  }

  getDataFrom(currentIndex) {
    const dataFrom = this.props.dataTable.basePageInfo.getDataFrom || [];
    if (dataFrom.length > currentIndex) {
      const currentDataFrom = dataFrom[currentIndex];
      if (currentDataFrom.tableSysName !== undefined) {
        this.props.API.GetFromOrg(
          currentDataFrom.tableSysName,
          this.props.user.isGod,
          this.props.user.isAdmin,
          this.props.user.organizationId
        )
          .then(responseStates => {
            if (responseStates.error === undefined) {
              this.props.setAnyData(
                currentDataFrom.reducerDataName,
                responseStates
              );
              const nextIndex = currentIndex + 1;
              this.getDataFrom(nextIndex);
            } else {
              this.checkLoading(false);
            }
          })
          .catch(error => {
            this.checkLoading(false);
          });
      } else {
        this.props.setAnyData(currentDataFrom.reducerDataName, []);
        const nextIndex = currentIndex + 1;
        this.getDataFrom(nextIndex);
      }
    } else {
      this.setState({ refreshDB: !this.state.refreshDB });
      this.checkData();
      this.checkLoading(false);
    }
  }

  refreshData() {
    this.checkLoading(true);
    this.props.API.GetOrganizations(
      this.props.user.isGod,
      this.props.user.isAdmin,
      this.props.user.organizationId
    )
      .then(responseOrganizations => {
        if (responseOrganizations.error === undefined) {
          this.props.setOrganizations(responseOrganizations);
          this.getDataFrom(0);
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={
          this.props.data[this.props.dataTable.basePageInfo.reducerDataName]
        }
        checkBeforeSave={(finalObjectSave, currentTableToChange) => {
          let returnSave = true;
          if (this.props.dataTable.checkBeforeSave !== undefined) {
            returnSave = this.props.dataTable.checkBeforeSave(
              finalObjectSave,
              currentTableToChange
            );
          }
          return returnSave;
        }}
        bdConnectedReturn={response => {
          this.props.setAnyData(
            this.props.dataTable.basePageInfo.reducerDataName,
            response
          );
        }}
        bdConnected={true}
        bdWhere={this.whereBDData}
        bdConnectInclude={this.props.dataTable.basePageInfo.tableInclude}
        bdLimitItems={30}
        bdDoRefresh={this.state.refreshDB}
        doPagination={true}
        tableParentIdConnect={this.tableParentIdConnect}
        tableParentId={this.tableParentId}
        tableViewItemData={this.props.dataTable.tableViewItemData}
        tableEditItemData={this.props.dataTable.tableEditItemData}
        tableCreateItemData={this.props.dataTable.tableCreateItemData}
        tableData={this.props.dataTable.tableData}
        tableSysName={this.props.dataTable.basePageInfo.tableSysName}
        tablePermName={this.props.dataTable.basePageInfo.tablePerm}
        tableName={this.props.dataTable.basePageInfo.tablePluralName}
        singleName={this.props.dataTable.basePageInfo.tableSingleName}
        tableSubtitle={
          "Administrador de " +
          this.props.dataTable.basePageInfo.tablePlurarlName
        }
        filterTable={this.props.dataTable.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshData();
        }}
        customCreateRenderData={this.props.customCreateRenderData}
        customEditRenderData={this.props.customEditRenderData}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { user, organizations, userPermissions } = data;
  return { API, user, organizations, userPermissions, data };
};

const mapDispatchToProps = dispatch => {
  return {
    setAnyData: (key, payload) => {
      dispatch(setAnyData(key, payload));
    },
    setOrganizations: organizations => {
      dispatch(setOrganizations(organizations));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseTable)
);
