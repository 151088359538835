import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setPages } from "../redux/actions";
import * as PageData from "../TablesData/pageDataTables";
import AdminTable from "../layaouts/AdminTable";

class PageTable extends Component {
  constructor(props) {
    super(props);
    this.refreshPages = this.refreshPages.bind(this);
  }

  componentDidMount() {
    this.refreshPages();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  refreshPages() {
    this.checkLoading(true);
    if (this.props.user.isGod === true) {
      this.props.API.GetPages(
        this.props.user.isGod,
        this.props.user.organizationId
      )
        .then(responsePages => {
          if (responsePages.error === undefined) {
            this.props.setPages(responsePages);
            this.checkLoading(false);
          } else {
            this.checkLoading(false);
          }
        })
        .catch(error => {
          this.checkLoading(false);
        });
    }
  }

  render() {
    return (
      <AdminTable
        table={this.props.pages}
        tableViewItemData={PageData.tableViewItemData}
        tableEditItemData={PageData.tableEditItemData}
        tableCreateItemData={PageData.tableCreateItemData}
        tableData={PageData.tableData}
        tableSysName={"Pages"}
        tablePermName={"web"}
        tableName={"PAGINAS"}
        singleName={"PAGINA"}
        tableSubtitle={"Administrador de Paginas"}
        filterTable={PageData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshPages();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { pages, user, roles, organizations, userPagesPermissions } = data;
  return { pages, user, API, organizations, roles, userPagesPermissions };
};

const mapDispatchToProps = dispatch => {
  return {
    setPages: pages => {
      dispatch(setPages(pages));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PageTable)
);
