import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setLanguagesTable } from "../redux/actions";
import * as LanguagesData from "../TablesData/languagesDataTable";
import AdminTable from "../layaouts/AdminTable";

class LanguageTable extends Component {
  constructor(props) {
    super(props);
    this.refreshLanguages = this.refreshLanguages.bind(this);
    this.pageId = this.props.pageId;
    let permissions = {
      read: true,
      update: true,
      delete: true,
      create: true
    };
    if (this.props.user.isGod === false) {
      permissions = {
        read: true,
        update: false,
        delete: false,
        create: false
      };
    }

    this.state = { permissions: permissions };
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  componentDidMount() {
    this.refreshLanguages();
  }

  refreshLanguages() {
    this.checkLoading(true);
    this.props.API.GetLanguages(
      this.props.user.organizationId,
      this.props.user.isGod
    )
      .then(responseLanguages => {
        if (responseLanguages.error === undefined) {
          this.props.setLanguagesTable(responseLanguages);
          this.checkLoading(false);
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.languagesTable}
        tableViewItemData={LanguagesData.tableViewItemData}
        tableEditItemData={LanguagesData.tableEditItemData}
        tableCreateItemData={LanguagesData.tableCreateItemData}
        tableData={LanguagesData.tableData}
        tableSysName={"Languages"}
        focePermissionData={this.state.permissions}
        tablePermName={"Organizaciones"}
        tableName={"Idiomas"}
        singleName={"Idioma"}
        tableSubtitle={"Administrador de Idiomas"}
        filterTable={LanguagesData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshLanguages();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const {
    page,
    pages,
    user,
    roles,
    organizations,
    organization,
    languagesTable,
    userPagesPermissions
  } = data;
  return {
    page,
    pages,
    user,
    API,
    organizations,
    organization,
    languagesTable,
    roles,
    userPagesPermissions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguagesTable: languagesTable => {
      dispatch(setLanguagesTable(languagesTable));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LanguageTable)
);
