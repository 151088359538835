import React, { Component } from "react";
import PagePermCheck from "../../layaouts/PagePermCheck";
import BlogTagsTable from "../../BaseTables/Blog/BlogTagsTable";
import AdminContent from "../../layaouts/AdminContent";

class BlogTagsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
  }

  componentDidMount() {}

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingUserTable}
          pageName={"Tags de Blog"}
          pageSubtitle={"Administrador de Tags de Blog"}
        >
          <BlogTagsTable
            checkLoading={doit => {
              this.setState({ loadingUserTable: doit });
            }}
          />
        </AdminContent>
      </PagePermCheck>
    );
  }
}

export default BlogTagsPage;
