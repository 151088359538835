import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AdminTable from "../layaouts/AdminTable";
import { setMetatags } from "../redux/actions";
import * as MetaTagsData from "../TablesData/metatagsDataTable";
import { systemLog } from "../scripts/General";

class MetatagsTable extends Component {
  constructor(props) {
    super(props);
    this.refreshPage = this.refreshPage.bind(this);
    this.refreshData = this.refreshData.bind(this);
    let permissions = {
      read: true,
      update: true,
      delete: true,
      create: true
    };
    if (this.props.user.isGod === false) {
      permissions = {
        read: true,
        update: true,
        delete: false,
        create: false
      };
    }
    this.state = { permissions: permissions, navPageId: "", pagesId: [] };
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  componentDidMount() {
    let navPageId = this.props.match.params.id;
    const indexOfSearch = navPageId.indexOf("&");
    navPageId = navPageId.substring(
      0,
      indexOfSearch !== -1 ? indexOfSearch : navPageId.length
    );
    this.refreshData(navPageId);
  }

  componentWillReceiveProps(nextProps) {
    let navPageId = nextProps.match.params.id;
    const indexOfSearch = navPageId.indexOf("&");
    navPageId = navPageId.substring(
      0,
      indexOfSearch !== -1 ? indexOfSearch : navPageId.length
    );
    if (navPageId !== this.state.navPageId) {
      this.refreshData(navPageId);
    }
  }

  refreshData(navPageId) {
    const currentNavPage = this.props.navigationPages.find(navPage => {
      return navPage.name === navPageId;
    });
    let pagesId = [];
    for (let i = 0; i < currentNavPage.totalPages.length; i++) {
      pagesId.push(currentNavPage.totalPages[i].id);
    }
    this.setState({ navPageId: navPageId, pagesId: pagesId });
    this.refreshPage(pagesId);
  }

  refreshPage(pagesId) {
    this.checkLoading(true);
    this.props.API.GetMetatags(pagesId)
      .then(responseMetatags => {
        systemLog(this, responseMetatags);
        if (responseMetatags.error === undefined) {
          this.props.setMetatags(responseMetatags);
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.metatags}
        tableViewItemData={
          this.props.user.isGod
            ? MetaTagsData.tableViewItemData
            : MetaTagsData.tableViewAdminItemData
        }
        tableEditItemData={
          this.props.user.isGod
            ? MetaTagsData.tableEditItemData
            : MetaTagsData.tableEditAdminItemData
        }
        tableCreateItemData={MetaTagsData.tableCreateItemData}
        tableData={
          this.props.user.isGod
            ? MetaTagsData.tableData
            : MetaTagsData.tableAdminData
        }
        focePermissionData={this.state.permissions}
        tableSysName={"MetaTagss"}
        tablePermName={"web"}
        tableName={"Meta Tags"}
        singleName={"META TAG"}
        tableSubtitle={"Administrador de Meta Tags"}
        filterTable={MetaTagsData.filterTable}
        filterAllThatApply={false}
        showLanguage={true}
        refreshTable={() => {
          this.refreshPage(this.state.pagesId);
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { metatags, user, navigationPages } = data;
  return { metatags, user, navigationPages, API };
};

const mapDispatchToProps = dispatch => {
  return {
    setMetatags: metatags => {
      dispatch(setMetatags(metatags));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MetatagsTable)
);
