export const MultipleLanguages = true; //Acepta diferentes lenguajes
export const showChangeButton = false; //Muestra el boton para cambiar de admin
export const adminSysName = ""; //Nombre del sistema en home
export const godOrg = "Guaostudio GOD"; //Nombre de la organizacion GOD (obligatorioa)
export const godRole = "AdminGOD"; //Nombre del rol GOD (obligatorioa)
export const adminOrg = "Nutriam"; //Nombre de la organizacion Admin (obligatorioa)
export const adminRole = "Admin"; //Nombre del rol Admin (obligatorioa)
export const mainIp = process.env.REACT_APP_API_HOST || ""; //IP del proyecto
export const initRoute = "landing"; //Donde empieza el sistema puede ser system o landing

export const minWidthView = 1000;

export const onlyOneOrganization = true;

//El selector que pueden tener las paginas de landing
export const menuTitle = [
  { key: "principal", value: "principal", text: "Paginas" }
];

export const baseLanguage = {
  key: "notlanguage",
  value: "notlanguage",
  data: "",
  text: "Español"
};

//Color principal del sistema
export const MainColor = "#eceff1";
export const ColorFrontMain = "#000000";
export const ButtonColor = "#8bbb17";
export const ColorFrontButton = "#FFFFFF";
export const LoginBackground = "#eceff1";

//Informacion del boton para pasar al sistema
export const SystemName = {
  name: "Admin",
  icon: "database",
  backgroundColor: "#7cb342",
  color: "#FFFFFF"
};

//Informacion del boton para pasar a landing
export const LandingName = {
  name: "Landing",
  icon: "home",
  backgroundColor: "#7986cb",
  color: "#FFFFFF"
};

//Prefijo que llevaran las imagenes de landing
export const BasedataImageNameSet = {
  separationText: "_",
  nameSet: [
    {
      from: "text",
      value: "nutriam"
    }
  ]
};
