import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import ApiReducer from "./ApiReducer";
import DataReducer from "./DataReducer";

const reducers = {
  api: ApiReducer,
  data: DataReducer
};

export default history =>
  combineReducers({
    router: connectRouter(history),
    ...reducers
  });
