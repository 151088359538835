import React, { Component } from "react";
import RolesModifyItem from "./RolesModifyItem";

class RolesEditItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <RolesModifyItem
        isEdit={true}
        table={this.props.table}
        showLanguage={this.props.showLanguage}
        tableEditRoute={this.props.tableEditRoute}
        tableData={this.props.tableData}
        tableParentIdConnect={this.props.tableParentIdConnect}
        tableParentId={this.props.tableParentId}
        tableSysName={this.props.tableSysName}
        deleteElement={this.props.deleteElement}
        clickOnSave={this.props.clickOnSave}
        returnEditObj={this.props.returnEditObj}
      />
    );
  }
}

export default RolesEditItem;
