import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setPage, setPages } from "../redux/actions";
import * as SectionData from "../TablesData/sectionDataTable";
import AdminTable from "../layaouts/AdminTable";

class SectionPageTable extends Component {
  constructor(props) {
    super(props);
    this.customUsersTableEditItemData = {};
    this.customPagesTableCreateItemData = {};
    this.refreshPage = this.refreshPage.bind(this);
    this.pageId = this.props.pageId;
    this.permissions = {
      read: true,
      update: true,
      delete: true,
      create: true
    };
    if (this.props.user.isGod === false) {
      this.permissions = {
        read: false,
        update: false,
        delete: false,
        create: false
      };
    }
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  componentDidMount() {
    if (this.pageId !== "") {
      this.refreshPage();
      const permissionData = this.props.userPagesPermissions.find(x => {
        return x.pageId === this.pageId;
      });
      if (
        this.props.user.isGod === false &&
        (permissionData === undefined || permissionData.read === false)
      ) {
        this.permissions = permissionData;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.pageId !== nextProps.pageId) {
      this.pageId = nextProps.pageId;
      this.refreshPage();
    }
  }

  refreshPage() {
    this.checkLoading(true);
    this.props.API.GetPage(this.pageId)
      .then(responsePage => {
        if (responsePage.error === undefined) {
          this.props.setPage(responsePage);
          this.props.API.GetPages(
            this.props.user.isGod,
            this.props.user.organizationId
          )
            .then(responsePages => {
              if (responsePages.error === undefined) {
                this.props.setPages(responsePages);
                this.checkLoading(false);
              } else {
                this.checkLoading(false);
              }
            })
            .catch(error => {
              this.checkLoading(false);
            });
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.page.sectionPages}
        tableCreateRoute={"Pages/" + this.pageId + "/sectionPages"}
        tableViewItemData={SectionData.tableViewItemData}
        tableEditItemData={SectionData.tableEditItemData}
        tableCreateItemData={SectionData.tableCreateItemData}
        tableParentIdConnect={"pageId"}
        tableParentId={this.pageId}
        tableData={SectionData.tableData}
        tableSysName={"SectionsPages"}
        focePermissionData={this.permissions}
        tablePermName={"web"}
        tableName={"SECCIONES"}
        singleName={"SECCION"}
        tableSubtitle={"Administrador de Secciones"}
        filterTable={SectionData.filterTable}
        filterAllThatApply={false}
        dontScrollTo={true}
        refreshTable={() => {
          this.refreshPage();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const {
    page,
    pages,
    user,
    roles,
    organizations,
    userPagesPermissions
  } = data;
  return { page, pages, user, API, organizations, roles, userPagesPermissions };
};

const mapDispatchToProps = dispatch => {
  return {
    setPage: page => {
      dispatch(setPage(page));
    },
    setPages: pages => {
      dispatch(setPages(pages));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SectionPageTable)
);
