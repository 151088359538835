import React, { Component } from "react";
import RolesModifyItem from "./RolesModifyItem";

class RolesCreateItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <RolesModifyItem
        isEdit={false}
        tableData={this.props.tableData}
        showLanguage={this.props.showLanguage}
        tableCreateRoute={this.props.tableCreateRoute}
        tableParentIdConnect={this.props.tableParentIdConnect}
        tableParentId={this.props.tableParentId}
        tableSysName={this.props.tableSysName}
        singleName={this.props.singleName}
        deleteElement={this.props.deleteElement}
        customAfterCreate={this.props.customAfterCreate}
        returnCreateObj={this.props.returnCreateObj}
      />
    );
  }
}

export default RolesCreateItem;
