import React, { Component } from "react";
import { styleBasic } from "../style/index";

class CenteredContent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let mainViewCentered = {
      display: "-webkit-flex",
      WebkitAlignItems: this.props.contentAlign || "center",
      WebkitAlignSelf: this.props.contentAlign || "center",
      display: "flex",
      alignItems: this.props.contentAlign || "center",
      alignSelf: this.props.contentAlign || "center",
      alignContent: this.props.contentAlign || "center",
      textAlign: "center"
    };
    let contentCentered = {
      display: "-webkit-flex",
      WebkitAlignSelf: this.props.contentAlign || "center",
      WebkitAlignItems: this.props.contentAlign || "center",
      display: "flex",
      alignSelf: this.props.contentAlign || "center",
      alignItems: this.props.contentAlign || "center",
      alignContent: this.props.contentAlign || "center",
      justifyContent: this.props.contentAlign || "center",
      textAlign: "center",
      flexDirection: "column"
    };
    return (
      <div
        style={{
          ...styleBasic,
          ...mainViewCentered,
          width: this.props.width || "100%",
          ...this.props.contentStyle
        }}
      >
        <div
          style={{
            ...styleBasic,
            ...contentCentered,
            width: this.props.width || "100%"
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CenteredContent;
