import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  setBlogEntries,
  setBlogCategories,
  setBlogTags,
  setOrganizations
} from "../../redux/actions";
import * as BlogEntriesData from "../../TablesData/Blog/BlogEntryTable";
import AdminTable from "../../layaouts/AdminTable";
import { systemLog, setURLType } from "../../scripts/General";

class BlogEntriesTable extends Component {
  constructor(props) {
    super(props);
    this.refreshStates = this.refreshStates.bind(this);
    this.checkData = this.checkData.bind(this);
    this.tableParentIdConnect = undefined;
    this.tableParentId = undefined;
    this.whereBDData = [{ organizationId: this.props.user.organizationId }];
    if (this.props.user.isGod) {
      this.whereBDData = [];
    }
    this.state = {
      refreshDB: true
    };
  }

  componentDidMount() {
    this.refreshStates();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  checkData() {
    this.tableParentIdConnect = "organizationId";
    this.tableParentId = this.props.user.organizationId;
    if (this.props.user.isAdmin === true || this.props.user.isGod === true) {
      this.tableParentIdConnect = undefined;
      this.tableParentId = undefined;
    }
  }

  refreshStates() {
    this.checkLoading(true);
    this.props.API.GetOrganizations(
      this.props.user.isGod,
      this.props.user.isAdmin,
      this.props.user.organizationId
    )
      .then(responseOrganizations => {
        if (responseOrganizations.error === undefined) {
          this.props.API.GetFromOrg(
            "BlogCategories",
            this.props.user.isGod,
            this.props.user.isAdmin,
            this.props.user.organizationId
          )
            .then(responseBlogCategories => {
              systemLog(this, responseBlogCategories);
              if (responseBlogCategories.error === undefined) {
                this.props.API.GetFromOrg(
                  "BlogTags",
                  this.props.user.isGod,
                  this.props.user.isAdmin,
                  this.props.user.organizationId
                )
                  .then(responseBlogTags => {
                    systemLog(this, responseBlogTags);
                    if (responseBlogTags.error === undefined) {
                      this.props.setOrganizations(responseOrganizations);
                      this.props.setBlogCategories(responseBlogCategories);
                      this.props.setBlogTags(responseBlogTags);
                      this.setState({ refreshDB: !this.state.refreshDB });
                      this.checkData();
                      this.checkLoading(false);
                    } else {
                      this.checkLoading(false);
                    }
                  })
                  .catch(error => {
                    this.checkLoading(false);
                  });
              } else {
                this.checkLoading(false);
              }
            })
            .catch(error => {
              this.checkLoading(false);
            });
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.blogEntries}
        bdConnectedReturn={response => {
          systemLog(this, response);
          this.props.setBlogEntries(response);
        }}
        checkBeforeSave={(finalObjectSave, currentTableToChange) => {
          finalObjectSave.url = setURLType(finalObjectSave.name);
          return true;
        }}
        showLanguage={true}
        bdConnected={true}
        bdWhere={this.whereBDData}
        bdConnectInclude={["organization", "blogTags", "blogCategory"]}
        bdLimitItems={30}
        bdDoRefresh={this.state.refreshDB}
        doPagination={true}
        tableParentIdConnect={this.tableParentIdConnect}
        tableParentId={this.tableParentId}
        tableViewItemData={BlogEntriesData.tableViewItemData}
        tableEditItemData={BlogEntriesData.tableEditItemData}
        tableCreateItemData={BlogEntriesData.tableCreateItemData}
        tableData={BlogEntriesData.tableData}
        tableSysName={"BlogEntries"}
        tablePermName={"Blog"}
        tableName={"Entradas de Blog"}
        singleName={"Entradas de Blog"}
        tableSubtitle={"Administrador de Entradas de Blog"}
        filterTable={BlogEntriesData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshStates();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { blogEntries, user, organizations, userPermissions } = data;
  return { API, blogEntries, user, organizations, userPermissions };
};

const mapDispatchToProps = dispatch => {
  return {
    setBlogEntries: blogEntries => {
      dispatch(setBlogEntries(blogEntries));
    },
    setBlogCategories: blogCategories => {
      dispatch(setBlogCategories(blogCategories));
    },
    setBlogTags: blogTags => {
      dispatch(setBlogTags(blogTags));
    },
    setOrganizations: organizations => {
      dispatch(setOrganizations(organizations));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BlogEntriesTable)
);
