import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PagePermCheck from "../../layaouts/PagePermCheck";
import AdminContent from "../../layaouts/AdminContent";
import BaseTable from "../../BaseTables/Project/BaseTable";
import { systemLog } from "../../scripts/General";

class BasePage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
    this.renderBaseTable = this.renderBaseTable.bind(this);
  }

  componentWillMount() {
    systemLog(this, this.props);
  }

  renderBaseTable() {
    if (this.props.routeData.baseTable !== undefined) {
      const CustomBaseTable = this.props.routeData.baseTable;
      return (
        <CustomBaseTable
          dataTable={this.props.routeData.dataTable}
          checkLoading={doit => {
            this.setState({ loadingUserTable: doit });
          }}
        />
      );
    } else {
      return (
        <BaseTable
          dataTable={this.props.routeData.dataTable}
          checkLoading={doit => {
            this.setState({ loadingUserTable: doit });
          }}
        />
      );
    }
  }

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingUserTable}
          pageName={this.props.routeData.dataTable.basePageInfo.tablePluralName}
          pageSubtitle={
            "Administrador de " +
            this.props.routeData.dataTable.basePageInfo.tablePluralName
          }
        >
          {this.renderBaseTable()}
        </AdminContent>
      </PagePermCheck>
    );
  }
}

export default withRouter(
  connect(
    null,
    {}
  )(BasePage)
);
