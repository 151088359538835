import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AdminCreateItem from "../../layaouts/AdminCreateItem";
import AdminEditItem from "../../layaouts/AdminEditItem";
import AdminTableComparation from "../../layaouts/AdminTableComparation";
import { systemLog } from "../../scripts/General";
import * as RoleModulesData from "../../TablesData/roleModulesDataTables";
import { godRole, adminRole } from "../../MainInfoData";
import { toast } from "react-toastify";

class RolesModifyItem extends Component {
  constructor(props) {
    super(props);
    this.currentData = this.props.table;
    this.currentValueData = [];
    this.renderMainView = this.renderMainView.bind(this);
    this.renderPerm = this.renderPerm.bind(this);
    this.saveRoleModules = this.saveRoleModules.bind(this);
    this.customRenderData = {};
  }

  componentWillMount() {
    this.customRenderData = {
      perm: this.renderPerm
    };
  }

  saveRoleModules(roleId) {
    systemLog(this, roleId);
    for (let i = 0; i < this.currentValueData.length; i++) {
      const currentRelation = this.currentValueData[i];
      currentRelation.roleId = roleId;
      if (
        currentRelation.roleId !== undefined &&
        currentRelation.roleId !== ""
      ) {
        if (currentRelation.id !== undefined && currentRelation.id !== "") {
          this.props.API.PatchTo(
            "RolesModules",
            currentRelation.id,
            currentRelation
          )
            .then(response => {})
            .catch(error => {});
        } else {
          this.props.API.PostTo("RolesModules", currentRelation)
            .then(response => {})
            .catch(error => {});
        }
      }
    }
  }

  renderPerm() {
    return (
      <AdminTableComparation
        useBase={true}
        base={this.currentData}
        returnValue={valueData => {
          this.currentValueData = valueData.slice(0);
          systemLog(this, this.currentValueData);
        }}
        compareWith={this.props.user.role}
        tableSysName={"RolesModules"}
        tableName={"PERMISOS"}
        tablePermName={"Privilegios de usuarios"}
        tableSubtitle={"Administrador de Permisos"}
        tableSelector={this.props.roles}
        selectorRelation={"roleId"}
        tableSelectorDataName={"namePublic"}
        tableSelectorPlaceholder={"Selecciona un Rol"}
        tableComparator={this.props.modules}
        tableComparatorDataName={RoleModulesData.comparatorDataName}
        comparatorRelation={"moduleId"}
        tableRelation={this.props.roleModules}
        tableData={RoleModulesData.tableData}
        filterAllThatApply={false}
        filterTable={RoleModulesData.filterTable}
        clickOnSave={() => {
          this.refreshUsers();
        }}
        forceSave={true}
      />
    );
  }

  renderMainView() {
    if (this.props.isEdit === true) {
      return (
        <AdminEditItem
          table={this.props.table}
          checkBeforeSave={(finalObjectSave, currentTableToChange) => {
            if (this.props.user.isGod === false) {
              if (
                currentTableToChange.name.toUpperCase() ===
                  adminRole.toUpperCase() ||
                currentTableToChange.name.toUpperCase() ===
                  godRole.toUpperCase()
              ) {
                toast.error(
                  "NO ES POSIBLE CREAR UN ROL CON ESE NOMBRE PRIVADO"
                );
                return false;
              }
            }
            return true;
          }}
          afterEditObj={editedRole => {
            systemLog(this, this.currentValueData);
            systemLog(this, editedRole);
            this.saveRoleModules(editedRole.id);
          }}
          customRenderData={this.customRenderData}
          showLanguage={this.props.showLanguage}
          tableEditRoute={this.props.tableEditRoute}
          tableData={this.props.tableData}
          tableParentIdConnect={this.props.tableParentIdConnect}
          tableParentId={this.props.tableParentId}
          tableSysName={this.props.tableSysName}
          deleteElement={this.props.deleteElement}
          clickOnSave={this.props.clickOnSave}
          returnEditObj={this.props.returnEditObj}
          forceSave={true}
        />
      );
    } else {
      return (
        <AdminCreateItem
          tableData={this.props.tableData}
          checkBeforeSave={(finalObjectSave, currentTableToChange) => {
            if (this.props.user.isGod === false) {
              if (
                currentTableToChange.name.toUpperCase() ===
                  adminRole.toUpperCase() ||
                currentTableToChange.name.toUpperCase() ===
                  godRole.toUpperCase()
              ) {
                toast.error(
                  "NO ES POSIBLE CREAR UN ROL CON ESE NOMBRE PRIVADO"
                );
                return false;
              }
            }
            return true;
          }}
          customRenderData={this.customRenderData}
          showLanguage={this.props.showLanguage}
          tableCreateRoute={this.props.tableCreateRoute}
          tableParentIdConnect={this.props.tableParentIdConnect}
          tableParentId={this.props.tableParentId}
          tableSysName={this.props.tableSysName}
          singleName={this.props.singleName}
          deleteElement={this.props.deleteElement}
          customAfterCreate={(createdRole, tableSend) => {
            systemLog(this, this.currentValueData);
            systemLog(this, createdRole);
            this.saveRoleModules(createdRole.id);
            this.props.customAfterCreate(createdRole, tableSend);
          }}
          returnCreateObj={this.props.returnCreateObj}
          forceSave={true}
        />
      );
    }
  }

  render() {
    return (
      <div
        style={{
          flexDirection: "column",
          width: "100%",
          height: "100%"
        }}
      >
        {this.renderMainView()}
      </div>
    );
  }
}

const mapStateToProps = ({ api, data }) => {
  const { API } = api;
  const { user, roles, modules, roleModules, organizations } = data;
  return { API, user, roles, modules, roleModules, organizations };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(RolesModifyItem)
);
