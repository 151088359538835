import 'semantic-ui-css/semantic.min.css';

import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import createRootReducer from './redux/reducers';
import { datePickerTheme } from './scripts/System';
import SetDataComponent from './SetDataComponent';

/* eslint-disable */
// pick utils
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Create a history of your choosing (we're using a browser history in this case)
    this.history = createHistory({ basename: '/admin' });

    this.store = createStore(
      createRootReducer(this.history), // new root reducer with router state
      compose(
        applyMiddleware(
          thunk,
          routerMiddleware(this.history) // for dispatching history actions
          // ... other middlewares ...
        )
      )
    );

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={datePickerTheme}>
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/icon?family=Material+Icons'
          />
          <Provider store={this.store}>
            <ConnectedRouter history={this.history}>
              <SetDataComponent />
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
