export const tableEditItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "pageId",
      dataLabel: "Pagina",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "pages",
        key: "id",
        value: "id",
        text: "publicName"
      },
      dataType: "selector"
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "content",
      dataLabel: "Contenido",
      dataType: "string",
      canChangeLanguage: true
    },
    {
      dataName: "useImgURL",
      dataLabel: "Usar Imagen",
      dataType: "bool"
    },
    {
      onlyShowIf: [
        {
          dataName: "useImgURL",
          value: true
        }
      ],
      dataName: "urImage",
      dataLabel: "URL de Imagen",
      dataType: "string",
      notRequired: true,
      canChangeLanguage: true
    },
    {
      dataName: "isProperty",
      dataLabel: "Es tipo Propiedad",
      dataType: "bool"
    }
  ]
};

export const tableEditAdminItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "content",
      dataLabel: "Contenido",
      dataType: "string",
      canChangeLanguage: true
    },
    {
      onlyShowIf: [
        {
          dataName: "useImgURL",
          value: true
        }
      ],
      notRequired: true,
      dataName: "urImage",
      dataLabel: "URL de Imagen",
      dataType: "string",
      canChangeLanguage: true
    }
  ]
};

export const tableCreateItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "pageId",
      dataLabel: "Pagina",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "pages",
        key: "id",
        value: "id",
        text: "publicName"
      },
      dataType: "selector"
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "content",
      dataLabel: "Contenido",
      dataType: "string",
      canChangeLanguage: true
    },
    {
      dataName: "useImgURL",
      dataLabel: "Usar Imagen",
      notRequired: true,
      dataType: "bool"
    },
    {
      onlyShowIf: [
        {
          dataName: "useImgURL",
          value: true
        }
      ],
      dataName: "urImage",
      dataLabel: "URL de Imagen",
      notRequired: true,
      dataType: "string",
      canChangeLanguage: true
    },
    {
      dataName: "isProperty",
      dataLabel: "Es tipo Propiedad",
      dataType: "bool"
    }
  ]
};

export const tableViewItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "page",
      dataType: "insideModule",
      dataLabel: "Pagina",
      dataModule: {
        dataName: "publicName",
        dataLabel: "Pagina",
        dataType: "string"
      }
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "content",
      dataLabel: "Contenido",
      dataType: "string"
    },
    {
      dataName: "useImgURL",
      dataLabel: "Usar Imagen",
      notRequired: true,
      dontShow: true,
      dataType: "bool"
    },
    {
      dataName: "urImage",
      dataLabel: "URL de Imagen",
      dataType: "string"
    },
    {
      dataName: "isProperty",
      dataLabel: "Es tipo Propiedad",
      dataType: "bool"
    }
  ]
};

export const tableViewAdminItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "page",
      dataType: "insideModule",
      dataLabel: "Pagina",
      dataModule: {
        dataName: "publicName",
        dataLabel: "Pagina",
        dataType: "string"
      }
    },
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "content",
      dataLabel: "Contenido",
      dataType: "string"
    },
    {
      dataName: "urImage",
      dataLabel: "URL de Imagen",
      dataType: "string"
    }
  ]
};

export const tableData = [
  {
    dataName: "page",
    dataType: "insideModule",
    dataLabel: "Pagina",
    dataModule: {
      dataName: "publicName",
      dataLabel: "Pagina",
      dataType: "string"
    }
  },
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string"
  },
  {
    dataName: "content",
    dataLabel: "Contenido",
    dataType: "string"
  },
  {
    dataName: "urImage",
    dataLabel: "URL de Imagen",
    dataType: "string"
  },
  {
    dataName: "isProperty",
    dataLabel: "Es tipo Propiedad",
    dataType: "bool"
  }
];

export const tableAdminData = [
  {
    dataName: "page",
    dataType: "insideModule",
    dataLabel: "Pagina",
    dataModule: {
      dataName: "publicName",
      dataLabel: "Pagina",
      dataType: "string"
    }
  },
  {
    dataName: "content",
    dataLabel: "Contenido",
    dataType: "string"
  },
  {
    dataName: "urImage",
    dataLabel: "URL de Imagen",
    dataType: "string"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataType: "string"
  },
  {
    dataName: "content",
    dataType: "string"
  },
  {
    dataName: "urImage",
    dataType: "string"
  },
  {
    dataName: "isProperty",
    dataLabel: "propiedad",
    dataType: "bool"
  }
];
