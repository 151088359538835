export const tableData = [
  {
    dataName: "read",
    dataLabel: "Ver",
    dataType: "bool",
    canBeEdited: true
  },
  {
    dataName: "create",
    dataLabel: "Crear",
    dataType: "bool",
    canBeEdited: true
  },
  {
    dataName: "update",
    dataLabel: "Editar",
    dataType: "bool",
    canBeEdited: true
  },
  {
    dataName: "delete",
    dataLabel: "Eliminar",
    dataType: "bool",
    canBeEdited: true
  }
];

export const comparatorDataName = {
  dataName: "name",
  dataLabel: "Nombre del Modulo",
  dataType: "string"
};

export const filterTable = [
  {
    dataName: "name",
    dataType: "string"
  }
];
