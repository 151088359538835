import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { styleBasic } from "../style/index";
import GridForContent from "./GridForContent";

class ContentBackground extends Component {
  constructor(props) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
  }

  handleItemClick(name) {
    this.setState({ activeItem: name });
  }

  renderChildren() {
    if (this.props.useGrid === false) {
      return (
        <div
          style={{
            display: "-webkit-flex",
            WebkitAlignContent: this.props.contentAlign || "flex-start",
            WebkitAlignSelf: this.props.contentAlign || "flex-start",
            WebkitAlignItems: this.props.contentAlign || "flex-start",
            display: "flex",
            alignSelf: this.props.contentAlign || "flex-start",
            alignItems: this.props.contentAlign || "flex-start",
            alignContent: this.props.contentAlign || "flex-start",
            justifyContent: this.props.contentAlign || "flex-start",
            verticalAlign: "middle",
            width: this.props.width || "100%",
            minHeight: this.props.height || 371,
            ...this.props.contentStyle
          }}
        >
          {this.props.children}
        </div>
      );
    } else {
      return (
        <GridForContent
          contentStyle={{
            width: this.props.width || "100%",
            minHeight: this.props.height || 371,
            ...this.props.contentStyle
          }}
          verticalAlign="middle"
          align={this.props.contentAlign || "left"}
          viewWidth={this.props.viewWidth || 12}
        >
          <div
            style={{
              display: "-webkit-flex",
              WebkitAlignContent: this.props.contentAlign || "flex-start",
              WebkitAlignSelf: this.props.contentAlign || "flex-start",
              WebkitAlignItems: this.props.contentAlign || "flex-start",
              display: "flex",
              alignSelf: this.props.contentAlign || "flex-start",
              alignItems: this.props.contentAlign || "flex-start",
              alignContent: this.props.contentAlign || "flex-start",
              justifyContent: this.props.contentAlign || "flex-start",
              verticalAlign: "middle",
              backgroundColor: "transparent",
              width: "100%",
              height: "100%"
            }}
          >
            {this.props.children}
          </div>
        </GridForContent>
      );
    }
  }

  render() {
    let borderRadiusStyle = {};

    if (this.props.borderTopRadius !== undefined) {
      borderRadiusStyle = {
        borderTopLeftRadius: this.props.borderTopRadius,
        borderTopRightRadius: this.props.borderTopRadius
      };
    }
    if (this.props.borderBottomRadius !== undefined) {
      borderRadiusStyle = {
        borderBottomRightRadius: this.props.borderBottomRadius,
        borderBottomLeftRadius: this.props.borderBottomRadius
      };
    }
    if (this.props.borderLeftRadius !== undefined) {
      borderRadiusStyle = {
        borderTopLeftRadius: this.props.borderLeftRadius,
        borderBottomLeftRadius: this.props.borderLeftRadius
      };
    }
    if (this.props.borderRightRadius !== undefined) {
      borderRadiusStyle = {
        borderBottomRightRadius: this.props.borderRightRadius,
        borderTopRightRadius: this.props.borderRightRadius
      };
    }
    if (this.props.borderRadius !== undefined) {
      borderRadiusStyle = {
        borderRadius: this.props.borderRadius
      };
    }
    return (
      <div
        style={{
          ...styleBasic,
          width: this.props.width || "100%",
          minHeight: this.props.height || 371,
          backgroundColor: this.props.backgroundColor || "transparent",
          backgroundImage: "url(" + this.props.background + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          ...borderRadiusStyle
        }}
      >
        <div
          style={{
            ...styleBasic,
            width: this.props.width || "100%",
            minHeight: this.props.height || 371,
            backgroundColor: this.props.backgroundTint || "transparent",
            backgroundSize: "cover",
            backgroundPosition: "center",
            ...borderRadiusStyle
          }}
        >
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    {}
  )(ContentBackground)
);
