// Import package from node modules
import * as types from "../types";

export const setEmailData = emailData => {
  return {
    type: types.SET_EMAIL_DATA,
    emailData: emailData
  };
};

export const setEmails = emails => {
  return {
    type: types.SET_EMAILS,
    emails: emails
  };
};

export const setAnyData = (key, payload) => {
  return {
    type: types.SET_ANY_DATA,
    key: key,
    payload: payload
  };
};

export const setLeads = leads => {
  return {
    type: types.SET_CURRENT_LEADS,
    leads: leads
  };
};
