import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal, Grid, Button } from "semantic-ui-react";
import { textSizePTitle, styleCenteredObject, colors } from "../style";
import { renderData } from "../scripts/System";
import ModalComponent from "../layaouts/ModalComponent";

const columnSize = "2rem";
const columnStyle = {
  ...styleCenteredObject,
  margin: 0,
  padding: 0,
  justifyContent: "flex-start",
  minHeight: columnSize,
  marginLeft: "0.5rem"
};

class ModalATDeleteItems extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let totalItems = [];
    if (this.props.itemsToDelete !== undefined) {
      totalItems = this.props.itemsToDelete;
    }
    return (
      <ModalComponent
        width={"98%"}
        onOpen={() => {}}
        closeModal={() => {
          this.props.closeModal();
        }}
        style={{ minHeight: "10rem" }}
        showModal={this.props.showModal || false}
      >
        <Modal.Header>
          <p>{"Eliminar Elementos"}</p>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{"Se eliminaran los siguientes elementos: "}</p>
          </Modal.Description>
          <div style={{ height: "1.5rem" }} />
          <div style={{ maxHeight: 600, overflowX: "scroll" }}>
            {totalItems.map((table, index) => {
              let styleExtraGrid = {
                backgroundColor: colors.adminTableItemMainColor,
                borderTop: "2px solid " + colors.adminTableItemBorderColor,
                borderBottom: "2px solid " + colors.adminTableItemBorderColor
              };
              if ((index + 1) % 2 === 0) {
                styleExtraGrid = {
                  backgroundColor: colors.adminTableItemSecondaryColor
                };
              }
              return (
                <Grid
                  key={index}
                  style={{
                    ...styleExtraGrid,
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                  }}
                  columns="equal"
                >
                  {this.props.tableData.map((data, columnIndex) => {
                    return (
                      <Grid.Column key={columnIndex} style={columnStyle}>
                        <div
                          style={{
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                            width: "100%"
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              textAlign: "left",
                              fontSize: textSizePTitle
                            }}
                          >
                            {renderData(
                              data,
                              table,
                              false,
                              this.props.formatter,
                              () => {},
                              false,
                              () => {}
                            )}
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  })}
                </Grid>
              );
            })}
          </div>
        </Modal.Content>
        <Modal.Content>
          <div
            style={{
              ...styleCenteredObject,
              justifyContent: "flex-end",
              flexDirection: "row"
            }}
          >
            <Button
              onClick={() => {
                this.props.closeModal();
              }}
              style={{
                backgroundColor: colors.removeColor,
                color: colors.removeFrontColor
              }}
              content={"CANCELAR"}
            />
            <div style={{ width: "1rem" }} />
            <Button
              onClick={() => {
                this.props.doDeleteAll();
              }}
              style={{
                backgroundColor: colors.buttonColor,
                color: colors.colorFrontButton
              }}
              content={"ACEPTAR"}
            />
          </div>
        </Modal.Content>
      </ModalComponent>
    );
  }
}

const mapStateToProps = ({ api }) => {
  const { formatter } = api;
  return { formatter };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ModalATDeleteItems)
);
