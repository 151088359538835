import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AdminContent from "../layaouts/AdminContent";
import AdminContentView from "../layaouts/AdminContentView";
import AdminEditItem from "../layaouts/AdminEditItem";
import PagePermCheck from "../layaouts/PagePermCheck";
import { adminRole, godRole } from "../MainInfoData";
import { setUser } from "../redux/actions";
import * as UserData from "../TablesData/userDataTables";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingUserTable: true };
    this.checkLoading = this.checkLoading.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
  }

  componentDidMount() {
    const orgTableEditItemData = UserData.tableEditItemData.dataVisual.find(
      data => data.dataName === "organizationId"
    );
    if (orgTableEditItemData !== undefined) {
      const index = UserData.tableEditItemData.dataVisual.indexOf(
        orgTableEditItemData
      );
      UserData.tableEditItemData.dataVisual.splice(index, 1);
    }

    const roleTableEditItemData = UserData.tableEditItemData.dataVisual.find(
      data => data.dataName === "roleId"
    );
    if (roleTableEditItemData !== undefined) {
      const index = UserData.tableEditItemData.dataVisual.indexOf(
        roleTableEditItemData
      );
      UserData.tableEditItemData.dataVisual.splice(index, 1);
    }
    this.refreshUsers();
  }

  checkLoading(doit) {
    this.setState({ loadingUserTable: doit });
  }

  refreshUsers() {
    this.checkLoading(true);
    this.props.API.GetUser(this.props.user.id)
      .then(responseUser => {
        if (responseUser.error === undefined) {
          if (responseUser.role.name.toUpperCase() === godRole.toUpperCase()) {
            responseUser.isGod = true;
          } else {
            responseUser.isGod = false;
          }
          if (
            responseUser.role.name.toUpperCase() === adminRole.toUpperCase()
          ) {
            responseUser.isAdmin = true;
          } else {
            responseUser.isAdmin = false;
          }
          this.props.setUser(responseUser);
          this.checkLoading(false);
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <PagePermCheck routeData={this.props.routeData}>
        <AdminContent
          onLoading={this.state.loadingUserTable}
          pageName={"Mi perfil"}
          pageSubtitle={"Administrador de Perfil"}
        >
          <AdminContentView>
            <AdminEditItem
              table={{ ...this.props.user }}
              tableData={UserData.tableEditItemData}
              tableSysName={"SysUsers"}
              clickOnSave={() => {
                this.refreshUsers();
              }}
            />
          </AdminContentView>
        </AdminContent>
      </PagePermCheck>
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { users, user, roles, organizations } = data;
  return { users, user, API, organizations, roles };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfilePage)
);
