import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Loader,
  Segment
} from "semantic-ui-react";
import LogoRender from "../components/LogoRender";
import { setAuthapiToken, setDispatch } from "../redux/actions";
import { replace } from "connected-react-router";
import { colors, styleCenteredObject } from "../style";
import API from "../API/API";
import { adminSysName } from "../MainInfoData";

let api = new API();
const resetPasswordFailGeneral = "Hubo un error, volver a intentar";
const resetPasswordFailText =
  "El token caduco, por favor volver seleccionar recuperar contraseña";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordCheck: false,
      currentError: "",
      currentErrorCode: "",
      currentPasswordConfirm: "",
      currentPassword: "",
      errorPassword: false,
      errorConfirmPassword: false
    };
    this.API = this.props.API;
    this.resetPassword = this.resetPassword.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderCode = this.renderCode.bind(this);
    this.id = "";
    this.token = "";
  }

  componentDidMount() {
    if (this.props.match.params !== undefined) {
      if (
        this.props.match.params.id !== "" &&
        this.props.match.params.token !== undefined &&
        this.props.match.params.token !== "" &&
        this.props.match.params.token !== undefined
      ) {
        this.id = this.props.match.params.id;
        this.token = this.props.match.params.token;
        return;
      }
    }
    this.props.replace("/login");
  }

  componentWillReceiveProps(nextProps) {
    this.API = nextProps.API;
  }

  resetPassword() {
    this.setState({
      resetPasswordCheck: true,
      currentError: "",
      currentErrorCode: "",
      errorPassword: false,
      errorConfirmPassword: false
    });

    let currentError = "";
    if (this.state.currentPassword === "") {
      currentError = "Debes colocar una contraseña";
      this.setState({
        currentError,
        errorPassword: true,
        errorConfirmPassword: true,
        resetPasswordCheck: false
      });
      return;
    }
    if (this.state.currentPassword !== this.state.currentPasswordConfirm) {
      currentError = "Contraseña no coincide";
      this.setState({
        currentError,
        errorPassword: true,
        errorConfirmPassword: true,
        resetPasswordCheck: false
      });
      return;
    }

    this.setState({ currentError });
    api = new API(this.token);
    api
      .PatchTo("SysUsers", this.id, {
        password: this.state.currentPassword
      })
      .then(response => {
        if (response.error !== undefined) {
          if (response.error.message !== undefined) {
            if (response.error.code.includes("AUTHORIZATION_REQUIRED")) {
              this.props.replace("/login");
              toast.error(resetPasswordFailText, { autoClose: false });
              return;
            }
          }
          if (
            response.error.statusCode !== undefined &&
            response.error.statusCode !== ""
          ) {
            this.setState({
              currentError: resetPasswordFailGeneral,
              currentErrorCode: response.error.statusCode,
              errorPassword: true,
              resetPasswordCheck: false
            });
          } else {
            this.setState({
              currentError: resetPasswordFailGeneral,
              currentErrorCode: "SIN CODIGO",
              errorPassword: true,
              resetPasswordCheck: false
            });
          }
        } else {
          sessionStorage.setItem("token", this.token);
          sessionStorage.setItem("id", response.id);
          this.props.setAuthapiToken(this.token);
        }
      })
      .catch(error => {
        if (error.message !== undefined) {
          if (error.code.includes("AUTHORIZATION_REQUIRED")) {
            this.props.replace("/login");
            toast.error(resetPasswordFailText, { autoClose: false });
            return;
          }
        }
        this.setState({
          currentError: resetPasswordFailGeneral,
          currentErrorCode: "SIN CODIGO",
          errorEmail: true,
          errorPassword: true,
          resetPasswordCheck: false
        });
      });
  }

  renderCode() {
    if (this.state.currentErrorCode !== "") {
      return (
        <div>
          <div style={{ height: "0.2rem" }} />
          <a style={{ color: colors.removeColor }}>
            {"CODIGO: " + this.state.currentErrorCode}
          </a>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderError() {
    if (this.state.currentError !== "") {
      return (
        <div>
          <div style={{ height: "1rem" }} />
          <a style={{ color: colors.removeColor }}>{this.state.currentError}</a>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            backgroundColor: colors.backgroundColorLogin,
            height: "100%",
            width: "100%"
          }}
          className="login-form"
        >
          <Dimmer active={this.state.resetPasswordCheck}>
            <Loader active={this.state.resetPasswordCheck} />
          </Dimmer>
          <style>{`
        body > div,
        body > div > div,
        body > div > div > div.resetPassword-form {
          height: 100%;
        }
      `}</style>
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 450 }}>
              <div style={{ ...styleCenteredObject }}>
                <LogoRender
                  useNormalLogo={true}
                  logoSizeW={"20rem"}
                  logoSizeH={"10rem"}
                  logoWidthContainer={"16rem"}
                  renderName={false}
                  aling={"center"}
                />
              </div>
              <Header
                as="h2"
                style={{
                  marginTop: "0px",
                  color: colors.frontColorLogin,
                  fontWeight: "normal"
                }}
                textAlign="center"
              >
                {adminSysName}
              </Header>
              <Header
                as="h4"
                style={{
                  marginTop: "0px",
                  color: colors.frontColorLogin,
                  fontWeight: "normal"
                }}
                textAlign="center"
              >
                {"RECUPERAR CONTRASEÑA"}
              </Header>
              <Form size="large">
                <Segment stacked>
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Contraseña"
                    type={"password"}
                    name={"password"}
                    error={this.state.errorPassword}
                    onChange={e => {
                      this.setState({ currentPassword: e.target.value });
                    }}
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Contraseña"
                    type={"password"}
                    name={"password"}
                    error={this.state.errorConfirmPassword}
                    onChange={e => {
                      this.setState({ currentPasswordConfirm: e.target.value });
                    }}
                  />

                  <Button
                    onClick={() => {
                      this.props.clickOnReset();
                      this.resetPassword();
                    }}
                    style={{
                      backgroundColor: colors.buttonColor,
                      color: colors.colorFrontButton
                    }}
                    fluid
                    size="large"
                  >
                    Cambiar
                  </Button>
                  {this.renderError()}
                  {this.renderCode()}
                </Segment>
              </Form>
              {/*
          <Message>
            New to us? <a href="#">Sign Up</a>
          </Message>
            */}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDispatch: () => {
      dispatch(setDispatch(dispatch));
    },
    replace: (location, state) => {
      dispatch(replace(location, state));
    },
    setAuthapiToken: token => {
      dispatch(setAuthapiToken(token));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ResetPasswordPage)
);
