import React, { Component } from "react";
import { colors, styleBasic, styleCenteredObject } from "../style/index";

class LetterGoogleStyle extends Component {
  constructor(props) {
    super(props);
    this.renderLetter = this.renderLetter.bind(this);
  }

  renderLetter() {
    return (
      <div
        style={{
          ...styleBasic,
          ...styleCenteredObject,
          backgroundColor: this.props.backgroundColor || colors.logoColor,
          width: "100%",
          height: "100%",
          borderRadius: 20
        }}
      >
        <p
          style={{
            ...styleBasic,
            ...styleCenteredObject,
            color: this.props.colorLetter || colors.logoColorLetter,
            width: "100%",
            height: "100%",
            fontSize: this.props.fontSize || "1.3rem"
          }}
        >
          {this.props.letter || "G"}
        </p>
      </div>
    );
  }

  render() {
    return this.renderLetter();
  }
}

export default LetterGoogleStyle;
