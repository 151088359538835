import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setModules } from "../redux/actions";
import * as ModulesData from "../TablesData/modulesDataTables";
import AdminTable from "../layaouts/AdminTable";

class ModulesTable extends Component {
  constructor(props) {
    super(props);
    this.refreshModules = this.refreshModules.bind(this);
  }

  componentDidMount() {
    this.refreshModules();
  }

  checkLoading(doit) {
    if (this.props.checkLoading !== undefined) {
      this.props.checkLoading(doit);
    }
  }

  refreshModules() {
    this.checkLoading(true);
    this.props.API.GetModules(this.props.user.isGod, this.props.userPermissions)
      .then(responseModules => {
        if (responseModules.error === undefined) {
          this.props.setModules(responseModules);
          this.checkLoading(false);
        } else {
          this.checkLoading(false);
        }
      })
      .catch(error => {
        this.checkLoading(false);
      });
  }

  render() {
    return (
      <AdminTable
        table={this.props.modules}
        tableViewItemData={ModulesData.tableViewItemData}
        tableEditItemData={ModulesData.tableEditItemData}
        tableCreateItemData={ModulesData.tableCreateItemData}
        tableData={ModulesData.tableData}
        tableSysName={"Modules"}
        tablePermName={"Módulos"}
        tableName={"Modulos"}
        singleName={"Modulo"}
        tableSubtitle={"Administrador de Modulos"}
        filterTable={ModulesData.filterTable}
        filterAllThatApply={false}
        refreshTable={() => {
          this.refreshModules();
        }}
      />
    );
  }
}

const mapStateToProps = ({ data, api }) => {
  const { API } = api;
  const { modules, user, userPermissions } = data;
  return { API, modules, user, userPermissions };
};

const mapDispatchToProps = dispatch => {
  return {
    setModules: modules => {
      dispatch(setModules(modules));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModulesTable)
);
