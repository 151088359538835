import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { replace } from "connected-react-router";

class PagePermCheck extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.routeData.perm !== "DASHBOARD") {
      let sendHome = true;
      const userperm = this.props.userPermissions.find(permission => {
        if (permission.module === undefined) {
          return false;
        }
        return (
          permission.module.name.toUpperCase() ===
          this.props.routeData.perm.toUpperCase()
        );
      });

      if (userperm !== undefined) {
        if (userperm.read === true) {
          sendHome = false;
        } else if (userperm.update === true) {
          sendHome = false;
        }
      }

      if (this.props.onlyIfGod === true) {
        if (this.props.user.isGod === true) {
          sendHome = false;
        } else {
          sendHome = true;
        }
      }

      if (this.props.user.isGod === true) {
        sendHome = false;
      }
      if (sendHome === true) {
        this.props.replace("/");
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

const mapStateToProps = ({ data }) => {
  const { user, userPermissions } = data;
  return { userPermissions, user };
};

const mapDispatchToProps = dispatch => {
  return {
    replace: (location, state) => {
      dispatch(replace(location, state));
    }
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PagePermCheck)
);
