const dataTitle = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

const baseImageNameSet = [
  {
    from: "text",
    value: "blog"
  },
  {
    from: "object",
    key: "name"
  }
];

const dataVisualNecesary = [
  {
    dataName: "organizationId",
    dataLabel: "Organizacion",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "organizations",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  },
  {
    dataName: "blogCategoryId",
    dataLabel: "Categoria",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "blogCategories",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  },
  {
    dataName: "blogTags",
    dataLabel: "Tags",
    dataType: "array",
    arrayFileAdd: "Tag",
    arrayUniqueValue: true,
    arrayDataType: {
      dataLabel: "Tag",
      dataType: "selector",
      selectorData: [],
      selectorDataFrom: {
        dataFrom: "props",
        dataName: "blogTags",
        key: "id",
        value: "id",
        customText: ["name"]
      }
    },
    relation: {
      relationName: "blogTags"
    },
    notRequired: true
  },
  {
    dataName: "name",
    dataLabel: "Nombre (Privado)",
    dataType: "string"
  },
  {
    dataName: "title",
    dataLabel: "Titulo",
    dataType: "string"
  },
  {
    dataName: "description",
    dataLabel: "Descripcion",
    dataType: "string"
  },
  {
    dataName: "thumbnail",
    dataLabel: "Imagen de Miniatura",
    dataType: "image",
    dataImageNameSet: {
      separationText: "_",
      nameSet: [
        ...baseImageNameSet,
        {
          from: "text",
          value: "thumbnail"
        }
      ]
    }
  },
  {
    dataName: "body",
    dataLabel: "Entrada",
    dataType: "richText"
  },
  {
    dataName: "gallery",
    dataLabel: "Galeria",
    dataType: "array",
    arrayFileAdd: "Imagen",
    arrayDataType: {
      dataLabel: "Imagen",
      dataType: "image",
      dataImageNameSet: {
        separationText: "_",
        nameSet: [
          ...baseImageNameSet,
          {
            from: "text",
            value: "gallery"
          }
        ]
      }
    }
  }
];

const dataVisualNecesaryView = [
  {
    dataName: "name",
    dataLabel: "Nombre (Privado)",
    dataType: "string"
  },
  {
    dataName: "title",
    dataLabel: "Titulo",
    dataType: "string"
  },
  {
    dataName: "description",
    dataLabel: "Descripcion",
    dataType: "string"
  },
  {
    dataName: "thumbnail",
    dataLabel: "Imagen de Miniatura",
    dataType: "image",
    dataImageNameSet: {
      separationText: "_",
      nameSet: [
        ...baseImageNameSet,
        {
          from: "text",
          value: "thumbnail"
        }
      ]
    }
  },
  {
    dataName: "body",
    dataLabel: "Entrada",
    dataType: "richText"
  },
  {
    dataName: "gallery",
    dataLabel: "Galeria",
    dataType: "array",
    arrayFileAdd: "Imagen",
    arrayDataType: {
      dataLabel: "Imagen",
      dataType: "image",
      dataImageNameSet: {
        separationText: "_",
        nameSet: [
          ...baseImageNameSet,
          {
            from: "text",
            value: "gallery"
          }
        ]
      }
    }
  }
];

export const tableCreateItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesary]
};

export const tableEditItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesary]
};

export const tableViewItemData = {
  dataTitle,
  dataVisual: [...dataVisualNecesaryView]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string",
    dataFilterType: "like"
  }
];
