import mainScreensSystem from "./system";
import mainScreensLanding from "./landing";
import mainScreensProject from "./project";
import ProfilePage from "../pages/ProfilePage";

const profile = {
  route: "/profile",
  name: "Perfil",
  perm: "Usuarios",
  page: ProfilePage,
  showOnMenu: false
};

export const landingRoutes = () => {
  const finalRutes = [];
  for (let i = 0; i < mainScreensProject.length; i++) {
    const currentRoute = mainScreensProject[i];
    if (currentRoute.route.includes("/landing")) {
      finalRutes.push(currentRoute);
    }
  }

  for (let i = 0; i < mainScreensLanding.length; i++) {
    const currentRoute = mainScreensLanding[i];
    finalRutes.push(currentRoute);
  }
  return finalRutes;
};

export const sytemRoutes = () => {
  const finalRutes = [];
  for (let i = 0; i < mainScreensProject.length; i++) {
    const currentRoute = mainScreensProject[i];
    if (currentRoute.route.includes("/system")) {
      finalRutes.push(currentRoute);
    }
  }

  for (let i = 0; i < mainScreensSystem.length; i++) {
    const currentRoute = mainScreensSystem[i];
    finalRutes.push(currentRoute);
  }
  return finalRutes;
};

export const fullRutes = () => {
  const finalRutes = [];

  for (let i = 0; i < mainScreensProject.length; i++) {
    const currentRoute = mainScreensProject[i];
    finalRutes.push(currentRoute);
    if (currentRoute.pages !== undefined) {
      for (let x = 0; x < currentRoute.pages.length; x++) {
        const insideRoute = currentRoute.pages[x];
        finalRutes.push(insideRoute);
      }
    }
  }

  for (let i = 0; i < mainScreensSystem.length; i++) {
    const currentRoute = mainScreensSystem[i];
    finalRutes.push(currentRoute);
    if (currentRoute.pages !== undefined) {
      for (let x = 0; x < currentRoute.pages.length; x++) {
        const insideRoute = currentRoute.pages[x];
        finalRutes.push(insideRoute);
      }
    }
  }

  for (let i = 0; i < mainScreensLanding.length; i++) {
    const currentRoute = mainScreensLanding[i];
    finalRutes.push(currentRoute);
    if (currentRoute.pages !== undefined) {
      for (let x = 0; x < currentRoute.pages.length; x++) {
        const insideRoute = currentRoute.pages[x];
        finalRutes.push(insideRoute);
      }
    }
  }

  finalRutes.push(profile);
  return finalRutes;
};

export const getDataFromURL = routeName => {
  const currentRoutes = fullRutes();
  let returnData = {};
  const findData = currentRoutes.find(route => {
    return route.name === routeName;
  });
  if (findData !== undefined) {
    returnData = findData;
  }

  return returnData;
};

export default fullRutes;
