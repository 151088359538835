export const tableEditItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "isBase",
      dataLabel: "Es Base",
      dataType: "bool"
    }
  ]
};

export const tableCreateItemData = {
  dataTitle: [
    {
      dataName: "publicName",
      dataLabel: "Nombre",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "isBase",
      dataLabel: "Es Base",
      dataType: "bool"
    }
  ]
};

export const tableViewItemData = {
  dataTitle: [
    {
      dataName: "email",
      dataLabel: "Correo",
      dataType: "string"
    }
  ],
  dataVisual: [
    {
      dataName: "name",
      dataLabel: "Nombre Privado",
      dataType: "string"
    },
    {
      dataName: "publicName",
      dataLabel: "Nombre Publico",
      dataType: "string"
    },
    {
      dataName: "isBase",
      dataLabel: "Es Base",
      dataType: "bool"
    }
  ]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre Privado",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string"
  },
  {
    dataName: "isBase",
    dataLabel: "Es Base",
    dataType: "bool"
  }
];

export const tableAdminData = [
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataType: "string"
  },
  {
    dataName: "isBase",
    dataLabel: "base",
    dataType: "bool"
  }
];
