export const basePageInfo = {
  tableSingleName: "Idioma",
  tablePluralName: "Idiomas",
  tablePerm: "Web",
  tableSysName: "Languages",
  reducerDataName: "languagesTable",
  tableInclude: [],
  getDataFrom: []
};

const dataTitle = [
  {
    dataName: "publicName",
    dataType: "string"
  }
];

const dataVisualBelongs = [
  {
    dataName: "organizationId",
    dataLabel: "Organizacion",
    selectorData: [],
    selectorDataFrom: {
      dataFrom: "props",
      dataName: "organizations",
      key: "id",
      value: "id",
      text: "name"
    },
    dataType: "selector"
  }
];

const dataVisualBelongsView = [];

const dataVisualNecesary = [
  {
    dataName: "name",
    dataLabel: "Nombre Privado",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string"
  },
  {
    dataName: "active",
    dataLabel: "Activo",
    dataType: "bool"
  }
];

export const tableCreateItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongs, ...dataVisualNecesary]
};

export const tableEditItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongs, ...dataVisualNecesary]
};

export const tableViewItemData = {
  dataTitle,
  dataVisual: [...dataVisualBelongsView, ...dataVisualNecesary]
};

export const tableData = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string"
  },
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string"
  },
  {
    dataName: "active",
    dataLabel: "Activo",
    dataType: "bool"
  }
];

export const filterTable = [
  {
    dataName: "name",
    dataLabel: "Nombre",
    dataType: "string",
    dataFilterType: "like"
  },
  {
    dataName: "publicName",
    dataLabel: "Nombre Publico",
    dataType: "string",
    dataFilterType: "like"
  },
  {
    dataName: "active",
    dataLabel: "Activo",
    dataType: "bool"
  }
];
